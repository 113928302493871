import _ from 'lodash';

require('./field-record-list.scss');

export default FieldRecordListDirective;

// @ngInject
function FieldRecordListDirective() {
  return {
    restrict: 'E',
    scope: {
      showExpertProvider: '&showExpert',
      showDetailsProvider: '&showDetails',
      processingProvider: '&processing',
      timezone: '@',
      fieldRecordListProvider: '&fieldRecordList',
      protectionsWithTaxonProvider: '&protectionsWithTaxon',
      onShowDescriptionFn: '&onShowDescription',
      onRemoveFn: '&onRemove',
      canRemoveProvider: '&canRemove',
      onFieldRecordSelectedFn: '&onFieldRecordSelected',
      onValidateRecordFn: '&onValidateRecord',
    },
    bindToController: true,
    controller: FieldRecordListController,
    controllerAs: 'fieldRecordListCtrl',
    template: require('./field-record-list.html'),
    replace: true,
  };
}

// @ngInject
function FieldRecordListController(
  $scope,
  FieldRecordRepository,
  TaxonDetailsService,
  TaxonRepository,
  LxDialogService
) {
  var fieldRecordListCtrl = this;

  fieldRecordListCtrl.canEdit = true;
  fieldRecordListCtrl.isCordovaUser = isCordovaUser;
  fieldRecordListCtrl.showExpert = fieldRecordListCtrl.showExpertProvider();
  fieldRecordListCtrl.onFieldRecordSelected = onFieldRecordSelected;
  fieldRecordListCtrl.onShowDescription = onShowDescription;
  fieldRecordListCtrl.onValidateRecord = onValidateRecord;
  fieldRecordListCtrl.getProtectionInfos = getProtectionInfos;
  fieldRecordListCtrl.updateTxHeadcount = updateTxHeadcount;
  fieldRecordListCtrl.incrementtxHeadcount = incrementtxHeadcount;
  fieldRecordListCtrl.onSubmitHeadCount = onSubmitHeadCount;
  fieldRecordListCtrl.onRemove = onRemove;
  fieldRecordListCtrl.prevPage = prevPage;
  fieldRecordListCtrl.nextPage = nextPage;
  fieldRecordListCtrl.setPageIndex = setPageIndex;
  fieldRecordListCtrl.pagination = {
    limit: 50,
    current: 0,
    nbPages: 0,
    btns: [],
  };
  fieldRecordListCtrl.getStatusDisplay = TaxonDetailsService.getStatusDisplay;
  fieldRecordListCtrl.openTaxonStatus = openTaxonStatus;
  fieldRecordListCtrl.taxonPopupStatusData = {};

  let initItems = [];

  fieldRecordListCtrl.fieldRecordValidateState = {};
  $scope.$watch(fieldRecordListCtrl.canRemoveProvider, (canRemove) => (fieldRecordListCtrl.canRemove = canRemove));

  $scope.$watch(fieldRecordListCtrl.fieldRecordListProvider, (fieldRecordList) => {
    initItems = _.map(fieldRecordList, (fieldRecord) => {
      return fieldRecord.asMutable();
    });
    fieldRecordListCtrl.pagination.nbPages = Math.ceil(initItems.length / fieldRecordListCtrl.pagination.limit);
    fieldRecordListCtrl.pagination.btns = [];
    for (let index = 0; index < fieldRecordListCtrl.pagination.nbPages; index++) {
      fieldRecordListCtrl.pagination.btns.push(index);
    }
    setPage();
  });
  $scope.$watch(
    fieldRecordListCtrl.showDetailsProvider,
    (showDetails) => (fieldRecordListCtrl.showDetails = showDetails)
  );
  $scope.$watch(
    fieldRecordListCtrl.protectionsWithTaxonProvider,
    (protectionsWithTaxon) => (fieldRecordListCtrl.protectionsWithTaxon = protectionsWithTaxon)
  );
  $scope.$watch(fieldRecordListCtrl.processingProvider, (processing) => (fieldRecordListCtrl.processing = processing));

  function prevPage() {
    if (fieldRecordListCtrl.pagination.current <= 0) {
      return;
    }
    fieldRecordListCtrl.pagination.current--;
    setPage();
  }

  function nextPage() {
    if (fieldRecordListCtrl.pagination.current + 1 >= fieldRecordListCtrl.pagination.nbPages) {
      return;
    }
    fieldRecordListCtrl.pagination.current++;
    setPage();
  }

  function setPageIndex(index) {
    fieldRecordListCtrl.pagination.current = index;
    setPage();
  }

  function setPage() {
    fieldRecordListCtrl.fieldRecordList = initItems.filter((item, index) => {
      return (
        index >= fieldRecordListCtrl.pagination.current * fieldRecordListCtrl.pagination.limit &&
        index <
          fieldRecordListCtrl.pagination.limit +
            fieldRecordListCtrl.pagination.current * fieldRecordListCtrl.pagination.limit
      );
    });
    fieldRecordListCtrl.withGhosts = _.some(fieldRecordListCtrl.fieldRecordList, (fieldRecord) => fieldRecord.ghost);
  }

  function updateTxHeadcount(fieldRecord) {
    //can't update a field record if the user is not the creator of the observation and if the user has not a lock on the study
    FieldRecordRepository.updateHeadcount(fieldRecord.id, fieldRecord.txHeadcount);
  }

  function incrementtxHeadcount(fieldRecord) {
    ++fieldRecord.txHeadcount;
    updateTxHeadcount(fieldRecord);
  }

  function onSubmitHeadCount(fieldRecord) {
    updateTxHeadcount(fieldRecord);
    if (IS_CORDOVA) {
      window.cordova.plugins.Keyboard.close();
    }
  }

  function onRemove(fieldRecord) {
    fieldRecordListCtrl.onRemoveFn()(fieldRecord);
  }

  function onFieldRecordSelected(fieldRecord) {
    if (fieldRecord.ghost) {
      console.log('fieldRecord.ghost');
      return;
    }
    fieldRecordListCtrl.onFieldRecordSelectedFn()(fieldRecord.id);
  }

  function onShowDescription($event, fieldRecordId) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    fieldRecordListCtrl.onShowDescriptionFn()(fieldRecordId);
  }

  function onValidateRecord($event, fieldRecordId) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    fieldRecordListCtrl.onValidateRecordFn()(fieldRecordId, 1);
  }

  function getProtectionInfos(txRefType, txRefVersion, txKey) {
    if (!fieldRecordListCtrl.protectionsWithTaxon) {
      return [];
    }
    // Recherche pour cette version du référentiel
    return _.filter(fieldRecordListCtrl.protectionsWithTaxon, (protectionWithTaxon) => {
      return _.some(protectionWithTaxon.taxons, { txRefVersion, txRefType, txKey });
    });
  }

  function openTaxonStatus(fieldRecord) {
    var filter = fieldRecord.txRefFilter;
    if (!IS_CORDOVA) {
      filter = fieldRecord.txRefHistory.tx_ref_filter;
    }
    LxDialogService.open('dialog-taxon-statuses-in-fieldrecords');
    TaxonRepository.getTaxon(fieldRecord.txRefType, fieldRecord.txRefVersion, filter, fieldRecord.txKey).then(
      (taxon) => {
        fieldRecordListCtrl.taxonPopupStatusData = taxon;
      },
      (error) => {
        if (_.get(error, 'message', '').indexOf('no such table: taxon') < 0) {
          fieldRecordListCtrl.taxonPopupStatusData = { error };
        } else {
          fieldRecordListCtrl.taxonPopupStatusData = {
            error: {
              message: 'Le référentiel ' + fieldRecord.txRefType + ' semble absent.',
            },
          };
        }
      }
    );
  }

  function isCordovaUser() {
    return IS_CORDOVA;
  }
}
