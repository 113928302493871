import _ from 'lodash';

require('./picture-name-input.scss');

export default PictureNameDirective;

// @ngInject
function PictureNameDirective() {
  return {
    restrict: 'E',
    scope: {
      model: '=',
      name: '@',
      formProvider: '&form',
      placeholder: '@',
      fixedLabelProvider: '&fixedLabel',
      requiredProvider: '&required',
      onChangeFn: '&onChange',
      disabledProvider: '&disabled',
    },
    bindToController: true,
    controller: PictureNameController,
    controllerAs: 'pictureNameCtrl',
    template: require('./picture-name-input.html'),
    replace: true,
  };
}

// @ngInject
function PictureNameController($scope, LxNotificationService) {
  var pictureNameCtrl = this;

  pictureNameCtrl.onChange = onChange;
  pictureNameCtrl.clearValue = clearValue;
  pictureNameCtrl.currentValue = null;
  pictureNameCtrl.addNextPicture = addNextPicture;
  pictureNameCtrl.nextPictureName = 'IMGxxxx';

  $scope.$watch(pictureNameCtrl.disabledProvider, (disabled) => (pictureNameCtrl.disabled = disabled));
  $scope.$watch(pictureNameCtrl.requiredProvider, (required) => (pictureNameCtrl.required = required));
  $scope.$watch(pictureNameCtrl.fixedLabelProvider, (fixedLabel) => (pictureNameCtrl.fixedLabel = fixedLabel));
  $scope.$watch(pictureNameCtrl.formProvider, (form) => (pictureNameCtrl.form = form));

  $scope.$watchCollection(pictureNameCtrl.model, (model) => {
    if (typeof pictureNameCtrl.model == 'string') {
      try {
        pictureNameCtrl.model = JSON.parse(pictureNameCtrl.model);
      } catch (error) {
        pictureNameCtrl.model = [];
      }
    }
    if (!Array.isArray(pictureNameCtrl.model)) {
      pictureNameCtrl.model = [];
    }
    if (pictureNameCtrl.model && pictureNameCtrl.model.length > 0) {
      setNextPictureName();
    } else {
      pictureNameCtrl.model = [];
    }
  });

  function setNextPictureName() {
    var regexp = new RegExp('^(.*)(\\d)$', 'g');
    var match = regexp.exec(pictureNameCtrl.model[pictureNameCtrl.model.length - 1]);
    if (match && match[2]) {
      pictureNameCtrl.nextPictureName = match[1] + (parseInt(match[2]) + 1);
    } else {
      pictureNameCtrl.nextPictureName = pictureNameCtrl.model[pictureNameCtrl.model.length - 1] + 1;
    }
  }

  function onChange() {
    // pictureNameCtrl.model a été mis à jour avant l'appeler onChange
    pictureNameCtrl.onChangeFn() && pictureNameCtrl.onChangeFn()(pictureNameCtrl.model);
  }

  function clearValue(name) {
    pictureNameCtrl.model = _.remove(pictureNameCtrl.model, function(n) {
      return n != name;
    });

    onChange();

    //pictureNameCtrl.form[pictureNameCtrl.name].$setDirty();
  }

  function addNextPicture() {
    if (pictureNameCtrl.model.some((e) => e === pictureNameCtrl.nextPictureName)) {
      /* If picture name already exists, we do nothing */
      LxNotificationService.warning("Ce nom d'image existe déjà");
      setNextPictureName();
      return;
    }

    pictureNameCtrl.model.push(pictureNameCtrl.nextPictureName);
    setNextPictureName();
    onChange();
    if (pictureNameCtrl.form[pictureNameCtrl.name]) pictureNameCtrl.form[pictureNameCtrl.name].$setDirty();
  }
}
