import _ from 'lodash';

const EVENTS = {
  SETTINGS_UPDATE_SUCCESS: 'shu-settings.settings-update-success',
  SETTINGS_LOADING: 'shu-settings.settings-loading',
  SETTINGS_LOAD_SUCCESS: 'shu-settings.settings-load-success',
  SETTINGS_LOAD_ERROR: 'shu-settings.settings-load-error',
};

export default SettingsService;

// @ngInject
function SettingsService(SettingsRepository, $rootScope, LxNotificationService, $q) {
  return { onFormSubmit, load };

  function onFormSubmit(settings) {
    var fixedSettings = Object.assign({}, settings, {
      route_auth: fixUri(settings.route_auth),
      route_referentiel: fixUri(settings.route_referentiel),
      route_naturaliste: fixUri(settings.route_naturaliste),
      route_status: fixUri(settings.route_status),
    });
    SettingsRepository.store(fixedSettings).then(
      () => $rootScope.$emit(EVENTS.SETTINGS_UPDATE_SUCCESS, fixedSettings),
      (error) => {
        LxNotificationService.error('Une erreur est survenue à la sauvegarde des paramètres. ' + (error.data || ''));
      }
    );
    // XXX: Notifier l'utilisateur que tout s'est bien passé ?
  }

  function load() {
    $rootScope.$emit(EVENTS.SETTINGS_LOADING);

    return SettingsRepository.get().then(
      (settings) => {
        $rootScope.$emit(EVENTS.SETTINGS_LOAD_SUCCESS, settings);
      },
      (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des paramètres. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.SETTINGS_LOAD_ERROR, error);
      }
    );
  }

  function fixUri(uri) {
    if (uri.lastIndexOf('/') !== uri.length - 1) {
      return uri;
    }
    return uri.substr(0, uri.length - 1);
  }

}

export { EVENTS };
