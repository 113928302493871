const IMPORT_STEP = {
  CARTO: 'CARTO',
  STUDY: 'STUDY',
  SURVEY_TYPE: 'SURVEY_TYPE',
  FIELD_SURVEY: 'FIELD_SURVEY',
  FIELD_RECORD: 'FIELD_RECORD',
  VALUES: [
    'CARTO',
    'STUDY',
    'SURVEY_TYPE',
    'FIELD_SURVEY',
    'FIELD_RECORD'
  ]
};
const SYNC_STEP = {
  REMOTE_MEDIA: 'REMOTE_MEDIA',
  REMOTE_UPDATE: 'REMOTE_UPDATE',
  REMOTE_UNLOCK: 'REMOTE_UNLOCK',
  LOCAL_UPDATE: 'LOCAL_UPDATE',
  LOCAL_UNLOCK: 'LOCAL_UNLOCK',
  LOCAL_MBTILES: 'LOCAL_MBTILES',
  VALUES: [
    'REMOTE_MEDIA',
    'REMOTE_UPDATE',
    'REMOTE_UNLOCK',
    'LOCAL_UPDATE',
    'LOCAL_UNLOCK',
    'LOCAL_MBTILES'
  ]
};
const BIOTOPE_REF = {
  name: 'applicatif Biotope',
  key: 'BIOTOPE_DATA_REF'
};

export { IMPORT_STEP, SYNC_STEP, BIOTOPE_REF };
