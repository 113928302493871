import { NATURALISTE } from '../base/services/url-resolver.service';

export default {
  listEndpoint: {
    server: NATURALISTE,
    path: '/protocol'
  },
  protocolByKeyWithGroupsEndpoint: {
    server: NATURALISTE,
    path: '/protocol/{protocolKey}'
  }
};
