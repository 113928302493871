import { STUDY_EXPERT_LAYER_SOURCE } from '../model';

export default StudyLayerSourceFilter;

// @ngInject
function StudyLayerSourceFilter() {
  return function(input) {
    switch (input) {
      case STUDY_EXPERT_LAYER_SOURCE.SERVER:
        return 'Serveur';
      case STUDY_EXPERT_LAYER_SOURCE.LOCAL:
        return 'Local';
      default:
        return '-';
    }
  };
}

