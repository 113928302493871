import _ from 'lodash';

import { IMPORT_STEP } from '../model';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de sélection des études à importer
  STUDY_IMPORTER_LOADING: 'shu-synchro.study-importer-loading',
  STUDY_IMPORTER_LOAD_SUCCESS: 'shu-synchro.study-importer-load-success',
  STUDY_IMPORTER_LOAD_ERROR: 'shu-synchro.study-importer-load-error',
  // Actions lancées quand l'utilisateur affiche la page de sélection des études à importer
  STUDY_IMPORTER_IMPORT_STARTED: 'shu-synchro.study-importer-import-started',
  STUDY_IMPORTER_IMPORT_STEP_STARTED: 'shu-synchro.study-importer-import-step-started',
  STUDY_IMPORTER_IMPORT_STEP_DONE: 'shu-synchro.study-importer-import-step-done',
  STUDY_IMPORTER_IMPORT_STEP_FAILED: 'shu-synchro.study-importer-import-step-failed',
  STUDY_IMPORTER_IMPORT_REFERENTIEL_STARTED: 'shu-synchro.study-importer-import-referentiel-started',
  STUDY_IMPORTER_IMPORT_REFERENTIEL_DONE: 'shu-synchro.study-importer-import-referentiel-done',
  STUDY_IMPORTER_IMPORT_REFERENTIEL_FAILED: 'shu-synchro.study-importer-import-referentiel-failed',
  STUDY_IMPORTER_IMPORT_BIOTOPEREF_STARTED: 'shu-synchro.study-importer-import-biotoperef-started',
  STUDY_IMPORTER_IMPORT_BIOTOPEREF_DONE: 'shu-synchro.study-importer-import-biotoperef-done',
  STUDY_IMPORTER_IMPORT_BIOTOPEREF_FAILED: 'shu-synchro.study-importer-import-biotoperef-failed',
  STUDY_IMPORTER_IMPORT_TERMINATED: 'shu-synchro.study-importer-import-terminated',
  STUDY_IMPORTER_IMPORT_STUDY_CANCELED: 'shu-synchro.study-importer-import-study-canceled',
};

export default StudyImporterService;

// @ngInject
function StudyImporterService(
  $rootScope,
  StudyImporterStore,
  StudyRepository,
  SynchroRepository,
  SynchroRefRepository,
  $q,
  $timeout,
  LxNotificationService,
  StudyExpertLayerRepository,
  MapBackgroundsService
) {
  return { load, importStudies, importStudiesMap };

  function load(studiesWithImagerySets) {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_LOADING);

    $q.all([
      SynchroRepository.getStudyTxRefs(_.keys(studiesWithImagerySets)),
      SynchroRefRepository.getLastVersionTxRefs(),
      SynchroRepository.getLocalTxRefs(),
    ]).then(
      ([{ studies, studiesTxRefs }, txRefs, localTxRefs]) => {
        var processedTxRefs = _.map(txRefs, (txRef) => {
          var isTxRefMandatory = _.indexOf(studiesTxRefs, txRef.key) !== -1;
          var isLocal = _.some(localTxRefs, { key: txRef.key, version: txRef.version });
          return _.merge({ mandatory: isTxRefMandatory, local: isLocal }, txRef);
        });
        $rootScope.$emit(
          EVENTS.STUDY_IMPORTER_LOAD_SUCCESS,
          studies.map((study) => _.merge(study, { imagerySets: studiesWithImagerySets[study.id] })),
          processedTxRefs
        );
      },
      (error) => {
        LxNotificationService.error(
          "Une erreur est survenue au chargement de l'étude à importer. " + (error.data || '')
        );
        $rootScope.$emit(EVENTS.STUDY_IMPORTER_LOAD_ERROR);
      }
    );
  }

  function importStudies(studies, selectedTxRefs) {
    MapBackgroundsService.load();
    var studyIds = studies.map((study) => study.id);
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STARTED, selectedTxRefs, studyIds);

    // XXX: Gérer les erreurs ?
    processTxRefs(selectedTxRefs)
      .then(
        () => processBiotopeRef(),
        () => {
          $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_BIOTOPEREF_FAILED);
          return $q.reject();
        }
      )
      .then(
        () =>
          _.reduce(
            studies,
            (previousPromise, study) => {
              var studyId = study.id;
              return previousPromise
                .then(() => processSurveyTypeData(studyId))
                .then(() => processFieldSurveyData(studyId))
                .then(() => processFieldRecordData(studyId))
                .then(() => processStudyMap(studyId, study.imagerySets, true))
                .then(() => processStudyData(studyId, study.imagerySets))
                .then(null, () => {
                  $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STUDY_CANCELED, studyId);
                  return $q.when();
                });
            },
            $q.when(true)
          ),
        // Problème à l'import des référentiels
        () => cancelStudiesImport(studyIds)
      )
      .then(() => {
        $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_TERMINATED);
      });
  }

  function cancelStudiesImport(studyIds) {
    _.forEach(studyIds, (studyId) => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STUDY_CANCELED, studyId));
  }

  function importStudiesMap(studyId, imagerySetsToDownload, localImagerySets, refreshExpertLayers) {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STARTED, [], [studyId]);

    // XXX: Gérer les erreurs ?
    return processStudyMap(studyId, imagerySetsToDownload, refreshExpertLayers)
      .then(() => SynchroRepository.updateStudyImagerySets(studyId, _.union(imagerySetsToDownload, localImagerySets)))
      .then(() => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_TERMINATED));
  }

  function processStudyMap(studyId, imagerySets, refreshExpertLayers) {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_STARTED, studyId, IMPORT_STEP.CARTO);

    var promise = _.reduce(
      imagerySets,
      (previousPromise, imagerySet) => {
        return previousPromise.then(() => SynchroRepository.getMbTiles(studyId, imagerySet));
      },
      $q.when(true)
    ).then(() => SynchroRepository.getTerrUnit(studyId));
    if (refreshExpertLayers) {
      promise = promise.then(() => SynchroRepository.replaceStudyExpertLayers(studyId));
    }
    return promise.then(
      () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_DONE, studyId, IMPORT_STEP.CARTO),
      () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, studyId, IMPORT_STEP.CARTO)
    );
  }

  function processTxRefs(selectedTxRefs) {
    var oneFailed = false;
    return _.reduce(
      selectedTxRefs,
      (previousPromise, txRef) => {
        return previousPromise.then(() => {
          $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_REFERENTIEL_STARTED, txRef.key);

          return SynchroRepository.getTxRef(txRef).then(
            () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_REFERENTIEL_DONE, txRef.key, txRef),
            () => {
              $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_REFERENTIEL_FAILED, txRef.key, txRef);
              oneFailed = true;
            }
          );
        });
      },
      $q.when(true)
    ).then(() => {
      if (oneFailed) {
        return $q.reject();
      }
    });
  }

  function processStudyData(studyId, imagerySets) {
    if (!canLockStudy()) {
      $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, studyId, IMPORT_STEP.STUDY);
      return;
    }

    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_STARTED, studyId, IMPORT_STEP.STUDY);

    return SynchroRepository.lockStudy(studyId)
      .then(() => {
        return $q
          .all([
            SynchroRepository.getStudy(studyId),
            SynchroRepository.getProtocols(),
            SynchroRepository.getProtocolTxGroups(),
          ])
          .then(([study, protocols, protocolTxGroups]) => {
            return SynchroRepository.storeStudyData(_.merge(study, { imagerySets }), protocols, protocolTxGroups);
          });
      })
      .then(
        () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_DONE, studyId, IMPORT_STEP.STUDY),
        () => {
          $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, studyId, IMPORT_STEP.STUDY);
          return $q.reject();
        }
      );
  }

  function processSurveyTypeData(studyId) {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_STARTED, studyId, IMPORT_STEP.SURVEY_TYPE);

    return $q
      .all([SynchroRepository.getSurveyTypes(studyId), SynchroRepository.getSurveyTypeProtocolTxGroups(studyId)])
      .then(([surveyTypes, surveyTypeProtocolTxGroups]) => {
        return SynchroRepository.storeSurveyTypeData(studyId, surveyTypes, surveyTypeProtocolTxGroups);
      })
      .then(
        () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_DONE, studyId, IMPORT_STEP.SURVEY_TYPE),
        () => {
          $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, studyId, IMPORT_STEP.SURVEY_TYPE);
          return $q.reject();
        }
      );
  }

  function processFieldSurveyData(studyId) {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_STARTED, studyId, IMPORT_STEP.FIELD_SURVEY);

    return $q
      .all([SynchroRepository.getFieldSurveys(studyId), SynchroRepository.getFieldSurveyExtraTables(studyId)])
      .then(([fieldSurveys, extraTables]) => {
        return SynchroRepository.storeFieldSurveyData(studyId, fieldSurveys, extraTables);
      })
      .then(
        () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_DONE, studyId, IMPORT_STEP.FIELD_SURVEY),
        () => {
          $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, studyId, IMPORT_STEP.FIELD_SURVEY);
          return $q.reject();
        }
      );
  }

  function processFieldRecordData(studyId) {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_STARTED, studyId, IMPORT_STEP.FIELD_RECORD);

    return $q
      .all([SynchroRepository.getFieldRecords(studyId), SynchroRepository.getFieldRecordExtraTables(studyId)])
      .then(([fieldRecords, extraTables]) => {
        return SynchroRepository.storeFieldRecordData(studyId, fieldRecords, extraTables);
      })
      .then(
        () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_DONE, studyId, IMPORT_STEP.FIELD_RECORD),
        () => {
          $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, studyId, IMPORT_STEP.FIELD_RECORD);
          return $q.reject();
        }
      );
  }

  function processTerrUnitData(studyId) {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_STARTED, studyId, IMPORT_STEP.TERR_UNIT);

    return SynchroRepository.getTerrUnits(studyId)
      .then((terrUnits) => SynchroRepository.storeTerrUnitData(studyId, terrUnits))
      .then(
        () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_DONE, studyId, IMPORT_STEP.TERR_UNIT),
        () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, studyId, IMPORT_STEP.TERR_UNIT)
      );
  }

  function processBiotopeRef() {
    $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_BIOTOPEREF_STARTED);

    return SynchroRepository.getBiotopeRefs()
      .then((data) => SynchroRepository.storeBiotopeRefs(data))
      .then(
        () => $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_BIOTOPEREF_DONE),
        () => {
          $rootScope.$emit(EVENTS.STUDY_IMPORTER_IMPORT_BIOTOPEREF_FAILED);
          return $q.reject();
        }
      );
  }

  function canLockStudy() {
    return (
      StudyImporterStore.getSyncState().referentielsFailed.length === 0 &&
      _.without(StudyImporterStore.getSyncState().stepsFailed, IMPORT_STEP.CARTO).length === 0
    );
  }
}

export { EVENTS };
