import _ from 'lodash';

require('./field-survey-field-records-rp-form.scss');

export default FieldSurveyFieldRecordsRpFormDirective;

// @ngInject
function FieldSurveyFieldRecordsRpFormDirective() {
  return {
    restrict: 'E',
    scope: {
      strateProvider: '&strate',
      taxonProvider: '&taxon',
      isLoadingProvider: '&isLoading',
      isLoadedProvider: '&isLoaded',
      isProcessingProvider: '&isProcessing',
      canEditProvider: '&canEdit',
      onIsTxSureChangeFn: '&onIsTxSureChange',
      onAbondanceDominanceChangeFn: '&onAbondanceDominanceChange',
      onSociabiliteChangeFn: '&onSociabiliteChange',
      onShowDescriptionFn: '&onShowDescription',
      dataProvider: '&data'
    },
    bindToController: true,
    controller: FieldSurveyFieldRecordsRpFormController,
    controllerAs: 'fieldSurveyFieldRecordsRpFormCtrl',
    template: require('./field-survey-field-records-rp-form.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyFieldRecordsRpFormController($scope) {
  var fieldSurveyFieldRecordsRpFormCtrl = this;

  fieldSurveyFieldRecordsRpFormCtrl.onIsTxSureChange = onIsTxSureChange;
  fieldSurveyFieldRecordsRpFormCtrl.onAbondanceDominanceChange = onAbondanceDominanceChange;
  fieldSurveyFieldRecordsRpFormCtrl.onSociabiliteChange = onSociabiliteChange;
  fieldSurveyFieldRecordsRpFormCtrl.onShowDescription = onShowDescription;

  $scope.$watch(fieldSurveyFieldRecordsRpFormCtrl.strateProvider, (strate) => {
    fieldSurveyFieldRecordsRpFormCtrl.strate = strate;
  });
  $scope.$watch(fieldSurveyFieldRecordsRpFormCtrl.taxonProvider, (taxon) => {
    fieldSurveyFieldRecordsRpFormCtrl.taxon = taxon;
  });
  $scope.$watch(fieldSurveyFieldRecordsRpFormCtrl.isLoadingProvider, (isLoading) => {
    fieldSurveyFieldRecordsRpFormCtrl.isLoading = isLoading;
  });
  $scope.$watch(fieldSurveyFieldRecordsRpFormCtrl.isLoadedProvider, (isLoaded) => {
    fieldSurveyFieldRecordsRpFormCtrl.isLoaded = isLoaded;
  });
  $scope.$watch(fieldSurveyFieldRecordsRpFormCtrl.isProcessingProvider, (isProcessing) => {
    fieldSurveyFieldRecordsRpFormCtrl.isProcessing = isProcessing;
  });
  $scope.$watch(fieldSurveyFieldRecordsRpFormCtrl.canEditProvider, (canEdit) => {
    fieldSurveyFieldRecordsRpFormCtrl.canEdit = canEdit;
  });
  $scope.$watch(fieldSurveyFieldRecordsRpFormCtrl.dataProvider, (data) => {
    if (data && typeof data.isTxSure == 'undefined') {
      data.isTxSure = true;
    }
    fieldSurveyFieldRecordsRpFormCtrl.data = data;
  });

  function onShowDescription() {
    fieldSurveyFieldRecordsRpFormCtrl.onShowDescriptionFn() &&
      fieldSurveyFieldRecordsRpFormCtrl.onShowDescriptionFn()(fieldSurveyFieldRecordsRpFormCtrl.data.fieldRecordId);
  }

  function onIsTxSureChange() {
    fieldSurveyFieldRecordsRpFormCtrl.onIsTxSureChangeFn() &&
      fieldSurveyFieldRecordsRpFormCtrl.onIsTxSureChangeFn()(fieldSurveyFieldRecordsRpFormCtrl.strate.id);
  }

  function onAbondanceDominanceChange(selectedValue) {
    fieldSurveyFieldRecordsRpFormCtrl.onAbondanceDominanceChangeFn() &&
      fieldSurveyFieldRecordsRpFormCtrl.onAbondanceDominanceChangeFn()(fieldSurveyFieldRecordsRpFormCtrl.strate.id, selectedValue);
  }

  function onSociabiliteChange(selectedValue) {
    fieldSurveyFieldRecordsRpFormCtrl.onSociabiliteChangeFn() &&
      fieldSurveyFieldRecordsRpFormCtrl.onSociabiliteChangeFn()(fieldSurveyFieldRecordsRpFormCtrl.strate.id, selectedValue);
  }
}
