import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur lance une recherche
  TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOADING: 'shu-taxon-search.taxon-search-by-tx-groups-results-loading',
  TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS: 'shu-taxon-search.taxon-search-by-tx-groups-results-load-success',
  TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR: 'shu-taxon-search.taxon-search-by-tx-groups-results-load-error',
};
const LIMIT = 20;

export default TaxonSearchByTxGroupsService;

// @ngInject
function TaxonSearchByTxGroupsService($rootScope, FieldRecordRepository, TaxonRepository, LxNotificationService) {
  return { load };

  function load(studyId, txRefKey, txRefVersion, txRefFilter, txGroupKeys, query) {
    $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOADING, { query, txGroupKeys, txRefKey, txRefVersion });

    // On ne requête le serveur qu'à partir de 3 caractères
    if ((query && query.length <= 2) || !txRefKey) {
      $rootScope.$emit(
        EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS,
        { 'Saissisez au moins 3 caractères': [] },
        { query, txGroupKeys, txRefKey, txRefVersion, txRefFilter }
      );
      return;
    }

    // VB comment
    /* if (!query) {
      FieldRecordRepository.latestTxKeysForStudyAndTxGroups(studyId, txGroupKeys, LIMIT)
        .then((txKeys) => {
          if (!txKeys || txKeys.length === 0) {
            return [];
          }
          return TaxonRepository.findByKeys(txRefKey, txRefVersion, txKeys);
          //return TaxonRepository.findByKeys(txRefKey, txRefVersion, txRefFilter, txKeys);
        })
        .then(
          (results) => {
            //$rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, results, {query, txGroupKeys, txRefKey, txRefVersion, txRefFilter});
            $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, results, {
              query,
              txGroupKeys,
              txRefKey,
              txRefVersion,
            });
          },
          (error) => {
            LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
            //$rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR, {query, txGroupKeys, txRefKey, txRefVersion, txRefFilter});
            $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR, {
              query,
              txGroupKeys,
              txRefKey,
              txRefVersion,
            });
          }
        );
      return;
    } */

    if (!studyId) {
      TaxonRepository.searchByTxGroupsAndQuery(txRefKey, txRefVersion, txRefFilter, txGroupKeys, query, LIMIT).then(
        (results) => {
          $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, results, {
            query,
            txGroupKeys,
            txRefKey,
            txRefVersion,
            txRefFilter,
          });
        }
      );
      return;
    }

    FieldRecordRepository.getForStudy(studyId).then(
      (records) => {
        if (!records.length) {
          TaxonRepository.searchByTxGroupsAndQuery(txRefKey, txRefVersion, txRefFilter, txGroupKeys, query, LIMIT).then(
            (results) => {
              $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, results, {
                query,
                txGroupKeys,
                txRefKey,
                txRefVersion,
                txRefFilter,
              });
            },
            (error) => {
              LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
              $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR, {
                query,
                txGroupKeys,
                txRefKey,
                txRefVersion,
                txRefFilter,
              });
            }
          );
          return;
        }

        const now = Date.now();
        const diffLimit = 1000 * 60 * 60 * 8;
        let recordedTaxa = [];
        const search = query && query.toLowerCase();
        records.forEach((record) => {
          const txName = record.txName || '';
          const txVernacularName = record.txVernacularName || '';
          const txGroup = record.txGroup || '';
          if (txGroupKeys.length && txGroupKeys.indexOf(txGroup) < 0) {
            return;
          }
          if (
            search &&
            txName.toLowerCase().indexOf(search) !== 0 &&
            txVernacularName.toLowerCase().indexOf(search) !== 0
          ) {
            return;
          }
          const diff = now - new Date(record.created * 1000);
          let recordedTaxon = recordedTaxa.find((taxon) => {
            return taxon.key == record.txKey;
          });
          if (!recordedTaxon) {
            recordedTaxon = {
              key: record.txKey,
              last: 0,
              total: 0,
            };
            recordedTaxa.push(recordedTaxon);
          }
          recordedTaxon.total++;
          if (diff <= diffLimit) {
            recordedTaxon.last++;
          }
        });
        if (!recordedTaxa.length) {
          if (query) {
            TaxonRepository.searchByTxGroupsAndQuery(
              txRefKey,
              txRefVersion,
              txRefFilter,
              txGroupKeys,
              query,
              LIMIT
            ).then(
              (results) => {
                $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, results, {
                  query,
                  txGroupKeys,
                  txRefKey,
                  txRefVersion,
                  txRefFilter,
                });
              },
              (error) => {
                LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
                $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR, {
                  query,
                  txGroupKeys,
                  txRefKey,
                  txRefVersion,
                  txRefFilter,
                });
              }
            );
          }
          return;
        }
        recordedTaxa.sort((a, b) => {
          const prop = a.last || b.last ? 'last' : 'total';
          if (a[prop] < b[prop]) {
            return 1;
          }
          if (a[prop] > b[prop]) {
            return -1;
          }
          return 0;
        });
        //recordedTaxa = recordedTaxa.slice(0, LIMIT);
        TaxonRepository.findByKeys(
          txRefKey,
          txRefVersion,
          txRefFilter,
          recordedTaxa.map((taxon) => taxon.key)
        ).then(
          (taxaData) => {
            recordedTaxa = recordedTaxa
              .map((recordedTaxon) => {
                const taxonData = taxaData.find((taxonData) => {
                  return taxonData.key == recordedTaxon.key;
                });
                if (IS_CORDOVA) {
                  TaxonRepository.setHeadStatuses(taxonData);
                }
                return Object.assign({}, recordedTaxon, taxonData);
              })
              .filter((taxon) => {
                return taxon.name;
              });

            if (!query) {
              $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, recordedTaxa, {
                query,
                txGroupKeys,
                txRefKey,
                txRefVersion,
                txRefFilter,
              });
              return;
            }
            TaxonRepository.searchByTxGroupsAndQuery(
              txRefKey,
              txRefVersion,
              txRefFilter,
              txGroupKeys,
              query,
              LIMIT
            ).then((results) => {
              for (let index = 0; index < Math.min(results.length, LIMIT); index++) {
                const element = results[index];
                const exists = recordedTaxa.find((recordedTaxon) => {
                  return recordedTaxon.key == element.key;
                });
                if (!exists) {
                  recordedTaxa.push(element);
                }
              }
              $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, recordedTaxa, {
                query,
                txGroupKeys,
                txRefKey,
                txRefVersion,
                txRefFilter,
              });
            });
          },
          (error) => {
            LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
            $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR, {
              query,
              txGroupKeys,
              txRefKey,
              txRefVersion,
              txRefFilter,
            });
          }
        );
      },
      (error) => {
        LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR, {
          query,
          txGroupKeys,
          txRefKey,
          txRefVersion,
          txRefFilter,
        });
      }
    );
  }
}
export { EVENTS };
