import _ from 'lodash';
import latinize from '../../base/tools/latinize';
import angular from 'angular';
import { IMAGERY_SET } from '../../map/model';

export default StudyRepository;

// @ngInject
function StudyRepository(DatabaseProvider, StorageService, $q, $http, StudyConfiguration, SynchroRefRepository) {
  return {
    query,
    getStudy,
    getGeom,
    getStudyMapCurrentLayer,
    storeStudyMapCurrentLayer,
    exportStudyMbTilesToUserDirectory,
    getStudyMapLayersVisibility,
    storeStudyMapLayersVisibility,
    getStudyTxRefs,
    isStudyHasOutdatedTxRefs,
  };

  function query(query, searchAll, status, noGeom, offset, limit) {
    // searchAll, status, limit et offset ne sont pas utilisés : on recherche dans toutes les données de la base
    var params = [];
    var querySql = '';
    var noGeomSql = '';

    if (query) {
      var normalizedQuery = latinize.latinize(query).toLocaleLowerCase('FR');
      querySql = "and (s.name_unaccent LIKE lower('%' || ? || '%')";
      params.push(normalizedQuery);
      if (query.length >= 7) {
        querySql = querySql + " or lower(s.key) LIKE lower(? || '%') ";
        params.push(normalizedQuery);
      }
      querySql = querySql + ' )';
    }
    if (!noGeom) {
      noGeomSql = 's.study_boundary as studyBoundary,';
    }

    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              s.id,
              s.key,
              s.name,
              ${noGeomSql}
              (select count(*) from field_record fr
                where fr.study = s.id
                and fr.ghost = 0
                and (fr.deleted is null or fr.deleted > datetime()) ) as nbFieldRecords,
              (select count(*) from field_survey fs
                join survey_type st on (st.id = fs.survey_type)
                where st.study = s.id
                and (fs.deleted is null or fs.deleted > datetime())
                and (st.deleted is null or st.deleted > datetime()) ) as nbFieldSurveys,
              (s.study_boundary is not null) as hasBoundary,
              (select count(*) from field_record fr
                where fr.study = s.id
                and fr.ghost = 0
                and fr.updated > coalesce(sli.latest_sync_time, sli.lock_time)
                and (fr.deleted is null or fr.deleted > datetime()) ) as nbOutOfSyncFieldRecords,
              (select count(*) from field_survey fs
                join survey_type st on (st.id = fs.survey_type)
                where st.study = s.id
                and fs.updated > coalesce(sli.latest_sync_time, sli.lock_time)
                and (st.deleted is null or st.deleted > datetime())
                and (fs.deleted is null or fs.deleted > datetime()) ) as nbOutOfSyncFieldSurveys,
              s.is_biblio as isBiblio,
              s.biblio_src as biblioSrc,
              s.start_time as startTime,
              s.end_time as endTime,
              s.timezone,
              s.application_key as applicationKey,
              s.access_constraint as accessConstraint,
              s.use_constraint as useConstraint,
              s.status,
              s.created,
              s.created_by as createdBy,
              s.updated,
              s.updated_by as updatedBy,
              s.deleted,
              s.deleted_by as deletedBy,
              (select group_concat(se.role, '||') from study_expert se where se.study = s.id) as roles,
              sli.lock_time as lockTime, sli.latest_sync_time as latestSyncTime
            FROM study s
            JOIN study_lock_info sli on (sli.study = s.id)
            WHERE 1
            ${querySql}
            ORDER BY s.start_time DESC, s.end_time DESC, s.id asc
          `,
        params
      ).then((results) => {
        var count = results.length;
        var studies = _.map(results, (study) => {
          return _.defaults(
            { roles: StorageService.parseArrayData(study.roles), studyExperts: [], locksInfo: [], canEdit: false },
            study
          );
        });
        return { studies, count };
      });
    });
  }

  function getStudy(studyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              s.id,
              s.key,
              s.name,
              s.is_biblio as isBiblio,
              s.biblio_src as biblioSrc,
              s.start_time as startTime,
              s.end_time as endTime,
              s.timezone,
              s.imagery_sets as imagerySets,
              (select count(*) from field_record fr
                where fr.study = s.id
                and fr.ghost = 0
                and fr.status = 'PRIVATE'
                and (fr.deleted is null or fr.deleted > datetime()) ) as nbPrivateFieldRecords,
              (select count(*) from field_survey fs
                join survey_type st on (st.id = fs.survey_type)
                where st.study = s.id
                and (fs.deleted is null or fs.deleted > datetime())
                and (st.deleted is null or st.deleted > datetime()) ) as nbFieldSurveys,
              (select count(*) from field_record fr
                where fr.study = s.id
                and fr.ghost = 0
                and fr.updated > coalesce(sli.latest_sync_time, sli.lock_time)
                and (fr.deleted is null or fr.deleted > datetime()) ) as nbOutOfSyncFieldRecords,
              (select count(*) from field_survey fs
                join survey_type st on (st.id = fs.survey_type)
                where st.study = s.id
                and fs.updated > coalesce(sli.latest_sync_time, sli.lock_time)
                and (st.deleted is null or st.deleted > datetime())
                and (fs.deleted is null or fs.deleted > datetime()) ) as nbOutOfSyncFieldSurveys,
              s.application_key as applicationKey,
              s.access_constraint as accessConstraint,
              s.use_constraint as useConstraint,
              s.status,
              s.created,
              s.created_by as createdBy,
              s.updated,
              s.updated_by as updatedBy,
              s.deleted,
              s.deleted_by as deletedBy,
              (select group_concat(se.role, '||') from study_expert se where se.study = s.id) as roles,
              sli.lock_time as lockTime, sli.latest_sync_time as latestSyncTime
            FROM study s
            JOIN study_lock_info sli on (sli.study = s.id)
            WHERE s.id = ?
          `,
        [studyId]
      ).then(([study]) => {
        return _.defaults(
          {
            roles: StorageService.parseArrayData(study.roles),
            studyExperts: [],
            locksInfo: [],
            imagerySets: StorageService.parseArrayData(study.imagerySets),
            canEdit: false,
            canCreateSubElements: true,
          },
          study
        );
      });
    });
  }

  function getStudyTxRefs(studyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `SELECT DISTINCT st.default_tx_ref_filter, tri.* FROM survey_type st join tx_ref_import tri on tri.key=st.default_tx_ref_filter WHERE study = ? and deleted is null`,
        [studyId]
      );
    });
  }

  function getGeom(studyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              s.id,
              s.key,
              s.name,
              s.imagery_sets as imagerySets,
              s.study_boundary as studyBoundary
            FROM study s
            WHERE s.id = ?
          `,
        [studyId]
      ).then(([study]) => {
        return _.defaults(
          {
            studyBoundary: StorageService.parseGeoJsonData(study.studyBoundary),
            imagerySets: StorageService.parseArrayData(study.imagerySets),
            canEdit: false,
            canCreateSubElements: true,
          },
          study
        );
      });
    });
  }

  function getStudyMapCurrentLayer() {
    return window.localStorage && window.localStorage.getItem('study-map.current-layer');
  }

  function storeStudyMapCurrentLayer(layer) {
    window.localStorage && window.localStorage.setItem('study-map.current-layer', layer);
  }

  function getStudyMapLayersVisibility(studyId) {
    var layersStr = window.localStorage && window.localStorage.getItem(`study.${studyId}.visible-layers`);
    try {
      return JSON.parse(layersStr);
    } catch (e) {
      return [];
    }
  }

  function storeStudyMapLayersVisibility(studyId, layerId) {
    var layers = getStudyMapLayersVisibility(studyId) || [];
    if (_.includes(layers, layerId)) {
      layers = _.without(layers, layerId);
    } else {
      layers = layers.concat([layerId]);
    }
    window.localStorage && window.localStorage.setItem(`study.${studyId}.visible-layers`, JSON.stringify(layers));
  }

  function exportStudyMbTilesToUserDirectory(studyId, studyKey, imagerySets) {
    return _.reduce(
      imagerySets,
      (previousPromise, imagerySet) => {
        return previousPromise.then((count) => {
          return $q((resolve, reject) => {
            document.addEventListener('deviceready', () => {
              var mbTilesPath = `${cordova.file.applicationStorageDirectory}databases/${studyId}_${imagerySet}.mbtiles`;
              var targetDirectoryPath = `${cordova.file.externalRootDirectory}Download/`;

              window.resolveLocalFileSystemURL(
                mbTilesPath,
                (fileEntry) => {
                  window.resolveLocalFileSystemURL(
                    targetDirectoryPath,
                    (targetDirectory) => {
                      fileEntry.copyTo(
                        targetDirectory,
                        `${studyKey}_${imagerySet}.mbtiles`,
                        () => resolve(count + 1),
                        reject
                      );
                    },
                    reject
                  );
                  // Si window.resolveLocalFileSystemURL(mbTilesPath, ...) échoue, cela veut probablement dire que le fichier n'est pas présent
                  // On échoue donc silencieusement en indiquant que l'export de ce fichier a réussi
                },
                () => resolve(count)
              );
            });
          });
        });
      },
      $q.when(0)
    );
  }

  function isStudyHasOutdatedTxRefs(studyId) {
    return $q((resolve, reject) => {
      if (!IS_CORDOVA) {
        return reject();
      }

      let allOutdateds = [];
      try {
        allOutdateds = JSON.parse(localStorage.getItem('outdatedTxRefs')) || [];
      } catch (error) {}

      getStudyTxRefs(studyId).then(
        (studyTxRefs) => {
          const outdated = studyTxRefs.some((studyTxRef) => {
            return allOutdateds.includes(studyTxRef.key);
          });
          if (outdated) {
            return resolve(true);
          }

          const hasNetwork = _.get(navigator, 'connection.type') != Connection.NONE;
          if (!hasNetwork) {
            return reject();
          }

          const lastCheckStorageKey = `outdatedTxRefs.study_${studyId}.lastCheck`;
          const lastCheck = Number(localStorage.getItem(lastCheckStorageKey)) || 0;
          const diff = moment().diff(moment(Number(lastCheck)), 'seconds');
          if (diff < 5) {
            return reject();
          }

          SynchroRefRepository.getLastVersionTxRefs().then(
            (lastTxRefs) => {
              const outdateds = studyTxRefs.filter((studyTxRef) => {
                const lastTxRef = lastTxRefs.find((lastTxRef) => {
                  return lastTxRef.key == studyTxRef.key;
                });
                return lastTxRef && lastTxRef.updated > studyTxRef.updated;
              });
              allOutdateds = allOutdateds.concat(
                outdateds
                  .filter((outdated) => {
                    return !allOutdateds.includes(outdated.key);
                  })
                  .map((outdated) => outdated.key)
              );
              localStorage.setItem('outdatedTxRefs', JSON.stringify(allOutdateds));
              localStorage.setItem(lastCheckStorageKey, Date.now());
              resolve(Boolean(outdateds.length));
            },
            (error) => {
              console.log('isStudyHasOutdatedTxRefs error', error);
              reject();
            }
          );
        },
        (error) => {
          console.log('isStudyHasOutdatedTxRefs error', error);
          reject();
        }
      );
    });
  }
}
