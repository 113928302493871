import studySearchContainerComponent from './components/study-search-container/study-search-container.component';
import studySearchFormComponent from './components/study-search-form/study-search-form.component';
import studySearchResultsComponent from './components/study-search-results/study-search-results.component';
import studyDetailsComponent from './components/study-details/study-details.component';
import studyDetailsContainerComponent from './components/study-details-container/study-details-container.component';
import studyMapComponent from './components/study-map/study-map.component';
import studyGridComponent from './components/study-grid/study-grid.component';
import studySynchronizerDialogComponent from './components/study-synchronizer-dialog/study-synchronizer-dialog.component';
import studyCreationDialogComponent from './components/study-creation-dialog/study-creation-dialog.component';
import menuUpdateLayersComponent from './components/menu-update-layers/menu-update-layers.component';
import studySearchStore from './stores/study-search.store';
import studyDetailsStore from './stores/study-details.store';
import studyExpertLayerStore from './stores/study-expert-layer.store';
import studyCreationStore from './stores/study-creation.store';
import studyMapStore from './stores/study-map.store';
import studyGridStore from './stores/study-grid.store';
import studySearchService from './services/study-search.service';
import studyDetailsService from './services/study-details.service';
import studyExpertLayerService from './services/study-expert-layer.service';
import studyCreationService from './services/study-creation.service';
import studyMapService from './services/study-map.service';
import studyGridService from './services/study-grid.service';
import remoteStudyRepository from './repositories/study.remote.repository';
import localStudyRepository from './repositories/study.local.repository';
import remoteStudyExpertLayerRepository from './repositories/study-expert-layer.remote.repository';
import localStudyExpertLayerRepository from './repositories/study-expert-layer.local.repository';
import studyKmlService from './services/study-kml.service';
import studyStatusFilter from './filters/study-status.filter';
import studyRoleFilter from './filters/study-role.filter';
import studyRestrictionFilter from './filters/study-restriction.filter';
import layerFormatFilter from './filters/study-layer-format.filter';
import layerSourceFilter from './filters/study-layer-source.filter';
import routerConfig from './routes';
import studyConfiguration from './configuration';

import baseModule from '../base/base.module';

const moduleName = 'shu-study';

angular.module(moduleName, ['lumx', baseModule.moduleName])
  .directive('shuStudySearchContainer', studySearchContainerComponent)
  .directive('shuStudySearchForm', studySearchFormComponent)
  .directive('shuStudySearchResults', studySearchResultsComponent)
  .directive('shuStudyDetails', studyDetailsComponent)
  .directive('shuStudyDetailsContainer', studyDetailsContainerComponent)
  .directive('shuStudyMap', studyMapComponent)
  .directive('shuStudyGrid', studyGridComponent)
  .directive('shuStudySynchronizerDialog', studySynchronizerDialogComponent)
  .directive('shuStudyCreationDialog', studyCreationDialogComponent)
  .directive('shuMenuUpdateLayers', menuUpdateLayersComponent)
  .factory('StudySearchStore', studySearchStore)
  .factory('StudyDetailsStore', studyDetailsStore)
  .factory('StudyExpertLayerStore', studyExpertLayerStore)
  .factory('StudyCreationStore', studyCreationStore)
  .factory('StudyMapStore', studyMapStore)
  .factory('StudyGridStore', studyGridStore)
  .factory('StudySearchService', studySearchService)
  .factory('StudyDetailsService', studyDetailsService)
  .factory('StudyExpertLayerService', studyExpertLayerService)
  .factory('StudyCreationService', studyCreationService)
  .factory('StudyMapService', studyMapService)
  .factory('StudyGridService', studyGridService)
  .factory('StudyKmlService', studyKmlService)
  .factory('StudyRepository', IS_CORDOVA ? localStudyRepository : remoteStudyRepository)
  .factory('StudyExpertLayerRepository', IS_CORDOVA ? localStudyExpertLayerRepository : remoteStudyExpertLayerRepository)
  .filter('studyStatusLabel', studyStatusFilter)
  .filter('studyRoleLabel', studyRoleFilter)
  .filter('studyRestrictionLabel', studyRestrictionFilter)
  .filter('layerFormatLabel', layerFormatFilter)
  .filter('layerSourceLabel', layerSourceFilter)
  .constant('StudyConfiguration', studyConfiguration)
  .config(routerConfig);

export default {moduleName};
