import _ from 'lodash';
import UUID from 'uuid-js';

import FieldSurveyExtraTableUtil from '../tools/extra-table-util';

export default FieldSurveyRepository;

// @ngInject
function FieldSurveyRepository(
  DatabaseProvider,
  StorageService,
  $q,
  SurveyTypeRepository,
  MediaRepository,
  FieldRecordRepository
) {
  return {
    getById,
    getList,
    getForStudy,
    current,
    getGeom,
    createFieldSurvey,
    updateFieldSurvey,
    deleteFieldSurvey,
    updateUpdatedFieldSurveys,
    getGeomForStudy,
    getForClonage,
    cloneFieldSurvey,
  };

  function getById(fieldSurveyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              fs.id,
              fs.key,
              fs.name,
              fs.description,
              fs.geometry AS geometry,
              fs.terr_unit AS terrUnit,
              fs.terr_unit_name AS terrUnitName,
              fs.place,
              fs.survey_type as surveyType,
              fs.start_time AS startTime,
              fs.end_time AS endTime,
              fs.created,
              fs.created_by AS createdBy,
              fs.updated,
              fs.updated_by AS updatedBy,
              (select count(*) from field_record fr
                where fr.field_survey = fs.id
                and fr.ghost = 0
                and (fr.deleted is null or fr.deleted > datetime()) ) as nbFieldRecord,
              (select count(*) from field_record fr
                where fr.field_survey = fs.id
                and fr.ghost = 0
                and status = 'PRIVATE'
                and (fr.deleted is null or fr.deleted > datetime()) ) as nbPrivateFieldRecords
            FROM field_survey fs
            JOIN survey_type st ON (st.id = fs.survey_type)
            WHERE fs.id = ?
          `,
        [fieldSurveyId]
      ).then(([fieldSurvey]) =>
        loadFieldSurveyExtraDatas(
          database,
          _.defaults({ geometry: StorageService.parseGeoJsonData(fieldSurvey.geometry) }, fieldSurvey)
        )
      );
    });
  }

  function getForClonage(fieldSurveyId, expertPosition) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
          SELECT DISTINCT
            fs.id,
            fs.key,
            fs.name AS name,
            fs.survey_type as surveyType,
            fs.geometry as geometry,
            p.field_survey_geometry_type as fieldSurveyGeometryType,
            (select count(*) from field_record fr
              where fr.field_survey = fs.id
              and fr.ghost = 0
              and (fr.deleted is null or fr.deleted > datetime()) ) as nbFieldRecord,
            (select count(*) from field_record fr
              where fr.field_survey = fs.id
              and fr.ghost = 0
              and status = 'PRIVATE'
              and (fr.deleted is null or fr.deleted > datetime()) ) as nbPrivateFieldRecords
          FROM field_survey fs
          JOIN survey_type st ON (st.id = fs.survey_type)
          JOIN protocol p ON (p.id = st.protocol)
          WHERE fs.id = ?
        `,
        [fieldSurveyId]
      ).then(([fieldSurvey]) => {
        var now = new Date().getTime() / 1000;
        var fieldSurveyGeometry = null;
        var sourceGeometry = StorageService.parseGeoJsonData(fieldSurvey.geometry);
        if (fieldSurvey.fieldSurveyGeometryType === 'POINT' || (sourceGeometry && sourceGeometry.type === 'Point')) {
          fieldSurveyGeometry = expertPosition;
        } else {
          fieldSurveyGeometry = sourceGeometry;
        }
        return loadFieldSurveyExtraDatasForClonage(
          database,
          _.defaults(
            {
              name: 'Copie_' + fieldSurvey.name,
              // sera renseigné à la sauvegarde
              createdBy: null,
              updatedBy: null,
              startTime: now,
              created: now,
              updated: now,
              geometry: fieldSurveyGeometry,
            },
            fieldSurvey
          )
        );
      });
    });
  }

  function loadFieldSurveyExtraDatasForClonage(database, fieldSurvey) {
    return loadExtraTablesForClonage(database, fieldSurvey).then((extraTablesAndSurveyType) => {
      return _.defaults(_.merge(extraTablesAndSurveyType, { canEdit: true }), fieldSurvey);
    });
  }
  function loadExtraTablesForClonage(database, fieldSurvey) {
    return SurveyTypeRepository.getSurveyType(fieldSurvey.surveyType).then((surveyType) => {
      // Construction des promises pour récupérer le contenu des extra_table
      var promises = _.chain(surveyType.protocolTxGroups)
        .filter('fieldSurveyExtraTables')
        .map('fieldSurveyExtraTables')
        .flatten()
        .uniq()
        .map((extraTable) => {
          var extraTableName = FieldSurveyExtraTableUtil.toTableName(extraTable);
          var fieldsToClone = FieldSurveyExtraTableUtil.fieldsToClone(extraTable);
          if (!fieldsToClone) {
            return $q.when({ [extraTable]: null });
          }
          var fieldsToCloneString = fieldsToClone.join(','); // TODO: vérifier
          return StorageService.executeSqlQuery(
            database,
            `SELECT ${fieldsToCloneString} FROM ${extraTableName} WHERE id = ?`,
            [fieldSurvey.id]
          ).then((extraTableValueResults) => {
            if (extraTableValueResults.length) {
              // On passe les clés de l'objet en camelcase
              return { [extraTable]: _.mapKeys(extraTableValueResults[0], (value, key) => _.camelCase(key)) };
            }
            return { [extraTable]: null };
          });
        })
        .value();

      return $q.all(promises).then((extraTableMaps) => {
        let extraTables = _.reduce(extraTableMaps, _.merge, {});
        return { extraTables, surveyType };
      });
    });
  }

  function cloneFieldSurvey(
    studyId,
    surveyTypeId,
    fieldSurveyId,
    fieldSurveyForm,
    fieldSurveyMapForm,
    fieldSurveyExtraTableForms
  ) {
    var { name, description, terrUnit, terrUnitName, place, startTime, endTime } = fieldSurveyForm;
    var geometry = null;
    if (fieldSurveyMapForm && fieldSurveyMapForm.geometry) {
      geometry = fieldSurveyMapForm.geometry;
    }
    var now = new Date().getTime() / 1000;

    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas à jour updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
        database,
        `
          INSERT INTO field_survey (key, survey_type, name, description, terr_unit, terr_unit_name, place, geometry,
            start_time, end_time, created, updated) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
        `,
        [
          UUID.create().toString(),
          surveyTypeId,
          name,
          description,
          terrUnit,
          terrUnitName,
          place,
          geometry,
          startTime,
          endTime,
          now,
          now,
        ]
      ).then((id) => {
        // Gestion des extraTables fieldSurvey
        return (
          createExtraTabs(database, id, fieldSurveyExtraTableForms)
            // Recuperation des fieldRecords du fieldSurvey
            .then(() => FieldRecordRepository.getForFieldSurvey(fieldSurveyId))
            .then((fieldRecords) => {
              return _.map(fieldRecords, (fieldRecord) => {
                return StorageService.executeSqlQuery(
                  //tx_ref_filter
                  database,
                  `
                      INSERT INTO field_record (key, study, field_survey, description, tx_ref_type, tx_ref_version, tx_ref_filter, tx_key,
                        tx_name, tx_vernacular_name, is_tx_sure, is_tx_present, tx_headcount, tx_headcount_accuracy,
                        tx_time, tx_group, status, tx_position, expert_position, expert_position_accuracy, created, updated, ghost)
                      VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
                    `,
                  [
                    UUID.create().toString(),
                    studyId,
                    id,
                    description,
                    fieldRecord.txRefType,
                    fieldRecord.txRefVersion,
                    fieldRecord.txRefFilter,
                    fieldRecord.txKey,
                    fieldRecord.txName,
                    null,
                    fieldRecord.isTxSure,
                    fieldRecord.isTxPresent,
                    null,
                    'MINIMUM',
                    now,
                    fieldRecord.txGroup,
                    fieldRecord.status,
                    null,
                    null,
                    null,
                    now,
                    now,
                    true,
                  ]
                ).then((fieldRecordId) => {
                  // clonage des extraTables fieldRecord
                  return FieldRecordRepository.loadExtraTablesForClonage(
                    database,
                    fieldRecord.id,
                    fieldRecord.txGroup,
                    fieldSurveyId
                  ).then((extraTables) => {
                    return $q.all(
                      _.mapKeys(extraTables, (extraTableData, extraTable) => {
                        // Sauvegarde des ExtraTables du fieldRecord
                        return FieldRecordRepository.insertFieldRecordExtraTableForClone(
                          database,
                          fieldRecordId,
                          extraTableData,
                          extraTable
                        );
                      })
                    );
                  });
                });
              });
            })
            .then(() => getById(id))
        );
      });
    });
  }

  function loadFieldSurveyExtraDatas(database, fieldSurvey) {
    return loadExtraTables(database, fieldSurvey).then((extraTablesAndSurveyType) => {
      return loadMedias(database, fieldSurvey.id).then((medias) => {
        return _.defaults(_.merge(extraTablesAndSurveyType, { medias, canEdit: true }), fieldSurvey);
      });
    });
  }
  function loadExtraTables(database, fieldSurvey) {
    return SurveyTypeRepository.getSurveyType(fieldSurvey.surveyType).then((surveyType) => {
      // Construction des promises pour récupérer le contenu des extra_table
      var promises = _.chain(surveyType.protocolTxGroups)
        .filter('fieldSurveyExtraTables')
        .map('fieldSurveyExtraTables')
        .flatten()
        .uniq()
        .map((extraTable) => {
          var extraTableName = FieldSurveyExtraTableUtil.toTableName(extraTable);
          return StorageService.executeSqlQuery(database, `SELECT * FROM ${extraTableName} WHERE id = ?`, [
            fieldSurvey.id,
          ]).then((extraTableValueResults) => {
            if (extraTableValueResults.length) {
              // On passe les clés de l'objet en camelcase
              return { [extraTable]: _.mapKeys(extraTableValueResults[0], (value, key) => _.camelCase(key)) };
            }

            return { [extraTable]: null };
          });
        })
        .value();

      return $q.all(promises).then((extraTableMaps) => {
        let extraTables = _.reduce(extraTableMaps, _.merge, {});
        return { extraTables, surveyType };
      });
    });
  }

  function loadMedias(database, fieldSurveyId) {
    return StorageService.executeSqlQuery(
      database,
      `
      SELECT
        m.id,
        m.mime_type AS mimeType,
        m.filename,
        m.created,
        m.updated,
        m.deleted
      FROM media m
      JOIN field_survey_media fsm ON (fsm.media = m.id)
      WHERE fsm.field_survey = ?
      `,
      [fieldSurveyId]
    ).then((medias) => {
      var mediaPromises = _.map(medias, (media) => {
        return MediaRepository.checkAndGetMediaLocalPath(media).then(
          (localPath) => _.merge({ localPath }, media),
          () => {
            return MediaRepository.getMediaHref(media).then((href) => _.merge({ href }, media));
          }
        );
      });
      return $q.all(mediaPromises);
    });
  }

  function getList(withDeleted) {
    const withDeletedFilter = withDeleted
      ? ''
      : ' AND (fs.deleted is null OR fs.deleted > datetime()) ' +
        ' AND (st.deleted is null OR st.deleted > datetime()) AND (fr.deleted is null OR fr.deleted > datetime()) ';

    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              st.study,
              fs.id,
              fs.key,
              fs.name,
              fs.description,
              fs.terr_unit_name AS terrUnitName,
              fs.terr_unit AS terrUnit,
              fs.place,
              fs.geometry,
              fs.survey_type as surveyType,
              fs.start_time AS startTime,
              fs.end_time AS endTime,
              fs.created,
              fs.created_by AS createdBy,
              fs.updated,
              fs.updated_by AS updatedBy,
              fs.deleted,
              fs.deleted_by AS deletedBy,
              1 AS canEdit,
              COUNT(fr.id) AS nbFieldRecord,
              p.key AS protocolKey
            FROM field_survey fs
            JOIN survey_type st ON (st.id = fs.survey_type)
            JOIN protocol p ON (p.id = st.protocol)
            LEFT JOIN field_record fr ON (fs.id = fr.field_survey AND fr.ghost = 0)
            WHERE 1
            ${withDeletedFilter}
            GROUP BY fs.id
            ORDER BY fs.created DESC
          `,
        []
      );
    });
  }

  function getForStudy(studyId, withDetails = false, withDeleted = false) {
    return DatabaseProvider.getDatabase().then((database) => {
      let withDeletedFilter = withDeleted
        ? ''
        : ' AND (fs.deleted is null OR fs.deleted > datetime()) ' +
          ' AND (st.deleted is null OR st.deleted > datetime()) ';
      let fieldSurveysPromise = StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              fs.id,
              fs.key,
              fs.name,
              fs.description,
              fs.terr_unit_name AS terrUnitName,
              fs.terr_unit AS terrUnit,
              fs.place,
              fs.geometry,
              fs.survey_type as surveyType,
              fs.start_time AS startTime,
              fs.end_time AS endTime,
              fs.created,
              fs.created_by AS createdBy,
              fs.updated,
              fs.updated_by AS updatedBy,
              fs.deleted,
              fs.deleted_by AS deletedBy,
              1 AS canEdit,
              COUNT(fr.id) AS nbFieldRecord,
              st.protocol
            FROM field_survey fs
            JOIN survey_type st ON (st.id = fs.survey_type)
            LEFT JOIN field_record fr ON (
              fs.id = fr.field_survey AND fr.ghost = 0 
              ${withDeleted ? '' : ' AND (fr.deleted is null OR fr.deleted > datetime()) '}
            )
            WHERE st.study = ?
            ${withDeletedFilter}
            GROUP BY fs.id
            ORDER BY fs.created DESC
          `,
        [studyId]
      );

      let promiseWithGeom = fieldSurveysPromise.then((fieldsurveys) =>
        _.map(fieldsurveys, (fieldsurvey) =>
          _.defaults({ geometry: StorageService.parseGeoJsonData(fieldsurvey.geometry) }, fieldsurvey)
        )
      );
      if (!withDetails) {
        return promiseWithGeom;
      }
      return promiseWithGeom.then((fieldSurveys) => {
        return $q.all(
          _.map(fieldSurveys, (fieldSurvey) => {
            return loadFieldSurveyExtraDatas(database, fieldSurvey);
          })
        );
      });
    });
  }

  function current(limit) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQueries(database, [
        {
          sql: `
            SELECT
              fs.id as id,
              fs.key as key,
              fs.name as name,
              fs.description as description,
              fs.terr_unit as terrUnit,
              fs.terr_unit_name as terrUnitName,
              fs.place as place,
              fs.expert as expert,
              fs.start_time as startTime,
              fs.end_time as endTime,
              fs.survey_type as surveyType,
              fs.created as created,
              fs.created_by as createdBy,
              fs.updated as updated,
              fs.updated_by as updatedBy,
              fs.deleted as deleted,
              fs.deleted_by as deletedBy,
              s.id as studyId,
              s.key as studyKey
            FROM field_survey fs
            JOIN survey_type st on (st.id = fs.survey_type)
            JOIN study s on (s.id = st.study)
            WHERE  fs.start_time > datetime()
            AND fs.end_time is null or fs.end_time <= datetime()
            AND fs.deleted is null or fs.deleted > datetime()
            AND st.deleted is null or st.deleted > datetime()
            AND s.deleted is null or s.deleted > datetime()
            ORDER BY fs.start_time DESC, fs.end_time DESC
            LIMIT ?
            `,
          parameters: [limit],
        },
        {
          sql: `
            SELECT count(fs.id) as count
            FROM field_survey fs
            JOIN survey_type st on (st.id = fs.survey_type)
            JOIN study s on (s.id = st.study)
            WHERE  fs.start_time > datetime()
            AND fs.end_time is null or fs.end_time <= datetime()
            AND fs.deleted is null or fs.deleted > datetime()
            AND st.deleted is null or st.deleted > datetime()
            AND s.deleted is null or s.deleted > datetime()
            ORDER BY fs.start_time DESC, fs.end_time DESC
            `,
          parameters: [],
        },
      ]).then(([fieldSurveys, [countRes]]) => {
        return { fieldSurveys, count: countRes.count };
      });
    });
  }

  function getGeom(fieldSurveyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
            SELECT DISTINCT
              fs.id,
              fs.key,
              fs.name,
              fs.geometry
            FROM field_survey fs
            WHERE fs.id = ?
          `,
        [fieldSurveyId]
      ).then(([fieldSurvey]) => {
        return _.defaults({ geometry: StorageService.parseGeoJsonData(fieldSurvey.geometry) }, fieldSurvey);
      });
    });
  }

  function createFieldSurvey(studyId, surveyTypeId, fieldSurveyForm, fieldSurveyMapForm, fieldSurveyExtraTableForms) {
    var { name, description, terrUnit, terrUnitName, place, startTime, endTime } = fieldSurveyForm;
    var now = new Date().getTime() / 1000;
    var geometry = null;
    if (fieldSurveyMapForm && fieldSurveyMapForm.geometry) {
      geometry = fieldSurveyMapForm.geometry;
    }

    return DatabaseProvider.getDatabase().then((database) => {
      // On ne met pas created_by ni updated_by, cela se fera à la synchro
      return StorageService.executeSqlQuery(
        database,
        `
          INSERT INTO field_survey (key, survey_type, name, description, terr_unit, terr_unit_name, place,
            geometry, start_time, end_time, created, updated)
          VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
        `,
        [
          UUID.create().toString(),
          surveyTypeId,
          name,
          description,
          terrUnit,
          terrUnitName,
          place,
          geometry,
          startTime,
          endTime,
          now,
          now,
        ]
      ).then((id) => createExtraTabs(database, id, fieldSurveyExtraTableForms));
    });
  }

  function createExtraTabs(database, fieldSurveyId, fieldSurveyExtraTableForms) {
    var promises = [];

    for (let extraTable in fieldSurveyExtraTableForms) {
      promises.push(
        createExtraTabPromise(
          database,
          fieldSurveyId,
          fieldSurveyExtraTableForms[extraTable],
          FieldSurveyExtraTableUtil.toTableName(extraTable)
        )
      );
    }

    $q.all(promises);

    return getById(fieldSurveyId);
  }

  function createExtraTabPromise(database, fieldSurveyId, extraTableData, extraTableName) {
    var sql = `INSERT INTO ${extraTableName}(id`;
    var parameters = [fieldSurveyId];

    for (let field in extraTableData) {
      sql += ', ' + _.snakeCase(field);
      parameters.push(extraTableData[field]);
    }

    sql += ') VALUES (?' + _.repeat(', ?', _.size(extraTableData)) + ')';

    return StorageService.executeSqlQuery(database, sql, parameters);
  }

  function updateUpdatedFieldSurveys(ids) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
          UPDATE field_survey
          SET updated = ?
          WHERE id IN (${ids.join(',')})
        `,
        [new Date().getTime() / 1000]
      );
    });
  }

  function updateFieldSurvey(
    fieldSurveyId,
    { name, description, terrUnit, terrUnitName, place, startTime, endTime },
    fieldSurveyMapForm,
    mediaForm,
    fieldSurveyExtraTableForms
  ) {
    var geometry = null;
    if (fieldSurveyMapForm && fieldSurveyMapForm.geometry) {
      geometry = fieldSurveyMapForm.geometry;
    }

    return DatabaseProvider.getDatabase().then((database) => {
      var promises = [];
      if (mediaForm.addedMediaIds && mediaForm.addedMediaIds.length > 0) {
        let sql = 'INSERT INTO field_survey_media (field_survey, media) VALUES (?, ?)';
        for (let i = 0; i < mediaForm.addedMediaIds.length; i++) {
          promises.push(StorageService.executeSqlQuery(database, sql, [fieldSurveyId, mediaForm.addedMediaIds[i]]));
        }
      }

      if (mediaForm.removedMediaIds && mediaForm.removedMediaIds.length > 0) {
        let sql = `
            DELETE FROM field_survey_media
            WHERE field_survey = ?
            AND media IN (${_.times(mediaForm.removedMediaIds.length, () => '?').join(',')})
          `;
        promises.push(
          StorageService.executeSqlQuery(database, sql, [].concat([fieldSurveyId], mediaForm.removedMediaIds))
        );
      }

      // On ne met pas à jour updated_by, cela se fera à la synchro
      promises.push(
        StorageService.executeSqlQuery(
          database,
          `
          UPDATE field_survey
          SET name = ?, description = ?, terr_unit = ?, terr_unit_name = ?, place = ?, start_time = ?, end_time = ?, updated = ?
          WHERE id = ?
        `,
          [
            name,
            description,
            terrUnit,
            terrUnitName,
            place,
            startTime,
            endTime,
            new Date().getTime() / 1000,
            fieldSurveyId,
          ]
        )
      );

      // On met à jour la géométrie seulement si on a une valeur
      if (geometry) {
        promises.push(
          // Récupération de la géométrie actuelle du relevé
          StorageService.executeSqlQuery(database, 'SELECT geometry FROM field_survey WHERE id = ?', [fieldSurveyId])
            .then(([fieldSurvey]) => StorageService.parseGeoJsonData(fieldSurvey.geometry))
            .then((currentPosition) => {
              if (!currentPosition) {
                return $q.when(true);
              }
              // Modification des géométries des observations si on a modifié la géométrie du relevé
              // En nomade on est sûr d'être sur du Point
              return StorageService.executeSqlQuery(
                database,
                `
                  UPDATE field_record
                  SET tx_position = ?, expert_position = ?, expert_position_accuracy = ?
                  WHERE field_survey = ? AND tx_position = ? AND (deleted is null or deleted > datetime())
                `,
                [geometry, geometry, 0.0, fieldSurveyId, currentPosition]
              );
            })
            .then(() =>
              StorageService.executeSqlQuery(
                database,
                `
                  UPDATE field_survey
                  SET geometry = ?
                  WHERE id = ?
                `,
                [geometry, fieldSurveyId]
              )
            )
        );
      }

      return $q.all(promises).then(() => updateExtraTabs(database, fieldSurveyId, fieldSurveyExtraTableForms));
    });
  }

  function updateExtraTabs(database, fieldSurveyId, fieldSurveyExtraTableForms) {
    var promises = [];

    for (let extraTable in fieldSurveyExtraTableForms) {
      promises.push(
        deleteExtraTabPromise(database, fieldSurveyId, FieldSurveyExtraTableUtil.toTableName(extraTable))
          /* jshint -W083 */
          .then(() =>
            createExtraTabPromise(
              database,
              fieldSurveyId,
              fieldSurveyExtraTableForms[extraTable],
              FieldSurveyExtraTableUtil.toTableName(extraTable)
            )
          )
      );
    }

    $q.all(promises);

    return getById(fieldSurveyId);
  }

  function deleteExtraTabPromise(database, fieldSurveyId, extraTableName) {
    var sql = `DELETE FROM ${extraTableName} WHERE id = ?`;
    var parameters = [fieldSurveyId];

    return StorageService.executeSqlQuery(database, sql, parameters);
  }

  function deleteFieldSurvey(fieldSurveyId) {
    return DatabaseProvider.getDatabase()
      .then((database) => {
        // On ne met pas à jour deleted_by, cela se fera à la synchro
        return StorageService.executeSqlQuery(
          database,
          `
          UPDATE field_survey
          SET deleted = ?
          WHERE id = ?
        `,
          [new Date().getTime() / 1000, fieldSurveyId]
        );
      })
      .then(() => getById(fieldSurveyId));
  }

  function getGeomForStudy(studyId) {
    return DatabaseProvider.getDatabase().then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
              SELECT DISTINCT
                fs.id as id,
                fs.key as key,
                fs.geometry as geometry,
                fs.name as name
              FROM field_survey fs
              JOIN survey_type st on (st.id = fs.survey_type)
              WHERE (fs.deleted is null or fs.deleted > datetime())
              AND (st.deleted is null or st.deleted > datetime())
              AND (st.study = ?)
              AND (fs.geometry is not null)
            `,
        [studyId]
      ).then((fieldsurveys) =>
        _.map(fieldsurveys, (fieldsurvey) =>
          _.defaults({ geometry: StorageService.parseGeoJsonData(fieldsurvey.geometry) }, fieldsurvey)
        )
      );
    });
  }
}
