import { STUDY_ROLE } from '../model';

export default StudyRoleFilter;

// @ngInject
function StudyRoleFilter() {
  return function(input) {
    switch (input) {
      case STUDY_ROLE.MANAGER:
        return 'Chef de projet';
      case STUDY_ROLE.OBSERVER:
        return 'Observateur';
      default:
        return '-';
    }
  };
}

