import _ from 'lodash';

import { EVENTS as TX_REF_STORE_EVENTS } from '../../stores/taxon-ref.store';

require('./select-referentiel.scss');

export default SelectReferentielDirective;

// @ngInject
function SelectReferentielDirective() {
  return {
    restrict: 'E',
    scope: {
      initialReferentielKeyProvider: '&initialReferentielKey',
      takeFirstValueIfNullProvider: '&takeFirstValueIfNull',
      canEditProvider: '&canEdit',
      onChangeFn: '&onChange',
      floatingLabel: '@',
      placeholder: '@',
      keepWatchingProvider: '&keepWatching',
      required: '@'
    },
    bindToController: true,
    controller: SelectReferentielController,
    controllerAs: 'selectReferentielCtrl',
    template: require('./select-referentiel.html'),
    replace: true
  };
}

// @ngInject
function SelectReferentielController($scope, TaxonRefStore, TaxonSearchService, $timeout) {
  var selectReferentielCtrl = this;

  selectReferentielCtrl.availableReferentiels = [];

  selectReferentielCtrl.onChange = onChange;

  $scope.$watch(selectReferentielCtrl.canEditProvider, (canEdit) => {
    selectReferentielCtrl.canEdit = canEdit;
  });

  $scope.$watch(selectReferentielCtrl.keepWatchingProvider, (keepWatching) => {
    selectReferentielCtrl.keepWatching = keepWatching;
  });


  $scope.$on(TX_REF_STORE_EVENTS.TX_REF_STATE_CHANGED, reloadTxRefResults);
  TaxonSearchService.loadTxRef();

  var removeInitialTxRefKeyWatcher = $scope.$watch(selectReferentielCtrl.initialReferentielKeyProvider, (initialReferentielKey) => {
    if (!initialReferentielKey) {
      return;
    }

    selectReferentielCtrl.initialReferentielKey = initialReferentielKey;
    updateInitialValue();

    if (!selectReferentielCtrl.keepWatching) {
      removeInitialTxRefKeyWatcher();
    }
  });

  function reloadTxRefResults() {
    selectReferentielCtrl.state = TaxonRefStore.getState();

    if (!selectReferentielCtrl.state.availableReferentiels) {
      return;
    }

    if(!IS_CORDOVA){
    selectReferentielCtrl.availableReferentiels = _.reject(selectReferentielCtrl.state.availableReferentiels, function(o) {
        return (o.deleted != null );//|| !o.allowedTargets.includes('tablet')
     });
   }else{
     selectReferentielCtrl.availableReferentiels =selectReferentielCtrl.state.availableReferentiels;
   }
    updateInitialValue();
  }

  function updateInitialValue() {

    if (!selectReferentielCtrl.availableReferentiels) {
      return;
    }
    var initialValue;
    if (selectReferentielCtrl.initialReferentielKey) {
      var initialValues = _.filter(selectReferentielCtrl.state.availableReferentiels, {key: selectReferentielCtrl.initialReferentielKey});
      if (_.every(initialValues, 'deleted')) {
        initialValue = _.chain(initialValues).sortBy('created').last().value();
      } else {
        initialValue = _.find(initialValues, (value) => !value.deleted);
      }

      if (!initialValue) {
        return;
      }
    } else if (selectReferentielCtrl.takeFirstValueIfNullProvider()) {
      initialValue = _.chain(selectReferentielCtrl.state.availableReferentiels)
        .reject('deleted')
        .head()
        .value();
    } else {
      return;
    }

    onChange(initialValue);
    if (initialValue.deleted) {
      selectReferentielCtrl.deletedValue = initialValue;
      return;
    }

    selectReferentielCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {
    selectReferentielCtrl.onChangeFn() && selectReferentielCtrl.onChangeFn()(newValue);
  }
}
