import _ from 'lodash';

import { EVENTS as SETTINGS_STORE_EVENTS } from '../../settings/stores/settings.store';

export default UrlResolverService;

// @ngInject
function UrlResolverService($rootScope, SettingsStore, SettingsService, $q, $timeout, $urlMatcherFactory) {
  return {resolveUrl, resolveUrlFromString};

  function resolveUrlFromString(url, pathParams = false) {
      var i = url.indexOf("/");

      var server = url.substring(0, i);
      var path = url.substring(i);

      var oo = {server: server, path: path};

      return resolveUrl(oo, pathParams);
  }

  function resolveUrl({server, path}, pathParams = false) {
    var compiledPath;
    if (!pathParams) {
      compiledPath = path;
    } else {
      compiledPath = $urlMatcherFactory.compile(path).format(pathParams);
    }
    if (!server) {
      return $q.when(compiledPath);
    }

    return getSettings().then((settings) => {
      switch (server) {
        case AUTH:
          return settings.route_auth + compiledPath;
        case REFERENTIEL:
          return settings.route_referentiel + compiledPath;
        case NATURALISTE:
          return settings.route_naturaliste + compiledPath;
        case STATUS:
          return settings.route_status + compiledPath;
      }
      throw 'Invalid server: ' + server;
    });
  }

  function getSettings() {
    if (!SettingsStore.get().loaded) {
      return $q((resolve, reject) => {
        var unsubscribe = $rootScope.$on(SETTINGS_STORE_EVENTS.SETTINGS_STATE_CHANGED, () => {
          var state = SettingsStore.get();
          if (!SettingsStore.get().loaded) {
            return;
          }

          unsubscribe();
          resolve(state.settings);
        });
        if (!SettingsStore.get().loading) {
          SettingsService.load();
        }

        // Si on dépasse les 5s, on quitte
        $timeout(() => {
          unsubscribe();
          reject();
        }, 5000);
      });
    }

    return $q.when(SettingsStore.get().settings);
  }
}

export const STATUS = 'status';
export const AUTH = 'auth';
export const REFERENTIEL = 'referentiel';
export const NATURALISTE = 'naturaliste';
