import _ from 'lodash';

import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as FIELD_RECORD_STORE_EVENTS } from '../../stores/study-field-records.store';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as TAXONS_PROTECTION_LIST_STORE } from '../../../protection/stores/taxons-protection-list.store';
import { EVENTS as FIELD_RECORD_DESCRIPTION_STORE_EVENTS } from '../../stores/field-record-description.store';

require('./study-field-records-container.scss');

export default StudyFieldRecordContainerDirective;

// @ngInject
function StudyFieldRecordContainerDirective() {
  return {
    restrict: 'E',
    scope: { studyId: '@' },
    bindToController: true,
    controller: StudyFieldRecordsContainerController,
    controllerAs: 'studyFieldRecordsContainerCtrl',
    template: require('./study-field-records-container.html'),
    replace: true,
  };
}

// @ngInject
function StudyFieldRecordsContainerController(
  $scope,
  LxDialogService,
  HeaderService,
  StudyFieldRecordsService,
  StudyFieldRecordsStore,
  TaxonsProtectionListService,
  TaxonsProtectionListStore,
  StudyDetailsService,
  StudyDetailsStore,
  FieldRecordDescriptionService,
  FieldRecordDescriptionStore,
  $state,
  UrlResolverService,
  StudyConfiguration
) {
  var studyFieldRecordsContainerCtrl = this;

  studyFieldRecordsContainerCtrl.headerUpdated = false;
  studyFieldRecordsContainerCtrl.showExpert = !IS_CORDOVA;
  studyFieldRecordsContainerCtrl.queryState = {};
  studyFieldRecordsContainerCtrl.showDetails = false;
  studyFieldRecordsContainerCtrl.protectionState = {};
  studyFieldRecordsContainerCtrl.fieldRecordDescriptionState = {};

  studyFieldRecordsContainerCtrl.isLoading = isLoading;
  studyFieldRecordsContainerCtrl.isLoaded = isLoaded;
  studyFieldRecordsContainerCtrl.isProcessing = isProcessing;
  studyFieldRecordsContainerCtrl.canCreateSubElements = canCreateSubElements;
  studyFieldRecordsContainerCtrl.onFieldRecordSelected = onFieldRecordSelected;
  studyFieldRecordsContainerCtrl.onUpdateFilterForm = onUpdateFilterForm;
  studyFieldRecordsContainerCtrl.onUpdateDetailsForm = onUpdateDetailsForm;
  studyFieldRecordsContainerCtrl.onShowDescription = onShowDescription;
  studyFieldRecordsContainerCtrl.onDescriptionChanged = onDescriptionChanged;

  UrlResolverService.resolveUrl(StudyConfiguration.studyCSVEndpoint, {
    studyId: studyFieldRecordsContainerCtrl.studyId,
  }).then((href) => (studyFieldRecordsContainerCtrl.hrefCSV = href));

  $scope.$on(FIELD_RECORD_STORE_EVENTS.STUDY_FIELD_RECORDS_STATE_CHANGED, reload);
  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(TAXONS_PROTECTION_LIST_STORE.TAXONS_PROTECTION_LIST_STATE_CHANGED, reloadProtections);
  $scope.$on(
    FIELD_RECORD_DESCRIPTION_STORE_EVENTS.FIELD_RECORD_DESCRIPTION_STATE_CHANGED,
    reloadFieldRecordDescription
  );

  StudyDetailsService.load(studyFieldRecordsContainerCtrl.studyId);
  StudyFieldRecordsService.load(studyFieldRecordsContainerCtrl.studyId);

  function isLoading() {
    return (
      !studyFieldRecordsContainerCtrl.fieldRecordsState ||
      studyFieldRecordsContainerCtrl.fieldRecordsState.loading ||
      !studyFieldRecordsContainerCtrl.studyState ||
      studyFieldRecordsContainerCtrl.studyState.processing
    );
  }

  function isLoaded() {
    return (
      studyFieldRecordsContainerCtrl.fieldRecordsState &&
      studyFieldRecordsContainerCtrl.fieldRecordsState.loaded &&
      studyFieldRecordsContainerCtrl.studyState &&
      studyFieldRecordsContainerCtrl.studyState.loaded
    );
  }

  function isProcessing() {
    return (
      studyFieldRecordsContainerCtrl.fieldRecordDescriptionState &&
      (studyFieldRecordsContainerCtrl.fieldRecordDescriptionState.processing ||
        studyFieldRecordsContainerCtrl.fieldRecordDescriptionState.loading)
    );
  }

  function canCreateSubElements() {
    return (
      studyFieldRecordsContainerCtrl.studyState &&
      studyFieldRecordsContainerCtrl.studyState.study &&
      studyFieldRecordsContainerCtrl.studyState.study.canCreateSubElements &&
      studyFieldRecordsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      studyFieldRecordsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE
    );
  }

  function onFieldRecordSelected(fieldRecordId) {
    $state.go('field-record-details', { studyId: studyFieldRecordsContainerCtrl.studyId, fieldRecordId });
  }

  function onUpdateFilterForm(txGroups, surveyTypeIds) {
    studyFieldRecordsContainerCtrl.queryState = { txGroups, surveyTypeIds };
    filterSurveyTypes();
  }

  function onUpdateDetailsForm(showDetails) {
    studyFieldRecordsContainerCtrl.showDetails = showDetails;
  }

  function filterSurveyTypes() {
    var chain = _.chain(studyFieldRecordsContainerCtrl.fieldRecordsState.fieldRecordList);

    if (
      studyFieldRecordsContainerCtrl.queryState.txGroups &&
      studyFieldRecordsContainerCtrl.queryState.txGroups.length
    ) {
      chain = chain.filter((fieldRecord) =>
        _.includes(studyFieldRecordsContainerCtrl.queryState.txGroups, fieldRecord.txGroup || null)
      );
    }
    if (
      studyFieldRecordsContainerCtrl.queryState.surveyTypeIds &&
      studyFieldRecordsContainerCtrl.queryState.surveyTypeIds.length
    ) {
      chain = chain.filter((fieldRecord) =>
        _.includes(studyFieldRecordsContainerCtrl.queryState.surveyTypeIds, fieldRecord.surveyType || null)
      );
    }

    studyFieldRecordsContainerCtrl.fieldRecords = chain.value();
  }

  function buildTaxonsByTxrefs() {
    return _.chain(studyFieldRecordsContainerCtrl.fieldRecordsState.fieldRecordList)
      .groupBy((fieldRecord) => `${fieldRecord.txRefType}##${fieldRecord.txRefVersion}`)
      .mapValues((taxons) => _.chain(taxons).map('txKey').uniq().value())
      .map((txKeys, txRefCode) => {
        var [txRefType, txRefVersion] = txRefCode.split('##');
        return { txRefType, txRefVersion, txKeys };
      })
      .value();
  }

  function reloadProtections(event) {
    studyFieldRecordsContainerCtrl.protectionState = TaxonsProtectionListStore.getState();
  }

  function reload(event) {
    studyFieldRecordsContainerCtrl.fieldRecordsState = StudyFieldRecordsStore.getState();
    studyFieldRecordsContainerCtrl.studyState = StudyDetailsStore.getState();

    if (studyFieldRecordsContainerCtrl.studyState.loaded) {
      studyFieldRecordsContainerCtrl.timezone = studyFieldRecordsContainerCtrl.studyState.study.timezone;
      studyFieldRecordsContainerCtrl.canEditStudy =
        studyFieldRecordsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE &&
        studyFieldRecordsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG;
    }

    filterSurveyTypes();
    updateHeader();
  }

  function updateHeader() {
    if (!studyFieldRecordsContainerCtrl.studyState.study || studyFieldRecordsContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: studyFieldRecordsContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: { studyId: studyFieldRecordsContainerCtrl.studyId },
        },
      },
      {
        label: 'Observations',
      },
    ]);
    studyFieldRecordsContainerCtrl.headerUpdated = true;
  }

  function reloadFieldRecordDescription() {
    studyFieldRecordsContainerCtrl.fieldRecordDescriptionState = FieldRecordDescriptionStore.getState();

    if (
      studyFieldRecordsContainerCtrl.fieldRecordDescriptionState.loaded &&
      !studyFieldRecordsContainerCtrl.fieldRecordDescriptionState.fieldRecordDescription
    ) {
      LxDialogService.close('fieldRecordDescriptionDialog');
    }
  }

  function onDescriptionChanged(fieldRecordId, description) {
    FieldRecordDescriptionService.update(fieldRecordId, description);
  }

  function onShowDescription(fieldRecordId) {
    FieldRecordDescriptionService.load(fieldRecordId);
    LxDialogService.open('fieldRecordDescriptionDialog');
  }
}
