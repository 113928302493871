import _ from 'lodash';

require('./field-record-details-ls-amphibia-tab.scss');

export default FieldRecordDetailsLsAmphibiaTabDirective;

// @ngInject
function FieldRecordDetailsLsAmphibiaTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsAmphibiaTabController,
    controllerAs: 'fieldRecordDetailsLsAmphibiaTabCtrl',
    template: require('./field-record-details-ls-amphibia-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsAmphibiaTabController($scope, $timeout) {
  var fieldRecordDetailsLsAmphibiaTabCtrl = this;
  fieldRecordDetailsLsAmphibiaTabCtrl.onModeContactChanged = onModeContactChanged;
  fieldRecordDetailsLsAmphibiaTabCtrl.onSexeChanged = onSexeChanged;
  fieldRecordDetailsLsAmphibiaTabCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsLsAmphibiaTabCtrl.onComportementChanged = onComportementChanged;
  fieldRecordDetailsLsAmphibiaTabCtrl.onProbabiliteReproductionChanged = onProbabiliteReproductionChanged;
  fieldRecordDetailsLsAmphibiaTabCtrl.onTypeIndicePresenceChanged = onTypeIndicePresenceChanged;
  fieldRecordDetailsLsAmphibiaTabCtrl.onChange = onChange;
  fieldRecordDetailsLsAmphibiaTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsAmphibiaTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsAmphibiaTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsAmphibiaTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsAmphibiaTabCtrl.isNew = isNew;
  fieldRecordDetailsLsAmphibiaTabCtrl.isReleveItinerant = isReleveItinerant;

  $scope.$watch(fieldRecordDetailsLsAmphibiaTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsAmphibiaTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsAmphibiaTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsLsAmphibiaTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsAmphibiaTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsAmphibiaTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsAmphibiaTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsAmphibiaTabCtrl.form = form;
  });

  function onModeContactChanged(modeContact) {
    fieldRecordDetailsLsAmphibiaTabCtrl.data.modeContact = modeContact ? modeContact.id : null;
    onChange();
  }

  function onSexeChanged(sexe) {
    fieldRecordDetailsLsAmphibiaTabCtrl.data.sexe = sexe ? sexe.id : null;
    onChange();
  }

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    fieldRecordDetailsLsAmphibiaTabCtrl.data.stadeDeveloppement = stadeDeveloppement ? stadeDeveloppement.id : null;
    onChange();
  }

  function onComportementChanged(comportement) {
    fieldRecordDetailsLsAmphibiaTabCtrl.data.comportement = comportement ? comportement.id : null;
    onChange();
  }

  function onProbabiliteReproductionChanged(probabiliteReproduction) {
    fieldRecordDetailsLsAmphibiaTabCtrl.data.probabiliteReproduction = probabiliteReproduction ? probabiliteReproduction.id : null;
    onChange();
  }

  function onTypeIndicePresenceChanged(typeIndicePresence) {
    fieldRecordDetailsLsAmphibiaTabCtrl.data.typeIndicePresence = typeIndicePresence ? typeIndicePresence.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsAmphibiaTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsAmphibiaTabCtrl.onChangeFn()(fieldRecordDetailsLsAmphibiaTabCtrl.extraTableEnum, {
        data: {
          modeContact: fieldRecordDetailsLsAmphibiaTabCtrl.data.modeContact,
          sexe: fieldRecordDetailsLsAmphibiaTabCtrl.data.sexe,
          stadeDeveloppement: fieldRecordDetailsLsAmphibiaTabCtrl.data.stadeDeveloppement,
          comportement: fieldRecordDetailsLsAmphibiaTabCtrl.data.comportement,
          probabiliteReproduction: fieldRecordDetailsLsAmphibiaTabCtrl.data.probabiliteReproduction,
          typeIndicePresence: fieldRecordDetailsLsAmphibiaTabCtrl.data.typeIndicePresence
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsAmphibiaTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsAmphibiaTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsAmphibiaTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsAmphibiaTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsAmphibiaTabCtrl.isNewFn()();
  }

  function isReleveItinerant() {
    return fieldRecordDetailsLsAmphibiaTabCtrl.extraTableEnum === 'RI_AMPHIBIA';
  }

  function isFormValid() {
    return true;
  }
}
