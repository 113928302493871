import angular from 'angular';

import mapContainerComponent from './components/map-container/map-container.component';
import imagerySetFilter from './filters/imagery-set.filter';
import geometryTypeFilter from './filters/geometry-type.filter';
import layerProviderLocalService from './services/layer-provider.local.service';
import layerProviderRemoteService from './services/layer-provider.remote.service';
import geolocationLocalService from './services/geolocation.local.service';
import geolocationService from './services/geolocation.service';
import mapBackgroundsService from './services/map-backgrounds.service';
import mapgroundRepository from './repositories/mapbackground.repository';
import mapConfiguration from './configuration';
import localSettingsService from '../synchro/services/settings-opp.service';
import fakeSettingsService from '../synchro/services/settings-opp-fake.service';

const moduleName = 'shu-map';

angular
  .module(moduleName, [])
  .directive('shuMapContainer', mapContainerComponent)
  .filter('imagerySet', imagerySetFilter)
  .filter('geometryType', geometryTypeFilter)
  .factory('Geolocation', IS_CORDOVA ? geolocationLocalService : geolocationService)
  .factory('LayerProvider', IS_CORDOVA ? layerProviderLocalService : layerProviderRemoteService)
  .factory('SettingsOppService', IS_CORDOVA ? localSettingsService : fakeSettingsService)
  .factory('MapBackgroundsRepository', mapgroundRepository)
  .factory('MapBackgroundsService', mapBackgroundsService)
  .constant('MapConfiguration', mapConfiguration);

export default { moduleName };
