import { IMAGERY_SET } from '../model';

export default GeometryTypeFilter;

// @ngInject
function GeometryTypeFilter() {
  return function(input) {
    switch (input) {
      case 'POINT':
        return 'Point';
      case 'LINESTRING':
        return 'Ligne';
      case 'POLYGON':
        return 'Polygone';
      case 'ALL_SIMPLE':
        return 'Tout format';
      default:
        return '-';
    }
  };
}
