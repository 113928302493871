import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur consulte les relevés d'une étude
  STUDY_FIELD_SURVEYS_LOADING: 'shu-field-survey.study-survey-loading',
  STUDY_FIELD_SURVEYS_LOAD_SUCCESS: 'shu-field-survey.study-field-survey-load-success',
  STUDY_FIELD_SURVEYS_LOAD_ERROR: 'shu-field-survey.study-field-survey-load-error',
};

export default StudyFieldSurveysService;

// @ngInject
function StudyFieldSurveysService($rootScope, FieldSurveyRepository, LxNotificationService) {
  return {load};

  function load(studyId) {
    $rootScope.$emit(EVENTS.STUDY_FIELD_SURVEYS_LOADING);

    FieldSurveyRepository.getForStudy(studyId)
      .then((fieldSurveyListResult) => {
        $rootScope.$emit(EVENTS.STUDY_FIELD_SURVEYS_LOAD_SUCCESS, fieldSurveyListResult);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la récupération des informations des relevés. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.STUDY_FIELD_SURVEYS_LOAD_ERROR);
      });
  }
}
export { EVENTS };
