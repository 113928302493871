import { NATURALISTE } from '../base/services/url-resolver.service';

export default {
  fieldSurveyByStudyEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/field_survey'
  },
  fieldSurveyCurrentEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/current'
  },
  fieldSurveyByIdEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}'
  },
  createFieldSurveyEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/survey_type/{surveyTypeId}/field_survey'
  },
  fieldSurveyGeomEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}/geom'
  },
  fieldSurveyUpdateGeomEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}/kml'
  },
  fieldSurveyExtractGeomEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/extract/{geometryType}/kml'
  },
  fieldSurveyGeomForStudyEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/field_survey/geom'
  },
  fieldSurveyForClonageEndPoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}/forclonage'
  },
  clonageFieldSurveyEndPoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/field_survey/{fieldSurveyId}/clonage'
  }
};
