import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur lance le chargement des données
  TAXONS_PROTECTION_LIST_LOADING: 'shu-protection.taxons-protection-list-loading',
  TAXONS_PROTECTION_LIST_LOAD_SUCCESS: 'shu-protection.taxons-protection-list-load-success',
  TAXONS_PROTECTION_LIST_LOAD_ERROR: 'shu-protection.taxons-protection-list-load-error',
};

export default TaxonsProtectionListService;

// @ngInject
function TaxonsProtectionListService($rootScope, $q, StudyRepository, ProtectionRepository, LxNotificationService) {
  return {load};

  function load(studyId, taxonsByTxrefs) {
    $rootScope.$emit(EVENTS.TAXONS_PROTECTION_LIST_LOADING);

    StudyRepository.getGeom(studyId)
      .then(({studyBoundary}) => {
        $q.all(_.map(taxonsByTxrefs, ({txRefType, txRefVersion, txKeys}) =>
            ProtectionRepository.getForProtectionsForTaxons(txRefType, txRefVersion, txKeys, studyBoundary)))
          .then(
            (protectionsWithTaxonsByTxRef) => $rootScope.$emit(EVENTS.TAXONS_PROTECTION_LIST_LOAD_SUCCESS, _.flatten(protectionsWithTaxonsByTxRef)),
            (error) => {
              LxNotificationService.error('Une erreur est survenue au chargement des protections. ' + (error.data || ''));
              $rootScope.$emit(EVENTS.TAXONS_PROTECTION_LIST_LOAD_ERROR);
            }
           );
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des protections. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TAXONS_PROTECTION_LIST_LOAD_ERROR);
      });
  }
}
export { EVENTS };
