let element;

function getInjector() {
  if (!element) {
    element = angular.element(document.body);
  }
  return element.injector();
}

let $stateProvider = () => getInjector().get('$state');

export {
  $stateProvider
};
