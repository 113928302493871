import _ from 'lodash';

require('./field-survey-details-pe-tab.scss');

export default FieldSurveyDetailsPETabDirective;

// @ngInject
function FieldSurveyDetailsPETabDirective() {
  return {
    restrict: 'AE',
    scope: {
      initialDataProvider: '&initialData',
      fieldSurveyStateProvider: '&fieldSurveyState',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete',
      canSubmitFn: '&canSubmit',
      isNewFn: '&isNew',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldSurveyDetailsPETabController,
    controllerAs: 'fieldSurveyDetailsPETabCtrl',
    template: require('./field-survey-details-pe-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyDetailsPETabController($scope, $timeout) {
  var fieldSurveyDetailsPETabCtrl = this;
  fieldSurveyDetailsPETabCtrl.onMilieuChange = onMilieuChange;
  fieldSurveyDetailsPETabCtrl.onPrecisionMilieuUnChange = onPrecisionMilieuUnChange;
  fieldSurveyDetailsPETabCtrl.onChange = onChange;
  fieldSurveyDetailsPETabCtrl.onDelete = onDelete;
  fieldSurveyDetailsPETabCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsPETabCtrl.isNew = isNew;
  fieldSurveyDetailsPETabCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldSurveyDetailsPETabCtrl.isAutoLocatedError = isAutoLocatedError;
  fieldSurveyDetailsPETabCtrl.locationPrecision = locationPrecision;

  $scope.$watch(fieldSurveyDetailsPETabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldSurveyDetailsPETabCtrl.data = {};
    } else {
      fieldSurveyDetailsPETabCtrl.data = _.cloneDeep(initialData);
    }

    if (isNew() && !fieldSurveyDetailsPETabCtrl.data.duree) {
      fieldSurveyDetailsPETabCtrl.data.duree = 10;
    }

    fieldSurveyDetailsPETabCtrl.onChange();
  });

  $scope.$watch(fieldSurveyDetailsPETabCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }

    fieldSurveyDetailsPETabCtrl.fieldSurveyState = fieldSurveyState;
  });

  $scope.$watch(fieldSurveyDetailsPETabCtrl.formProvider, (form) => {
    fieldSurveyDetailsPETabCtrl.form = form;
  });

  function onMilieuChange(currentMilieu) {
    fieldSurveyDetailsPETabCtrl.data.milieu = currentMilieu ? currentMilieu.id : null;
    onChange();
  }

  function onPrecisionMilieuUnChange(currentPrecisionMilieuUn) {
    fieldSurveyDetailsPETabCtrl.data.precisionMilieuUn = currentPrecisionMilieuUn ? currentPrecisionMilieuUn.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldSurveyDetailsPETabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldSurveyDetailsPETabCtrl.onChangeFn()('PE', {
        data: {
          duree: fieldSurveyDetailsPETabCtrl.data.duree,
          numPassage: fieldSurveyDetailsPETabCtrl.data.numPassage,
          milieu: fieldSurveyDetailsPETabCtrl.data.milieu,
          precisionMilieuUn: fieldSurveyDetailsPETabCtrl.data.precisionMilieuUn,
          precisionMilieuDeux: fieldSurveyDetailsPETabCtrl.data.precisionMilieuDeux
        },
        formValid: isFormValid()
      });
    });
  }

  function onDelete() {
    fieldSurveyDetailsPETabCtrl.onDeleteFn()();
  }

  function canSubmit() {
    return fieldSurveyDetailsPETabCtrl.canSubmitFn()();
  }

  function isNew() {
    return fieldSurveyDetailsPETabCtrl.isNewFn()();
  }

  // TODO : vérifier que les champs int sont bien des valeurs entières ?
  function isFormValid() {
    return !!(fieldSurveyDetailsPETabCtrl.form.duree && fieldSurveyDetailsPETabCtrl.form.duree.$valid);
  }

  function locationPrecision() {
    return fieldSurveyDetailsPETabCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return fieldSurveyDetailsPETabCtrl.isAutoLocatedValidFn() &&
           fieldSurveyDetailsPETabCtrl.isAutoLocatedValidFn()();
  }

  function isAutoLocatedError() {
    return fieldSurveyDetailsPETabCtrl.isAutoLocatedErrorFn() &&
           fieldSurveyDetailsPETabCtrl.isAutoLocatedErrorFn()();
  }
}
