
const EVENTS = {
  // Actions lancées quand l'utilisateur click sur le bouton clonnage d'un relevé
  FIELD_SURVEY_CLONAGE_LOADING: 'shu-field-survey.field-survey-clonage-loading',
  FIELD_SURVEY_CLONAGE_LOAD_SUCCESS: 'shu-field-survey.field-survey-clonage-load-success',
  FIELD_SURVEY_CLONAGE_LOAD_ERROR: 'shu-field-survey.field-survey-clonage-load-error',
};

export default FieldSurveyClonageService;

// @ngInject
function FieldSurveyClonageService($rootScope, FieldSurveyRepository, LxNotificationService) {
  return {load};

  function load(fieldSurveyId, expertPosition) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_CLONAGE_LOADING);

    FieldSurveyRepository.getForClonage(fieldSurveyId, expertPosition)
      .then((fieldSurvey) => {
        $rootScope.$emit(EVENTS.FIELD_SURVEY_CLONAGE_LOAD_SUCCESS, fieldSurvey);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la récupération des informations du relevé pour le clonnage.');
        $rootScope.$emit(EVENTS.FIELD_SURVEY_CLONAGE_LOAD_ERROR);
      });
  }
}

export {EVENTS};
