import { CREATION_MODE } from './model';

export default FieldSurveyRouterConfig;

// @ngInject
function FieldSurveyRouterConfig($stateProvider) {
  $stateProvider.state('study-field-records', {
    url: '/study/{studyId}/field-record',
    template: '<shu-study-field-records-container study-id="{{ ::routeCtrl.studyId }}"></shu-study-field-records-container>',
    needAuthenticatedUser: true,
    controller: StudyFieldRecordsRouteController,
    controllerAs: 'routeCtrl'
  }).state('field-survey-field-records', {
    url: '/study/{studyId}/field-survey/{fieldSurveyId}',
    template: `
      <shu-field-survey-field-records-container study-id="{{ ::routeCtrl.studyId }}" field-survey-id="{{ ::routeCtrl.fieldSurveyId}}">
      </shu-field-survey-field-records-container>
    `,
    needAuthenticatedUser: true,
    controller: FieldSurveyFieldRecordsRouteController,
    controllerAs: 'routeCtrl'
  }).state('field-survey-field-records-rp', {
    url: '/study/{studyId}/field-survey-rp/{fieldSurveyId}',
    template: `
      <shu-field-survey-field-records-rp study-id="{{ ::routeCtrl.studyId }}" field-survey-id="{{ ::routeCtrl.fieldSurveyId}}">
      </shu-field-survey-field-records-rp>
    `,
    needAuthenticatedUser: true,
    controller: FieldSurveyFieldRecordsRouteController,
    controllerAs: 'routeCtrl'
  }).state('field-record-creation', {
    url: '/study/{studyId}/field-record/create?fieldSurveyId&txKey&txRefKey&txRefVersion&txRefFilter&txGroup&txHeadcount&txHeadcountAccuracy' +
      '&creationMode&fromState&fromParams&extraTables',
    template: `<shu-field-record-details-container study-id="{{ ::routeCtrl.studyId }}"
                                                   field-survey-id="{{ ::routeCtrl.fieldSurveyId }}"
                                                   tx-key="{{ ::routeCtrl.txKey }}"
                                                   tx-ref-key="{{ ::routeCtrl.txRefKey }}"
                                                   tx-ref-version="{{ ::routeCtrl.txRefVersion }}"
                                                   tx-ref-filter="{{ ::routeCtrl.txRefFilter }}"
                                                   tx-group="{{ ::routeCtrl.txGroup }}"
                                                   tx-headcount="{{ ::routeCtrl.txHeadcount }}"
                                                   tx-headcount-accuracy="{{ ::routeCtrl.txHeadcountAccuracy }}"
                                                   creation-mode="{{ ::routeCtrl.creationMode }}"
                                                   from-state="{{ ::routeCtrl.fromState }}"
                                                   from-params="::routeCtrl.fromParams"
                                                   extra-tables="::routeCtrl.extraTables">
               </shu-field-record-details-container>`,
    needAuthenticatedUser: true,
    controller: FieldRecordsCreationRouteController,
    controllerAs: 'routeCtrl'
  }).state('field-record-details', {
    url: '/study/{studyId}/field-record/{fieldRecordId}?fromState&fromParams',
    template: `<shu-field-record-details-container study-id="{{ ::routeCtrl.studyId }}"
                                                   field-record-id="{{ ::routeCtrl.fieldRecordId }}"
                                                   from-state="{{ ::routeCtrl.fromState }}"
                                                   from-params="::routeCtrl.fromParams">
               </shu-field-record-details-container>`,
    needAuthenticatedUser: true,
    controller: FieldRecordsDetailsRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function StudyFieldRecordsRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
}

// @ngInject
function FieldSurveyFieldRecordsRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
  routeCtrl.fieldSurveyId = $stateParams.fieldSurveyId;
}
// @ngInject
function FieldRecordsCreationRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
  routeCtrl.fromState = $stateParams.fromState;
  try {
    routeCtrl.fromParams = JSON.parse($stateParams.fromParams);
  } catch (e) {
    routeCtrl.fromParams = {};
  }
  try {
    routeCtrl.extraTables = JSON.parse($stateParams.extraTables);
  } catch (e) {
    routeCtrl.extraTables = {};
  }

  routeCtrl.fieldSurveyId = $stateParams.fieldSurveyId;
  routeCtrl.txKey = $stateParams.txKey;
  routeCtrl.txRefKey = $stateParams.txRefKey;
  routeCtrl.txRefVersion = $stateParams.txRefVersion;
  routeCtrl.txRefFilter = $stateParams.txRefFilter;
  routeCtrl.txGroup = $stateParams.txGroup;
  routeCtrl.txHeadcount = $stateParams.txHeadcount;
  routeCtrl.txHeadcountAccuracy = $stateParams.txHeadcountAccuracy ;
  routeCtrl.creationMode = $stateParams.creationMode || CREATION_MODE.WITHOUT_GEOMETRY;
}
// @ngInject
function FieldRecordsDetailsRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
  routeCtrl.fieldRecordId = $stateParams.fieldRecordId;
  routeCtrl.fromState = $stateParams.fromState;
  try {
    routeCtrl.fromParams = JSON.parse($stateParams.fromParams);
  } catch (e) {
    routeCtrl.fromParams = {};
  }
}
