import Immutable from 'seamless-immutable';
import _ from 'lodash';

const EVENTS = {
  LOCATION_REFRESHED: 'shu-map.location-refreshed',
  BACKGROUND_LOCATION: 'shu-map.background-location',
  REMOVE_TRACKING_POINTS: 'shu-map.remove-tracking-points',
  TRACKING_STATUS: 'shu-map.tracking-status',
};

export default RemoteGeolocation;

function RemoteGeolocation($q, $rootScope) {

  var state = Immutable({
  watchId: null,
  position: null,
  initializing: false
});

  var options = {
  timeout: 10000,
  enableHighAccuracy: true
  };
  var deferredLocationRequests = [];

return {isInitializing,
    getCurrentPosition,
    isLocationServiceActive,
    toggleLocationService,
    getPrecision,
    startTrackingService,
    stopTrackingService,
    getTracking,
    getTrackingForStudy,
    setTracking,
    removeTrackingPoints,
    removeTrackings,
    getTrackingStatus,
    getTrackedSurveys
  };

  function isInitializing() {
    return state.initializing;
  }


  function getPrecision() {
    return state.position ? state.position.coords.accuracy : null;
  }

  function getCurrentPosition() {

      if (isLocationServiceActive() && !state.initializing) {
      if (state.position === null) {
        return $q.reject();
      }
      return $q.when(state.position);
    }

      var deferred = $q.defer();

      deferredLocationRequests.push(deferred);

      startLocationService();

      return deferred.promise;
    }



  function startLocationService() {
    if (isLocationServiceActive()) {
      return;
    }

    let watchId = navigator.geolocation.watchPosition(function(position) {
      state = state.merge({
        position: Immutable({
          timestamp: position.timestamp,
          coords: _.toPlainObject(position.coords)
        }),
        initializing: false
      });

      $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
      deferredSuccess(state.position);
    }, function(error) {
      state = state.merge({
              position: null,
              initializing: false
            });
      $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
      deferredError(error);
      }, options);

      state = Immutable({
        watchId,
        position: null,
        initializing: true
      });

      $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
  }

  function deferredSuccess(position) {
    _.forEach(deferredLocationRequests, (deferred) => deferred.resolve(position));
    deferredLocationRequests = [];
  }

  function deferredError(error) {
    _.forEach(deferredLocationRequests, (deferred) => deferred.reject(error));
    deferredLocationRequests = [];
  }

  function stopLocationService() {
      if (!isLocationServiceActive()) {
        return;
      }

      navigator.geolocation.clearWatch(state.watchId);
      state = Immutable({
        watchId: null,
        position: null,
        initializing: false
      });
      $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
  }


  function toggleLocationService() {
    if (!isLocationServiceActive()) {
      startLocationService();
    } else {
      stopLocationService();
    }
  }

  function isLocationServiceActive() {
        return state.watchId !== null;
    }

  function stopTrackingService() {
    console.error('Geolocation is not available in web mode');
  }

  function startTrackingService() {
      console.error('Geolocation is not available in web mode');
    }

  function getTrackingStatus() {
    console.error('Geolocation is not available in web mode');
  }

  function getTracking() {
      console.error('Geolocation is not available in web mode');
  }

  function getTrackingForStudy(studyId) {
    console.error('Geolocation is not available in web mode');
  }

  function getTrackedSurveys() {
      console.error('Geolocation is not available in web mode');
  }

  function removeTrackingPoints(timestamp, studyId, direction, surveyId) {
      console.error('Geolocation is not available in web mode');
  }

  function removeTrackings(studyId, surveyId) {
      console.error('Geolocation is not available in web mode');
  }

  function setTracking(value) {
    console.error('Geolocation is not available in web mode');
  }

}
RemoteGeolocation.$inject = ['$q', '$rootScope'];
export { EVENTS };
