import { STUDY_RESTRICTION } from '../model';

export default StudyRestrictionFilter;

// @ngInject
function StudyRestrictionFilter() {
  return function(input) {
    switch (input) {
      case STUDY_RESTRICTION.COPYRIGHT:
        return 'Droits d\'auteur';
      case STUDY_RESTRICTION.PATENT:
        return 'Brevet';
      case STUDY_RESTRICTION.PATENT_PENDING:
        return 'En attente de brevet';
      case STUDY_RESTRICTION.TRADEMARK:
        return 'Marque';
      case STUDY_RESTRICTION.LICENSE:
        return 'Licence';
      case STUDY_RESTRICTION.INTELLECTUAL_PROPERTYRIGHTS:
        return 'Droits de propriété intellectuelle';
      case STUDY_RESTRICTION.RESTRICTED:
        return 'Limité';
      case STUDY_RESTRICTION.OTHER_RESTRICTIONS:
        return 'Restrictions non prévues';
      default:
        return '-';
    }
  };
}
