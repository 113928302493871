const EVENTS = {
  // Action lancée quand l'utilisateur met à jour les données,
  FIELD_RECORD_VALIDATE_PROCESSING: 'shu-field-record.field-record-validate-processing',
  FIELD_RECORD_VALIDATE_PROCESSING_SUCCESS: 'shu-field-record.field-record-validate-processing-success',
  FIELD_RECORD_VALIDATE_PROCESSING_ERROR: 'shu-field-record.field-record-validate-processing-error'
};

export default FieldRecordValidateService;

// @ngInject
function FieldRecordValidateService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {validate};

  function validate(fieldRecordId, headcount) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_VALIDATE_PROCESSING);
    FieldRecordRepository.validateFieldRecord(fieldRecordId, headcount)
      .then(
        () => {
          LxNotificationService.success('Validation effectuée avec succès');
          $rootScope.$emit(EVENTS.FIELD_RECORD_VALIDATE_PROCESSING_SUCCESS, fieldRecordId, headcount);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue lors de la validation. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_RECORD_VALIDATE_PROCESSING_ERROR);
        }
      );
  }
}
export { EVENTS };
