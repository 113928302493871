import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur charge les protocoles
  PROTOCOL_LIST_RESULTS_LOADING: 'shu-protocol.protocol-list-results-loading',
  PROTOCOL_LIST_RESULTS_LOAD_SUCCESS: 'shu-protocol.protocol-list-results-load-success',
  PROTOCOL_LIST_RESULTS_LOAD_ERROR: 'shu-protocol.protocol-list-results-load-error',
};

export default ProtocolListService;

// @ngInject
function ProtocolListService($rootScope, ProtocolListStore, ProtocolRepository, LxNotificationService) {
  return {load};

  function load() {
    if (ProtocolListStore.getState().loaded) {
      $rootScope.$emit(EVENTS.PROTOCOL_LIST_RESULTS_LOAD_SUCCESS, ProtocolListStore.getState().results);
      return;
    }

    $rootScope.$emit(EVENTS.PROTOCOL_LIST_RESULTS_LOADING);

    ProtocolRepository.list()
      .then((result) => {
        $rootScope.$emit(EVENTS.PROTOCOL_LIST_RESULTS_LOAD_SUCCESS, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des protocoles. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PROTOCOL_LIST_RESULTS_LOAD_ERROR);
      });
  }
}
export { EVENTS };
