import Immutable from 'seamless-immutable';
import _ from 'lodash';

import { EVENTS as SERVICE_EVENTS } from '../services/favoris.service';

const EVENTS = {
  FAVORIS_STATE_CHANGED: 'shu-favoris.favoris-state-changed'
};

export default FavorisStore;

// @ngInject
function FavorisStore($rootScope) {
  var state = Immutable({
    favoris: {},
    loading: true,
    loaded: false,
    error: false
  });

  $rootScope.$on(SERVICE_EVENTS.FAVORIS_LOADING, onFavorisLoading);
  $rootScope.$on(SERVICE_EVENTS.FAVORIS_LOAD_SUCCESS, onFavorisLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FAVORIS_LOAD_ERROR, onFavorisLoadError);

  return {getState};

  function getState(studyId) {
    return Immutable({
      favoris: state.favoris[studyId] || [],
      loading: state.loading,
      loaded: state.loaded,
      error: state.error
    });
  }

  function onFavorisLoading() {
    state = Immutable({
      favoris: state.favoris,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FAVORIS_STATE_CHANGED);
  }

  function onFavorisLoadSuccess(event, studyId, favoris) {
    var asMutable = state.favoris.asMutable();
    asMutable[studyId] = favoris;
    state = Immutable({
      favoris: asMutable,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FAVORIS_STATE_CHANGED);
  }

  function onFavorisLoadError() {
    state = Immutable({
      favoris: {},
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FAVORIS_STATE_CHANGED);
  }

}

export { EVENTS };
