import _ from 'lodash';
export default TaxonRefRepository;

// @ngInject
function TaxonRefRepository(DatabaseProvider, $q) {
  return { getAllTypes, getVersions, getTaxonRef, getAllFiltersTypes, getAllTxRefFilters };

  // TODO: Fusionner getAllTypes et getVersions en une seule requête

  function getAllTypes(target) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        database.readTransaction((transaction) => {
          transaction.executeSql(`SELECT * FROM tx_ref_import WHERE key = ref ORDER BY key ASC`, [], (tx, data) => {
            var txRefs = [];
            for (let i = 0; i < data.rows.length; i++) {
              data.rows.item(i);
              txRefs.push({
                key: data.rows.item(i).ref,
                version: data.rows.item(i).version,
                name: _.startCase(data.rows.item(i).ref + ' ' + data.rows.item(i).version),
                ref: data.rows.item(i).ref,
                imported: null,
                updated: null,
              });
            }
            resolve(txRefs);
          });
        });
      });
    });
  }
  function getVersions(key) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        database.readTransaction((transaction) => {
          transaction.executeSql(`SELECT * FROM tx_ref_import WHERE key = ?`, [key], (tx, data) => {
            var txRefs = [];
            for (let i = 0; i < data.rows.length; i++) {
              txRefs.push(data.rows.item(i));
            }
            resolve(txRefs);
          });
        });
      });
    });
  }

  function getTaxonRef(key) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        database.readTransaction((transaction) => {
          transaction.executeSql(`SELECT * FROM tx_ref_import WHERE key = ?`, [key], (tx, data) => {
            if (data.rows.length === 1) {
              resolve(data.rows.item(0));
              return;
            }
            resolve(null);
          });
        });
      });
    });
  }

  function getAllFiltersTypes(txRefKey) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        database.readTransaction((transaction) => {
          transaction.executeSql(
            `SELECT * FROM tx_ref_import  WHERE ref = ? AND key <> ref ORDER BY key ASC`,
            [txRefKey],
            (tx, data) => {
              var txRefs = [];
              for (let i = 0; i < data.rows.length; i++) {
                txRefs.push(data.rows.item(i));
              }
              resolve(txRefs);
            }
          );
        });
      });
    });
  }

  function getAllTxRefFilters() {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        database.readTransaction((transaction) => {
          transaction.executeSql(
            `SELECT * FROM tx_ref_import WHERE key <> ref and ref is not null and ref != "" ORDER BY key ASC`,
            [],
            (tx, data) => {
              var txRefs = [];
              for (let i = 0; i < data.rows.length; i++) {
                const rowData = data.rows.item(i);
                if (rowData.ref) {
                  txRefs.push(rowData);
                }
              }
              resolve(txRefs);
            }
          );
        });
      });
    });
  }
}
