import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'un relevé type
  SURVEY_TYPE_DETAILS_LOADING: 'shu-survey-type.survey-type-details-loading',
  SURVEY_TYPE_DETAILS_PROCESSING: 'shu-survey-type.survey-type-details-processing',
  SURVEY_TYPE_DETAILS_LOAD_SUCCESS: 'shu-survey-type.survey-type-details-load-success',
  SURVEY_TYPE_DETAILS_LOAD_ERROR: 'shu-survey-type.survey-type-details-load-error',
  SURVEY_TYPE_DETAILS_CREATING: 'shu-survey-type.survey-type-details-creating'
};

export default SurveyTypeDetailsService;

// @ngInject
function SurveyTypeDetailsService($rootScope, SurveyTypeRepository, SurveyTypeDetailsStore, $q, LxNotificationService) {
  return {load, onFormSubmit, onNewFormSubmit, onDelete};

  function load(surveyTypeId) {
    $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOADING);

    SurveyTypeRepository.getSurveyType(surveyTypeId)
      .then(
        (surveyType) => $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_SUCCESS, surveyType),
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement du relevé-type. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_ERROR);
        }
      );
  }

  function onNewFormSubmit(surveyTypeId, surveyTypeForm, formController) {
    $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_CREATING);
    SurveyTypeRepository.createSurveyType(surveyTypeForm)
      .then(
        (surveyType) => {
          formController.$setPristine();
          LxNotificationService.success('Relevé-type enregistré.');
          $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_SUCCESS, surveyType);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde du relevé-type. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_ERROR);
        }
      );
  }

  function onFormSubmit(surveyTypeId, surveyTypeForm, formController) {
    $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_PROCESSING);
    SurveyTypeRepository.updateSurveyType(surveyTypeId, surveyTypeForm)
      .then(
        (surveyType) => {
          formController.$setPristine();
          LxNotificationService.success('Relevé-type enregistré');
          $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_SUCCESS, surveyType);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde du relevé-type. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_ERROR);
        }
      );
  }

  function onDelete(surveyTypeId) {
    $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_PROCESSING);
    SurveyTypeRepository.deleteSurveyType(surveyTypeId)
      .then(
        (surveyType) => {
          LxNotificationService.success('Relevé-type supprimé.');
          $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_SUCCESS, null);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la suppression du relevé-type. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.SURVEY_TYPE_DETAILS_LOAD_ERROR);
        }
      );
  }
}
export { EVENTS };
