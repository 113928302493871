import { ACCURACY } from '../model';

export default FieldRecordAccuracyFilter;

// @ngInject
function FieldRecordAccuracyFilter() {
  return function(input) {
    switch (input) {
      case ACCURACY.EXACT:
        return 'Égal';
      case ACCURACY.APPROXIMATE:
        return 'Environ';
      case ACCURACY.MINIMUM:
        return 'Au moins';
      case ACCURACY.ESTIMATED:
        return 'Estimé';
      default:
        return '-';
    }
  };
}
