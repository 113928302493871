import _ from 'lodash';

import { EVENTS as SURVEY_TYPE_LIST_STORE_EVENTS } from '../../../survey-type/stores/survey-type-list.store';

require('./study-field-records-form.scss');

export default StudySearchFormDirective;

// @ngInject
function StudySearchFormDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      queryStateProvider: '&queryState',
      showDetailsProvider: '&showDetails',
      onUpdateFilterFormFn: '&onUpdateFilterForm',
      onUpdateDetailsFormFn: '&onUpdateDetailsForm'
    },
    controller: StudyFieldRecordsController,
    controllerAs: 'studyFieldRecordsCtrl',
    bindToController: true,
    template: require('./study-field-records-form.html'),
    replace: true
  };
}

// @ngInject
function StudyFieldRecordsController($scope, SurveyTypeListStore, SurveyTypeListService) {
  var studyFieldRecordsCtrl = this;

  studyFieldRecordsCtrl.onQueryUpdated = onQueryUpdated;
  studyFieldRecordsCtrl.onShowDetailsUpdated = onShowDetailsUpdated;

  $scope.$on(SURVEY_TYPE_LIST_STORE_EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED, onSurveyTypeListStateChange);

  $scope.$watch(studyFieldRecordsCtrl.queryStateProvider, (queryState) => {
    studyFieldRecordsCtrl.queryState = queryState;
    updateFromQueryState();
  });
  $scope.$watch(studyFieldRecordsCtrl.showDetailsProvider, (showDetails) => {
    studyFieldRecordsCtrl.showDetails = showDetails;
  });

  load();

  function onQueryUpdated() {
    studyFieldRecordsCtrl.onUpdateFilterFormFn()(studyFieldRecordsCtrl.txGroups, _.map(studyFieldRecordsCtrl.surveyTypes, 'id'));
  }

  function onShowDetailsUpdated() {
    studyFieldRecordsCtrl.onUpdateDetailsFormFn()(studyFieldRecordsCtrl.showDetails);
  }

  function onSurveyTypeListStateChange() {
    studyFieldRecordsCtrl.surveyTypeListState = SurveyTypeListStore.getState();

    if (studyFieldRecordsCtrl.surveyTypeListState.results) {
      studyFieldRecordsCtrl.availableSurveyTypes = [
        {id: null, name: 'Observations opportunistes'}
      ].concat(_.cloneDeep(studyFieldRecordsCtrl.surveyTypeListState.results));
    }
    studyFieldRecordsCtrl.availableTxGroups = _.chain(studyFieldRecordsCtrl.surveyTypeListState.results)
                                               .map('protocolTxGroups')
                                               .flatten()
                                               .map('txGroup')
                                               .uniq()
                                               .sort()
                                               .value();
    updateFromQueryState();
  }

  function updateFromQueryState() {
    if (!studyFieldRecordsCtrl.queryState) {
      return;
    }
    studyFieldRecordsCtrl.txGroups = studyFieldRecordsCtrl.queryState.txGroups;
    studyFieldRecordsCtrl.surveyTypeIds = studyFieldRecordsCtrl.queryState.surveyTypeIds;

    if (studyFieldRecordsCtrl.availableSurveyTypes && studyFieldRecordsCtrl.surveyTypeIds) {
      studyFieldRecordsCtrl.surveyTypes = _.filter(studyFieldRecordsCtrl.availableSurveyTypes,
        (surveyType) => _.includes(studyFieldRecordsCtrl.surveyTypeIds, surveyType.id));
    }
  }

  function load() {
    SurveyTypeListService.load(studyFieldRecordsCtrl.studyId);
  }
}
