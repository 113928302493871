export default AutoSyncService;

// @ngInject
function AutoSyncService(
  $rootScope,
  //$scope,
  HeaderStore,
  AuthStore,
  ProfileStore,
  ProfileService,
  StudyDetailsStore,
  $state,
  FieldSurveyDetailsStore,
  Geolocation,
  $timeout,
  LxDialogService,
  LxNotificationService,
  StudyRepository,
  FieldSurveyRepository,
  $q,
  SynchroRepository
) {
  return {
    setAutoSyncStudyId,
    openDialogAutoSync,
    onSubmitAutoSyncInterval,
    saveAutoSyncInterval,
    saveAutoSyncEnabled,
    saveAutoSync
     }

  function setAutoSyncStudyId(curStudyId){
    SynchroRepository.setAutoSyncStudyId(curStudyId);
  }
  /*$rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_SURVEYS_LOAD_SUCCESS, (fieldSurveys) => {
    console.log('fieldSurveys', fieldSurveys);
  });

  autoSyncCtrl.autoSync = {};
  autoSyncCtrl.openDialogAutoSync = openDialogAutoSync;
  autoSyncCtrl.onSubmitAutoSyncInterval = onSubmitAutoSyncInterval;
  autoSyncCtrl.saveAutoSyncInterval = saveAutoSyncInterval;
  autoSyncCtrl.saveAutoSyncEnabled = saveAutoSyncEnabled;
  autoSyncCtrl.isAutoSyncRunning = false;
  $rootScope.$on(SYNCHRO_EVENTS.AUTO_SYNC_STATUS, (e, value) => {
    autoSyncCtrl.isAutoSyncRunning = value === 'running';
  });*/

  /*let curStudyId = null;
  $rootScope.$on('$stateChangeStart', (e, toState, toParams, fromState, fromParams) => {
    curStudyId = toState.url.indexOf('/study/{studyId}') === 0 ? Number(_.get(toParams, 'studyId', 0)) : null;
    SynchroRepository.setAutoSyncStudyId(curStudyId || null);
  });*/

  function openDialogAutoSync(headerCtrl) {
    SynchroRepository.getAutoSync().then((as) => {
      headerCtrl.autoSync = as;
      headerCtrl.autoSync.interval = as.interval / 1000 / 60;
    });
    LxDialogService.open('dialog-autosync');
  }

  function onSubmitAutoSyncInterval(headerCtrl) {
    saveAutoSync(headerCtrl);
    window.cordova.plugins.Keyboard.close();
  }

  function saveAutoSyncInterval(headerCtrl) {
    saveAutoSync(headerCtrl);
  }

  function saveAutoSyncEnabled(headerCtrl) {
    saveAutoSync(headerCtrl).then(() => {
      SynchroRepository.setAutoSyncEnabled(headerCtrl.autoSync.isEnabled);
    });
  }

  function saveAutoSync(headerCtrl) {
    return new Promise((resolve) => {
      SynchroRepository.getAutoSync().then((as) => {
        as.interval = headerCtrl.autoSync.interval * 60 * 1000;
        SynchroRepository.saveAutoSync(as).then(() => {
          resolve();
        });
      });
    });
  }

}

//fix e <- provider" error
//AutoSyncService.$inject = ['$rootScope', '$q', '$state', '$timeout'];
