export default StudyRouterConfig;

// @ngInject
function StudyRouterConfig($stateProvider) {
  $stateProvider.state('study-search', {
    url: '/study/search',
    template: '<shu-study-search-container></shu-study-search-container>',
    needAuthenticatedUser: true
  }).state('study-details', {
    url: '/study/{studyId}/details',
    template: '<shu-study-details-container study-id="{{ ::routeCtrl.studyId }}"></shu-study-details-container>',
    needAuthenticatedUser: true,
    controller: StudyRouteController,
    controllerAs: 'routeCtrl'
  }).state('study-map', {
    url: '/study/{studyId}/map',
    template: '<shu-study-map study-id="{{ ::routeCtrl.studyId }}"></shu-study-map>',
    needAuthenticatedUser: true,
    controller: StudyRouteController,
    controllerAs: 'routeCtrl'
  }).state('study-grid', {
    url: '/study/{studyId}/grid',
    template: '<shu-study-grid study-id="{{ ::routeCtrl.studyId }}"></study-grid>',
    needAuthenticatedUser: true,
    controller: StudyRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function StudyRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
}
