import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur consulte un relevé
  FIELD_SURVEY_DETAILS_LOADING: 'shu-field-survey.field-survey-details-loading',
  FIELD_SURVEY_DETAILS_LOAD_SUCCESS: 'shu-field-survey.field-survey-details-load-success',
  FIELD_SURVEY_DETAILS_LOAD_ERROR: 'shu-field-survey.field-survey-details-load-error',
  // Actions lancées quand l'utilisateur consulte les médias d'un relevé
  FIELD_SURVEY_MEDIAS_LOADING: 'shu-field-survey.field-survey-medias-loading',
  FIELD_SURVEY_MEDIAS_LOAD_SUCCESS: 'shu-field-survey.field-survey-medias-load-success',
  FIELD_SURVEY_MEDIAS_LOAD_ERROR: 'shu-field-survey.field-survey-medias-load-error',
  // Action lancée quand l'utilisateur mets à jour les données,
  FIELD_SURVEY_DETAILS_PROCESSING: 'shu-field-survey.field-survey-details-processing',
};

export default FieldSurveyDetailsService;

// @ngInject
function FieldSurveyDetailsService(
  $rootScope,
  FieldSurveyRepository,
  FavorisUtils,
  FavorisService,
  LxNotificationService,
  $state
) {
  return { load, createFieldSurvey, cloneFieldSurvey, updateFieldSurvey, onDelete };

  function load(fieldSurveyId) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOADING);

    FieldSurveyRepository.getById(fieldSurveyId).then(
      (fieldSurvey) => {
        $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_SUCCESS, fieldSurvey);
      },
      (error) => {
        LxNotificationService.error(
          'Une erreur est survenue lors de la récupération des informations du relevé. ' + (error.data || '')
        );
        $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_ERROR);
      }
    );
  }

  function createFieldSurvey(
    studyId,
    surveyTypeId,
    fieldSurveyForm,
    fieldSurveyMapForm,
    fieldSurveyExtraTableForms,
    formController
  ) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_PROCESSING);

    FieldSurveyRepository.createFieldSurvey(
      studyId,
      surveyTypeId,
      fieldSurveyForm,
      fieldSurveyMapForm,
      fieldSurveyExtraTableForms
    )
      .then(
        (fieldSurvey) => {
          formController.$setPristine();
          LxNotificationService.success('Relevé créé');
          return fieldSurvey;
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la création du relevé. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_ERROR);
          throw error;
        }
      )
      .then(({ id }) => {
        $state.go('field-survey-field-records', { studyId, fieldSurveyId: id });
      });
  }

  function cloneFieldSurvey(
    studyId,
    surveyTypeId,
    fieldSurveyId,
    fieldSurveyForm,
    fieldSurveyMapForm,
    fieldSurveyExtraTableForms,
    formController
  ) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_PROCESSING);

    FieldSurveyRepository.cloneFieldSurvey(
      studyId,
      surveyTypeId,
      fieldSurveyId,
      fieldSurveyForm,
      fieldSurveyMapForm,
      fieldSurveyExtraTableForms
    )
      .then(
        (fieldSurvey) => {
          formController.$setPristine();
          LxNotificationService.success('Relevé cloné');
          $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_SUCCESS, fieldSurvey);
          return fieldSurvey;
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue au clonage du relevé. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_ERROR);
          throw error;
        }
      )
      .then(({ id }) => $state.go('field-survey-field-records', { studyId, fieldSurveyId: id }));
  }

  function updateFieldSurvey(
    fieldSurveyId,
    fieldSurveyForm,
    fieldSurveyMapForm,
    mediaForm,
    fieldSurveyExtraTableForms,
    formController
  ) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_PROCESSING);
    FieldSurveyRepository.updateFieldSurvey(
      fieldSurveyId,
      fieldSurveyForm,
      fieldSurveyMapForm,
      mediaForm,
      fieldSurveyExtraTableForms
    ).then(
      (fieldSurvey) => {
        formController.$setPristine();
        LxNotificationService.success('Relevé enregistré');
        $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_SUCCESS, fieldSurvey);
      },
      (error) => {
        LxNotificationService.error('Une erreur est survenue à la sauvegarde du relevé. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_ERROR);
      }
    );
  }

  function onDelete(studyId, fieldSurveyId) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_PROCESSING);

    var favoriFieldRecords = FavorisUtils.toFavori(
      'field-survey-details',
      {
        studyId: studyId,
        fieldSurveyId: fieldSurveyId,
      },
      null,
      null
    );
    var favoriMap = FavorisUtils.toFavori(
      'field-survey-field-records',
      {
        studyId: studyId,
        fieldSurveyId: fieldSurveyId,
      },
      null,
      null
    );

    FieldSurveyRepository.deleteFieldSurvey(fieldSurveyId).then(
      (surveyType) => {
        FavorisService.removeAll(studyId, [favoriMap, favoriFieldRecords]);
        LxNotificationService.success('Relevé supprimé.');
        $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_SUCCESS, null);
      },
      (error) => {
        LxNotificationService.error('Une erreur est survenue à la suppression du relevé. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_SURVEY_DETAILS_LOAD_ERROR);
      }
    );
  }
}
export { EVENTS };
