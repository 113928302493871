import { CONTENT_TYPE as FORM_CONTENT_TYPE } from '../../base/services/x-www-form-urlencoded.service';

const EVENTS = {
  AUTH_SUCCESS: 'shu-auth.auth-success',
  AUTH_FAILURE: 'shu-auth.auth-failure',
  AUTH_LOG_OUT: 'shu-auth.log-out',
  AUTH_LOST: 'shu-auth.lost'
};

export default AuthService;

// @ngInject
function AuthService($rootScope, $state, AuthConfiguration, LxNotificationService, $http, $timeout, MapBackgroundsService) {

  return {authenticate, logOut, forceLogOut, makeLoginExpired};

  function authenticate(login, password, toState = 'home', toParams = {}) {
    $http({
      method: 'POST',
      url: AuthConfiguration.tokenEndpoint,
      data: {login, password},
      headers: {'Content-Type': FORM_CONTENT_TYPE},
      doNotIntercept: true,
      // Force Angular à ne pas désérialiser les données
      transformResponse: []
    }).then((response) => {
      $rootScope.$emit(EVENTS.AUTH_SUCCESS, {token: response.data});
      MapBackgroundsService.load();
      $state.go(toState, toParams);
    }, (error) => {
      LxNotificationService.error('Identification non valide. ' + (error.data || ''));
      $rootScope.$emit(EVENTS.AUTH_FAILURE);
    });
  }

  function logOut() {
    if (IS_CORDOVA) {
      LxNotificationService.confirm('Êtes-vous sur de vouloir vous déconnecter ?',
                'Une connexion internet sera nécessaire pour vous reconnecter sur la tablette.',
                {cancel: 'Non', ok: 'Oui'},
                (answer) => (answer && forceLogOut()));
    } else {
      forceLogOut();
    }
  }

  function forceLogOut() {
    $rootScope.$emit(EVENTS.AUTH_LOG_OUT);

    // Au cas où l'utilisateur est sur une page protégée
    // On fait du timeout pour laisser le temps à l'application
    // de gérer AUTH_LOG_OUT et de mettre à jour le state
    $timeout(() => $state.go('home', {}, {reload: true}), 0);
  }

  function makeLoginExpired() {
    $rootScope.$emit(EVENTS.AUTH_LOST);
  }
}

export { EVENTS };
