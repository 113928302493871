import _ from 'lodash';

const EVENTS = {
  SETTINGS_UPDATE_SUCCESS: 'shu-settings.settings-update-success',
  SETTINGS_LOADING: 'shu-settings.settings-loading',
  SETTINGS_LOAD_SUCCESS: 'shu-settings.settings-load-success',
  SETTINGS_LOAD_ERROR: 'shu-settings.settings-load-error',
};

export default SettingsOppService;
//
// @ngInject

function SettingsOppService($rootScope, LxNotificationService, $q, DatabaseProvider, StorageService) {
  return { getShuSettings, setShuSettings, rmShuSettings };

  function getShuSettings(key, defaultValue) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        StorageService.executeSqlQuery(database, 'SELECT * FROM ShuSettings').then((rows) => {
          const row = rows.find((row) => {
            return row.key === key;
          });
          if (row) {
            return resolve(row.value);
          } else if (defaultValue === undefined) {
            return reject();
          }
          StorageService.executeSqlQuery(database, 'INSERT INTO ShuSettings (key, value) VALUES (?, ?)', [
            key,
            defaultValue,
          ]).then((res) => {
            resolve(defaultValue);
          });
        });
      });
    });
  }

  function setShuSettings(key, value) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        getShuSettings(key).then(
          (found) => {
            StorageService.executeSqlQuery(database, 'UPDATE ShuSettings SET value= ? WHERE key= ?', [value, key]).then(
              (res) => {
                resolve();
              }
            );
          },
          (err) => {
            StorageService.executeSqlQuery(database, 'INSERT INTO ShuSettings (key, value) VALUES (?, ?)', [
              key,
              value,
            ]).then((res) => {
              resolve();
            });
          }
        );
      });
    });
  }

  function rmShuSettings(key) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        StorageService.executeSqlQuery(database, 'DELETE FROM ShuSettings WHERE key= ?', [key]).then((res) => {
          resolve();
        });
      });
    });
  }
}
export { EVENTS };
