import _ from 'lodash';

import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as FIELD_RECORD_STORE_EVENTS } from '../../stores/field-survey-field-records.store';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as FIELD_SURVEY_STORE_EVENTS } from '../../../field-survey/stores/field-survey-details.store';
import { EVENTS as TAXONS_PROTECTION_LIST_STORE } from '../../../protection/stores/taxons-protection-list.store';
import { EVENTS as FIELD_RECORD_DESCRIPTION_STORE_EVENTS } from '../../stores/field-record-description.store';
import { EVENTS as FIELD_RECORD_DETAILS_STORE_EVENTS } from '../../stores/field-record-details.store';
import { PROTOCOL_WITH_VIGNETTE } from '../../../protocol/model';
import TxgroupUtils from '../../../txgroup/tools/txgroup-utils';

require('./field-survey-field-records-container.scss');

export default StudyFieldRecordContainerDirective;

// @ngInject
function StudyFieldRecordContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      fieldSurveyId: '@'
    },
    bindToController: true,
    controller: FieldSurveyFieldRecordsContainerController,
    controllerAs: 'fieldSurveyFieldRecordsContainerCtrl',
    template: require('./field-survey-field-records-container.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyFieldRecordsContainerController($scope, LxDialogService, HeaderService,
  FieldSurveyFieldRecordsService, FieldSurveyFieldRecordsStore,
  StudyDetailsService, StudyDetailsStore,
  TaxonsProtectionListService, TaxonsProtectionListStore,
  FieldSurveyDetailsService, FieldSurveyDetailsStore,
  FieldRecordHeadcountService, LxNotificationService,
  FieldRecordDescriptionService, FieldRecordDescriptionStore,
  FieldRecordValidateService, $state) {

  var fieldSurveyFieldRecordsContainerCtrl = this;

  fieldSurveyFieldRecordsContainerCtrl.headerUpdated = false;
  fieldSurveyFieldRecordsContainerCtrl.queryState = {};
  fieldSurveyFieldRecordsContainerCtrl.protectionState = {};
  fieldSurveyFieldRecordsContainerCtrl.vignetteState = {};
  fieldSurveyFieldRecordsContainerCtrl.fieldRecordDescriptionState = {};
  fieldSurveyFieldRecordsContainerCtrl.allowVignette = false;
  fieldSurveyFieldRecordsContainerCtrl.showExpert = false;
  fieldSurveyFieldRecordsContainerCtrl.vignette = null;
  fieldSurveyFieldRecordsContainerCtrl.isProcessing = false;

  fieldSurveyFieldRecordsContainerCtrl.isLoading = isLoading;
  fieldSurveyFieldRecordsContainerCtrl.isLoaded = isLoaded;
  fieldSurveyFieldRecordsContainerCtrl.isProcessing = isProcessing;
  fieldSurveyFieldRecordsContainerCtrl.canCreateSubElements = canCreateSubElements;
  fieldSurveyFieldRecordsContainerCtrl.onFieldRecordSelected = onFieldRecordSelected;
  fieldSurveyFieldRecordsContainerCtrl.onUpdateFilterForm = onUpdateFilterForm;
  fieldSurveyFieldRecordsContainerCtrl.onShowDescription = onShowDescription;
  fieldSurveyFieldRecordsContainerCtrl.onDescriptionChanged = onDescriptionChanged;
  fieldSurveyFieldRecordsContainerCtrl.onUpdateHeadcount = onUpdateHeadcount;
  fieldSurveyFieldRecordsContainerCtrl.onUpdateVignetteForm = onUpdateVignetteForm;
  fieldSurveyFieldRecordsContainerCtrl.createFieldRecord = createFieldRecord;
  fieldSurveyFieldRecordsContainerCtrl.onFieldRecordRemove = onFieldRecordRemove;
  fieldSurveyFieldRecordsContainerCtrl.onValidateRecord = onValidateRecord;

  $scope.$on(FIELD_RECORD_STORE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED, reload);
  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(FIELD_SURVEY_STORE_EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(TAXONS_PROTECTION_LIST_STORE.TAXONS_PROTECTION_LIST_STATE_CHANGED, reloadProtections);
  $scope.$on(FIELD_RECORD_DESCRIPTION_STORE_EVENTS.FIELD_RECORD_DESCRIPTION_STATE_CHANGED, reloadFieldRecordDescription);
  $scope.$on(FIELD_RECORD_DETAILS_STORE_EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED, function(event) {
    FieldSurveyDetailsService.load(fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId);
    FieldSurveyFieldRecordsService.load(fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId);
  });

  StudyDetailsService.load(fieldSurveyFieldRecordsContainerCtrl.studyId);
  FieldSurveyDetailsService.load(fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId);
  FieldSurveyFieldRecordsService.load(fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId);

  function isLoading() {
    return (!fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState || fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.loading) ||
      (!fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState || fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.processing) ||
      (!fieldSurveyFieldRecordsContainerCtrl.studyState || fieldSurveyFieldRecordsContainerCtrl.studyState.processing);
  }

  function isLoaded() {
    return fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState && fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.loaded &&
      fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState && fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.loaded &&
      fieldSurveyFieldRecordsContainerCtrl.studyState && fieldSurveyFieldRecordsContainerCtrl.studyState.loaded;
  }

  function isProcessing() {
    return (fieldSurveyFieldRecordsContainerCtrl.fieldRecordDescriptionState &&
        (fieldSurveyFieldRecordsContainerCtrl.fieldRecordDescriptionState.processing ||
          fieldSurveyFieldRecordsContainerCtrl.fieldRecordDescriptionState.loading)) ||
        (fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState &&
           fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.processing);
  }

  function canCreateSubElements() {
    return fieldSurveyFieldRecordsContainerCtrl.studyState &&
        fieldSurveyFieldRecordsContainerCtrl.studyState.study &&
        fieldSurveyFieldRecordsContainerCtrl.studyState.study.canCreateSubElements &&
        fieldSurveyFieldRecordsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
        fieldSurveyFieldRecordsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE;
  }

  function onFieldRecordSelected(fieldRecordId) {
    $state.go('field-record-details', {studyId: fieldSurveyFieldRecordsContainerCtrl.studyId, fieldRecordId});
  }

  function onUpdateFilterForm(txGroups) {
    fieldSurveyFieldRecordsContainerCtrl.queryState = {txGroups};
    filterSurveyTypes();
  }

  function filterSurveyTypes() {
    var chain = _.chain(fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.fieldRecordList);

    if (fieldSurveyFieldRecordsContainerCtrl.queryState.txGroups && fieldSurveyFieldRecordsContainerCtrl.queryState.txGroups.length) {
      chain = chain.filter((fieldRecord) => _.includes(fieldSurveyFieldRecordsContainerCtrl.queryState.txGroups, fieldRecord.txGroup || null));
    }

    fieldSurveyFieldRecordsContainerCtrl.fieldRecords = chain.value();
  }

  function buildTaxonsByTxrefs() {
    return _.chain(fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.fieldRecordList)
      .groupBy((fieldRecord) => `${fieldRecord.txRefType}##${fieldRecord.txRefVersion}##${fieldRecord.txRefFilter}`)
      .mapValues((taxons) => _.chain(taxons).map('txKey').uniq().value())
      .map((txKeys, txRefCode) => {
        var [txRefType, txRefVersion, txRefFilter] = txRefCode.split('##');
        return {txRefType, txRefVersion, txRefFilter, txKeys};
      })
      .value();
  }

  function reloadProtections(event) {
    fieldSurveyFieldRecordsContainerCtrl.protectionState = TaxonsProtectionListStore.getState();
  }



  function reloadFr(event){
    fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState = FieldSurveyFieldRecordsStore.getState();
    fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState = FieldSurveyDetailsStore.getState();
    fieldSurveyFieldRecordsContainerCtrl.studyState = StudyDetailsStore.getState();
  }
  function reload(event) {
    fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState = FieldSurveyFieldRecordsStore.getState();
    fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState = FieldSurveyDetailsStore.getState();
    fieldSurveyFieldRecordsContainerCtrl.studyState = StudyDetailsStore.getState();

    if (fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState &&
        fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey &&
        fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId == fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.id) {

      //if ('RP' === fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key) {
                /*$state.go('field-survey-field-records-rp', {
          studyId: fieldSurveyFieldRecordsContainerCtrl.studyId,
          fieldSurveyId: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId
        });*/
        
      if(_.includes(
        ['PE', 'RP', 'RA', 'ADNE','LS','SM'],
        fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key
      ) && fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.loaded && fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.fieldRecordList.length==0) {

        //on redirige directement sur la carte du relevé pour que l'utilisateur vérifie que 
        //l'enregistrement de la localisatin a bien fonctionné. Comportement valable pour tous les
        //types de relevé sauf les RI et uniquement à la création du relevé, donc quand il est vide
        //si le relevé a des obs alors on est n'est pas redirigé sur la carte mais sur la liste de ces obs.
        $state.go('field-survey-details', {
          studyId: fieldSurveyFieldRecordsContainerCtrl.studyId,
          fieldSurveyId: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId,
          openMapTab: true
        });
      }

      fieldSurveyFieldRecordsContainerCtrl.protocolTxGroups =
        TxgroupUtils.sortBy(fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocolTxGroups, 'txGroup');
    }

    if (fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey && fieldSurveyFieldRecordsContainerCtrl.studyState.study) {
      fieldSurveyFieldRecordsContainerCtrl.needSync = !isWebClient() &&
          fieldSurveyFieldRecordsContainerCtrl.studyState.study.lockTime &&
          (
            fieldSurveyFieldRecordsContainerCtrl.studyState.study.latestSyncTime || fieldSurveyFieldRecordsContainerCtrl.studyState.study.lockTime
          ) < fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.updated;
      fieldSurveyFieldRecordsContainerCtrl.allowVignette = _.includes(PROTOCOL_WITH_VIGNETTE,
        fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key);
      if (fieldSurveyFieldRecordsContainerCtrl.vignette === null) {
        fieldSurveyFieldRecordsContainerCtrl.vignette = fieldSurveyFieldRecordsContainerCtrl.allowVignette;
      }
      fieldSurveyFieldRecordsContainerCtrl.canEdit = fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.canEdit &&
               fieldSurveyFieldRecordsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE &&
               fieldSurveyFieldRecordsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG;
    }

    if (fieldSurveyFieldRecordsContainerCtrl.studyState.loaded) {
      fieldSurveyFieldRecordsContainerCtrl.timezone = fieldSurveyFieldRecordsContainerCtrl.studyState.study.timezone;
    }

    fieldSurveyFieldRecordsContainerCtrl.fieldRecords = fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.fieldRecordList;
    if (fieldSurveyFieldRecordsContainerCtrl.fieldRecordsState.loaded) {
      fieldSurveyFieldRecordsContainerCtrl.txGroups = _.chain(fieldSurveyFieldRecordsContainerCtrl.fieldRecords)
                .map('txGroup')
                .uniq()
                .sort()
                .value();
    }
    filterSurveyTypes();
    updateHeader();
  }

  function isWebClient() {
    return !IS_CORDOVA;
  }

  function updateHeader() {
    if (!fieldSurveyFieldRecordsContainerCtrl.studyState.study ||
        !fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey ||
        fieldSurveyFieldRecordsContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: fieldSurveyFieldRecordsContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: {studyId: fieldSurveyFieldRecordsContainerCtrl.studyId}
        }
      }, {
        label: 'Relevés',
        createFieldSurvey: fieldSurveyFieldRecordsContainerCtrl.studyId,
        link: {
          sref: 'study-field-surveys',
          params: {studyId: fieldSurveyFieldRecordsContainerCtrl.studyId}
        }
      }, {
        label: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.name,
        clonage: fieldSurveyFieldRecordsContainerCtrl.canCreateSubElements(),
        favori: {
          state: 'field-survey-field-records',
          params: {
            studyId: fieldSurveyFieldRecordsContainerCtrl.studyId,
            fieldSurveyId: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId
          },
          picto: 'view-list',
          title: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.name
        },
        link: null
      }
    ]);
    fieldSurveyFieldRecordsContainerCtrl.headerUpdated = true;
  }

  function reloadFieldRecordDescription() {
    fieldSurveyFieldRecordsContainerCtrl.fieldRecordDescriptionState = FieldRecordDescriptionStore.getState();

    if (fieldSurveyFieldRecordsContainerCtrl.fieldRecordDescriptionState.loaded &&
        !fieldSurveyFieldRecordsContainerCtrl.fieldRecordDescriptionState.fieldRecordDescription) {
      LxDialogService.close('fieldRecordDescriptionDialog');
    }
  }

  function onDescriptionChanged(fieldRecordId, description) {
    FieldRecordDescriptionService.update(fieldRecordId, description);
  }

  function onUpdateHeadcount(fieldRecordId, headcount) {
    FieldRecordHeadcountService.update(fieldRecordId, headcount);
  }

  function onValidateRecord(fieldRecordId, headcount) {
    FieldRecordValidateService.validate(fieldRecordId, headcount);

    //méthode qui force le rechargement de l'état actuel sans redémarrer les  les événements
    $state.reload();
  }

  function onShowDescription(fieldRecordId) {
    FieldRecordDescriptionService.load(fieldRecordId);
    LxDialogService.open('fieldRecordDescriptionDialog');
  }

  function createFieldRecord(txGroup) {
    let params = {
      studyId: fieldSurveyFieldRecordsContainerCtrl.studyId,
      fieldSurveyId: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId,
      txRefKey: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.defaultTxRefType,
      txRefFilter: fieldSurveyFieldRecordsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.defaultTxRefFilter,
      txGroup: txGroup
    };
    $state.go('field-record-creation', params);
  }

  function onFieldRecordRemove(fieldRecord) {
    // Ne pas afficher la popup de confirmation si fieldRecord.ghost
    if (fieldRecord.ghost) {
      FieldSurveyFieldRecordsService.removeFieldRecord(fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId, fieldRecord.id);
      return;
    }
    LxNotificationService.confirm('Attention', 'Êtes-vous sur de vouloir supprimer l\'observation ?',
      {cancel: 'Non', ok: 'Oui'},
      (answer) => (answer && FieldSurveyFieldRecordsService.removeFieldRecord(fieldSurveyFieldRecordsContainerCtrl.fieldSurveyId, fieldRecord.id)));
  }

  function onUpdateVignetteForm(vignette) {
    fieldSurveyFieldRecordsContainerCtrl.vignette = vignette;
  }
}
