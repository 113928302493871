import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-record-list-map.service';

const EVENTS = {
  FIELD_RECORD_LIST_MAP_STATE_CHANGED: 'shu-field-record.field-record-list-map-state-changed'
};

export default FieldRecordListMapStore;

// @ngInject
function FieldRecordListMapStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_LIST_MAP_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_LIST_MAP_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_LIST_MAP_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      fieldRecords: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_LIST_MAP_STATE_CHANGED);
  }

  function onLoadSuccess(event, fieldRecords) {
    state = Immutable({
      fieldRecords,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_LIST_MAP_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      fieldRecords: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_LIST_MAP_STATE_CHANGED);
  }
}

export { EVENTS };
