import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'une étude
  STUDY_CREATE_LOADING: 'shu-study-creation.study-creation-loading',
  STUDY_CREATE_PROCESSING: 'shu-study-creation.study-creation-processing',
  STUDY_CREATE_PROCESSING_DONE: 'shu-study-creation.study-creation-processing-done',
  STUDY_CREATE_LOAD_SUCCESS: 'shu-study-creation.study-creation-load-success',
  STUDY_CREATE_LOAD_ERROR: 'shu-study-creation.study-creation-load-error'
};
export default StudyCreationService;

// @ngInject
function StudyCreationService($rootScope, StudyRepository, StudyCreationStore, LxNotificationService) {
  return {load, onFormSubmit};

  function load() {
    $rootScope.$emit(EVENTS.STUDY_CREATE_LOADING);

    StudyRepository.getPersonalStudyKey()
      .then(
        (studyKey) => $rootScope.$emit(EVENTS.STUDY_CREATE_LOAD_SUCCESS, studyKey),
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement de la clé générée pour l\'étude. ');
          $rootScope.$emit(EVENTS.STUDY_CREATE_LOAD_ERROR);
        }
      );
  }

  function onFormSubmit(studyKey, studyName) {
    $rootScope.$emit(EVENTS.STUDY_CREATE_PROCESSING);

    StudyRepository.addPersonalStudy(studyKey, studyName)
      .then(
        (studyId) => {
          LxNotificationService.success('Étude enregistrée.');
          $rootScope.$emit(EVENTS.STUDY_CREATE_PROCESSING_DONE, studyId);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde de l\'étude. ');
          $rootScope.$emit(EVENTS.STUDY_CREATE_LOAD_ERROR);
        }
      );
  }
}
export { EVENTS };
