import Immutable from 'seamless-immutable';
import _ from 'lodash';

import { EVENTS as SERVICE_EVENTS } from '../services/study-importer.service';
import { IMPORT_STEP, BIOTOPE_REF } from '../model';

const EVENTS = {
  STUDY_IMPORTER_FORM_STATE_CHANGED: 'shu-synchro.study-importer-form-state-changed',
  STUDY_IMPORTER_SYNC_STATE_CHANGED: 'shu-synchro.study-importer-sync-state-changed'
};

export default StudyImporterStore;

// @ngInject
function StudyImporterStore($rootScope) {
  var formState = Immutable({});
  var syncState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_LOAD_ERROR, onLoadError);

  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_STARTED, onImportStarted);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_STEP_STARTED, onImportStepStarted);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_STEP_DONE, onImportStepDone);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_STEP_FAILED, onImportStepFailed);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_REFERENTIEL_STARTED, onImportReferentielStarted);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_REFERENTIEL_DONE, onImportReferentielDone);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_REFERENTIEL_FAILED, onImportReferentielFailed);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_BIOTOPEREF_STARTED, onImportBiotopRefStarted);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_BIOTOPEREF_DONE, onImportBiotopRefDone);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_BIOTOPEREF_FAILED, onImportBiotopRefFailed);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_TERMINATED, onImportTerminated);
  $rootScope.$on(SERVICE_EVENTS.STUDY_IMPORTER_IMPORT_STUDY_CANCELED, onImportStudyCanceled);

  return {getFormState, getSyncState};

  function getFormState() {
    return formState;
  }

  function getSyncState() {
    return syncState;
  }

  function onLoading() {
    formState = Immutable({
      studies: [],
      txRefs: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_FORM_STATE_CHANGED);
  }

  function onLoadSuccess(event, studies, txRefs) {
    formState = Immutable({
      studies,
      txRefs,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_FORM_STATE_CHANGED);
  }

  function onLoadError() {
    formState = Immutable({
      studies: [],
      txRefs: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_FORM_STATE_CHANGED);
  }

  function onImportStarted(event, selectedTxRefs, studyIds) {
    syncState = Immutable({
      studies: _.map(studyIds, (studyId) => {
        return {
          id: studyId,
          stepsStarted: [],
          stepsDone: [],
          stepsFailed: [],
          stepsWaiting: IMPORT_STEP.VALUES
        };
      }),
      referentielsStarted: [],
      referentielsDone: [],
      referentielsFailed: [],
      referentiels: [BIOTOPE_REF].concat(selectedTxRefs),
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportStudyCanceled(event, studyId) {
    var studies = _.map(syncState.studies, (study) => {
      if (studyId !== study.id) {
        return study;
      }
      return _.defaults({
        stepsStarted: [],
        stepsDone: [],
        stepsFailed: IMPORT_STEP.VALUES,
        stepsWaiting: []
      }, study);
    });

    syncState = Immutable({
      studies,
      referentielsStarted: syncState.referentielsStarted,
      referentielsDone: syncState.referentielsDone,
      referentielsFailed: syncState.referentielsFailed,
      referentiels: syncState.referentiels,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportStepStarted(event, studyId, importStep) {
    var studies = _.map(syncState.studies, (study) => {
      if (studyId !== study.id) {
        return study;
      }
      return _.defaults({
        stepsStarted: [importStep].concat(study.stepsStarted),
        stepsWaiting: _.without(study.stepsWaiting, importStep),
      }, study);
    });

    syncState = Immutable({
      studies,
      referentielsStarted: syncState.referentielsStarted,
      referentielsDone: syncState.referentielsDone,
      referentielsFailed: syncState.referentielsFailed,
      referentiels: syncState.referentiels,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportStepDone(event, studyId, importStep) {
    var studies = _.map(syncState.studies, (study) => {
      if (studyId !== study.id) {
        return study;
      }
      return _.defaults({
        stepsStarted: _.without(study.stepsStarted, importStep),
        stepsDone: [importStep].concat(study.stepsDone),
      }, study);
    });

    syncState = Immutable({
      studies,
      referentielsStarted: syncState.referentielsStarted,
      referentielsDone: syncState.referentielsDone,
      referentielsFailed: syncState.referentielsFailed,
      referentiels: syncState.referentiels,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportStepFailed(event, studyId, importStep) {
    var studies = _.map(syncState.studies, (study) => {
      if (studyId !== study.id) {
        return study;
      }
      return _.defaults({
        stepsStarted: _.without(study.stepsStarted, importStep),
        stepsDone: _.without(study.stepsDone, importStep),
        stepsWaiting: _.without(study.stepsWaiting, importStep),
        stepsFailed: [importStep].concat(study.stepsFailed),
      }, study);
    });

    syncState = Immutable({
      studies,
      referentielsStarted: syncState.referentielsStarted,
      referentielsDone: syncState.referentielsDone,
      referentielsFailed: syncState.referentielsFailed,
      referentiels: syncState.referentiels,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportReferentielStarted(event, key) {
    syncState = Immutable({
      studies: syncState.studies,
      referentielsStarted: [key].concat(syncState.referentielsStarted),
      referentielsDone: syncState.referentielsDone,
      referentielsFailed: syncState.referentielsFailed,
      referentiels: syncState.referentiels,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportReferentielDone(event, key) {
    syncState = Immutable({
      studies: syncState.studies,
      referentielsStarted: _.without(syncState.referentielsStarted, key),
      referentielsDone: [key].concat(syncState.referentielsDone),
      referentielsFailed: syncState.referentielsFailed,
      referentiels: syncState.referentiels,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportReferentielFailed(event, key) {
    syncState = Immutable({
      studies: syncState.studies,
      referentielsStarted: _.without(syncState.referentielsStarted, key),
      referentielsDone: syncState.referentielsDone,
      referentielsFailed: [key].concat(syncState.referentielsFailed),
      referentiels: syncState.referentiels,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }

  function onImportBiotopRefStarted(event) {
    onImportReferentielStarted(event, BIOTOPE_REF.key);
  }

  function onImportBiotopRefDone(event, key) {
    onImportReferentielDone(event, BIOTOPE_REF.key);
  }

  function onImportBiotopRefFailed(event, key) {
    onImportReferentielFailed(event, BIOTOPE_REF.key);
  }

  function onImportTerminated() {
    syncState = Immutable({
      studies: syncState.studies,
      referentielsStarted: syncState.referentielsStarted,
      referentielsDone: syncState.referentielsDone,
      referentielsFailed: syncState.referentielsFailed,
      referentiels: syncState.referentiels,
      terminated: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED);
  }
}

export { EVENTS };
