import { NATURALISTE } from '../base/services/url-resolver.service';

export default {
  searchEndpoint: {
    server: NATURALISTE,
    path: '/study'
  },
  studyDetailsEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}'
  },
  studyGeomEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/geom'
  },
  studyUpdateGeomEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/kml'
  },
  studyJoinEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/join'
  },
  studyCSVEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/csv'
  },
  studyPersonalKeyEndpoint: {
    server: NATURALISTE,
    path: '/study/personalStudyKey'
  },
  studyPersonalEndpoint: {
    server: NATURALISTE,
    path: '/study/{key}/{name}'
  },
  studyGridEndpoint: {
    server: NATURALISTE,
    path: '/referentiel/grid/{studyId}'
  },
  studyGridSaveEndPoint: {
    server: NATURALISTE,
    path: '/study/grid/{studyId}/save'
  },
  studyExpertLayerEndpoint: {
    server: NATURALISTE,
    path: '/study-layers/{studyId}'
  },
  studyExpertLayerDetailEndpoint: {
    server: NATURALISTE,
    path: '/study-layers/{studyId}/{studyExpertLayerId}'
  }
};
