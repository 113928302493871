import { EXTRA_TABLE } from '../model';

export default {toTableName, fieldsToClone};

function toTableName(enumName) {
  switch (enumName) {
    case EXTRA_TABLE.COMMON:
      return 'ET_FS_COMMON';
    case EXTRA_TABLE.BIRDS:
      return 'ET_FS_BIRDS';
    case EXTRA_TABLE.PE:
      return 'ET_FS_PE';
    case EXTRA_TABLE.RP:
      return 'ET_FS_RP';
    case EXTRA_TABLE.RA:
      return 'ET_FS_RA';
    case EXTRA_TABLE.ADNE:
      return 'ET_FS_ADNE';
    default:
      return null;
  }
}

function fieldsToClone(enumName) {
  switch (enumName) {
    case EXTRA_TABLE.COMMON:
      return null;
    case EXTRA_TABLE.BIRDS:
      return null;
    case EXTRA_TABLE.PE:
      return ['duree'];
    case EXTRA_TABLE.RP:
      return null;
    case EXTRA_TABLE.RA:
        return null;
    case EXTRA_TABLE.ADNE:
      return null;
    default:
      return null;
  }
}
