const EVENTS = {
  FIELD_RECORD_EXTRA_TABLES_LOADING: 'shu-field-record.field-record-extra-tables-loading',
  FIELD_RECORD_EXTRA_TABLES_LOAD_SUCCESS: 'shu-field-record.field-record-extra-tables-load-success',
  FIELD_RECORD_EXTRA_TABLES_LOAD_ERROR: 'shu-field-record.field-record-extra-tables-load-error',
  FIELD_RECORD_EXTRA_TABLES_PROCESSING: 'shu-field-record.field-record-extra-tables-processing',
};

export default FieldRecordExtraTablesService;

// @ngInject
function FieldRecordExtraTablesService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {load};

  function load(fieldSurveyId, txGroup) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_EXTRA_TABLES_LOADING);

    FieldRecordRepository.getExtraTables(fieldSurveyId, txGroup)
      .then(
        (extraTables) => {

          $rootScope.$emit(EVENTS.FIELD_RECORD_EXTRA_TABLES_LOAD_SUCCESS, extraTables);
          return extraTables;
        },
        (error) => {
          LxNotificationService.error(
            'Une erreur est survenue lors de la récupération des informations de données satellitaires de l\'observation. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_RECORD_EXTRA_TABLES_LOAD_ERROR);
          throw error;
        }
      );
  }
}
export { EVENTS };
