import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../../../synchro/stores/study-synchronizer.store';
import { SYNC_STEP } from '../../../synchro/model';

require('./study-synchronizer-dialog.scss');

export default StudySynchronizerDialogDirective;

// @ngInject
function StudySynchronizerDialogDirective() {
  return {
    restrict: 'E',
    scope: {
      studyProvider: '&study',
      syncStateProvider: '&syncState'
    },
    controller: StudySynchronizerDialogController,
    controllerAs: 'studySynchronizerDialogCtrl',
    bindToController: true,
    template: require('./study-synchronizer-dialog.html'),
    replace: true
  };
}

// @ngInject
function StudySynchronizerDialogController($scope, $state, LxDialogService, $timeout) {
  var studySynchronizerDialogCtrl = this;

  studySynchronizerDialogCtrl.study = studySynchronizerDialogCtrl.studyProvider();

  $scope.$watch(studySynchronizerDialogCtrl.syncStateProvider, (newSyncState) => {
    var oldState = studySynchronizerDialogCtrl.syncState;
    studySynchronizerDialogCtrl.syncState = newSyncState;

    if ((!oldState || oldState.terminated) && !newSyncState.terminated) {
      $timeout(() => LxDialogService.open('synchronisation-dlg'), 0);
    }
  });

  studySynchronizerDialogCtrl.isStepWaiting = isStepWaiting;
  studySynchronizerDialogCtrl.isStepStarted = isStepStarted;
  studySynchronizerDialogCtrl.isStepDone = isStepDone;
  studySynchronizerDialogCtrl.isStepFailed = isStepFailed;
  studySynchronizerDialogCtrl.isStepCancelled = isStepCancelled;
  studySynchronizerDialogCtrl.onClose = onClose;

  function isStepWaiting(step) {
    return !isStepStarted(step) && !isStepDone(step) && !isStepFailed(step) && !studySynchronizerDialogCtrl.syncState.terminated;
  }

  function isStepStarted(step) {
    return _.includes(studySynchronizerDialogCtrl.syncState.stepsStarted, step);
  }

  function isStepDone(step) {
    return _.includes(studySynchronizerDialogCtrl.syncState.stepsDone, step);
  }

  function isStepFailed(step) {
    return _.includes(studySynchronizerDialogCtrl.syncState.stepsFailed, step);
  }

  function isStepCancelled(step) {
    return !isStepStarted(step) && !isStepDone(step) && !isStepFailed(step) && studySynchronizerDialogCtrl.syncState.terminated;
  }

  function isTerminatedWithFailures() {
    if (studySynchronizerDialogCtrl.syncState.stepsFailed.length === 0) {
      return false;
    }
    // On considère que la synchro n'a pas été faite si :
    // - unlock et REMOTE_UNLOCK failed
    // - save et REMOTE_UPDATE failed
    if (studySynchronizerDialogCtrl.syncState.unlock) {
      return _.includes(studySynchronizerDialogCtrl.syncState.stepsFailed, SYNC_STEP.REMOTE_UNLOCK);
    }
    if (studySynchronizerDialogCtrl.syncState.save) {
      return _.includes(studySynchronizerDialogCtrl.syncState.stepsFailed, SYNC_STEP.REMOTE_UPDATE);
    }

    // Normalement ce cas n'arrive pas
    return studySynchronizerDialogCtrl.syncState.stepsFailed.length > 0;
  }

  function onClose() {
    if (studySynchronizerDialogCtrl.syncState.unlock && !isTerminatedWithFailures()) {
      $state.go('study-search');
    }
  }
}
