export default {
  settings: {
    route_auth: 'https://auth.biotope.fr',
    route_referentiel: 'https://ref.biotope.fr',
    route_naturaliste: 'https://api.biotope.fr',
    /* route_referentiel: 'https://dev.ref.biotope.fr',
    route_naturaliste: 'https://dev.s6n.biotope.fr/api', */
    /* route_status: 'https://0.0.0.0', */
    custom_map_layers: '', // ex. OSM;https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png
    uptimerobot_apikey: 'm790669626-bb06d8665bc782e2c1760d48',
  },

  //settingsEndpoint: { path: 'https://dev.s6n.biotope.fr/api/settings' },
  settingsEndpoint: { path: 'https://api.biotope.fr/settings' },
};
