import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-search.service';

const EVENTS = {
  STUDY_SEARCH_QUERY_STATE_CHANGED: 'shu-study-search.study-search-query-state-changed',
  STUDY_SEARCH_RESULTS_STATE_CHANGED: 'shu-study-search.study-search-results-state-changed'
};

export default StudySearchStore;

// @ngInject
function StudySearchStore($rootScope, StudySearchService) {
  var queryState = Immutable({});
  var resultsState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_SEARCH_RESULTS_LOADING, onStudySearchResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SEARCH_RESULTS_LOAD_SUCCESS, onStudySearchResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SEARCH_RESULTS_LOAD_ERROR, onStudySearchResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.STUDY_SEARCH_NEXT_RESULTS_LOADING, onStudySearchNextResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, onStudySearchNextResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SEARCH_NEXT_RESULTS_LOAD_ERROR, onStudySearchNextResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.STUDY_SEARCH_QUERY_UPDATE, onUpdateStudySearchQuery);

  return {getQueryState, getResultsState};

  function getQueryState() {
    return queryState;
  }

  function getResultsState() {
    return resultsState;
  }

  function onUpdateStudySearchQuery(events, {query, searchAll, status}) {
    queryState = Immutable({query, searchAll, status});
    $rootScope.$broadcast(EVENTS.STUDY_SEARCH_QUERY_STATE_CHANGED);
  }

  function onStudySearchResultsLoading() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchResultsLoadSuccess(event, results, totalResults) {
    resultsState = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchResultsLoadError() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchNextResultsLoading() {
    resultsState = Immutable({
      results: resultsState.results,
      totalResults: resultsState.totalResults,
      loading: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchNextResultsLoadSuccess(event, results, totalResults) {
    resultsState = Immutable({
      results: [].concat.call(resultsState.results, results),
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onStudySearchNextResultsLoadError() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_SEARCH_RESULTS_STATE_CHANGED);
  }
}

export { EVENTS };
