export default ProfileRepository;

// @ngInject
function ProfileRepository(ProfileConfiguration, $http, $q) {
  return {get, store};

  function get() {
    return $http({
      method: 'GET',
      url: ProfileConfiguration.userinfoEndpoint
    }).then((response) => response.data);
  }

  function store(expert) {
    // NOOP
    return $q.when(expert);
  }
}
