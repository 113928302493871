import _ from 'lodash';

import TxgroupUtils from '../../../txgroup/tools/txgroup-utils';

require('./field-survey-details-main-tab.scss');

export default FieldSurveyDetailsMainTabDirective;

// @ngInject
function FieldSurveyDetailsMainTabDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      fieldSurveyNumber: '@',
      initialFieldSurveyProvider: '&initialFieldSurvey',
      fieldSurveyStateProvider: '&fieldSurveyState',
      uploadingKmlProvider: '&uploadingKml',
      needSyncProvider: '&needSync',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete',
      onActivateMapTabFn: '&onActivateMapTab' ,
      onRemoveGeomFn: '&onRemoveGeom',
      onSubmitKmlFn: '&onSubmitKml',
      onMakeFieldRecordsPublicFn: '&onMakeFieldRecordsPublic',
      canSubmitFn: '&canSubmit',
      isNewFn: '&isNew',
      canEditKmlFn: '&canEditKml',
      geomCanBePointProvider: '&geomCanBePoint',
      hasGeomProvider: '&hasGeom',
      showLocationHelperProvider: '&showLocationHelper',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision',
      warnGeometryClonageProvider: '&warnGeometryClonage',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldSurveyDetailsMainTabController,
    controllerAs: 'fieldSurveyDetailsMainTabCtrl',
    template: require('./field-survey-details-main-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyDetailsMainTabController($scope, $state, $timeout) {
  var fieldSurveyDetailsMainTabCtrl = this;

  fieldSurveyDetailsMainTabCtrl.onTerrUnitChange = onTerrUnitChange;
  fieldSurveyDetailsMainTabCtrl.onChange = onChange;
  fieldSurveyDetailsMainTabCtrl.onDelete = onDelete;
  fieldSurveyDetailsMainTabCtrl.onRemoveGeom = onRemoveGeom;
  fieldSurveyDetailsMainTabCtrl.onActivateMapTab = onActivateMapTab;
  fieldSurveyDetailsMainTabCtrl.onSubmitKml = onSubmitKml;
  fieldSurveyDetailsMainTabCtrl.onMakeFieldRecordsPublic = onMakeFieldRecordsPublic;
  fieldSurveyDetailsMainTabCtrl.canEditKml = canEditKml;
  fieldSurveyDetailsMainTabCtrl.geomCanBePoint = geomCanBePoint;
  fieldSurveyDetailsMainTabCtrl.hasGeom = hasGeom;
  fieldSurveyDetailsMainTabCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsMainTabCtrl.isNew = isNew;
  fieldSurveyDetailsMainTabCtrl.createFieldRecord = createFieldRecord;
  fieldSurveyDetailsMainTabCtrl.showLocationHelper = showLocationHelper;
  fieldSurveyDetailsMainTabCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldSurveyDetailsMainTabCtrl.isAutoLocatedError = isAutoLocatedError;
  fieldSurveyDetailsMainTabCtrl.locationPrecision = locationPrecision;

  $scope.$watch(fieldSurveyDetailsMainTabCtrl.initialFieldSurveyProvider, (initialFieldSurvey) => {
    if (!initialFieldSurvey) {
      return;
    }

    fieldSurveyDetailsMainTabCtrl.fieldSurvey = _.defaults({
      startTime: initialFieldSurvey.startTime * 1000,
      name: initialFieldSurvey.name || generateName(initialFieldSurvey)
    }, initialFieldSurvey);
    // on n'utilse pas _.defaults car on veut mettre undefined
    fieldSurveyDetailsMainTabCtrl.fieldSurvey.endTime = initialFieldSurvey.endTime ? initialFieldSurvey.endTime * 1000 : undefined;

    fieldSurveyDetailsMainTabCtrl.protocolTxGroups = TxgroupUtils.sortBy(fieldSurveyDetailsMainTabCtrl.fieldSurvey.surveyType.protocolTxGroups,
      'txGroup');

    // On gère l'upload de KML pour tous les types sauf POINT
    fieldSurveyDetailsMainTabCtrl.geomTypeHandledByKml =
      fieldSurveyDetailsMainTabCtrl.fieldSurvey.surveyType.protocol.fieldSurveyGeometryType !== 'POINT';

    fieldSurveyDetailsMainTabCtrl.onChange();
  });

  $scope.$watch(fieldSurveyDetailsMainTabCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }
    fieldSurveyDetailsMainTabCtrl.fieldSurveyState = fieldSurveyState;
  });

  $scope.$watch(fieldSurveyDetailsMainTabCtrl.formProvider, (form) => {
    fieldSurveyDetailsMainTabCtrl.form = form;
  });

  $scope.$watch(fieldSurveyDetailsMainTabCtrl.warnGeometryClonageProvider, (warnGeometryClonage) => {
    fieldSurveyDetailsMainTabCtrl.warnGeometryClonage = warnGeometryClonage;
  });

  $scope.$watch(fieldSurveyDetailsMainTabCtrl.needSyncProvider, (needSync) => {
    fieldSurveyDetailsMainTabCtrl.needSync = needSync;
  });

  $scope.$watch(fieldSurveyDetailsMainTabCtrl.uploadingKmlProvider, (uploadingKml) => {
    fieldSurveyDetailsMainTabCtrl.uploadingKml = uploadingKml;
  });

  function generateName(initialFieldSurvey) {
    return `${initialFieldSurvey.surveyType.protocol.key}${_.padStart(fieldSurveyDetailsMainTabCtrl.fieldSurveyNumber, 3, '0')}`;
  }

  function onTerrUnitChange(selectedTerrUnit) {
    fieldSurveyDetailsMainTabCtrl.selectedTerrUnit = selectedTerrUnit;
    onChange();
  }

  function onChange() {
    if (!fieldSurveyDetailsMainTabCtrl.fieldSurvey) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldSurveyDetailsMainTabCtrl.onChangeFn()({
        data: {
          name: fieldSurveyDetailsMainTabCtrl.fieldSurvey.name,
          description: fieldSurveyDetailsMainTabCtrl.fieldSurvey.description,
          terrUnit: fieldSurveyDetailsMainTabCtrl.fieldSurvey.terrUnit,
          terrUnitName: fieldSurveyDetailsMainTabCtrl.fieldSurvey.terrUnitName,
          place: fieldSurveyDetailsMainTabCtrl.fieldSurvey.place,
          startTime: fieldSurveyDetailsMainTabCtrl.fieldSurvey.startTime / 1000,
          endTime: fieldSurveyDetailsMainTabCtrl.fieldSurvey.endTime ? (fieldSurveyDetailsMainTabCtrl.fieldSurvey.endTime / 1000) : null,
        },
        formValid: isFormValid()
      });
    });
  }

  function onDelete() {
    fieldSurveyDetailsMainTabCtrl.onDeleteFn()();
  }

  function canSubmit() {
    return fieldSurveyDetailsMainTabCtrl.canSubmitFn()();
  }

  function isNew() {
    return fieldSurveyDetailsMainTabCtrl.isNewFn()();
  }

  function canEditKml() {
    return fieldSurveyDetailsMainTabCtrl.canEditKmlFn()();
  }
  function geomCanBePoint() {
    return fieldSurveyDetailsMainTabCtrl.geomCanBePointProvider();
  }
  function hasGeom() {
    return fieldSurveyDetailsMainTabCtrl.hasGeomProvider();
  }
  function showLocationHelper() {
    return fieldSurveyDetailsMainTabCtrl.showLocationHelperProvider();
  }

  function onSubmitKml(input) {
    if (input.files.length <= 0) {
      return;
    }
    fieldSurveyDetailsMainTabCtrl.onSubmitKmlFn()(input.files[0]);
  }
  function onActivateMapTab() {
    fieldSurveyDetailsMainTabCtrl.onActivateMapTabFn()();
  }
  function onRemoveGeom() {
    fieldSurveyDetailsMainTabCtrl.onRemoveGeomFn()();
  }

  function onMakeFieldRecordsPublic() {
    fieldSurveyDetailsMainTabCtrl.onMakeFieldRecordsPublicFn()();
  }
  function createFieldRecord(txGroup) {
    let params = {
      studyId: fieldSurveyDetailsMainTabCtrl.studyId,
      fieldSurveyId: fieldSurveyDetailsMainTabCtrl.fieldSurvey.id,
      txRefKey: fieldSurveyDetailsMainTabCtrl.fieldSurvey.surveyType.defaultTxRefType,
      txRefFilter: fieldSurveyDetailsMainTabCtrl.fieldSurvey.surveyType.defaultTxRefFilter,
      txGroup: txGroup
    };
    $state.go('field-record-creation', params);
  }
  function isFormValid() {
    return !!(fieldSurveyDetailsMainTabCtrl.form.fieldSurveyName && fieldSurveyDetailsMainTabCtrl.form.fieldSurveyName.$valid &&
      fieldSurveyDetailsMainTabCtrl.form.fieldSurveyStartTime && fieldSurveyDetailsMainTabCtrl.form.fieldSurveyStartTime.$valid);
  }

  function locationPrecision() {
    return fieldSurveyDetailsMainTabCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return fieldSurveyDetailsMainTabCtrl.isAutoLocatedValidFn() &&
           fieldSurveyDetailsMainTabCtrl.isAutoLocatedValidFn()();
  }

  function isAutoLocatedError() {
    return fieldSurveyDetailsMainTabCtrl.isAutoLocatedErrorFn() &&
           fieldSurveyDetailsMainTabCtrl.isAutoLocatedErrorFn()();
  }
}
