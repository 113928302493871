import _ from 'lodash';
import {STUDY_STATUS} from '../../model.js';

require('./study-search-form.scss');

export default StudySearchFormDirective;

// @ngInject
function StudySearchFormDirective() {
  return {
    restrict: 'E',
    scope: {
      queryStateProvider: '&queryState',
      onUpdateFormFn: '&onUpdateForm'
    },
    controller: StudySearchFormController,
    controllerAs: 'studySearchFormCtrl',
    bindToController: true,
    template: require('./study-search-form.html'),
    replace: true
  };
}

// @ngInject
function StudySearchFormController($scope, LxDialogService) {
  var studySearchFormCtrl = this;

  studySearchFormCtrl.availableStatus = Array.prototype.concat.apply([''], STUDY_STATUS.VALUES);
  studySearchFormCtrl.onQueryUpdated = onQueryUpdated;

  studySearchFormCtrl.enableSearchOptions = !IS_CORDOVA;
  studySearchFormCtrl.enableCreateStudy = !IS_CORDOVA;
  studySearchFormCtrl.onCreateStudy = onCreateStudy;

  $scope.$watch(studySearchFormCtrl.queryStateProvider, (queryState) => {
    studySearchFormCtrl.query = queryState.query;
    studySearchFormCtrl.searchAll = queryState.searchAll;
    studySearchFormCtrl.status = queryState.status;
  });

  function getAvailableStatus() {
    return STUDY_STATUS.VALUES;
  }

  function onQueryUpdated() {
    studySearchFormCtrl.onUpdateFormFn()(studySearchFormCtrl.query, studySearchFormCtrl.searchAll, studySearchFormCtrl.status);
  }

  function onCreateStudy() {
    LxDialogService.open('studyCreationDialog');
  }

}
