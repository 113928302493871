import { STUDY_EXPERT_LAYER_FORMAT } from '../model';

export default StudyLayerFormatFilter;

// @ngInject
function StudyLayerFormatFilter() {
  return function(input) {
    switch (input) {
      case STUDY_EXPERT_LAYER_FORMAT.KML:
        return 'KML';
      case STUDY_EXPERT_LAYER_FORMAT.MBTILES:
        return 'MBTiles';
      default:
        return '-';
    }
  };
}

