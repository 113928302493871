import _ from 'lodash';

import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as STORE_EVENTS } from '../../stores/survey-type-details.store';
import { EVENTS as PROTOCOL_LIST_STORE_EVENTS } from '../../../protocol/stores/protocol-list.store';
import { EVENTS as TX_REF_STORE_EVENTS } from '../../../taxon/stores/taxon-ref.store';
import TxgroupUtils from '../../../txgroup/tools/txgroup-utils';

require('./survey-type-details.scss');

export default SurveyTypeDetailsDirective;

// @ngInject
function SurveyTypeDetailsDirective() {
  return {
    restrict: 'E',
    scope: {
      studyProvider: '&study',
      surveyTypeProvider: '&surveyType',
      processingProvider: '&processing',
      onSubmitFn: '&onSubmit',
      onDeleteFn: '&onDelete',
      onMakeFieldRecordsPublicFn: '&onMakeFieldRecordsPublic'
    },
    bindToController: true,
    controller: SurveyTypeDetailsController,
    controllerAs: 'surveyTypeDetailsCtrl',
    template: require('./survey-type-details.html'),
    replace: true
  };
}

// @ngInject
function SurveyTypeDetailsController($scope, LxNotificationService) {
  var surveyTypeDetailsCtrl = this;

  surveyTypeDetailsCtrl.study = {};
  surveyTypeDetailsCtrl.surveyType = {};
  surveyTypeDetailsCtrl.status = null;
  surveyTypeDetailsCtrl.protocolTxGroups = [];
  surveyTypeDetailsCtrl.availableProtocolTxGroups = [];
  surveyTypeDetailsCtrl.canEditStudy = canEditStudy;
  surveyTypeDetailsCtrl.isProtocolTxGroupChecked = isProtocolTxGroupChecked;
  surveyTypeDetailsCtrl.toggleProtocolTxGroupChecked = toggleProtocolTxGroupChecked;
  surveyTypeDetailsCtrl.formIsValid = formIsValid;
  surveyTypeDetailsCtrl.onSubmit = onSubmit;
  surveyTypeDetailsCtrl.onDelete = onDelete;
  surveyTypeDetailsCtrl.onProtocolChanged = onProtocolChanged;
  surveyTypeDetailsCtrl.onReferentielChanged = onReferentielChanged;
  surveyTypeDetailsCtrl.onFilterChanged = onFilterChanged;

  surveyTypeDetailsCtrl.onMakeFieldRecordsPublic = onMakeFieldRecordsPublic;

  $scope.$watch(surveyTypeDetailsCtrl.studyProvider, (value) => {
    if (!value) {
      surveyTypeDetailsCtrl.study = {};
    } else {
      surveyTypeDetailsCtrl.study = value;
    }
  });

  $scope.$watch(surveyTypeDetailsCtrl.surveyTypeProvider, (value) => {
    if (!value) {
      surveyTypeDetailsCtrl.surveyType = {};
      surveyTypeDetailsCtrl.isNew = true;
      surveyTypeDetailsCtrl.name = null;
      surveyTypeDetailsCtrl.defaultTxRefType = null;
      surveyTypeDetailsCtrl.defaultTxRefFilter = null;
      surveyTypeDetailsCtrl.initialProtocolId = null;
      surveyTypeDetailsCtrl.protocolTxGroups = [];
    } else {
      surveyTypeDetailsCtrl.surveyType = value;
      surveyTypeDetailsCtrl.isNew = false;
      surveyTypeDetailsCtrl.name = value.name;
      surveyTypeDetailsCtrl.defaultTxRefType = value.defaultTxRefType;
      surveyTypeDetailsCtrl.defaultTxRefFilter = value.defaultTxRefFilter;
      surveyTypeDetailsCtrl.initialProtocolId = value.protocol.id;
      surveyTypeDetailsCtrl.protocolTxGroups = TxgroupUtils.sortBy(value.protocolTxGroups, 'txGroup');
    }
  });

  $scope.$watch(surveyTypeDetailsCtrl.processingProvider, (value) => {
    surveyTypeDetailsCtrl.processing = value;
  });

  function onDelete() {
    LxNotificationService.confirm('Attention', 'Êtes-vous sur de vouloir supprimer le relevé-type ?',
              {cancel: 'Non', ok: 'Oui'},
              (answer) => (answer && surveyTypeDetailsCtrl.onDeleteFn()(surveyTypeDetailsCtrl.surveyType.id)));
  }

  function onSubmit() {
    var surveyTypeForm = _.pick(surveyTypeDetailsCtrl, ['name', 'defaultTxRefType', 'defaultTxRefFilter', 'protocol']);
    surveyTypeForm.study = surveyTypeDetailsCtrl.study.id;
    surveyTypeForm.defaultTxRefType = surveyTypeDetailsCtrl.defaultTxRefType.key;
    surveyTypeForm.defaultTxRefFilter = surveyTypeDetailsCtrl.defaultTxRefFilter.key;
    surveyTypeForm.protocol = surveyTypeDetailsCtrl.protocol.id;
    surveyTypeForm.protocolTxGroups = _.map(surveyTypeDetailsCtrl.protocolTxGroups, 'id');
    surveyTypeDetailsCtrl.onSubmitFn()(surveyTypeDetailsCtrl.surveyType.id, surveyTypeForm, $scope.form);
  }

  function canEditStudy() {
    return surveyTypeDetailsCtrl.study.canEdit &&
      // On peut créer si l'étude est verrouillée mais on ne peut pas modifier une relevé-type existant
      (surveyTypeDetailsCtrl.study.locksInfo.length === 0 || surveyTypeDetailsCtrl.isNew) &&
      surveyTypeDetailsCtrl.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      surveyTypeDetailsCtrl.study.status !== STUDY_STATUS.ARCHIVED;
  }

  function onProtocolChanged(currentProtocol) {
    surveyTypeDetailsCtrl.protocol = currentProtocol;
    surveyTypeDetailsCtrl.availableProtocolTxGroups = TxgroupUtils.sortBy(_.filter(surveyTypeDetailsCtrl.protocol.protocolTxGroups,
      (g) => !g.deleted), 'txGroup');

    if (currentProtocol.id !== surveyTypeDetailsCtrl.initialProtocolId) {
      surveyTypeDetailsCtrl.protocolTxGroups = [];
    }

    if(surveyTypeDetailsCtrl.protocol.key === 'ADNE'){
      surveyTypeDetailsCtrl.protocolTxGroups = surveyTypeDetailsCtrl.availableProtocolTxGroups;
    }
  }

  function onReferentielChanged(selectedReferentiel) {
    surveyTypeDetailsCtrl.defaultTxRefType = selectedReferentiel;
  }

  function onFilterChanged(selectedFilter) {
    surveyTypeDetailsCtrl.defaultTxRefFilter = selectedFilter;
  }

  function onMakeFieldRecordsPublic() {
    surveyTypeDetailsCtrl.onMakeFieldRecordsPublicFn()();
  }

  function formIsValid() {

    return surveyTypeDetailsCtrl.name &&
            surveyTypeDetailsCtrl.defaultTxRefType &&
            surveyTypeDetailsCtrl.defaultTxRefFilter &&
            surveyTypeDetailsCtrl.protocol &&
            surveyTypeDetailsCtrl.protocolTxGroups.length;
  }

  function isProtocolTxGroupChecked(protocolTxGroup) {
    return _.some(surveyTypeDetailsCtrl.protocolTxGroups, {id: protocolTxGroup.id});
  }

  function toggleProtocolTxGroupChecked(protocolTxGroup) {
    if (_.some(surveyTypeDetailsCtrl.protocolTxGroups, {id: protocolTxGroup.id})) {
      surveyTypeDetailsCtrl.protocolTxGroups = _.without(surveyTypeDetailsCtrl.protocolTxGroups, protocolTxGroup);
    } else {
      surveyTypeDetailsCtrl.protocolTxGroups.push(protocolTxGroup);
    }
  }
}
