import _ from 'lodash';
import { IMAGERY_SET } from '../../../map/model';

require('./menu-update-layers.scss');

export default MenuUpdateLayersDirective;

function MenuUpdateLayersDirective() {
  return {
    restrict: 'E',
    scope: {
      imagerySetsProvider: '&imagerySets',
      disabledProvider: '&disabled',
      onRefreshImagerySetsFn: '&onRefreshImagerySets'
    },
    bindToController: true,
    controller: MenuUpdateLayersController,
    controllerAs: 'menuUpdateLayersCtrl',
    template: require('./menu-update-layers.html'),
    replace: true
  };
}

// @ngInject
function MenuUpdateLayersController($scope) {
  var menuUpdateLayersCtrl = this;

  menuUpdateLayersCtrl.disabled = true;
  menuUpdateLayersCtrl.localImagerySets = [];
  menuUpdateLayersCtrl.remoteImagerySets = [];

  $scope.$watch(menuUpdateLayersCtrl.disabledProvider, (value) => {
    menuUpdateLayersCtrl.disabled = value;
  });

  $scope.$watch(menuUpdateLayersCtrl.imagerySetsProvider, (value) => {
    menuUpdateLayersCtrl.localImagerySets = value || [];
    menuUpdateLayersCtrl.remoteImagerySets = _.difference(IMAGERY_SET.VALUES, menuUpdateLayersCtrl.localImagerySets);
  });

  menuUpdateLayersCtrl.refreshLocalImagerySets = function() {
    if (!menuUpdateLayersCtrl.localImagerySets || !menuUpdateLayersCtrl.localImagerySets.length) {
      return;
    }
    menuUpdateLayersCtrl.onRefreshImagerySetsFn()(menuUpdateLayersCtrl.localImagerySets, false);
  };
  menuUpdateLayersCtrl.refreshImagerySet = function(imagerySet) {
    menuUpdateLayersCtrl.onRefreshImagerySetsFn()([imagerySet], false);
  };
  menuUpdateLayersCtrl.refreshExpertLayers = function() {
    menuUpdateLayersCtrl.onRefreshImagerySetsFn()([], true);
  };
}
