import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-synchronizer.service';

const EVENTS = {
  STUDY_SYNC_STATE_CHANGED: 'shu-synchro.study-synchronizer-state-changed'
};

export default StudySynchronizerStore;

// @ngInject
function StudySynchronizerStore($rootScope) {
  var state = Immutable({});

  return {getState};

  function getState() {
    return state;
  }
}

export { EVENTS };
