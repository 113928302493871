import _ from 'lodash';
import moment from 'moment-timezone';

import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as FIELD_RECORD_STORE_RP_EVENTS } from '../../stores/field-survey-field-records-rp.store';
import { EVENTS as FIELD_RECORD_DETAILS_STORE_EVENTS } from '../../stores/field-record-details.store';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as FIELD_SURVEY_STORE_EVENTS } from '../../../field-survey/stores/field-survey-details.store';
import { EVENTS as TAXONS_PROTECTION_LIST_STORE } from '../../../protection/stores/taxons-protection-list.store';
import { EVENTS as FIELD_RECORD_DESCRIPTION_STORE_EVENTS } from '../../stores/field-record-description.store';
import { EVENTS as BIOTOPE_REF_MAP_STORE_EVENTS } from '../../../biotope-ref/stores/biotope-ref-map.store';
import { STATUS } from '../../model';
import TxgroupUtils from '../../../txgroup/tools/txgroup-utils';

require('./field-survey-field-records-rp.scss');

const PLANTS_STRATE = 'plants_strate';
const PLANTS_ABONDANCE_DOMINANCE = 'plants_abondance_dominance';

export default FieldSurveyFieldRecordsRpDirective;

// @ngInject
function FieldSurveyFieldRecordsRpDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      fieldSurveyId: '@',
    },
    bindToController: true,
    controller: FieldSurveyFieldRecordsRpController,
    controllerAs: 'fieldSurveyFieldRecordsRpCtrl',
    template: require('./field-survey-field-records-rp.html'),
    replace: true,
  };
}

// @ngInject
function FieldSurveyFieldRecordsRpController(
  $scope,
  $state,
  LxDialogService,
  LxNotificationService,
  FieldSurveyFieldRecordsRpService,
  FieldSurveyFieldRecordsRpStore,
  StudyDetailsService,
  StudyDetailsStore,
  FieldSurveyDetailsService,
  FieldSurveyDetailsStore,
  FieldRecordDescriptionService,
  FieldRecordDescriptionStore,
  FieldRecordDetailsService,
  FieldRecordDetailsStore,
  BiotopeRefMapService,
  BiotopeRefMapStore,
  HeaderService
) {
  var fieldSurveyFieldRecordsRpCtrl = this;

  fieldSurveyFieldRecordsRpCtrl.headerUpdated = false;
  fieldSurveyFieldRecordsRpCtrl.fieldRecordDescriptionState = {};
  fieldSurveyFieldRecordsRpCtrl.strateData = [];
  fieldSurveyFieldRecordsRpCtrl.taxon = {};

  fieldSurveyFieldRecordsRpCtrl.isLoading = isLoading;
  fieldSurveyFieldRecordsRpCtrl.isLoaded = isLoaded;
  fieldSurveyFieldRecordsRpCtrl.isProcessing = isProcessing;

  fieldSurveyFieldRecordsRpCtrl.createOrUpdate = createOrUpdate;
  fieldSurveyFieldRecordsRpCtrl.canCreateSubElements = canCreateSubElements;
  fieldSurveyFieldRecordsRpCtrl.getAbondanceDominance = getAbondanceDominance;
  fieldSurveyFieldRecordsRpCtrl.getAbondanceDominanceLevel = getAbondanceDominanceLevel;
  fieldSurveyFieldRecordsRpCtrl.onShowDescription = onShowDescription;
  fieldSurveyFieldRecordsRpCtrl.onDescriptionChanged = onDescriptionChanged;
  fieldSurveyFieldRecordsRpCtrl.onFieldRecordRemove = onFieldRecordRemove;
  fieldSurveyFieldRecordsRpCtrl.onTxRefChanged = onTxRefChanged;
  fieldSurveyFieldRecordsRpCtrl.onTxRefFilterChanged = onTxRefFilterChanged;
  fieldSurveyFieldRecordsRpCtrl.onTaxonChanged = onTaxonChanged;
  fieldSurveyFieldRecordsRpCtrl.onTxGroupsChanged = onTxGroupsChanged;
  fieldSurveyFieldRecordsRpCtrl.onFieldRecordSelected = onFieldRecordSelected;
  fieldSurveyFieldRecordsRpCtrl.onTxGroupSelected = onTxGroupSelected;

  fieldSurveyFieldRecordsRpCtrl.onAbondanceDominanceChanged = onAbondanceDominanceChanged;
  fieldSurveyFieldRecordsRpCtrl.onSociabiliteChanged = onSociabiliteChanged;
  fieldSurveyFieldRecordsRpCtrl.onIsTxSureChanged = onIsTxSureChanged;
  fieldSurveyFieldRecordsRpCtrl.onValidateRecord = onValidateRecord;

  $scope.$on(BIOTOPE_REF_MAP_STORE_EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, reloadResults);
  $scope.$on(FIELD_RECORD_STORE_RP_EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_STATE_CHANGED, reloadFieldRecordsState);
  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reloadStudyState);
  $scope.$on(FIELD_SURVEY_STORE_EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED, reloadFieldSurveyState);
  $scope.$on(
    FIELD_RECORD_DESCRIPTION_STORE_EVENTS.FIELD_RECORD_DESCRIPTION_STATE_CHANGED,
    reloadFieldRecordDescription
  );

  BiotopeRefMapService.load(PLANTS_STRATE);
  BiotopeRefMapService.load(PLANTS_ABONDANCE_DOMINANCE);

  StudyDetailsService.load(fieldSurveyFieldRecordsRpCtrl.studyId);
  FieldSurveyDetailsService.load(fieldSurveyFieldRecordsRpCtrl.fieldSurveyId);
  FieldSurveyFieldRecordsRpService.load(fieldSurveyFieldRecordsRpCtrl.fieldSurveyId);

  function isLoading() {
    return (
      !fieldSurveyFieldRecordsRpCtrl.fieldRecordsState ||
      fieldSurveyFieldRecordsRpCtrl.fieldRecordsState.loading ||
      !fieldSurveyFieldRecordsRpCtrl.fieldSurveyState ||
      fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.processing ||
      !fieldSurveyFieldRecordsRpCtrl.studyState ||
      fieldSurveyFieldRecordsRpCtrl.studyState.processing
    );
  }

  function isLoaded() {
    return (
      fieldSurveyFieldRecordsRpCtrl.fieldRecordsState &&
      fieldSurveyFieldRecordsRpCtrl.fieldRecordsState.loaded &&
      fieldSurveyFieldRecordsRpCtrl.fieldSurveyState &&
      fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.loaded &&
      fieldSurveyFieldRecordsRpCtrl.studyState &&
      fieldSurveyFieldRecordsRpCtrl.studyState.loaded
    );
  }

  function isProcessing() {
    return (
      (fieldSurveyFieldRecordsRpCtrl.fieldRecordDescriptionState &&
        (fieldSurveyFieldRecordsRpCtrl.fieldRecordDescriptionState.processing ||
          fieldSurveyFieldRecordsRpCtrl.fieldRecordDescriptionState.loading)) ||
      (fieldSurveyFieldRecordsRpCtrl.fieldRecordsState && fieldSurveyFieldRecordsRpCtrl.fieldRecordsState.processing)
    );
  }

  function canCreateSubElements() {
    return (
      fieldSurveyFieldRecordsRpCtrl.studyState &&
      fieldSurveyFieldRecordsRpCtrl.studyState.study &&
      fieldSurveyFieldRecordsRpCtrl.studyState.study.canCreateSubElements &&
      fieldSurveyFieldRecordsRpCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      fieldSurveyFieldRecordsRpCtrl.studyState.study.status === STUDY_STATUS.ACTIVE
    );
  }

  function reloadFieldRecordsState(event) {
    fieldSurveyFieldRecordsRpCtrl.fieldRecordsState = FieldSurveyFieldRecordsRpStore.getState();
    fieldSurveyFieldRecordsRpCtrl.fieldRecords = fieldSurveyFieldRecordsRpCtrl.fieldRecordsState.fieldRecordList;
    fieldSurveyFieldRecordsRpCtrl.nbObservations = _.filter(
      fieldSurveyFieldRecordsRpCtrl.fieldRecords,
      (fieldRecord) => !fieldRecord.ghost
    ).length;
    fieldSurveyFieldRecordsRpCtrl.withGhosts = _.some(
      fieldSurveyFieldRecordsRpCtrl.fieldRecords,
      (fieldRecord) => fieldRecord.ghost
    );
    onTaxonChanged(fieldSurveyFieldRecordsRpCtrl.taxon);
  }

  function reloadFieldSurveyState(event) {
    fieldSurveyFieldRecordsRpCtrl.fieldSurveyState = FieldSurveyDetailsStore.getState();
    initState();
  }
  function initState() {
    
    if (!fieldSurveyFieldRecordsRpCtrl.fieldSurveyState || !fieldSurveyFieldRecordsRpCtrl.studyState) {
      return;
    }
    if (fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey) {
      fieldSurveyFieldRecordsRpCtrl.availableTxGroups = _.map(
        TxgroupUtils.sortBy(
          fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.surveyType.protocolTxGroups,
          'txGroup'
        ),
        'txGroup'
      );
      fieldSurveyFieldRecordsRpCtrl.txGroup = fieldSurveyFieldRecordsRpCtrl.availableTxGroups[0];
      fieldSurveyFieldRecordsRpCtrl.txGroups = [fieldSurveyFieldRecordsRpCtrl.txGroup];
    }

    if (fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey && fieldSurveyFieldRecordsRpCtrl.studyState.study) {
      fieldSurveyFieldRecordsRpCtrl.needSync =
        !isWebClient() &&
        fieldSurveyFieldRecordsRpCtrl.studyState.study.lockTime &&
        (fieldSurveyFieldRecordsRpCtrl.studyState.study.latestSyncTime ||
          fieldSurveyFieldRecordsRpCtrl.studyState.study.lockTime) <
          fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.updated;
      fieldSurveyFieldRecordsRpCtrl.canEdit =
        fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.canEdit &&
        fieldSurveyFieldRecordsRpCtrl.studyState.study.status === STUDY_STATUS.ACTIVE &&
        fieldSurveyFieldRecordsRpCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG;
    }
    updateHeader();
  }

  function reloadStudyState(event) {
    fieldSurveyFieldRecordsRpCtrl.studyState = StudyDetailsStore.getState();
    initState();
  }

  function reloadResults(event, tableChanged) {
    if (tableChanged === PLANTS_STRATE) {
      let tableState = BiotopeRefMapStore.getState(PLANTS_STRATE);
      if (!tableState.results) {
        return;
      }
      fieldSurveyFieldRecordsRpCtrl.strates = _.reject(tableState.results, 'deleted');
    } else if (tableChanged === PLANTS_ABONDANCE_DOMINANCE) {
      let tableState = BiotopeRefMapStore.getState(PLANTS_ABONDANCE_DOMINANCE);
      if (!tableState.results) {
        return;
      }
      fieldSurveyFieldRecordsRpCtrl.abondancesDominances = _.reject(tableState.results, 'deleted');
    }
  }

  function reloadFieldRecordDescription() {
    fieldSurveyFieldRecordsRpCtrl.fieldRecordDescriptionState = FieldRecordDescriptionStore.getState();

    if (
      fieldSurveyFieldRecordsRpCtrl.fieldRecordDescriptionState.loaded &&
      !fieldSurveyFieldRecordsRpCtrl.fieldRecordDescriptionState.fieldRecordDescription
    ) {
      LxDialogService.close('fieldRecordDescriptionDialog');
    }
    updateHeader();
  }

  function onDescriptionChanged(fieldRecordId, description) {
    FieldRecordDescriptionService.update(fieldRecordId, description);
  }

  function onShowDescription(fieldRecordId) {
    FieldRecordDescriptionService.load(fieldRecordId);
    LxDialogService.open('fieldRecordDescriptionDialog');
  }

  function isWebClient() {
    return !IS_CORDOVA;
  }

  var abondanceDominance = _.memoize(function(id) {
    var abondanceDominance = _.find(fieldSurveyFieldRecordsRpCtrl.abondancesDominances, { id: id });
    if (abondanceDominance) {
      return abondanceDominance;
    }
    return id;
  });

  function getAbondanceDominance(abondanceDominanceId) {
    return abondanceDominance(abondanceDominanceId);
  }

  // Fonction propre au orderBy
  function getAbondanceDominanceLevel(fieldRecord) {
    return getAbondanceDominance(fieldRecord.extraTables.RP_PLANTS.abondanceDominance).level;
  }

  function onIsTxSureChanged(strateId) {
    if (!fieldSurveyFieldRecordsRpCtrl.strateData[strateId]) {
      return;
    }
    createOrUpdate(fieldSurveyFieldRecordsRpCtrl.strateData[strateId]);
  }

  function onAbondanceDominanceChanged(strateId, abondanceDominance) {
    // Blocage des appels non souhaités
    if (
      !fieldSurveyFieldRecordsRpCtrl.strateData[strateId] ||
      (!abondanceDominance && !fieldSurveyFieldRecordsRpCtrl.strateData[strateId].abondanceDominance) ||
      (abondanceDominance &&
        fieldSurveyFieldRecordsRpCtrl.strateData[strateId].abondanceDominance === abondanceDominance.id)
    ) {
      return;
    }
    fieldSurveyFieldRecordsRpCtrl.strateData[strateId].abondanceDominance = abondanceDominance
      ? abondanceDominance.id
      : null;
    createOrUpdate(fieldSurveyFieldRecordsRpCtrl.strateData[strateId]);
  }

  function onValidateRecord(fieldRecord) {
    FieldSurveyFieldRecordsRpService.validateFieldRecord(
      fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.id,
      fieldRecord.id,
      $scope.fieldSurveyFieldRecordsRpForm
    );
  }

  function onSociabiliteChanged(strateId, sociabilite) {
    // Blocage des appels non souhaités
    if (
      !fieldSurveyFieldRecordsRpCtrl.strateData[strateId] ||
      (!sociabilite && !fieldSurveyFieldRecordsRpCtrl.strateData[strateId].sociabilite) ||
      (sociabilite && fieldSurveyFieldRecordsRpCtrl.strateData[strateId].sociabilite === sociabilite.id)
    ) {
      return;
    }
    fieldSurveyFieldRecordsRpCtrl.strateData[strateId].sociabilite = sociabilite ? sociabilite.id : null;
    createOrUpdate(fieldSurveyFieldRecordsRpCtrl.strateData[strateId]);
  }

  function onTaxonChanged(taxon) {
    fieldSurveyFieldRecordsRpCtrl.taxon = taxon;
    if (taxon && taxon.key) {
      fieldSurveyFieldRecordsRpCtrl.txKey = taxon.key;
    } else if (!taxon) {
      fieldSurveyFieldRecordsRpCtrl.txKey = null;
    }
    let data = _.map(
      _.filter(fieldSurveyFieldRecordsRpCtrl.fieldRecords, { txKey: taxon ? taxon.key : null }),
      function(data) {
        return {
          fieldRecordId: data.id,
          isTxSure: data.isTxSure,
          strate: data.extraTables.RP_PLANTS.strate,
          abondanceDominance: data.extraTables.RP_PLANTS.abondanceDominance,
          sociabilite: data.extraTables.RP_PLANTS.sociabilite,
          ghost: data.ghost,
        };
      }
    );
    fieldSurveyFieldRecordsRpCtrl.strateData = _.reduce(
      fieldSurveyFieldRecordsRpCtrl.strates,
      function(result, value, key) {
        result[value.id] = _.filter(data, { strate: value.id })[0] || { strate: value.id };
        return result;
      },
      []
    );
  }

  function onTxGroupSelected() {
    onTxGroupsChanged(fieldSurveyFieldRecordsRpCtrl.txGroup);
  }

  function onTxGroupsChanged(txGroup) {
    fieldSurveyFieldRecordsRpCtrl.txGroups = [txGroup];
  }

  function onTxRefChanged(txRefType) {
    fieldSurveyFieldRecordsRpCtrl.txRefType = txRefType;
  }

  function onTxRefFilterChanged(txRefFilter) {
    fieldSurveyFieldRecordsRpCtrl.txRefFilter = txRefFilter;
  }

  

  function onFieldRecordSelected(fieldRecord) {
    // Le taxon est le taxon courant, inutile de recharger
    if (fieldSurveyFieldRecordsRpCtrl.txKey === fieldRecord.txKey) {
      return;
    }
    let txRefType = {
      key: fieldRecord.txRefType,
      version: fieldRecord.txRefVersion,
    };
    onTxRefChanged(txRefType);
    
    if(fieldRecord.txRefHistory){
      fieldRecord =
      Object.assign({}, fieldRecord, {txRefFilter:fieldRecord.txRefHistory.tx_ref_filter});
    }
    let txRefFilter = {
      key: fieldRecord.txRefFilter,
    };
    onTxRefFilterChanged(txRefFilter)
    if (fieldSurveyFieldRecordsRpCtrl.txGroup !== fieldRecord.txGroup) {
      fieldSurveyFieldRecordsRpCtrl.txGroup = fieldRecord.txGroup;
      onTxGroupsChanged(fieldRecord.txGroup);
    }

    fieldSurveyFieldRecordsRpCtrl.txKey = fieldRecord.txKey;
  }

  function onFieldRecordRemove(fieldRecord) {
    // Ne pas afficher la popup de confirmation si fieldRecord.ghost
    if (fieldRecord.ghost) {
      FieldSurveyFieldRecordsRpService.removeFieldRecord(fieldSurveyFieldRecordsRpCtrl.fieldSurveyId, fieldRecord.id);
      return;
    }
    LxNotificationService.confirm(
      'Attention',
      "Êtes-vous sur de vouloir supprimer l'observation ?",
      { cancel: 'Non', ok: 'Oui' },
      (answer) =>
        answer &&
        FieldSurveyFieldRecordsRpService.removeFieldRecord(fieldSurveyFieldRecordsRpCtrl.fieldSurveyId, fieldRecord.id)
    );
  }

  function createOrUpdate(strate) {
    if (!strate.abondanceDominance) {
      if (strate.fieldRecordId) {
        // Observation existante mais pas d'abondance/dominance : suppression
        FieldSurveyFieldRecordsRpService.removeFieldRecord(
          fieldSurveyFieldRecordsRpCtrl.fieldSurveyId,
          strate.fieldRecordId
        );
      }
      // Pas d'abondance/dominance : aucune action
      return;
    }

    let txTime =
      moment(new Date().getTime()).tz(fieldSurveyFieldRecordsRpCtrl.studyState.study.timezone).toDate().getTime() /
      1000;
    let data = {
      studyId: fieldSurveyFieldRecordsRpCtrl.studyId,
      txKey: fieldSurveyFieldRecordsRpCtrl.taxon.key,
      txRefKey: fieldSurveyFieldRecordsRpCtrl.txRefType.key,
      txRefType: fieldSurveyFieldRecordsRpCtrl.txRefType.key,
      txRefVersion: fieldSurveyFieldRecordsRpCtrl.txRefType.version,
      txRefFilter: fieldSurveyFieldRecordsRpCtrl.txRefFilter.key,
      txGroupKey: fieldSurveyFieldRecordsRpCtrl.taxon.txGroup_key,
      txName: fieldSurveyFieldRecordsRpCtrl.taxon.name,
      status: STATUS.DEFAULT,
      txPresent: true,
      txTime: txTime,
      txGroup: fieldSurveyFieldRecordsRpCtrl.taxon.txGroup_key,
      fieldSurveyId: fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.id,
      txSure: !!strate.isTxSure,
      ghost: false,
    };
    if (fieldSurveyFieldRecordsRpCtrl.taxon.vernacularName) {
      data.txVernacularName = fieldSurveyFieldRecordsRpCtrl.taxon.vernacularName[0];
    }
    // Données de l'extratable de la strate
    let extraData = {};
    extraData.RP_PLANTS = {
      strate: strate.strate,
      abondanceDominance: strate.abondanceDominance,
      sociabilite: strate.sociabilite,
    };

    // Observation inexistante  : création
    if (!strate.fieldRecordId) {
      FieldSurveyFieldRecordsRpService.createFieldRecord(
        fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.id,
        fieldSurveyFieldRecordsRpCtrl.studyId,
        data,
        {},
        extraData,
        $scope.fieldSurveyFieldRecordsRpForm
      );
      return;
    }
    // Observation existante  : MàJ
    FieldSurveyFieldRecordsRpService.updateFieldRecord(
      fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.id,
      strate.fieldRecordId,
      data,
      {},
      {},
      extraData,
      $scope.fieldSurveyFieldRecordsRpForm
    );
  }

  function updateHeader() {
    if (
      !fieldSurveyFieldRecordsRpCtrl.studyState ||
      !fieldSurveyFieldRecordsRpCtrl.studyState.study ||
      !fieldSurveyFieldRecordsRpCtrl.fieldSurveyState ||
      !fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey ||
      fieldSurveyFieldRecordsRpCtrl.headerUpdated
    ) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: fieldSurveyFieldRecordsRpCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: { studyId: fieldSurveyFieldRecordsRpCtrl.studyId },
        },
      },
      {
        label: 'Relevés',
        createFieldSurvey: fieldSurveyFieldRecordsRpCtrl.studyId,
        link: {
          sref: 'study-field-surveys',
          params: { studyId: fieldSurveyFieldRecordsRpCtrl.studyId },
        },
      },
      {
        label: fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.name,
        clonage: fieldSurveyFieldRecordsRpCtrl.canCreateSubElements(),
        favori: {
          state: 'field-survey-field-records',
          params: {
            studyId: fieldSurveyFieldRecordsRpCtrl.studyId,
            fieldSurveyId: fieldSurveyFieldRecordsRpCtrl.fieldSurveyId,
          },
          picto: 'view-list',
          title: fieldSurveyFieldRecordsRpCtrl.fieldSurveyState.fieldSurvey.name,
        },
        link: null,
      },
    ]);
    fieldSurveyFieldRecordsRpCtrl.headerUpdated = true;
  }
}
