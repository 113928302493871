export default StudyImporterService;

// @ngInject
function StudyImporterService() {
  return { load, importStudies, importStudiesMap };

  function load(studyId) {
    // fake impl
    console.error('Sync is not available in web mode');
  }

  function importStudies(studyIds, selectedTxRefs) {
    // fake impl
    console.error('Sync is not available in web mode');
  }

  function importStudiesMap(studyId, imagerySetsToDownload, localImagerySets, refreshExpertLayers) {
    // fake impl
    console.error('Sync is not available in web mode');
  }
}
