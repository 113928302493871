import _ from 'lodash';

export default FieldRecordRepository;

// @ngInject
function FieldRecordRepository(FieldRecordConfiguration, MediaRepository, $http, $q) {
  return {latest, latestTxKeysForStudyAndTxGroups, getGeomForFieldSurvey, getGeomForStudy, getForStudy, getForFieldSurvey, getForFieldSurveyRp,
    getById, getDescription, getExtraTables, create, update, updateDescription, updateHeadcount, deleteFieldRecord, makePublicForSurveyType,
    makePublicForFieldSurvey, getLatestTxGroupInFieldRecord, saveTxGroupInFieldRecord,
    validateFieldRecord, getExtraDataToggle, setExtraDataToggle};

  function getLatestTxGroupInFieldRecord() {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    return window.localStorage.getItem('txkey.latest-in-field-survey');
  }

  function saveTxGroupInFieldRecord(txKey) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    if (!txKey) {
      return;
    }
    window.localStorage.setItem('txkey.latest-in-field-survey', txKey);
  }

  function latest(limit) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordLatestEndpoint,
      params: {limit}
    }).then((response) => response.data);
  }

  function latestTxKeysForStudyAndTxGroups(studyId, txGroupKeys, limit) {
    var params = {limit};
    if (txGroupKeys && txGroupKeys.length > 0) {
      params.txGroups = txGroupKeys;
    }

    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.latestTxKeysForStudyAndTxGroupsEndpoint,
      pathParams: {studyId},
      params
    }).then((response) => response.data);
  }

  function getGeomForFieldSurvey(fieldSurveyId) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordGeomForFieldRecordEndpoint,
      pathParams: {fieldSurveyId}
    }).then((response) => response.data);
  }

  function getGeomForStudy(studyId) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordGeomForStudyEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function getForStudy(studyId) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordByStudyEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function getForFieldSurvey(fieldSurveyId) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordByFieldSurveyEndpoint,
      pathParams: {fieldSurveyId}
    }).then((response) => response.data);
  }

  function getForFieldSurveyRp(fieldSurveyId) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordByFieldSurveyRpEndpoint,
      pathParams: {fieldSurveyId}
    }).then((response) => response.data);
  }

  function getById(fieldRecordId, withFullDetails = false) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordEndpoint,
      pathParams: {fieldRecordId},
      params: {withFullDetails}
    }).then((response) => response.data)
      .then(computeMediaHref);
  }

  function getDescription(fieldRecordId) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordDescriptionEndpoint,
      pathParams: {fieldRecordId}
    }).then((response) => response.data);
  }

  function getExtraTables(fieldSurveyId, txGroup) {
    return $http({
      method: 'GET',
      url: FieldRecordConfiguration.fieldRecordExtraTablesEndpoint,
      pathParams: {fieldSurveyId},
      params: {txGroup}
    }).then((response) => response.data);
  }

  function create(studyId, fieldRecordForm, fieldRecordExtraTableForms) {
    return $http({
      method: 'POST',
      url: FieldRecordConfiguration.fieldRecordByStudyEndpoint,
      pathParams: {studyId},
      data: _.merge({}, fieldRecordForm, {extraTables: fieldRecordExtraTableForms})
    }).then((response) => response.data)
      .then(computeMediaHref);
  }

  function update(fieldRecordId, fieldRecordForm, mediaForm, fieldRecordExtraTableForms) {
    return $http({
      method: 'PUT',
      url: FieldRecordConfiguration.fieldRecordEndpoint,
      pathParams: {fieldRecordId},
      data: _.merge({}, fieldRecordForm, mediaForm, {extraTables: fieldRecordExtraTableForms})
    }).then((response) => response.data)
      .then(computeMediaHref);
  }

  function updateDescription(fieldRecordId, description) {
    return $http({
      method: 'PUT',
      url: FieldRecordConfiguration.fieldRecordDescriptionEndpoint,
      pathParams: {fieldRecordId},
      data: {description}
    }).then((response) => response.data);
  }

  function updateHeadcount(fieldRecordId, headcount) {
    return $http({
      method: 'PUT',
      url: FieldRecordConfiguration.fieldRecordHeadcountEndpoint,
      pathParams: {fieldRecordId},
      data: {headcount}
    }).then((response) => response.data);
  }

  function validateFieldRecord(fieldRecordId, headcount) {
    return $http({
      method: 'PUT',
      url: FieldRecordConfiguration.fieldRecordValidationEndpoint,
      pathParams: {fieldRecordId},
      data: {headcount}
    }).then((response) => response.data);
  }

  function deleteFieldRecord(fieldRecordId) {
    return $http({
      method: 'DELETE',
      url: FieldRecordConfiguration.fieldRecordEndpoint,
      pathParams: {fieldRecordId}
    }).then((response) => response.data);
  }

  function makePublicForSurveyType(surveyTypeId) {
    return $http({
          method: 'PUT',
          url: FieldRecordConfiguration.fieldRecordMakePublicForSurveyTypeEndpoint,
          pathParams: {surveyTypeId}
        }).then((response) => response.data);
  }

  function makePublicForFieldSurvey(fieldSurveyId) {
    return $http({
          method: 'PUT',
          url: FieldRecordConfiguration.fieldRecordMakePublicForFieldSurveyEndpoint,
          pathParams: {fieldSurveyId}
        }).then((response) => response.data);
  }

  function computeMediaHref(fieldRecord) {
    var mediaPromises = _.map(fieldRecord.medias, (media) => {
      return MediaRepository.getMediaHref(media)
        .then((href) => {
          return _.merge({href}, media);
        });
    });
    return $q.all(mediaPromises)
      .then((medias) => {
        return _.defaults({medias}, fieldRecord);
      });
  }

  function getExtraDataToggle() {
    return window.localStorage && window.localStorage.getItem('field-record.extra-data-toggle') === 'true';
  }

  function setExtraDataToggle(extraDataToggle) {
    window.localStorage && window.localStorage.setItem('field-record.extra-data-toggle', extraDataToggle);
  }
}
