import { REFERENTIEL } from '../../base/services/url-resolver.service';
import configuration from '../../settings/configuration';

export default MapBackgroundsService;

// @ngInject
function MapBackgroundsService($http, MapBackgroundsRepository) {
  return { load };

  function getLocalData() {
    try {
      const cache = JSON.parse(localStorage.getItem('shu.ref_maps_background'));
      return Array.isArray(cache) ? cache : [];
    } catch (err) {
      return [];
    }
  }

  function load() {
    return new Promise((resolve) => {
      $http
        .post('https://api.uptimerobot.com/v2/getMonitors', {
          api_key: configuration.settings.uptimerobot_apikey,
        })
        .then(
          (response) => {
            if (response.data.stat != 'ok') {
              console.log('MapBackgroundsService unavailable, get local fallback');
              return resolve(getLocalData());
            }
            console.log('MapBackgroundsService load');
            MapBackgroundsRepository.get().then(
                (response) => {
                  console.log('MapBackgroundsService loaded');
                  //const layers = response.data;
                  const layers = response;
                  localStorage.setItem('shu.ref_maps_background', JSON.stringify(layers));
                  resolve(layers);
                },
                (error) => {
                  resolve(getLocalData());
                }
              );
            //});
          },
          (err) => {
            resolve(getLocalData());
          }
        );
    });
  }
}
