import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-grid.service';

const EVENTS = {
  STUDY_GRID_STATE_CHANGED: 'shu-study-grid.study-grid-state-changed'
};

export default StudyGridStore;

// @ngInject
function StudyGridStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_GRID_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_GRID_SAVING, onSaving);
  $rootScope.$on(SERVICE_EVENTS.STUDY_GRID_SAVE_SUCCESS, onSaveSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_GRID_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_GRID_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      grid: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_GRID_STATE_CHANGED);
  }

  function onSaving() {
    state = Immutable({
      grid: state.grid,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_GRID_STATE_CHANGED);
  }

  function onLoadSuccess(event, data) {
    state = Immutable({
      grid: data.grid,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_GRID_STATE_CHANGED);
  }

  function onSaveSuccess(event) {
    state = Immutable({
      grid: state.grid,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_GRID_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      grid: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_GRID_STATE_CHANGED);
  }
}

export { EVENTS };
