export default SigninRouterConfig;

// @ngInject
function SigninRouterConfig($stateProvider) {
  $stateProvider.state('signin', {
    url: '/signin?toState&toParams',
    template: `
      <shu-signin-container to-state="signinRouterCtrl.toState"
                            to-params="signinRouterCtrl.toParams">
      </shu-signin-container>`,
    resolve: {
      toState: /* @ngInject */ ($stateParams) => $stateParams.toState,
      toParams: /* @ngInject */ ($stateParams) => angular.fromJson($stateParams.toParams)
    },
    controller: SigninRouterController,
    controllerAs: 'signinRouterCtrl',
    needAuthenticatedUser: false,
    ignoreAuthenticatedUser: true
  });
}

function SigninRouterController(toState, toParams) {
  var signinRouterCtrl = this;

  signinRouterCtrl.toState = toState;
  signinRouterCtrl.toParams = toParams;
}
