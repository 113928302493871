import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxon-search.service';

const EVENTS = {
  TAXON_SEARCH_QUERY_STATE_CHANGED: 'shu-taxon-search.taxon-search-query-state-changed',
  TAXON_SEARCH_RESULTS_STATE_CHANGED: 'shu-taxon-search.taxon-search-results-state-changed'
};

export default TaxonSearchStore;

// @ngInject
function TaxonSearchStore($rootScope, TaxonSearchService) {
  var queryState = Immutable({});
  var resultsState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_RESULTS_LOADING, onTaxonSearchResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_RESULTS_LOAD_SUCCESS, onTaxonSearchResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_RESULTS_LOAD_ERROR, onTaxonSearchResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOADING, onTaxonSearchNextResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, onTaxonSearchNextResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR, onTaxonSearchNextResultsLoadError);

  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_QUERY_UPDATE, onUpdateTaxonSearchQuery);

  return {getQueryState, getResultsState};

  function getQueryState() {
    return queryState;
  }

  function getResultsState() {
    return resultsState;
  }

  function onUpdateTaxonSearchQuery(events, {query, selectedReferentiel, selectedFilter}) {
    queryState = Immutable({query, selectedReferentiel, selectedFilter});
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_QUERY_STATE_CHANGED);
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchResultsLoading() {
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchResultsLoadSuccess(event, results, totalResults, {query, selectedReferentiel, selectedFilter}) {
    
   if (queryState.query !== query || queryState.selectedReferentiel !== selectedReferentiel ) {
      /*|| queryState.selectedFilter !== Immutable({selectedFilter})*/
      return;
    }
    resultsState = Immutable({
      results,
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchResultsLoadError({query, selectedReferentiel, selectedFilter}) {
    if (queryState.query !== query || queryState.selectedReferentiel !== selectedReferentiel || queryState.selectedFilter !== selectedFilter) {
      return;
    }
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchNextResultsLoading() {
    resultsState = Immutable({
      results: resultsState.results,
      totalResults: resultsState.totalResults,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchNextResultsLoadSuccess(event, results, totalResults, {query, referentiel, filter}) {
    if (queryState.query !== query || queryState.selectedReferentiel !== referentiel) {
        /*|| queryState.selectedFilter !== filter*/ 
      return;
    }
    resultsState = Immutable({
      results: [].concat.call(resultsState.results, results),
      totalResults,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchNextResultsLoadError({query, referentiel, filter}) {
    if (queryState.query !== query || queryState.selectedReferentiel !== referentiel ) {
      /*|| queryState.selectedFilter !== filter */
      return;
    }
    resultsState = Immutable({
      results: null,
      totalResults: 0,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED);
  }
}

export { EVENTS };
