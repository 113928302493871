
import _ from 'lodash';

require('./field-record-details-ra-bats-tab.scss');

export default FieldRecordDetailsRaBatsTabDirective;

// @ngInject
function FieldRecordDetailsRaBatsTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsRaBatsTabController,
    controllerAs: 'fieldRecordDetailsRaBatsTabCtrl',
    template: require('./field-record-details-ra-bats-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsRaBatsTabController($scope, $timeout) {
  var fieldRecordDetailsRaBatsTabCtrl = this;
  fieldRecordDetailsRaBatsTabCtrl.onActiviteChanged = onActiviteChanged;
  fieldRecordDetailsRaBatsTabCtrl.onChange = onChange;
  fieldRecordDetailsRaBatsTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsRaBatsTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsRaBatsTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsRaBatsTabCtrl.canEdit = canEdit;
  fieldRecordDetailsRaBatsTabCtrl.isNew = isNew;

  $scope.$watch(fieldRecordDetailsRaBatsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsRaBatsTabCtrl.data = {};
    } else {
      fieldRecordDetailsRaBatsTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsRaBatsTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsRaBatsTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsRaBatsTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsRaBatsTabCtrl.formProvider, (form) => {
    fieldRecordDetailsRaBatsTabCtrl.form = form;
  });

  function onActiviteChanged(activite) {
	    fieldRecordDetailsRaBatsTabCtrl.data.activite = activite ? activite.id : null;
	    onChange();
	  }

  function onChange() {
    if (!fieldRecordDetailsRaBatsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsRaBatsTabCtrl.onChangeFn()(fieldRecordDetailsRaBatsTabCtrl.extraTableEnum, {
        data: {
        	activite: fieldRecordDetailsRaBatsTabCtrl.data.activite
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsRaBatsTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsRaBatsTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsRaBatsTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsRaBatsTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsRaBatsTabCtrl.isNewFn()();
  }

  function isFormValid() {
    return true;
  }
}
