export default SurveyTypeRouterConfig;

// @ngInject
function SurveyTypeRouterConfig($stateProvider) {
  $stateProvider.state('survey-type-new', {
    url: '/study/{studyId}/survey-type',
    template: '<shu-survey-type-new-container study-id="{{ ::routeCtrl.studyId }}"></shu-survey-type-new-container>',
    needAuthenticatedUser: true,
    controller: SurveyTypeNewRouteController,
    controllerAs: 'routeCtrl'
  }).state('survey-type-details', {
    url: '/study/{studyId}/survey-type/{surveyTypeId}',
    template: `
        <shu-survey-type-details-container study-id="{{ ::routeCtrl.studyId }}"
                                           survey-type-id="{{ ::routeCtrl.surveyTypeId }}">
        </shu-survey-type-details-container>`,
    needAuthenticatedUser: true,
    controller: SurveyTypeDetailsRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function SurveyTypeNewRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
}

// @ngInject
function SurveyTypeDetailsRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
  routeCtrl.surveyTypeId = $stateParams.surveyTypeId;
}
