import { EXTRA_TABLE } from '../model';

export default FieldSurveyExtraTableFilter;

// @ngInject
function FieldSurveyExtraTableFilter() {
  return function(input) {
    switch (input) {
      case EXTRA_TABLE.COMMON:
        return 'Météo';
      case EXTRA_TABLE.BIRDS:
        return 'Oiseaux';
      case EXTRA_TABLE.PE:
        return 'Point d\'échantillonnage';
      case EXTRA_TABLE.RP:
        return 'Relevé phytosocio';
      case EXTRA_TABLE.RA:
        return 'Relevé acoustique';
      case EXTRA_TABLE.ADNE:
        return 'ADN environnemental';
      default:
        return '-';
    }
  };
}
