import Leaflet from 'leaflet';

import configuration from '../configuration';

export default RemoteLayerProvider;

//@ngInject
function RemoteLayerProvider(UrlResolverService) {
  return {provideLayer};

  function provideLayer(imagerySet) {
    return UrlResolverService.resolveUrl(configuration.mapEndpoint)
      .then((url) => {
        return {
          layer: Leaflet.tileLayer(url, {imagerySet}),
          imagerySet
        };
      });
  }
}
