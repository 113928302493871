export default TaxonRefRepository;

// @ngInject
function TaxonRefRepository(TaxonConfiguration, $http) {
  return {getAllTypes, getVersions, getTaxonRef, getAllFiltersTypes, getAllTxRefFilters};

  function getAllTypes(target) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.txRefTypesEndpoint,
      params: {"target": target}
    }).then((response) => response.data);
  }

  function getAllFiltersTypes(txRefTypeKey) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.txRefFiltersEndpoint,
      pathParams: {txRefTypeKey}
    }).then((response) => response.data);
  }


  function getAllTxRefFilters() {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.txRefAllFiltersEndpoint
    }).then((response) => response.data);
  }

  function getVersions(key) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.txRefVersionsEndpoint,
      pathParams: {key}
    }).then((response) => response.data);
  }

  function getTaxonRef(key, version) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.txRefEndpoint,
      pathParams: {key, version}
    }).then((response) => response.data);
  }
}
