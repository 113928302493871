import _ from 'lodash';

import { STUDY_STATUS } from '../model';

const EVENTS = {
  // Action lancée quand un des champs du formulaire de recherche est modifié
  STUDY_SEARCH_QUERY_UPDATE: 'shu-study-search.study-search-query-update',
  // Actions lancées quand l'utilisateur lance une recherche
  STUDY_SEARCH_RESULTS_LOADING: 'shu-study-search.study-search-results-loading',
  STUDY_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-study-search.study-search-results-load-success',
  STUDY_SEARCH_RESULTS_LOAD_ERROR: 'shu-study-search.study-search-results-load-error',
  // Actions lancées quand l'utilisateur consulte les résultats suivants d'une recherche
  STUDY_SEARCH_NEXT_RESULTS_LOADING: 'shu-study-search.study-search-next-results-loading',
  STUDY_SEARCH_NEXT_RESULTS_LOAD_SUCCESS: 'shu-study-search.study-search-next-results-load-success',
  STUDY_SEARCH_NEXT_RESULTS_LOAD_ERROR: 'shu-study-search.study-search-next-results-load-error'
};
const LIMIT = 20;
const NO_GEOM = true;

export default StudySearchService;

// @ngInject
function StudySearchService($rootScope, StudyRepository, $state, LxNotificationService) {
  return {onUpdateForm, loadNextResults, onStudySelected, reset};

  function onUpdateForm(query, searchAll, status) {

    $rootScope.$emit(EVENTS.STUDY_SEARCH_QUERY_UPDATE, {query, searchAll, status});
    $rootScope.$emit(EVENTS.STUDY_SEARCH_RESULTS_LOADING);

    StudyRepository.query(query, searchAll, status, NO_GEOM, 0, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.STUDY_SEARCH_RESULTS_LOAD_SUCCESS, result.studies, result.count);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la rercherche des études. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.STUDY_SEARCH_RESULTS_LOAD_ERROR);
      });
  }

  function loadNextResults(query, searchAll, status, currentOffset) {
    $rootScope.$emit(EVENTS.STUDY_SEARCH_NEXT_RESULTS_LOADING);

    StudyRepository.query(query, searchAll, status, NO_GEOM, currentOffset, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.STUDY_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, result.studies, result.count);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la rercherche des études. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.STUDY_SEARCH_NEXT_RESULTS_LOAD_ERROR);
      });
  }

  function onStudySelected(studyId) {
    $state.go('study-details', {studyId});
  }

  function reset() {
    onUpdateForm('', false, STUDY_STATUS.DEFAULT);
  }
}
export { EVENTS };
