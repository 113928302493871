import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxon.service';

export default TaxonStore;

const EVENTS = {
  TAXON_SYNONYMS_STATE_CHANGED: 'shu-taxon-synonyms.taxon-synonyms-state-changed'
};

// @ngInject
function TaxonStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXA_SYNONYMS_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXA_SYNONYMS_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXA_SYNONYMS_LOAD_ERROR, onLoadError);

  return {getTaxaStates};

  function getTaxaStates() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      synonyms: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SYNONYMS_STATE_CHANGED);
  }

  function onLoadSuccess(event, synonyms) {
    state = Immutable({
      synonyms,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SYNONYMS_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      synonyms: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_SYNONYMS_STATE_CHANGED);
  }
}

export { EVENTS };
