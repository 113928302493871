import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxon-status.service';

export default TaxonStatusStore;

const EVENTS = {
  TAXON_STATUS_STATE_CHANGED: 'shu-taxon-status.taxon-status-state-changed',
  TAXON_SIMPLE_STATUS_STATE_CHANGED: 'shu-taxon-simple-status.taxon-simple-status-state-changed',
};

// @ngInject
function TaxonStatusStore($rootScope) {
  var state = Immutable({});
  var simpleStatusState = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXA_STATUTES_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXA_STATUTES_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXA_STATUTES_LOAD_ERROR, onLoadError);

  $rootScope.$on(SERVICE_EVENTS.TAXA_SIMPLE_STATUTES_LOADING, onSimpleStatusLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXA_SIMPLE_STATUTES_LOAD_SUCCESS, onSimpleStatusLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXA_SIMPLE_STATUTES_LOAD_ERROR, onSimpleStatusLoadError);

  return { getStatutesStates, getSimpleStatutesStates };

  function getStatutesStates() {
    return state;
  }

  function getSimpleStatutesStates() {
    return simpleStatusState;
  }

  function onLoading() {
    state = Immutable({
      statutes: null,
      loading: true,
      loaded: false,
      error: false,
    });
    $rootScope.$broadcast(EVENTS.TAXON_STATUS_STATE_CHANGED);
  }

  function onLoadSuccess(event, statutes) {
    state = Immutable({
      statutes,
      loading: false,
      loaded: true,
      error: false,
    });
    $rootScope.$broadcast(EVENTS.TAXON_STATUS_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      statutes: null,
      loading: false,
      loaded: false,
      error: true,
    });
    $rootScope.$broadcast(EVENTS.TAXON_STATUS_STATE_CHANGED);
  }

  function onSimpleStatusLoading() {
    simpleStatusState = Immutable({
      statutes: null,
      loading: true,
      loaded: false,
      error: false,
    });
    $rootScope.$broadcast(EVENTS.TAXON_SIMPLE_STATUS_STATE_CHANGED);
  }

  function onSimpleStatusLoadSuccess(event, statutes) {
    simpleStatusState = Immutable({
      statutes,
      loading: false,
      loaded: true,
      error: false,
    });
    $rootScope.$broadcast(EVENTS.TAXON_SIMPLE_STATUS_STATE_CHANGED);
  }

  function onSimpleStatusLoadError() {
    simpleStatusState = Immutable({
      statutes: null,
      loading: false,
      loaded: false,
      error: true,
    });
    $rootScope.$broadcast(EVENTS.TAXON_SIMPLE_STATUS_STATE_CHANGED);
  }
}

export { EVENTS };
