import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/survey-type-details.service';
import { EVENTS as SYNC_SERVICE_EVENTS } from '../../synchro/services/study-synchronizer.service';

const EVENTS = {
  SURVEY_TYPE_DETAILS_STATE_CHANGED: 'shu-survey-type.survey-type-details-state-changed'
};

export default SurveyTypeDetailsStore;

// @ngInject
function SurveyTypeDetailsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_DETAILS_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_DETAILS_PROCESSING, onProcessing);
  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_DETAILS_CREATING, onCreating);
  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_DETAILS_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_DETAILS_LOAD_ERROR, onLoadError);
  $rootScope.$on(SYNC_SERVICE_EVENTS.STUDY_SYNC_TERMINATED, onSyncStudy);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStudy() {
    state = Immutable({});
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED);
  }

  function onLoading() {
    state = Immutable({
      surveyType: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED);
  }

  function onLoadSuccess(event, surveyType) {
    state = Immutable({
      surveyType,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED);
  }

  function onLoadError(event) {
    state = Immutable({
      surveyType: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED);
  }

  function onProcessing() {
    state = Immutable({
      surveyType: state.surveyType,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED);
  }

  function onCreating() {
    state = Immutable({
      surveyType: null,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED);
  }
}

export { EVENTS };
