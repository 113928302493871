import { NATURALISTE } from '../base/services/url-resolver.service';

export default {
  surveyTypeListEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/survey_type'
  },
  surveyTypeDetailsEndpoint: {
    server: NATURALISTE,
    path: '/survey_type/{surveyTypeId}'
  },
  surveyTypeCreateEndpoint: {
    server: NATURALISTE,
    path: '/survey_type/create'
  }
};
