import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-field-records.service';

const EVENTS = {
  STUDY_FIELD_RECORDS_STATE_CHANGED: 'shu-field-record.study-field-record-state-changed'
};

export default StudyFieldRecordsStore;

// @ngInject
function StudyFieldRecordsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_RECORDS_LOADING, onStudyFieldRecordsLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_RECORDS_LOAD_SUCCESS, onStudyFieldRecordsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_RECORDS_LOAD_ERROR, onStudyFieldRecordsLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onStudyFieldRecordsLoading() {
    state = Immutable({
      fieldRecordList: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onStudyFieldRecordsLoadSuccess(event, fieldRecordList) {
    state = Immutable({
      fieldRecordList,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onStudyFieldRecordsLoadError() {
    state = Immutable({
      fieldRecordList: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_FIELD_RECORDS_STATE_CHANGED);
  }
}

export { EVENTS };
