import { IMAGERY_SET } from '../model';

export default ImagerySetFilter;

// @ngInject
function ImagerySetFilter() {
  return function(input) {
    switch (input) {
      case IMAGERY_SET.AERIAL:
        return 'Vue satellite';
      case IMAGERY_SET.AERIAL_WITH_LABEL:
        return 'Vue satellite avec routes';
      case IMAGERY_SET.ROAD:
        return 'Vue routière';
      default:
        return '-';
    }
  };
}
