import angular from 'angular';

import studyFieldSurveysContainerComponent from './components/study-field-surveys-container/study-field-surveys-container.component';
import fieldSurveyListComponent from './components/field-survey-list/field-survey-list.component';
import fieldSurveyCurrentComponent from './components/field-survey-current/field-survey-current.component';
import fieldSurveyDetailsContainerComponent from './components/field-survey-details-container/field-survey-details-container.component';
import fieldSurveyDetailsMainTabComponent from './components/field-survey-details-main-tab/field-survey-details-main-tab.component';
import fieldSurveyDetailsCommonTabComponent from './components/field-survey-details-common-tab/field-survey-details-common-tab.component';
import fieldSurveyDetailsBirdsTabComponent from './components/field-survey-details-birds-tab/field-survey-details-birds-tab.component';
import fieldSurveyDetailsPETabComponent from './components/field-survey-details-pe-tab/field-survey-details-pe-tab.component';
import fieldSurveyDetailsRPTabComponent from './components/field-survey-details-rp-tab/field-survey-details-rp-tab.component';
import fieldSurveyDetailsRATabComponent from './components/field-survey-details-ra-tab/field-survey-details-ra-tab.component';
import fieldSurveyDetailsADNETabComponent from './components/field-survey-details-adne-tab/field-survey-details-adne-tab.component';
import fieldSurveyDetailsMapTabComponent from './components/field-survey-details-map-tab/field-survey-details-map-tab.component';
import fieldSurveyDetailsMediasTabComponent from './components/field-survey-details-medias-tab/field-survey-details-medias-tab.component';
import fieldSurveyCreationDialogComponent from './components/field-survey-creation-dialog/field-survey-creation-dialog.component';
import studyFieldSurveysService from './services/study-field-surveys.service';
import fieldSurveyCurrentService from './services/field-survey-current.service';
import fieldSurveyMapService from './services/field-survey-map.service';
import fieldSurveyKmlService from './services/field-survey-kml.service';
import fieldSurveyListMapService from './services/field-survey-list-map.service';
import studyFieldSurveysStore from './stores/study-field-surveys.store';
import fieldSurveyCurrentStore from './stores/field-survey-current.store';
import fieldSurveyDetailsService from './services/field-survey-details.service';
import fieldSurveyDetailsStore from './stores/field-survey-details.store';
import fieldSurveyClonageService from './services/field-survey-clonage.service';
import fieldSurveyClonageStore from './stores/field-survey-clonage.store';
import fieldSurveyMapStore from './stores/field-survey-map.store';
import fieldSurveyListMapStore from './stores/field-survey-list-map.store';
import fieldSurveyRemoteRepository from './repositories/field-survey.remote.repository';
import fieldSurveyLocalRepository from './repositories/field-survey.local.repository';
import fieldSurveyExtraTableFilter from './filters/field-survey-extra-table.filter';
import precisionColorFilter from './filters/precision-color.filter.js';
import fieldSurveyConfiguration from './configuration';
import routerConfig from './routes';

const moduleName = 'shu-field-survey';

angular.module(moduleName, ['lumx'])
  .directive('shuFieldSurveyList', fieldSurveyListComponent)
  .directive('shuStudyFieldSurveysContainer', studyFieldSurveysContainerComponent)
  .directive('shuFieldSurveyCurrent', fieldSurveyCurrentComponent)
  .directive('shuFieldSurveyDetailsContainer', fieldSurveyDetailsContainerComponent)
  .directive('shuFieldSurveyDetailsMainTab', fieldSurveyDetailsMainTabComponent)
  .directive('shuFieldSurveyDetailsCommonTab', fieldSurveyDetailsCommonTabComponent)
  .directive('shuFieldSurveyDetailsBirdsTab', fieldSurveyDetailsBirdsTabComponent)
  .directive('shuFieldSurveyDetailsPETab', fieldSurveyDetailsPETabComponent)
  .directive('shuFieldSurveyDetailsRPTab', fieldSurveyDetailsRPTabComponent)
  .directive('shuFieldSurveyDetailsRATab', fieldSurveyDetailsRATabComponent)
  .directive('shuFieldSurveyDetailsADNETab', fieldSurveyDetailsADNETabComponent)
  .directive('shuFieldSurveyDetailsMapTab', fieldSurveyDetailsMapTabComponent)
  .directive('shuFieldSurveyDetailsMediasTab', fieldSurveyDetailsMediasTabComponent)
  .directive('shuFieldSurveyCreationDialog', fieldSurveyCreationDialogComponent)
  .factory('FieldSurveyRepository', IS_CORDOVA ? fieldSurveyLocalRepository : fieldSurveyRemoteRepository)
  .factory('StudyFieldSurveysService', studyFieldSurveysService)
  .factory('FieldSurveyCurrentService', fieldSurveyCurrentService)
  .factory('FieldSurveyDetailsService', fieldSurveyDetailsService)
  .factory('FieldSurveyClonageService', fieldSurveyClonageService)
  .factory('FieldSurveyMapService', fieldSurveyMapService)
  .factory('FieldSurveyKmlService', fieldSurveyKmlService)
  .factory('FieldSurveyListMapService', fieldSurveyListMapService)
  .factory('StudyFieldSurveysStore', studyFieldSurveysStore)
  .factory('FieldSurveyCurrentStore', fieldSurveyCurrentStore)
  .factory('FieldSurveyDetailsStore', fieldSurveyDetailsStore)
  .factory('FieldSurveyClonageStore', fieldSurveyClonageStore)
  .factory('FieldSurveyMapStore', fieldSurveyMapStore)
  .factory('FieldSurveyListMapStore', fieldSurveyListMapStore)
  .filter('fieldSurveyExtraTableLabel', fieldSurveyExtraTableFilter)
  .filter('precisionColor', precisionColorFilter)
  .constant('FieldSurveyConfiguration', fieldSurveyConfiguration)
  .config(routerConfig);

export default {moduleName};
