import _ from 'lodash';

export default SettingsOppService;
//
// @ngInject
function SettingsOppService($rootScope) {
  return { getShuSettings, setShuSettings, rmShuSettings };

  function getShuSettings(key, defaultValue) {
    console.error('Not available in web mode');
  }

  function setShuSettings(key, value) {
    console.error('Not available in web mode');
  }

  function rmShuSettings(key) {
    console.error('Not available in web mode');
  }
}
