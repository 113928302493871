require('./signin-form.scss');

export default SigninFormDirective;

// @ngInject
function SigninFormDirective() {
  return {
    restrict: 'E',
    scope: {
      onSubmitProvider: '&'
    },
    bindToController: true,
    controller: SigninFormController,
    controllerAs: 'signinFormCtrl',
    template: require('./signin-form.html'),
    replace: true
  };
}

// @ngInject
function SigninFormController() {
  var signinFormCtrl = this;

  signinFormCtrl.onSubmit = onSubmit;
  signinFormCtrl.appVersion = APP_VERSION;
  signinFormCtrl.logoUrl = require('./logo-biotope.png');

  function onSubmit() {
    signinFormCtrl.onSubmitProvider()(signinFormCtrl.login, signinFormCtrl.password);
  }
}
