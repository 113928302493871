import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxon-search.service';

const EVENTS = {
  TX_REF_STATE_CHANGED: 'shu-taxon-search.taxon-search-state-changed'
};

export default TaxonRefStore;

// @ngInject
function TaxonRefStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TX_REF_LOADING, onTxRefLoading);
  $rootScope.$on(SERVICE_EVENTS.TX_REF_LOAD_SUCCESS, onTxRefLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TX_REF_LOAD_ERROR, onTxRefLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onTxRefLoading() {
    state = Immutable({
      availableReferentiels: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TX_REF_STATE_CHANGED);
  }

  function onTxRefLoadSuccess(event, availableReferentiels) {
    state = Immutable({
      availableReferentiels,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TX_REF_STATE_CHANGED);
  }

  function onTxRefLoadError() {
    state = Immutable({
      availableReferentiels: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TX_REF_STATE_CHANGED);
  }
}

export { EVENTS };
