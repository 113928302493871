const EVENTS = {
  // Actions lancées quand l'utilisateur lance une recherche
  FIELD_RECORD_LATEST_LOADING: 'shu-field-record.field-record-latest-loading',
  FIELD_RECORD_LATEST_LOAD_SUCCESS: 'shu-field-record.field-record-latest-load-success',
  FIELD_RECORD_LATEST_LOAD_ERROR: 'shu-field-record.field-record-latest-load-error',
};

const LIMIT = 20;

export default FieldRecordLatestService;

// @ngInject
function FieldRecordLatestService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {load};

  function load() {
    $rootScope.$emit(EVENTS.FIELD_RECORD_LATEST_LOADING);

    FieldRecordRepository.latest(LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.FIELD_RECORD_LATEST_LOAD_SUCCESS, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des observations. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_LATEST_LOAD_ERROR);
      });
  }
}
export { EVENTS };
