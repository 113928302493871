import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/biotope-ref-map.service';

const EVENTS = {
  PROTOCOL_MAP_RESULTS_STATE_CHANGED: 'shu-biotope-ref.biotope-ref-map-results-state-changed'
};

export default BiotopeRefMapStore;

// @ngInject
function BiotopeRefMapStore($rootScope) {
  var states = {};

  $rootScope.$on(SERVICE_EVENTS.BIOTOPE_REF_MAP_RESULTS_LOADING, onBiotopeRefMapResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.BIOTOPE_REF_MAP_RESULTS_LOAD_SUCCESS, onBiotopeRefMapResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.BIOTOPE_REF_MAP_RESULTS_LOAD_ERROR, onBiotopeRefMapResultsLoadError);

  return {getState};

  function getState(table) {
    return states[table];
  }

  function onBiotopeRefMapResultsLoading(event, table) {
    states[table] = Immutable({
      results: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, table);
  }

  function onBiotopeRefMapResultsLoadSuccess(event, table, results) {
    states[table] = Immutable({
      results,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, table);
  }

  function onBiotopeRefMapResultsLoadError(event, table) {
    states[table] = Immutable({
      results: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.BIOTOPE_REF_MAP_RESULTS_STATE_CHANGED, table);
  }

}

export { EVENTS };
