import _ from 'lodash';

import { SYNC_STEP } from '../model';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de sélection des études à importer
  STUDY_SYNC_STARTED: 'shu-synchro.study-sync-started',
  STUDY_SYNC_STEP_STARTED: 'shu-synchro.study-sync-step-started',
  STUDY_SYNC_STEP_DONE: 'shu-synchro.study-sync-step-done',
  STUDY_SYNC_STEP_FAILED: 'shu-synchro.study-sync-step-failed',
  STUDY_SYNC_TERMINATED: 'shu-synchro.study-sync-terminated',
};

export default StudySynchronizerService;

// @ngInject
function StudySynchronizerService(
  $rootScope,
  StudyRepository,
  SynchroRepository,
  LxNotificationService,
  StudyMapService,
  FavorisService,
  $q
) {
  return { syncStudy };

  function syncStudy(studyId, save, unlock) {
    // On fait une requête pour vérifier qu'on est connecté.
    SynchroRepository.getStudy(studyId)
      // On charge la liste des imagerySets de l'étude
      .then(
        () => StudyRepository.getGeom(studyId).then((study) => doSyncStudy(studyId, study.imagerySets, save, unlock)),
        (reason) => LxNotificationService.error("Une erreur est survenue lors de l'appel au serveur.")
      );
  }

  function doSyncStudy(studyId, imagerySets, save, unlock) {
    $rootScope.$emit(EVENTS.STUDY_SYNC_STARTED, studyId, unlock, save);

    let promise = $q.when(true);
    if (save) {
      promise = promise.then(() => sendMedia(studyId)).then(() => sendStudyData(studyId));
    }
    if (unlock) {
      promise = promise.then(() => unlockStudy(studyId, imagerySets));
    } else if (save) {
      promise = promise.then(({ latestSyncTime }) => updateSyncState(studyId, latestSyncTime));
    }
    // On marque la synchro comme terminée même s'il y a eu des erreurs
    promise.then(
      () => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_TERMINATED);
      },
      (reason) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_TERMINATED);
      }
    );
  }

  /**
   * Sauvegarde des media de l'étude sur le serveur.
   */
  function sendMedia(studyId) {
    $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_STARTED, SYNC_STEP.REMOTE_MEDIA);

    // Upload des media des relevés et obsevrations de l'étude
    return SynchroRepository.sendMedia(studyId).then(
      (mediasIdMapping) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_DONE, SYNC_STEP.REMOTE_MEDIA);
        return $q.when(mediasIdMapping);
      },
      (reason) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_FAILED, SYNC_STEP.REMOTE_MEDIA);
        return $q.reject(reason);
      }
    );
  }

  /**
   * Sauvegarde des données de l'étude sur le serveur.
   */
  function sendStudyData(studyId) {
    $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_STARTED, SYNC_STEP.REMOTE_UPDATE);

    // Récupérer les données de l'étude et les envoyer au serveur
    return SynchroRepository.sendStudyData(studyId).then(
      ({ latestSyncTime }) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_DONE, SYNC_STEP.REMOTE_UPDATE);
        return $q.when({ latestSyncTime });
      },
      (reason) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_FAILED, SYNC_STEP.REMOTE_UPDATE);
        return $q.reject(reason);
      }
    );
  }

  /**
   * Suppression du verrou côté serveur et suppression des données en local.
   */
  function unlockStudy(studyId, imagerySets) {
    return unlockRemoteStudy(studyId).then(() => unlockLocalStudy(studyId, imagerySets));
  }

  /**
   * Suppression du verrou côté serveur
   */
  function unlockRemoteStudy(studyId) {
    $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_STARTED, SYNC_STEP.REMOTE_UNLOCK);

    // Libérer l'étude côté serveur (reject en cas d'erreur pour ne pas modifier les données locales)
    return SynchroRepository.unlockStudy(studyId).then(
      () => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_DONE, SYNC_STEP.REMOTE_UNLOCK);
        return $q.when();
      },
      (reason) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_FAILED, SYNC_STEP.REMOTE_UNLOCK);
        return $q.reject(reason);
      }
    );
  }

  /**
   * Mise à jour des informations de synchronisation en local.
   */
  function updateSyncState(studyId, latestSyncTime) {
    $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_STARTED, SYNC_STEP.LOCAL_UPDATE);

    // Modifier la date de dernière synchronisation
    return SynchroRepository.updateStudyLockInfo(studyId, latestSyncTime).then(
      () => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_DONE, SYNC_STEP.LOCAL_UPDATE);
        return $q.when();
      },
      (reason) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_FAILED, SYNC_STEP.LOCAL_UPDATE);
        return $q.reject(reason);
      }
    );
  }

  /**
   * Suppression des données en local.
   */
  function unlockLocalStudy(studyId, imagerySets) {
    // Supprimer les informations de verrou
    // Supprimer les fichiers mbtiles commencant par '{studyId}_'
    return $q.all([removeStudyData(studyId), removeStudyMap(studyId, imagerySets)]);
  }

  /**
   * Suppression des données de l'étude
   */
  function removeStudyData(studyId) {
    $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_STARTED, SYNC_STEP.LOCAL_UNLOCK);

    return SynchroRepository.removeStudyData(studyId).then(
      () => {
        StudyMapService.deleteCurrentPosition(studyId);
        FavorisService.removeStudy(studyId);
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_DONE, SYNC_STEP.LOCAL_UNLOCK);
        return $q.when();
      },
      (reason) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_FAILED, SYNC_STEP.LOCAL_UNLOCK);
        return $q.reject(reason);
      }
    );
  }

  /**
   * Suppression du fichier mbtiles
   */
  function removeStudyMap(studyId, imagerySets) {
    $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_STARTED, SYNC_STEP.LOCAL_MBTILES);
    return SynchroRepository.removeStudyMap(studyId, imagerySets).then(
      () => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_DONE, SYNC_STEP.LOCAL_MBTILES);
        return $q.when();
      },
      (reason) => {
        $rootScope.$emit(EVENTS.STUDY_SYNC_STEP_FAILED, SYNC_STEP.LOCAL_MBTILES);
        // On ne reject pas dans ce cas (il est possible qu'il s'agissant juste d'un fichier non présent)
      }
    );
  }
}

export { EVENTS };
