import moment from 'moment-timezone';

export default FavorisRepository;

// @ngInject
function FavorisRepository($q) {

  return {list, remove, save, purge};

  function list(studyId) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    var favorisString = window.localStorage.getItem(`study.${studyId}.favoris`);
    if (!favorisString) {
      return $q((resolve, reject) => resolve([]));
    }
    try {
      return $q((resolve, reject) => resolve(JSON.parse(favorisString).favoris));
    } catch (e) {
      return $q((resolve, reject) => resolve([]));
    }
  }

  function remove(studyId) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    window.localStorage.removeItem(`study.${studyId}.favoris`);
  }

  function save(studyId, favoris) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    if (!favoris) {
      return remove(studyId);
    }
    window.localStorage.setItem(`study.${studyId}.favoris`, JSON.stringify({updatedAt: moment().format('x'), favoris}));
    return $q((resolve, reject) => resolve());
  }

  function purge() {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    for (var i = 0; i < window.localStorage.length; i++) {
      var key = localStorage.key(i);
      if (!key.match(/^study\..+\.favoris$/)) {
        continue;
      }
      var favorisString = window.localStorage.getItem(key);
      if (!favorisString) {
        // Pas de données, on efface l'entrée
        window.localStorage.removeItem(key);
        continue;
      }
      try {
        var favoris = JSON.parse(favorisString);
        if (!favoris || !favoris.updatedAt || moment(favoris.updatedAt, 'x').add(6, 'months').isBefore(moment())) {
          // Pas de données ou pas de date de mise à jour ou donnée trop ancienne, on efface l'entrée
          window.localStorage.removeItem(key);
          continue;
        }
      } catch (e) {
        // Problème de format de données, on efface l'entrée
        window.localStorage.removeItem(key);
        continue;
      }
    }
  }
}
