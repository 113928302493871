import _ from 'lodash';

export default FieldSurveyRepository;

// @ngInject
function FieldSurveyRepository(FieldSurveyConfiguration, MediaRepository, $http, $q) {
  return {getById, getForStudy, current, getGeom, createFieldSurvey, updateFieldSurvey, deleteFieldSurvey, updateGeom, extractGeom, getGeomForStudy,
    getForClonage, cloneFieldSurvey};

  function getById(fieldSurveyId) {
    return $http({
      method: 'GET',
      url: FieldSurveyConfiguration.fieldSurveyByIdEndpoint,
      pathParams: {fieldSurveyId}
    })
      .then((response) => response.data)
      .then(computeMediaHref);
  }

  function getForClonage(fieldSurveyId, expertPosition) {
    return $http({
      method: 'GET',
      url: FieldSurveyConfiguration.fieldSurveyForClonageEndPoint,
      pathParams: {fieldSurveyId}
    }).then(response => {
      var fieldSurveyGeometry = null;
      var sourceGeometry = response.data.geometry;
      if (response.data.surveyType.protocol.fieldSurveyGeometryType === 'POINT' || (sourceGeometry && sourceGeometry.type === 'Point')) {
        fieldSurveyGeometry = expertPosition;
      } else {
        fieldSurveyGeometry = sourceGeometry;
      }

      return _.defaults({geometry: fieldSurveyGeometry}, response.data);
    });
  }

  function getForStudy(studyId) {
    return $http({
      method: 'GET',
      url: FieldSurveyConfiguration.fieldSurveyByStudyEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function current(limit) {
    return $http({
      method: 'GET',
      url: FieldSurveyConfiguration.fieldSurveyCurrentEndpoint,
      params: {limit}
    }).then((response) => response.data);
  }

  function getGeom(fieldSurveyId) {
    return $http({
      method: 'GET',
      url: FieldSurveyConfiguration.fieldSurveyGeomEndpoint,
      pathParams: {fieldSurveyId}
    }).then((response) => response.data);
  }

  function createFieldSurvey(studyId, surveyTypeId, fieldSurveyForm, fieldSurveyMapForm, fieldSurveyExtraTableForms) {
    return $http({
      method: 'POST',
      url: FieldSurveyConfiguration.createFieldSurveyEndpoint,
      pathParams: {studyId, surveyTypeId},
      data: _.merge(fieldSurveyForm, {extraTables: fieldSurveyExtraTableForms}, fieldSurveyMapForm)
    }).then((response) => response.data)
      .then(computeMediaHref);
  }

  function cloneFieldSurvey(studyId, surveyTypeId, fieldSurveyId, fieldSurveyForm,
    fieldSurveyMapForm, fieldSurveyExtraTableForms) {
    // On n'utilise pas surveyTypeId, on récupérera la valeur côté serveur à partir de fieldSurveyId

    var form = _.merge(fieldSurveyForm, {extraTables: fieldSurveyExtraTableForms}, fieldSurveyMapForm);

    return $http({
      method: 'POST',
      url: FieldSurveyConfiguration.clonageFieldSurveyEndPoint,
      pathParams: {studyId, fieldSurveyId},
      data: form
    }).then((response) => response.data)
      .then(computeMediaHref);
  }

  function updateFieldSurvey(fieldSurveyId, fieldSurveyForm, fieldSurveyMapForm, mediaForm, fieldSurveyExtraTableForms) {
    return $http({
      method: 'PUT',
      url: FieldSurveyConfiguration.fieldSurveyByIdEndpoint,
      pathParams: {fieldSurveyId},
      data: _.merge(fieldSurveyForm, mediaForm, {extraTables: fieldSurveyExtraTableForms}, fieldSurveyMapForm)
    })
      .then((response) => response.data)
      .then(computeMediaHref);
  }

  function deleteFieldSurvey(fieldSurveyId) {
    return $http({
      method: 'DELETE',
      url: FieldSurveyConfiguration.fieldSurveyByIdEndpoint,
      pathParams: {fieldSurveyId}
    }).then((response) => response.data);
  }

  function updateGeom(fieldSurveyId, formData) {
    return $http({
      method: 'POST',
      url: FieldSurveyConfiguration.fieldSurveyUpdateGeomEndpoint,
      headers: {'Content-Type': undefined},
      pathParams: {fieldSurveyId},
      data: formData
    }).then((response) => response.data);
  }

  function extractGeom(geometryType, formData) {
    return $http({
      method: 'POST',
      url: FieldSurveyConfiguration.fieldSurveyExtractGeomEndpoint,
      headers: {'Content-Type': undefined},
      pathParams: {geometryType},
      data: formData
    }).then((response) => response.data);
  }

  function computeMediaHref(fieldSurvey) {
    var mediaPromises = _.map(fieldSurvey.medias, (media) => {
      return MediaRepository.getMediaHref(media)
        .then((href) => {
          return _.merge({href}, media);
        });
    });
    return $q.all(mediaPromises)
      .then((medias) => {
        return _.defaults({medias}, fieldSurvey);
      });
  }

  function getGeomForStudy(studyId) {
    return $http({
      method: 'GET',
      url: FieldSurveyConfiguration.fieldSurveyGeomForStudyEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }
}
