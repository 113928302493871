export default ProtectionRepository;

// @ngInject
function ProtectionRepository($http, ProtectionConfiguration) {
  return {getForProtectionsForTaxons};

  function getForProtectionsForTaxons(txRefType, txRefVersion, txKeys, studyBoundary) {
    return $http({
      method: 'POST',
      url: ProtectionConfiguration.protectionByEnvelopeEndpoint,
      pathParams: {
        txRefType,
        txRefVersion
      },
      data: {txKeys: txKeys, boundary: studyBoundary}
    }).then((response) => response.data);
  }
}
