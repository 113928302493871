const EVENTS = {
  // Actions lancées quand l'utilisateur consulte les observations d'un relevé
  FIELD_SURVEY_FIELD_RECORDS_LOADING: 'shu-field-record.field-survey-record-load-loading',
  FIELD_SURVEY_FIELD_RECORDS_LOAD_SUCCESS: 'shu-field-record.field-survey-field-record-load-success',
  FIELD_SURVEY_FIELD_RECORDS_LOAD_ERROR: 'shu-field-record.field-survey-field-record-load-error',
  FIELD_SURVEY_FIELD_RECORDS_PROCESSING: 'shu-field-record.field-survey-field-record-processing',
  FIELD_SURVEY_FIELD_RECORDS_PROCESS_ERROR: 'shu-field-record.field-survey-field-record-process-error',
};

export default FieldSurveyFieldRecordsService;

// @ngInject
function FieldSurveyFieldRecordsService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {load, removeFieldRecord};

  function load(fieldSurveyId) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_LOADING);

    FieldRecordRepository.getForFieldSurvey(fieldSurveyId)
      .then((fieldRecordListResult) => {
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_LOAD_SUCCESS, fieldRecordListResult);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la récupération des informations des observations. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_LOAD_ERROR);
      });
  }

  function removeFieldRecord(fieldSurveyId, fieldRecordId) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_PROCESSING);

    FieldRecordRepository.deleteFieldRecord(fieldRecordId)
      .then(() => FieldRecordRepository.getForFieldSurvey(fieldSurveyId))
      .then((fieldRecordListResult) => {
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_LOAD_SUCCESS, fieldRecordListResult);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la suppression de l\'observation. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_SURVEY_FIELD_RECORDS_PROCESS_ERROR);
      });
  }
}
export { EVENTS };
