const ACCURACY = {
  EXACT: 'EXACT',
  APPROXIMATE: 'APPROXIMATE',
  MINIMUM: 'MINIMUM',
  ESTIMATED: 'ESTIMATED',
  VALUES: [
    'EXACT',
    'APPROXIMATE',
    'MINIMUM',
    'ESTIMATED'
  ],
  DEFAULT: 'MINIMUM'
};

const STATUS = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  VALIDATED: 'VALIDATED',
  VALUES: [
    'PRIVATE',
    'PUBLIC',
    'VALIDATED'
  ],
  DEFAULT: 'PRIVATE'
};

const EXTRA_TABLE = {
  LS_AMPHIBIA: 'LS_AMPHIBIA',
  LS_BIRDS: 'LS_BIRDS',
  LS_FISHES: 'LS_FISHES',
  LS_INSECTS_ODO: 'LS_INSECTS_ODO',
  LS_INSECTS_ORTHO: 'LS_INSECTS_ORTHO',
  LS_INSECTS_OTHER: 'LS_INSECTS_OTHER',
  LS_INSECTS_RHO: 'LS_INSECTS_RHO',
  LS_MAMMALS: 'LS_MAMMALS',
  LS_MOLLUSCS: 'LS_MOLLUSCS',
  LS_PLANTS: 'LS_PLANTS',
  LS_REPTILES: 'LS_REPTILES',
  RI_AMPHIBIA: 'RI_AMPHIBIA',
  RI_BIRDS: 'RI_BIRDS',
  RI_FISHES: 'RI_FISHES',
  RI_INSECTS_ODO: 'RI_INSECTS_ODO',
  RI_INSECTS_ORTHO: 'RI_INSECTS_ORTHO',
  RI_INSECTS_OTHER: 'RI_INSECTS_OTHER',
  RI_INSECTS_RHO: 'RI_INSECTS_RHO',
  RI_MAMMALS: 'RI_MAMMALS',
  RI_MOLLUSCS: 'RI_MOLLUSCS',
  RI_PLANTS: 'RI_PLANTS',
  RI_REPTILES: 'RI_REPTILES',
  RP_PLANTS: 'RP_PLANTS',
  RA_BATS: 'RA_BATS',
  GENETIC_SAMPLING: 'GENETIC_SAMPLING',



  VALUES: [
    'LS_AMPHIBIA',
    'LS_BIRDS',
    'LS_FISHES',
    'LS_INSECTS_ODO',
    'LS_INSECTS_ORTHO',
    'LS_INSECTS_OTHER',
    'LS_INSECTS_RHO',
    'LS_MAMMALS',
    'LS_MOLLUSCS',
    'LS_PLANTS',
    'LS_REPTILES',
    'RI_AMPHIBIA',
    'RI_BIRDS',
    'RI_FISHES',
    'RI_INSECTS_ODO',
    'RI_INSECTS_ORTHO',
    'RI_INSECTS_OTHER',
    'RI_INSECTS_RHO',
    'RI_MAMMALS',
    'RI_MOLLUSCS',
    'RI_PLANTS',
    'RI_REPTILES',
    'RP_PLANTS',
    'RA_BATS',
    'GENETIC_SAMPLING'
  ]
};

const CREATION_MODE = {
  WITHOUT_GEOMETRY: 'WITHOUT_GEOMETRY',
  ACTIVATE_GPS: 'ACTIVATE_GPS',
  DEFINE_ON_MAP: 'DEFINE_ON_MAP',

  VALUES: [
    'WITHOUT_GEOMETRY',
    'ACTIVATE_GPS',
    'DEFINE_ON_MAP'
  ]
};

const ON_THE_FLY_EXTRA_TABLES = [
  {name: 'LS_INSECTS_RHO', stadeDeveloppementTable: 'insects_rho_stade_developpement', comportementTable: 'insects_rho_comportement'},
  {name: 'RI_INSECTS_RHO', stadeDeveloppementTable: 'insects_rho_stade_developpement', comportementTable: 'insects_rho_comportement'},
  {name: 'LS_INSECTS_ODO', stadeDeveloppementTable: 'insects_odo_stade_developpement', comportementTable: 'insects_odo_comportement'},
  {name: 'RI_INSECTS_ODO', stadeDeveloppementTable: 'insects_odo_stade_developpement', comportementTable: 'insects_odo_comportement'},
  {name: 'LS_INSECTS_ORTHO', stadeDeveloppementTable: 'insects_ortho_stade_developpement', comportementTable: 'insects_ortho_comportement'},
  {name: 'RI_INSECTS_ORTHO', stadeDeveloppementTable: 'insects_ortho_stade_developpement', comportementTable: 'insects_ortho_comportement'},
  {name: 'LS_INSECTS_OTHER', stadeDeveloppementTable: 'insects_other_stade_developpement', comportementTable: 'insects_other_comportement'},
  {name: 'RI_INSECTS_OTHER', stadeDeveloppementTable: 'insects_other_stade_developpement', comportementTable: 'insects_other_comportement'},
  {name: 'LS_AMPHIBIA', stadeDeveloppementTable: 'amphibia_stade_developpement'},
  {name: 'RI_AMPHIBIA', stadeDeveloppementTable: 'amphibia_stade_developpement'},
  {name: 'LS_REPTILES', stadeDeveloppementTable: 'reptiles_stade_developpement'},
  {name: 'RI_REPTILES', stadeDeveloppementTable: 'reptiles_stade_developpement'},
  {name: 'LS_BIRDS', comportementTable: 'birds_comportement'},
  {name: 'RI_BIRDS', comportementTable: 'birds_comportement'}
];

export { ACCURACY, STATUS, EXTRA_TABLE, CREATION_MODE, ON_THE_FLY_EXTRA_TABLES };
