import _ from 'lodash';

require('./field-record-details-ls-molluscs-tab.scss');

export default FieldRecordDetailsLsMolluscsTabDirective;

// @ngInject
function FieldRecordDetailsLsMolluscsTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsMolluscsTabController,
    controllerAs: 'fieldRecordDetailsLsMolluscsTabCtrl',
    template: require('./field-record-details-ls-molluscs-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsMolluscsTabController($scope, $timeout) {
  var fieldRecordDetailsLsMolluscsTabCtrl = this;
  fieldRecordDetailsLsMolluscsTabCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsLsMolluscsTabCtrl.onTypeIndicePresenceChanged = onTypeIndicePresenceChanged;
  fieldRecordDetailsLsMolluscsTabCtrl.onChange = onChange;
  fieldRecordDetailsLsMolluscsTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsMolluscsTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsMolluscsTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsMolluscsTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsMolluscsTabCtrl.isNew = isNew;

  $scope.$watch(fieldRecordDetailsLsMolluscsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsMolluscsTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsMolluscsTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsLsMolluscsTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsMolluscsTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsMolluscsTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsMolluscsTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsMolluscsTabCtrl.form = form;
  });

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    fieldRecordDetailsLsMolluscsTabCtrl.data.stadeDeveloppement = stadeDeveloppement ? stadeDeveloppement.id : null;
    onChange();
  }

  function onTypeIndicePresenceChanged(typeIndicePresence) {
    fieldRecordDetailsLsMolluscsTabCtrl.data.typeIndicePresence = typeIndicePresence ? typeIndicePresence.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsMolluscsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsMolluscsTabCtrl.onChangeFn()(fieldRecordDetailsLsMolluscsTabCtrl.extraTableEnum, {
        data: {
          stadeDeveloppement: fieldRecordDetailsLsMolluscsTabCtrl.data.stadeDeveloppement,
          typeIndicePresence: fieldRecordDetailsLsMolluscsTabCtrl.data.typeIndicePresence
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsMolluscsTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsMolluscsTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsMolluscsTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsMolluscsTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsMolluscsTabCtrl.isNewFn()();
  }

  function isFormValid() {
    return fieldRecordDetailsLsMolluscsTabCtrl.form['fieldRecord.typeIndicePresence'] &&
      fieldRecordDetailsLsMolluscsTabCtrl.form['fieldRecord.typeIndicePresence'].$valid;
  }
}
