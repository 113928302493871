import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../../stores/study-creation.store';

require('./study-creation-dialog.scss');

export default StudyCreationDialogDirective;

// @ngInject
function StudyCreationDialogDirective() {
  return {
    restrict: 'E',
    scope: {
      dialogId: '@'
    },
    controller: StudyCreationDialogController,
    controllerAs: 'studyCreationDialogCtrl',
    bindToController: true,
    template: require('./study-creation-dialog.html'),
    replace: true
  };
}

// @ngInject
function StudyCreationDialogController($scope, StudyCreationService, StudyCreationStore, $state, LxNotificationService, LxDialogService) {
  var studyCreationDialogCtrl = this;

  studyCreationDialogCtrl.resultsState = {};
  studyCreationDialogCtrl.numero = null;
  studyCreationDialogCtrl.initialized = false;
  studyCreationDialogCtrl.creating = false;
  studyCreationDialogCtrl.loadError = false;
  studyCreationDialogCtrl.createError = false;
  studyCreationDialogCtrl.name = null;
  studyCreationDialogCtrl.onSubmitForm = onSubmitForm;

  /*
     Abonnement à l'evenement stateChanged du store pour
     recuperer les infos fiables dans le store suite aux mises à jours
  */
  $scope.$on(STORE_EVENTS.STUDY_CREATION_STATE_CHANGED, loading);

  /*
     Abonnement à l'evenement d'ouverture de dialogue dans l'appli
     pour pouvoir lancer le service de génération de clé à l'ouverture
     de notre dialogue
  */
  $scope.$on('lx-dialog__open-start', onDialogOpen);

  function onDialogOpen(event, eventDialogId) {
    // Si eventDialogId est différent de notre dialogue alors on ne fait rien
    if (eventDialogId !== studyCreationDialogCtrl.dialogId) {
      return;
    }

    studyCreationDialogCtrl.numero = null;
    studyCreationDialogCtrl.initialized = false;
    studyCreationDialogCtrl.name = null;
    studyCreationDialogCtrl.creating = false;
    studyCreationDialogCtrl.loadError = false;
    studyCreationDialogCtrl.createError = false;

    // lancement du service
    StudyCreationService.load();
  }

  // Ce qu'il faut faire après notification stateChanged du store
  function loading() {
    var oldResultsState = studyCreationDialogCtrl.resultsState;
    studyCreationDialogCtrl.resultsState = StudyCreationStore.getState();

    // Si on est en train de sauvegarder
    if (studyCreationDialogCtrl.resultsState.processing && !oldResultsState.processing) {
      studyCreationDialogCtrl.creating = true;
      return;
    }

    // Si on vient de terminer la sauvegarde
    if (!studyCreationDialogCtrl.resultsState.processing && oldResultsState.processing) {
      studyCreationDialogCtrl.creating = false;
      // Si on vient de terminer la sauvegarde avec erreur
      if (studyCreationDialogCtrl.resultsState.error) {
        studyCreationDialogCtrl.createError = true;
        LxNotificationService.error('Erreur de création étude personnelle. ');
      } else {
        // Si on vient de terminer la sauvegarde avec succes
        LxDialogService.close('studyCreationDialog');
        $state.go('study-details', {studyId: studyCreationDialogCtrl.resultsState.studyId});
      }
      return;
    }

    // Si initialized alors on ne fait rien
    if (studyCreationDialogCtrl.initialized) {
      return;
    }

    // Si on vient de terminer le chargement avec erreur
    if (studyCreationDialogCtrl.resultsState.error) {
      LxNotificationService.error('Une erreur est survenue au chargement de la clé générée pour l\'étude.');
      studyCreationDialogCtrl.loadError = true;
      studyCreationDialogCtrl.initialized = true;
      return;
    }

    // Si studyKey est renseigné et loaded  alors on met le numéro à jour et initialized = true
    if (studyCreationDialogCtrl.resultsState.loaded) {
      studyCreationDialogCtrl.initialized = true;
      studyCreationDialogCtrl.numero = studyCreationDialogCtrl.resultsState.studyKey;
    }

  }

  function onSubmitForm() {
    if (studyCreationDialogCtrl.numero !== null && studyCreationDialogCtrl.name !== null) {
      StudyCreationService.onFormSubmit(studyCreationDialogCtrl.numero, studyCreationDialogCtrl.name);
    }
  }

}
