import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-record-description.service';

const EVENTS = {
  FIELD_RECORD_DESCRIPTION_STATE_CHANGED: 'shu-field-record.field-record-description-state-changed'
};

export default FieldRecordDescriptionStore;

// @ngInject
function FieldRecordDescriptionStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DESCRIPTION_LOADING, onFieldRecordDescriptionLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DESCRIPTION_LOAD_SUCCESS, onFieldRecordDescriptionLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DESCRIPTION_LOAD_ERROR, onFieldRecordDescriptionLoadError);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DESCRIPTION_PROCESSING, onFieldRecordDescriptionProcessing);

  return {getState};

  function getState() {
    return state;
  }

  function onFieldRecordDescriptionLoading() {
    state = Immutable({
      fieldRecordDescription: null,
      processing: false,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DESCRIPTION_STATE_CHANGED);
  }

  function onFieldRecordDescriptionLoadSuccess(event, fieldRecordDescription) {
    state = Immutable({
      fieldRecordDescription,
      processing: false,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DESCRIPTION_STATE_CHANGED);
  }

  function onFieldRecordDescriptionLoadError() {
    state = Immutable({
      fieldRecordDescription: null,
      processing: false,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DESCRIPTION_STATE_CHANGED);
  }

  function onFieldRecordDescriptionProcessing() {
    state = Immutable({
      fieldRecordDescription: state.fieldRecordDescription,
      processing: true,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DESCRIPTION_STATE_CHANGED);
  }
}

export { EVENTS };
