import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/protocol-list.service';

const EVENTS = {
  PROTOCOL_LIST_RESULTS_STATE_CHANGED: 'shu-protocol.protocol-list-results-state-changed'
};

export default ProtocolListStore;

// @ngInject
function ProtocolListStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.PROTOCOL_LIST_RESULTS_LOADING, onProtocolListResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.PROTOCOL_LIST_RESULTS_LOAD_SUCCESS, onProtocolListResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PROTOCOL_LIST_RESULTS_LOAD_ERROR, onProtocolListResultsLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onProtocolListResultsLoading() {
    state = Immutable({
      results: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PROTOCOL_LIST_RESULTS_STATE_CHANGED);
  }

  function onProtocolListResultsLoadSuccess(event, results) {
    state = Immutable({
      results,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PROTOCOL_LIST_RESULTS_STATE_CHANGED);
  }

  function onProtocolListResultsLoadError() {
    state = Immutable({
      results: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PROTOCOL_LIST_RESULTS_STATE_CHANGED);
  }

}

export { EVENTS };
