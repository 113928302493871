import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'une étude
  STUDY_DETAILS_LOADING: 'shu-study-details.study-details-loading',
  STUDY_DETAILS_PROCESSING: 'shu-study-details.study-details-processing',
  STUDY_DETAILS_PROCESS_DONE: 'shu-study-details.study-details-process-done',
  STUDY_DETAILS_LOAD_SUCCESS: 'shu-study-details.study-details-load-success',
  STUDY_DETAILS_LOAD_ERROR: 'shu-study-details.study-details-load-error'
};

export default StudyDetailsService;

// @ngInject
function StudyDetailsService($rootScope, StudyRepository, StudyDetailsStore, LxNotificationService) {
  return {load, onFormSubmit, joinStudy, exportMbTiles};

  function load(studyId) {
    $rootScope.$emit(EVENTS.STUDY_DETAILS_LOADING);

    StudyRepository.getStudy(studyId)
      .then(
        (study) => $rootScope.$emit(EVENTS.STUDY_DETAILS_LOAD_SUCCESS, study),
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement de l\'étude. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.STUDY_DETAILS_LOAD_ERROR);
        }
      );
  }

  function onFormSubmit(studyId, studyFormData, studyFormCtrl) {
    $rootScope.$emit(EVENTS.STUDY_DETAILS_PROCESSING);

    StudyRepository.updateStudy(studyId, studyFormData)
      .then(
        (study) => {
          studyFormCtrl.$setPristine();
          LxNotificationService.success('Étude enregistrée.');
          $rootScope.$emit(EVENTS.STUDY_DETAILS_LOAD_SUCCESS, study);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde de l\'étude. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.STUDY_DETAILS_LOAD_ERROR);
        }
      );
  }

  function joinStudy(studyId) {
    $rootScope.$emit(EVENTS.STUDY_DETAILS_PROCESSING);

    StudyRepository.joinStudy(studyId)
      .then(
        (study) => {
          LxNotificationService.success('Étude enregistrée.');
          $rootScope.$emit(EVENTS.STUDY_DETAILS_LOAD_SUCCESS, study);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde de l\'étude. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.STUDY_DETAILS_LOAD_ERROR);
        }
      );
  }

  function exportMbTiles(studyId, studyKey, imagerySets) {
    // Les MBTiles ne sont disponibles que sous la tablette
    if (!IS_CORDOVA) {
      return;
    }

    $rootScope.$emit(EVENTS.STUDY_DETAILS_PROCESSING);

    StudyRepository.exportStudyMbTilesToUserDirectory(studyId, studyKey, imagerySets)
      .then(
        (count) => {
          LxNotificationService.success(count + ' fichiers MBTiles ont été exportés avec succès dans le dossier Download.');
          $rootScope.$emit(EVENTS.STUDY_DETAILS_PROCESS_DONE);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue durant l’export des fichiers MBTiles. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.STUDY_DETAILS_LOAD_ERROR);
        }
      );
  }
}
export { EVENTS };
