import FieldRecordDetailsMediaTabController from '../../../media/controllers/media-details.controller';

require('./field-record-details-medias-tab.scss');

export default FieldRecordDetailsMediasTabDirective;

// @ngInject
function FieldRecordDetailsMediasTabDirective() {
  return {
    restrict: 'E',
    scope: {
      mediasProvider: '&medias',
      onChangeFn: '&onChange',
      onAddMediaFn: '&onAddMedia',
      onDeleteFn: '&onDelete',
      canSubmit: '&canSubmit',
      canEditProvider: '&canEdit',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsMediaTabController,
    controllerAs: 'fieldRecordDetailsMediasTabCtrl',
    template: require('./field-record-details-medias-tab.html'),
    replace: true
  };
}
