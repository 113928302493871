import _ from 'lodash';

const EVENTS = {
  // Actions lancées au (re)chargement de la page
  TX_REF_LOADING: 'shu-taxon-search.tx-ref-loading',
  TX_REF_LOAD_SUCCESS: 'shu-taxon-search.tx-ref-load-success',
  TX_REF_LOAD_ERROR: 'shu-taxon-search.tx-ref-load-error',
  //
  TX_REF_FILTER_LOADING: 'shu-taxon-search.tx-ref-filter-loading',
  TX_REF_FILTER_LOAD_SUCCESS: 'shu-taxon-search.tx-ref-filter-load-success',
  TX_REF_FILTER_LOAD_ERROR: 'shu-taxon-search.tx-ref-filter-load-error',
  // Action lancée quand un des champs du formulaire de recherche est modifié
  TAXON_SEARCH_QUERY_UPDATE: 'shu-taxon-search.taxon-search-query-update',
  // Actions lancées quand l'utilisateur lance une recherche
  TAXON_SEARCH_RESULTS_LOADING: 'shu-taxon-search.taxon-search-results-loading',
  TAXON_SEARCH_RESULTS_LOAD_SUCCESS: 'shu-taxon-search.taxon-search-results-load-success',
  TAXON_SEARCH_RESULTS_LOAD_ERROR: 'shu-taxon-search.taxon-search-results-load-error',
  // Actions lancées quand l'utilisateur consulte les résultats suivants d'une recherche
  TAXON_SEARCH_NEXT_RESULTS_LOADING: 'shu-taxon-search.taxon-search-next-results-loading',
  TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS: 'shu-taxon-search.taxon-search-next-results-load-success',
  TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR: 'shu-taxon-search.taxon-search-next-results-load-error'
};
const LIMIT = 20;
var filter=null;

export default TaxonSearchService;

// @ngInject
function TaxonSearchService($rootScope, TaxonRepository, TaxonRefStore, TaxonRefFilterStore, TaxonRefRepository, $q, $state, LxNotificationService) {
  return {onUpdateForm, loadNextResults, onTaxonSelected, reset, loadTxRef, loadTxRefFilters, loadTxRefAllFilters};


  function onUpdateForm(query, selectedReferentiel, selectedFilter) {
    $rootScope.$emit(EVENTS.TAXON_SEARCH_QUERY_UPDATE, {query, selectedReferentiel, selectedFilter});

    // On ne requête le serveur qu'à partir de 3 caractères
    if (!query || query.length <= 2 || !selectedReferentiel || !selectedFilter) {
      return;
    }
    $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOADING);
    TaxonRepository.query(query, selectedReferentiel.key, selectedReferentiel.version, selectedFilter.key, null, 0, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOAD_SUCCESS, result.taxons, result.count, {query, selectedReferentiel, selectedFilter});
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TAXON_SEARCH_RESULTS_LOAD_ERROR, {query, selectedReferentiel, selectedFilter});
      });
  }

function loadNextResults(query, referentiel, selectedFilter, currentOffset) {
    $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOADING);
    TaxonRepository.query(query, referentiel.key, referentiel.version, selectedFilter, null, currentOffset, LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_SUCCESS, result.taxons, result.count, {query, referentiel, selectedFilter});
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la recherche des taxons. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TAXON_SEARCH_NEXT_RESULTS_LOAD_ERROR, {query, referentiel});
      });
  }

  function onTaxonSelected(txRefType, txRefVersion, txRefFilter, taxonKey) {

    $state.go('taxon-details', {txRefType, txRefVersion, txRefFilter, taxonKey});
  }

  function reset() {
    $rootScope.$emit(EVENTS.TX_REF_LOADING);

    var target = IS_CORDOVA ? "tablet": "web";
    //TaxonRefRepository.getAllFiltersTypes(txRefType.key);
    TaxonRefRepository.getAllTypes(target)
      .then((txRefTypes) => {
        // TODO: Modifier le service de récupération des types pour tout récupérer en un seul coup
        return $q.all(_.map(txRefTypes, (txRefType) => TaxonRefRepository.getVersions(txRefType.key)))
          .then((txRefs) => _.flatten(txRefs));
      })
      .then((txRefs) => {
        //search filter list  with first value from txRefs array
        loadTxRefFilters(_.chain(txRefs)
          .values()
          .flatten()
          .orderBy(['ordre', 'created'], ['asc', 'desc'])
          .first()
          .value().key);

          $rootScope.$emit(EVENTS.TX_REF_LOAD_SUCCESS, txRefs);
          $rootScope.$emit(EVENTS.TAXON_SEARCH_QUERY_UPDATE, {
            query: '',
            selectedReferentiel: _.chain(txRefs)
              .values()
              .flatten()
              .orderBy(['ordre', 'created'], ['asc', 'desc'])
              .first()
              .value(),
            selectedFilter: filter
          });
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la récupération des référentiels. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TX_REF_LOAD_ERROR);
      });
  }

  function loadTxRef() {
    $rootScope.$emit(EVENTS.TX_REF_LOADING);

    var target = IS_CORDOVA ? "tablet": "web";

    TaxonRefRepository.getAllTypes(target)
      .then((txRefTypes) => {
        // TODO: Modifier le service de récupération des types pour tout récupérer en un seul coup
        return $q.all(_.map(txRefTypes, (txRefType) => TaxonRefRepository.getVersions(txRefType.key)))
          .then((txRefs) => _.flatten(txRefs));
      })
      .then((txRefs) => {
        $rootScope.$emit(EVENTS.TX_REF_LOAD_SUCCESS, txRefs);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la récupération des référentiels. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TX_REF_LOAD_ERROR);
      });
  }
  
  function loadTxRefFilters(txRefTypeKey){
    $rootScope.$emit(EVENTS.TX_REF_FILTER_LOADING);

    TaxonRefRepository.getAllFiltersTypes(txRefTypeKey)
      .then((txRefFilters) => {
        filter = txRefFilters[0];
        $rootScope.$emit(EVENTS.TX_REF_FILTER_LOAD_SUCCESS, txRefFilters);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la récupération des filtres. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TX_REF_FILTER_LOAD_ERROR);
      });
  }
  function loadTxRefAllFilters(){
    $rootScope.$emit(EVENTS.TX_REF_FILTER_LOADING);

    TaxonRefRepository.getAllTxRefFilters()
      .then((txRefFilters) => {
        $rootScope.$emit(EVENTS.TX_REF_FILTER_LOAD_SUCCESS, txRefFilters);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la récupération des filtres. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TX_REF_FILTER_LOAD_ERROR);
      });
  }
}
export { EVENTS };
