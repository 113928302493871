import angular from 'angular';
import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../../stores/taxon-details.store';
import { EVENTS as TAXA_SERVICE_STORE_EVENTS } from '../../stores/taxon-status.store';
import { EVENTS as TAXA_STORE_EVENTS } from '../../stores/taxon.store';
import { EVENTS as TAXA_REF_FILTER_STORE_EVENTS } from '../../stores/taxon-ref-filter.store';

require('./taxon-details.scss');

export default TaxonDetailsDirective;

// @ngInject
function TaxonDetailsDirective() {
  return {
    restrict: 'E',
    scope: {
      txRefType: '@',
      txRefVersion: '@',
      txRefFilter: '@',
      taxonKey: '@',
    },
    controller: TaxonDetailsController,
    controllerAs: 'taxonDetailsCtrl',
    bindToController: true,
    template: require('./taxon-details.html'),
    replace: true,
  };
}

// @ngInject
function TaxonDetailsController(
  $scope,
  $rootScope,
  TaxonDetailsStore,
  TaxonDetailsService,
  HeaderService,
  TaxonStatusService,
  TaxonStatusStore,
  TaxonService,
  TaxonStore,
  TaxonSearchService,
  TaxonRefFilterStore
) {
  var taxonDetailsCtrl = this;

  taxonDetailsCtrl.state = {};
  taxonDetailsCtrl.statutesState = {};
  taxonDetailsCtrl.simpleStatutesState = {};
  taxonDetailsCtrl.synonymsState = {};
  taxonDetailsCtrl.headerUpdated = false;
  taxonDetailsCtrl.terrUnit = '';
  taxonDetailsCtrl.itemTracker = itemTracker;

  $scope.$on(STORE_EVENTS.TAXON_DETAILS_STATE_CHANGED, onStateChanged);
  $scope.$on(TAXA_SERVICE_STORE_EVENTS.TAXON_STATUS_STATE_CHANGED, reloadTaxaStatutes);
  $scope.$on(TAXA_SERVICE_STORE_EVENTS.TAXON_SIMPLE_STATUS_STATE_CHANGED, reloadTaxaSimpleStatutes);
  $scope.$on(TAXA_STORE_EVENTS.TAXON_SYNONYMS_STATE_CHANGED, reloadTaxaSynonyms);
  $scope.$on(TAXA_REF_FILTER_STORE_EVENTS.TX_REF_FILTER_STATE_CHANGED, reloadFilter);

  reload();
  function onStateChanged() {
    taxonDetailsCtrl.state = TaxonDetailsStore.getState();
    if (!taxonDetailsCtrl.state.referentiel || taxonDetailsCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: 'Catalogue de taxons',
        link: {
          sref: 'taxon-search',
        },
      },
      taxonDetailsCtrl.state.referentiel.name + ' ' + taxonDetailsCtrl.state.referentiel.version,
      taxonDetailsCtrl.state.taxon.name,
    ]);
    taxonDetailsCtrl.headerUpdated = true;
  }

  function reload() {
    TaxonDetailsService.load(
      taxonDetailsCtrl.txRefType,
      taxonDetailsCtrl.txRefVersion,
      taxonDetailsCtrl.txRefFilter,
      taxonDetailsCtrl.taxonKey
    );
    TaxonSearchService.loadTxRefAllFilters();
    TaxonService.getTaxaSynonyms(taxonDetailsCtrl.txRefType, taxonDetailsCtrl.txRefVersion, taxonDetailsCtrl.taxonKey);
    loadTaxaStatutes();
  }

  function loadTaxaStatutes() {
    TaxonStatusService.getTaxaStatus(
      taxonDetailsCtrl.txRefType,
      taxonDetailsCtrl.txRefVersion,
      taxonDetailsCtrl.taxonKey
    );

    TaxonStatusService.getTaxaSimpleStatus(
      taxonDetailsCtrl.txRefType,
      taxonDetailsCtrl.txRefVersion,
      taxonDetailsCtrl.taxonKey
    );
  }

  //we reload filter to get the terrUnitKey of the filter that is used.
  //with terrUnitKey of the filter we can select simplified status of the specific filter
  function reloadFilter() {
    taxonDetailsCtrl.terrUnitState = TaxonRefFilterStore.getState();

    if (taxonDetailsCtrl.terrUnitState.error || taxonDetailsCtrl.terrUnitState.availableFilters == null) {
      return;
    }

    taxonDetailsCtrl.terrUnit = taxonDetailsCtrl.terrUnitState.availableFilters.filter(
      (t) => t.key === taxonDetailsCtrl.txRefFilter
    )[0];
  }

  function reloadTaxaStatutes() {
    taxonDetailsCtrl.statutesState = TaxonStatusStore.getStatutesStates();

    if (taxonDetailsCtrl.statutesState.error) {
      return;
    }

    // Create a copy of the immutable
    taxonDetailsCtrl.statutesState = _.toArray(_.defaultsDeep({}, taxonDetailsCtrl.statutesState.statutes));
    //taxonDetailsCtrl.statutesState = _.defaultsDeep({}, taxonDetailsCtrl.statutesState.statutes);
  }

  function reloadTaxaSimpleStatutes() {
    taxonDetailsCtrl.simpleStatutesState = TaxonStatusStore.getSimpleStatutesStates();
    if (taxonDetailsCtrl.simpleStatutesState.error) {
      return;
    }

    // Create a copy of the immutable
    //taxonDetailsCtrl.simpleStatutesState = _.toArray(_.defaultsDeep({}, taxonDetailsCtrl.simpleStatutesState.statutes));

    let hashmap = new Map();

    /*taxonDetailsCtrl.simpleStatutesState =  _.keyBy(a, 'terrUnitKey');

    taxonDetailsCtrl.simpleStatutesState =  _.flatMap(a, function(a) {
      hashmap.set(a.terrUnitKey, a.shortened)
    });*/

    let flattenItems = _.flatMap(taxonDetailsCtrl.simpleStatutesState.statutes, ({ terrUnitKey, shortened, value }) => {
      var stasim =
        hashmap.get(terrUnitKey) != null
          ? hashmap.get(terrUnitKey)
          : {
              isPa: [],
              isPr: [],
              isEe: [],
              isMen: [],
              isSen: [],
            };

      if (shortened == 'STASIM_PATRI_DETAILS' && stasim.isPa.length != 0) stasim.isPa[stasim.isPa.length] = value;
      else if (shortened == 'STASIM_PROTEC_DETAILS' && stasim.isPr.length != 0) stasim.isPr[stasim.isPr.length] = value;
      else if (shortened == 'STASIM_EEE_DETAILS' && stasim.isEe.length != 0) stasim.isEe[stasim.isEe.length] = value;
      else if (shortened == 'STASIM_MEN_DETAILS' && stasim.isMen.length != 0) stasim.isMen[stasim.isMen.length] = value;
      else if (shortened == 'STASIM_SEN_DETAILS' && stasim.isSen.length != 0) stasim.isSen[stasim.isSen.length] = value;

      if (shortened == 'STASIM_PATRI_DETAILS' && stasim.isPa.length == 0) stasim.isPa[0] = value;
      else if (shortened == 'STASIM_PROTEC_DETAILS' && stasim.isPr.length == 0) stasim.isPr[0] = value;
      else if (shortened == 'STASIM_EEE_DETAILS' && stasim.isEe.length == 0) stasim.isEe[0] = value;
      else if (shortened == 'STASIM_MEN_DETAILS' && stasim.isMen.length == 0) stasim.isMen[0] = value;
      else if (shortened == 'STASIM_SEN_DETAILS' && stasim.isSen.length == 0) stasim.isSen[0] = value;

      hashmap.set(terrUnitKey, stasim);
    });

    taxonDetailsCtrl.simpleStatutesState = hashmap;
  }

  function reloadTaxaSynonyms() {
    taxonDetailsCtrl.synonymsState = TaxonStore.getTaxaStates();

    if (taxonDetailsCtrl.synonymsState.error) {
      return;
    }

    // Create a copy of the immutable
    taxonDetailsCtrl.synonymsState = _.toArray(_.defaultsDeep({}, taxonDetailsCtrl.synonymsState.synonyms));
  }

  function itemTracker(item) {
    return item.terrUnitKey + '-' + item.rank;
  }
}
