import angular from 'angular';

import { TerrUnitService } from './services/terr-unit.service';
import terrUnitServiceLocal from './services/terr-unit-fake.service';
import terrUnitKeyFilter from './filters/terr-unit-key.filter';

const moduleName = 'shu-terrunit';

angular
  .module(moduleName, ['lumx'])
  .service('TerrUnitService', IS_CORDOVA ? terrUnitServiceLocal : TerrUnitService)
  .filter('terrUnitKeyLabel', terrUnitKeyFilter);

export default { moduleName };
