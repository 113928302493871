const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de sélection des études à importer
  STUDY_SYNC_STARTED: 'shu-synchro.study-sync-started',
  STUDY_SYNC_STEP_STARTED: 'shu-synchro.study-sync-step-started',
  STUDY_SYNC_STEP_DONE: 'shu-synchro.study-sync-step-done',
  STUDY_SYNC_STEP_FAILED: 'shu-synchro.study-sync-step-failed',
  STUDY_SYNC_TERMINATED: 'shu-synchro.study-sync-terminated',
};

export default StudySynchronizerService;

// @ngInject
function StudySynchronizerService() {
  return { syncStudy };

  function syncStudy(studyId, save, unlock) {
    // fake impl
    console.error('Sync is not available in web mode');
  }
}

export { EVENTS };
