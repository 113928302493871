import * as models from '@biotope/shuriken-ng-referentiel-api'
import { TaxaApi } from '@biotope/shuriken-ng-referentiel-api'
import _ from 'lodash';

export const EVENTS = {
  TAXA_SYNONYMS_LOADING: 'shu-taxa-synonyms.taxa-synonyms-loading',
  TAXA_SYNONYMS_LOAD_SUCCESS: 'shu-taxa-synonyms.taxa-synonyms-load-success',
  TAXA_SYNONYMS_LOAD_ERROR: 'shu-taxa-synonyms.taxa-synonyms-load-error',
}

export class TaxonService {

  private taxaApi: TaxaApi;
  private toaster: any;
  private $rootScope: any;

  /** @ngInject */
  constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, TaxaApi: TaxaApi) {
    this.taxaApi = TaxaApi;
    this.toaster = LxNotificationService;
    this.$rootScope = $rootScope;
  }

  public getTaxaSynonyms(txRefType, txRefVersion, taxonKey) {
     this.$rootScope.$emit(EVENTS.TAXA_SYNONYMS_LOADING);

     this.taxaApi.getSynonymsForKey(txRefType, txRefVersion, taxonKey)
     .then(
       (statutes) => {
         this.$rootScope.$emit(EVENTS.TAXA_SYNONYMS_LOAD_SUCCESS, statutes.data);
       },
       (error: any) => {
         this.toaster.error('Une erreur est survenue au chargement des synonymes. ' + (error.data || ''));
         this.$rootScope.$emit(EVENTS.TAXA_SYNONYMS_LOAD_ERROR);
       }
     );
  }
}
