
const STUDY_STATUS = {
  INITIALIZED: 'INITIALIZED',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  VALUES: [
    'INITIALIZED',
    'ACTIVE',
    'ARCHIVED'
  ],
  DEFAULT: 'ACTIVE'
};
const STUDY_ROLE = {
  MANAGER: 'MANAGER',
  OBSERVER: 'OBSERVER',
  VALUES: [
    'MANAGER',
    'OBSERVER'
  ],
  DEFAULT: 'OBSERVER'
};
const STUDY_RESTRICTION = {
  COPYRIGHT: 'COPYRIGHT',
  PATENT: 'PATENT',
  PATENT_PENDING: 'PATENT_PENDING',
  TRADEMARK: 'TRADEMARK',
  LICENSE: 'LICENSE',
  INTELLECTUAL_PROPERTYRIGHTS: 'INTELLECTUAL_PROPERTYRIGHTS',
  RESTRICTED: 'RESTRICTED',
  OTHER_RESTRICTIONS: 'OTHER_RESTRICTIONS',
  VALUES: [
    'COPYRIGHT',
    'PATENT',
    'PATENT_PENDING',
    'TRADEMARK',
    'LICENSE',
    'INTELLECTUAL_PROPERTYRIGHTS',
    'RESTRICTED',
    'OTHER_RESTRICTIONS'
  ],
  DEFAULT: 'COPYRIGHT'
};
const APPLICATION_KEY = {
  SHURIKEN: 'SHURIKEN',
  SHURIKEN_NG: 'SHURIKEN_NG',
  VALUES: [
    'SHURIKEN',
    'SHURIKEN_NG'
  ],
  DEFAULT: 'SHURIKEN_NG'
};
const STUDY_EXPERT_LAYER_FORMAT = {
  KML: 'KML',
  MBTILES: 'MBTILES',
  VALUES: [
    'MBTILES',
    'KML'
  ],
  DEFAULT: 'KML'
};
const STUDY_EXPERT_LAYER_SOURCE = {
  LOCAL: 'LOCAL',
  SERVER: 'SERVER',
  VALUES: [
    'LOCAL',
    'SERVER'
  ],
  DEFAULT: 'LOCAL'
};
export { STUDY_STATUS, STUDY_ROLE, STUDY_RESTRICTION, APPLICATION_KEY, STUDY_EXPERT_LAYER_FORMAT, STUDY_EXPERT_LAYER_SOURCE };
