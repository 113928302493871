import authModule from '../auth/auth.module';
import settingsModule from '../settings/settings.module';
import favorisModule from '../favoris/favoris.module';
import headerComponent from './components/header/header.component';
import homeComponent from './components/home/home.component';
import asideComponent from './components/aside/aside.component';
import txGroupIconComponent from './components/tx-group/tx-group-icon.component';
import numberInputComponent from './components/number-input/number-input.component';
import pictureNameInputComponent from './components/picture-name-input/picture-name-input.component';
import promptComponent from './components/prompt/prompt.component';
import deferredContainerComponent from './components/deferred-container/deferred-container.component';
import menuStudySurveyTypeController from './components/menu-study-survey-type/menu-study-survey-type.component';
import onBeforeUnloadDirective from './directives/on-before-unload.directive';
import xWwwFormUrlEncodedService from './services/x-www-form-urlencoded.service';
import xWwwFormUrlEncodedInterceptor from './interceptors/x-www-form-urlencoded.interceptor';
import urlResolverService from './services/url-resolver.service';
import urlResolverInterceptor from './interceptors/url-resolver.interceptor';
import headerService from './services/header.service';
import autoSyncService from './services/auto-sync-fake.service'
import autoSyncLocalService from './services/auto-sync.service'
import headerStore from './stores/header.store';
import tzDateFilter from './filters/tz-date.filter';
import routerConfig from './routes';

require('./base.scss');

const moduleName = 'shu-base';

angular.module(moduleName, ['ui.router', 'lumx', authModule.moduleName, settingsModule.moduleName, favorisModule.moduleName])
  .directive('shuHeader', headerComponent)
  .directive('shuHome', homeComponent)
  .directive('shuAside', asideComponent)
  .directive('shuTxGroupIcon', txGroupIconComponent)
  .directive('shuNumberInput', numberInputComponent)
  .directive('shuPictureNameInput', pictureNameInputComponent)
  .directive('shuDeferredContainer', deferredContainerComponent)
  .directive('shuPrompt', promptComponent)
  .directive('shuOnBeforeUnload', onBeforeUnloadDirective)
  .directive('shuMenuStudySurveyType', menuStudySurveyTypeController)
  .factory('XWwwFormUrlEncoded', xWwwFormUrlEncodedService)
  .factory('XWwwFormUrlEncodedInterceptor', xWwwFormUrlEncodedInterceptor)
  .factory('UrlResolverService', urlResolverService)
  .factory('UrlResolverInterceptor', urlResolverInterceptor)
  .factory('HeaderService', headerService)
  .factory('HeaderStore', headerStore)
  .factory('AutoSyncService', IS_CORDOVA ? autoSyncLocalService : autoSyncService)
  .filter('tzDate', tzDateFilter)
  .config(routerConfig)
  .config(/* @ngInject */ ($httpProvider) => {
    $httpProvider.interceptors.push('XWwwFormUrlEncodedInterceptor');
    $httpProvider.interceptors.push('UrlResolverInterceptor');
  })
  .config(/* @ngInject */ ($provide) => {
    // On décore le service LxNotificationService pour qu'il lance des événements d'ouverture et de fermeture de boîte de dialogue
    // afin de pouvoir empêcher les requêtes de changement d'état de s'effectuer lorsqu'une alerte ou une boîte de confirmation est affichée
    $provide.decorator('LxNotificationService', /* @ngInject */ ($delegate, $rootScope, $timeout) => {
      const confirm = $delegate.confirm;
      const alert = $delegate.alert;

      $delegate.alert = (title, text, button, callback, unbind) => {
        $rootScope.$emit('lx-dialog__open-start');

        alert(title, text, button, (confirmed) => {
          $rootScope.$emit('lx-dialog__close-start');
          $timeout(() => $rootScope.$emit('lx-dialog__close-end'));

          callback(confirmed);
        }, unbind);
      };

      $delegate.confirm = (title, text, buttons, callback, unbind) => {
        $rootScope.$emit('lx-dialog__open-start');

        confirm(title, text, buttons, (confirmed) => {
          $rootScope.$emit('lx-dialog__close-start');
          $timeout(() => $rootScope.$emit('lx-dialog__close-end'));

          callback(confirmed);
        }, unbind);
      };

      return $delegate;
    });
  })
  .run(/* @ngInject */ ($rootScope, $state, $timeout) => {
    // On empêche le changement d'état lorsqu'une fenêtre modale est ouverte.
    // Changer d'état change le scope global de l'application mais la fenêtre modale reste sous le précédent scope.
    // L'incohérence de scope provoque une situation dans laquelle la fenêtre modale ne peut plus être fermée
    var removeStateChangeListener = null;
    // opened et closing gère le cas où plusieurs fenêtres modales sont ouvertes
    // Ce cas peut arriver si on ouvre une fenêtre modale et qu'une fenêtre de confirmation est par la suite affichée en supplément
    var opened = 0;
    var closing = 0;
    var nextState;

    $rootScope.$on('lx-dialog__open-start', manageDialogOpened);
    $rootScope.$on('lx-dialog__close-start', manageDialogClosing);
    $rootScope.$on('lx-dialog__close-end', manageDialogClosed);

    function manageDialogOpened() {
      nextState = null;
      opened++;
      if (removeStateChangeListener !== null) {
        return;
      }

      removeStateChangeListener = $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
        event.preventDefault();

        if (closing > 0 || toState.name === 'signin') {
          // Entre le moment où on demande à la fenêtre de se fermer et le moment où la fenêtre est réellement fermée,
          // plusieurs boucle d'Angular se sont déjà déroulées
          // A partir de ce moment, si une requête de changement d'état est lancée,
          // cette requête sera mise de côté pour être finalement relancée à la fermeture complète de la fenêtre
          // On stocke également le nextState si on doit aller sur la page d'authentification (cas d'un token expiré)
          nextState = {toState: toState.name, toParams};
        }
      });
    }

    function manageDialogClosing() {
      // La fenêtre commence à se fermer
      opened--;
      closing++;
    }

    function manageDialogClosed() {
      // La fenêtre est désormais bien fermée
      closing--;

      if (closing === 0 && opened === 0) {
        // Plus aucune fenêtre n'est affichée
        // On peut donc couper le listener se chargeant de stopper les requêtes de changement d'état
        removeStateChangeListener();
        removeStateChangeListener = null;
      }

      if (!nextState || !nextState.toState) {
        return;
      }

      // Une requête de changement d'état a été soumise entre le début et la fin de la fermeture de la fenêtre
      // Relançons la requête (qui ne sera cette fois-ci plus interceptée)
      $state.go(nextState.toState, nextState.toParams);
    }
  });

export default {moduleName};
