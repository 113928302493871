import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-map.service';

const EVENTS = {
  STUDY_MAP_STATE_CHANGED: 'shu-study-map.study-map-state-changed'
};

export default StudyMapStore;

// @ngInject
function StudyMapStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_MAP_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_MAP_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_MAP_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      study: null,
      currentLayer: null,
      layersVisibility: [],
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_MAP_STATE_CHANGED);
  }

  function onLoadSuccess(event, data) {
    state = Immutable({
      study: data.study,
      currentLayer: data.currentLayer,
      layersVisibility: data.layersVisibility,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_MAP_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      study: null,
      currentLayer: null,
      layersVisibility: [],
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_MAP_STATE_CHANGED);
  }
}

export { EVENTS };
