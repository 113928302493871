export default AuthInterceptor;

// @ngInject
function AuthInterceptor($injector, $q, $rootScope, AuthStore) {
  const securedPattern = /^https?:\/\/([\w\-]+.)*(biotope\.fr|b5e\.dev|b5e\.io)/;
  return {
    request: function(config) {
      if (AuthStore.isAuthenticated()) {
        if (typeof config.url !== 'string' || securedPattern.test(config.url) || config.url.indexOf('http') != 0) {
          config.headers.Authorization = 'Bearer ' + AuthStore.getState().token;
		      //config.headers["shuriken-version"] = APP_VERSION;
        }
      }
      return config;
    },
    responseError: function(rejection) {
      if (rejection.status === 401 && !rejection.config.doNotIntercept) {
        // On injecte AuthService au runtime pour éviter
        // des problèmes de dépendences cycliques
        $injector.get('AuthService').makeLoginExpired();
        return $q.reject(rejection);
      }
      if (rejection.status === 403 && !rejection.config.doNotIntercept) {
        // On injecte AuthService au runtime pour éviter
        // des problèmes de dépendences cycliques
        $injector.get('AuthService').forceLogOut();
        return $q.reject(rejection);
      }
      return $q.reject(rejection);
    },
  };
}
