import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'un taxon
  TAXON_DETAILS_LOADING: 'shu-taxon-details.taxon-details-loading',
  TAXON_DETAILS_LOAD_SUCCESS: 'shu-taxon-details.taxon-details-load-success',
  TAXON_DETAILS_LOAD_ERROR: 'shu-taxon-details.taxon-details-load-error',
};

export default TaxonDetailsService;

// @ngInject
function TaxonDetailsService($rootScope, TaxonRepository, TaxonRefRepository, LxNotificationService) {
  return { load, getStatusDisplay, isProtected };

  function load(txRefType, txRefVersion, txRefFilter, taxonKey) {
    $rootScope.$emit(EVENTS.TAXON_DETAILS_LOADING);
    TaxonRepository.getTaxon(txRefType, txRefVersion, txRefFilter, taxonKey)
      .then(
        ({taxon, txRef, raretes, errorRaretes}) => $rootScope.$emit(EVENTS.TAXON_DETAILS_LOAD_SUCCESS, taxon, txRef, raretes, errorRaretes),
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement du taxon. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.TAXON_DETAILS_LOAD_ERROR);
        }
      );
  }

  function isProtected(taxon) {
    return Boolean(
      _.get(taxon, 'head_statuses.simplified', []).find((status) => {
        return status.value == 'PR_s';
      })
    );
  }

  function getStatusDisplay(key, value) {
    const conf = getStatusDisplayConf();
    return _.get(conf, `fr.${key}.${value}`, {
      short: value,
      long: '',
    });
  }

  function getStatusDisplayConf() {
    return {
      fr: {
        scale: {
          Reg: {
            short: 'Rég',
            long: 'Régional',
          },
          Nat: {
            short: 'Nat',
            long: 'National',
          },
          Cont: {
            short: 'Cont',
            long: 'Continental',
          },
          World: {
            short: 'Mond',
            long: 'Monde',
          },
        },
        value: {
          MEN: {
            long: 'Menacé',
          },
          PR_s: {
            long: 'Protégé strict',
          },
          PA_sc: {
            long: 'Patrimonial sous condition',
          },
          PA_sc_repro: {
            long: 'Patrimonial sous condition en période de reproduction',
          },
          PA_sc_migr: {
            long: 'Patrimonial sous condition en période de migration',
          },
          PA_sc_hiv: {
            long: "Patrimonial sous condition en période d'hivernage",
          },
          MEN_repro: {
            long: 'Menacé en période de reproduction',
          },
          MEN_migr: {
            long: 'Menacé en période de migration',
          },
          MEN_hiv: {
            long: "Menacé en période d'hivernage",
          },
          EEE_p: {
            long: 'Espèce exotique envahissante potentielle',
          },
          EEE_a_e: {
            long: 'Espèce exotique envahissante avérées et émergentes',
          },
          SEN_e: {
            long: 'Sensibilité éolien',
          },
          SEN_e_repro: {
            long: 'Sensibilité éolien en période de reproduction',
          },
          SEN_e_migr: {
            long: 'Sensibilité éolien en période de migration',
          },
          SEN_e_hiv: {
            long: "Sensibilité éolien en période d'hivernage",
          },
          '#': {
            long: 'Taxon lié à un statut cité par erreur, douteux ou hypothétique',
          },
          NE: {
            long: 'Non évalué',
          },
          NA: {
            long: 'Non applicable',
          },
          NAa: {
            long: 'Non applicable car introduite dans la période récente',
          },
          NAb: {
            long: 'Non applicable car nicheuse occasionnelle ou marginale en métropole',
          },
          NAc: {
            long: "Non applicable car régulièrement présente en métropole en hivernage ou en passage mais ne remplissant pas les critères d'une présence significative",
          },
          NAd: {
            long: "Non applicable car régulièrement présente en métropole en hivernage ou en passage mais pour laquelle le manque de données disponibles ne permet pas de confirmer que les critères d'une présence significative sont remplis",
          },
          DD: {
            long: 'Données insuffisantes',
          },
          LC: {
            long: 'Préoccupation mineure',
          },
          NT: {
            long: 'Quasi menacé',
          },
          VU: {
            long: 'Vulnérable',
          },
          EN: {
            long: 'En danger',
          },
          CR: {
            long: 'En danger critique',
          },
          'CR*': {
            long: 'En danger critique (non revu récemment)',
          },
          REw: {
            long: "Eteint au niveau régional à l'état sauvage",
          },
          RE: {
            long: 'Eteint au niveau régional',
          },
          EW: {
            long: "Eteint à l'état sauvage",
          },
          EX: {
            long: 'Eteint',
          },
        },
      },
    };
  }
}
export { EVENTS };
