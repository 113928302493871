import _ from 'lodash';

export default SurveyTypeRepository;

// @ngInject
function SurveyTypeRepository(DatabaseProvider, StorageService, SurveyTypeConfiguration, $q) {
  return {list, getSurveyType};

  function list(studyId) {
    return DatabaseProvider.getDatabase()
      .then((database) => {
        //
        return StorageService.executeSqlQueries(database, [{
            sql: `
              SELECT DISTINCT
                st.id as st_id,
                st.key as st_key,
                st.name as st_name,
                st.default_tx_ref_type as st_defaultTxRefType,
                st.study as st_study,
                st.created as st_created,
                st.created_by as st_createdBy,
                st.updated as st_updated,
                st.updated_by as st_updatedBy,
                st.deleted as st_deleted,
                st.deleted_by as st_deletedBy,
                st.default_tx_ref_filter as st_defaultTxRefFilter,
                p.id as p_id,
                p.key as p_key,
                p.name as p_name,
                p.description as p_description,
                p.tx_groups as p_txGroups,
                p.field_survey_geometry_type as p_fieldSurveyGeometryType,
                p.created as p_created,
                p.created_by as p_createdBy,
                p.updated as p_updated,
                p.updated_by as p_updatedBy,
                p.deleted as p_deleted,
                p.deleted_by as p_deletedBy,
                (select count(*) from field_record fr join field_survey fs on (fs.id = fr.field_survey)
                  where fs.survey_type = st.id
                  and fr.ghost = 0
                  and (fs.deleted is null or fs.deleted > datetime())
                  and (fr.deleted is null or fr.deleted > datetime()) ) as st_nbFieldRecord,
                (select count(*) from field_survey fs
                  where fs.survey_type = st.id
                  and (fs.deleted is null or fs.deleted > datetime()) ) as st_nbFieldSurvey
              FROM survey_type st
              JOIN protocol p on (p.id = st.protocol)
              WHERE st.study = ?
              AND (p.deleted is null or p.deleted > datetime())
              AND (st.deleted is null or st.deleted > datetime())
            `,
            parameters: [studyId]
          }, {
            sql: `
              SELECT DISTINCT
                st.id as surveyType,
                ptg.tx_group as txGroup,
                ptg.id as id
              FROM survey_type st
              JOIN survey_type_protocol_tx_group stptg on (stptg.survey_type = st.id)
              JOIN protocol_tx_group ptg on (ptg.id = stptg.protocol_tx_group)
              WHERE st.study = ?
              AND (ptg.deleted is null or ptg.deleted > datetime())
              AND (st.deleted is null or st.deleted > datetime())
            `,
            parameters: [studyId]
          }
        ])
        .then(([surveyTypeResults, protocolTxGroupsResults]) => {
          var surveyTypes = _.map(surveyTypeResults, (record) => {
            var res = {};
            _.forOwn(record, function(value, key) {
              if (_.startsWith(key, 'p_')) {
                _.set(res, 'protocol.' + key.substr(2), value);
                return;
              }
              if (_.startsWith(key, 'st_')) {
                _.set(res, key.substr(3) , value);
                return;
              }
            });
            var protocolTxGroups = _.chain(protocolTxGroupsResults)
                  .filter({surveyType: res.id})
                  .map((p) => _.pick(p, ['id', 'txGroup']))
                  .value();
            return _.defaults({protocolTxGroups, canEdit: false}, res);
          });
          return surveyTypes;
        });
      });
  }

  function getSurveyType(surveyTypeId) {
    return DatabaseProvider.getDatabase()
      .then((database) => {        
        return StorageService.executeSqlQueries(database, [{
            sql: `
              SELECT DISTINCT
                st.id as st_id,
                st.key as st_key,
                st.name as st_name,
                st.default_tx_ref_type as st_defaultTxRefType,
                st.study as st_study,
                st.created as st_created,
                st.created_by as st_createdBy,
                st.updated as st_updated,
                st.updated_by as st_updatedBy,
                st.deleted as st_deleted,
                st.deleted_by as st_deletedBy,
                st.default_tx_ref_filter as st_defaultTxRefFilter,
                p.id as p_id,
                p.key as p_key,
                p.name as p_name,
                p.description as p_description,
                p.tx_groups as p_txGroups,
                p.field_survey_geometry_type as p_fieldSurveyGeometryType,
                p.created as p_created,
                p.created_by as p_createdBy,
                p.updated as p_updated,
                p.updated_by as p_updatedBy,
                p.deleted as p_deleted,
                p.deleted_by as p_deletedBy,
                (select count(*) from field_record fr join field_survey fs on (fs.id = fr.field_survey)
                  where fs.survey_type = st.id
                  and fr.ghost = 0
                  and (fs.deleted is null or fs.deleted > datetime())
                  and (fr.deleted is null or fr.deleted > datetime()) ) as st_nbFieldRecord,
                (select count(*) from field_record fr join field_survey fs on (fs.id = fr.field_survey)
                  where fs.survey_type = st.id
                  and fr.ghost = 0
                  and fr.status = 'PRIVATE'
                  and (fs.deleted is null or fs.deleted > datetime())
                  and (fr.deleted is null or fr.deleted > datetime()) ) as st_nbPrivateFieldRecords,
                (select count(*) from field_survey fs
                  where fs.survey_type = st.id
                  and (fs.deleted is null or fs.deleted > datetime()) ) as st_nbFieldSurvey
              FROM survey_type st
              JOIN protocol p on (p.id = st.protocol)
              WHERE st.id = ?
              AND (p.deleted is null or p.deleted > datetime())
              AND (st.deleted is null or st.deleted > datetime())
            `,
            parameters: [surveyTypeId]
          }, {
            sql: `
              SELECT DISTINCT
                ptg.tx_group as txGroup,
                ptg.field_survey_extra_tables as fieldSurveyExtraTables,
                ptg.id as id
              FROM survey_type_protocol_tx_group stptg
              JOIN protocol_tx_group ptg on (ptg.id = stptg.protocol_tx_group)
              WHERE stptg.survey_type = ?
              AND (ptg.deleted is null or ptg.deleted > datetime())
            `,
            parameters: [surveyTypeId]
          }
        ])
        .then(([[surveyTypeResult], protocolTxGroupsResults]) => {
          var surveyType = {};
          _.forOwn(surveyTypeResult, function(value, key) {
            if (_.startsWith(key, 'p_')) {
              _.set(surveyType, 'protocol.' + key.substr(2), value);
              return;
            }
            if (_.startsWith(key, 'st_')) {
              _.set(surveyType, key.substr(3) , value);
              return;
            }
          });
          var protocolTxGroups = _.map(protocolTxGroupsResults, (protocolTxGroup) => {
            return _.defaults({fieldSurveyExtraTables: StorageService.parseArrayData(protocolTxGroup.fieldSurveyExtraTables)}, protocolTxGroup);
          });
          return _.defaults({protocolTxGroups, canEdit: false}, surveyType);
        });
      });
  }
}
