import _ from 'lodash';
import moment from 'moment-timezone';

import { EVENTS as STORE_EVENTS } from '../../../txgroup/stores/txgroup-list.store';
import { EVENTS as TX_REF_STORE_EVENTS } from '../../../taxon/stores/taxon-ref.store';
import { EVENTS as TX_REF_FILTER_STORE_EVENTS } from '../../../taxon/stores/taxon-ref-filter.store';
import { EVENTS as FIELD_RECORD_DETAILS_STORE_EVENTS } from '../../stores/field-record-details.store';
import { EVENTS as FIELD_RECORD_EXTRA_TABLES_STORE_EVENTS } from '../../stores/field-record-extra-tables.store';
import { ACCURACY, STATUS, CREATION_MODE, ON_THE_FLY_EXTRA_TABLES } from '../../model';

require('./field-record-details-on-the-fly-form.scss');

var DETAILS = 0;
var ON_THE_FLY = 1;

export default FieldRecordDetailsOnTheFlyFormDirective;

// @ngInject
function FieldRecordDetailsOnTheFlyFormDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      fieldSurveyId: '@',
      openMapTabProvider: '&openMapTab',
      studyStateProvider: '&studyState',
      fieldSurveyStateProvider: '&fieldSurveyState',
    },
    controller: FieldRecordDetailsOnTheFlyFormController,
    controllerAs: 'fieldRecordDetailsOnTheFlyFormCtrl',
    bindToController: true,
    template: require('./field-record-details-on-the-fly-form.html'),
    replace: true,
  };
}

// @ngInject
function FieldRecordDetailsOnTheFlyFormController(
  $scope,
  $state,
  $stateParams,
  Geolocation,
  TaxonRefFilterStore,
  LxNotificationService,
  TxGroupListService,
  TxGroupListStore,
  FieldRecordDetailsService,
  FieldRecordDetailsStore,
  FieldRecordExtraTablesService,
  FieldRecordExtraTablesStore,
  TaxonRefStore,
  TaxonSearchService,
  FieldRecordRepository,
  TaxonRefRepository,
  SettingsOppService
) {
  var fieldRecordDetailsOnTheFlyFormCtrl = this;

  fieldRecordDetailsOnTheFlyFormCtrl.onTaxonChange = onTaxonChange;
  fieldRecordDetailsOnTheFlyFormCtrl.onTxGroupSelect = onTxGroupSelect;
  fieldRecordDetailsOnTheFlyFormCtrl.onSurveyTypeChange = onSurveyTypeChange;
  fieldRecordDetailsOnTheFlyFormCtrl.onCreateFieldRecord = onCreateFieldRecord;

  $scope.$watch(fieldRecordDetailsOnTheFlyFormCtrl.studyStateProvider, (studyState) => {
    if (!studyState) {
      return;
    }
    fieldRecordDetailsOnTheFlyFormCtrl.extraDataToggle = true;
    fieldRecordDetailsOnTheFlyFormCtrl.isAvailable = true;
    fieldRecordDetailsOnTheFlyFormCtrl.studyState = studyState;
    refresh();
  });
  $scope.$watch(fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }
    fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState = fieldSurveyState;
    refresh();
  });
  $scope.$watch(
    fieldRecordDetailsOnTheFlyFormCtrl.openMapTabProvider,
    (openMapTab) => (fieldRecordDetailsOnTheFlyFormCtrl.openMapTab = openMapTab)
  );

  fieldRecordDetailsOnTheFlyFormCtrl.txHeadcountAccuracyValues = ACCURACY.VALUES;
  fieldRecordDetailsOnTheFlyFormCtrl.creationModeValues = CREATION_MODE.VALUES;
  fieldRecordDetailsOnTheFlyFormCtrl.surveyTypes = { details: DETAILS, onTheFly: ON_THE_FLY };

  $scope.$on(STORE_EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED, reloadTxGroupsList);
  $scope.$on(TX_REF_STORE_EVENTS.TX_REF_STATE_CHANGED, reloadTxRefResults);
  $scope.$on(TX_REF_FILTER_STORE_EVENTS.TX_REF_FILTER_STATE_CHANGED, reloadTxRefFilterResults);
  $scope.$on(FIELD_RECORD_DETAILS_STORE_EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED, reloadFieldRecordState);
  $scope.$on(FIELD_RECORD_EXTRA_TABLES_STORE_EVENTS.FIELD_RECORD_EXTRA_TABLES_STATE_CHANGED, reloadExtraTablesState);

  fieldRecordDetailsOnTheFlyFormCtrl.setExtraDataToggle = setExtraDataToggle;
  fieldRecordDetailsOnTheFlyFormCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsOnTheFlyFormCtrl.onComportementChanged = onComportementChanged;

  TxGroupListService.load();
  TaxonSearchService.loadTxRef();
  TaxonSearchService.loadTxRefAllFilters();

  fieldRecordDetailsOnTheFlyFormCtrl.txGroup = FieldRecordRepository.getLatestTxGroupInFieldRecord();

  // Default false
  fieldRecordDetailsOnTheFlyFormCtrl.extraDataToggle = FieldRecordRepository.getExtraDataToggle();

  fieldRecordDetailsOnTheFlyFormCtrl.freeObsLocationProtocols = ['LS', 'PE', 'RA', 'SM', 'RP', 'ADNE'];
  fieldRecordDetailsOnTheFlyFormCtrl.isFreeObsLocationProtocol = false;

  function onSurveyTypeChange() {
    if (fieldRecordDetailsOnTheFlyFormCtrl.surveyType === DETAILS) {
      fieldRecordDetailsOnTheFlyFormCtrl.availableGroups = _.map(
        fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.surveyType.protocolTxGroups,
        (p) => p.txGroup
      );
    } else if (fieldRecordDetailsOnTheFlyFormCtrl.surveyType === ON_THE_FLY) {
      fieldRecordDetailsOnTheFlyFormCtrl.availableGroups = _.chain(
        fieldRecordDetailsOnTheFlyFormCtrl.txGroupsState.results
      )
        .filter((r) => !r.deleted)
        .map((r) => r.key)
        .value();
    }
    if (!_.includes(fieldRecordDetailsOnTheFlyFormCtrl.availableGroups, fieldRecordDetailsOnTheFlyFormCtrl.txGroup)) {
      fieldRecordDetailsOnTheFlyFormCtrl.txGroup = fieldRecordDetailsOnTheFlyFormCtrl.availableGroups[0];
    }
    onTxGroupSelect();
    reloadTxRef();
    reloadTxRefFilter();
  }

  function onTxGroupSelect() {
    fieldRecordDetailsOnTheFlyFormCtrl.txGroups = [fieldRecordDetailsOnTheFlyFormCtrl.txGroup];
    FieldRecordRepository.saveTxGroupInFieldRecord(fieldRecordDetailsOnTheFlyFormCtrl.txGroup);
    fieldRecordDetailsOnTheFlyFormCtrl.taxon = null;
    FieldRecordExtraTablesService.load(
      fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.id,
      fieldRecordDetailsOnTheFlyFormCtrl.txGroup
    );

  
  }

  function onTaxonChange(taxon) {
    fieldRecordDetailsOnTheFlyFormCtrl.taxon = taxon;
  }

  function extraDataClear() {
    fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm = {};
    delete fieldRecordDetailsOnTheFlyFormCtrl.extraTable;
  }

  function extraDataChange() {
    if (
      fieldRecordDetailsOnTheFlyFormCtrl.surveyType !== fieldRecordDetailsOnTheFlyFormCtrl.surveyTypes.details ||
      !fieldRecordDetailsOnTheFlyFormCtrl.extraTablesState.extraTables
    ) {
      return;
    }
    var extraTable = _.find(
      ON_THE_FLY_EXTRA_TABLES,
      (e) => fieldRecordDetailsOnTheFlyFormCtrl.extraTablesState.extraTables.indexOf(e.name) !== -1
    );
    fieldRecordDetailsOnTheFlyFormCtrl.isAvailable = true;
    if (!extraTable) {
      return;
    }
    fieldRecordDetailsOnTheFlyFormCtrl.extraTable = extraTable;
  }

  function reloadTxGroupsList() {
    fieldRecordDetailsOnTheFlyFormCtrl.txGroupsState = TxGroupListStore.getState();
  }

  function reloadTxRefResults() {
    fieldRecordDetailsOnTheFlyFormCtrl.txRefState = TaxonRefStore.getState();
    if (!fieldRecordDetailsOnTheFlyFormCtrl.txRefState.availableReferentiels) {
      return;
    }
    reloadTxRef();
  }

  function reloadTxRefFilterResults() {
    fieldRecordDetailsOnTheFlyFormCtrl.txRefFilterState = TaxonRefFilterStore.getState();
    if (!fieldRecordDetailsOnTheFlyFormCtrl.txRefFilterState.availableFilters) {
      return;
    }
    reloadTxRefFilter();
  }

  function reloadFieldRecordState() {
    fieldRecordDetailsOnTheFlyFormCtrl.fieldRecordState = FieldRecordDetailsStore.getState();
  }

  function reloadTxRef() {
    fieldRecordDetailsOnTheFlyFormCtrl.taxon = null;

    if (
      fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.surveyType.defaultTxRefType &&
      fieldRecordDetailsOnTheFlyFormCtrl.surveyType === DETAILS
    ) {
      var availableReferentiels = _.filter(fieldRecordDetailsOnTheFlyFormCtrl.txRefState.availableReferentiels, {
        key: fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.surveyType.defaultTxRefType,
      });

      if (_.every(availableReferentiels, 'deleted')) {
        fieldRecordDetailsOnTheFlyFormCtrl.txRef = _.chain(availableReferentiels).sortBy('created').last().value();
      } else {
        fieldRecordDetailsOnTheFlyFormCtrl.txRef = _.find(availableReferentiels, function(value) {
          return !value.deleted;
        });
      }
    } else {
      if (IS_CORDOVA) {
        SettingsOppService.getShuSettings('opportunistObsTxRef', null).then((opportunistObsTxRefKey) => {
          if (!opportunistObsTxRefKey) {
            fieldRecordDetailsOnTheFlyFormCtrl.txRef = getDefaultOpportunistObsTxRef();
            return;
          }

          //we search if the ref for opp obs is a basic ref or a filter
          var oppTxRef = JSON.parse(opportunistObsTxRefKey);

          if(oppTxRef.ref !== oppTxRef.key){
            TaxonRefRepository.getAllTxRefFilters().then((txRefs) => {
              const opportunistObsTxRef = _.find(txRefs, (txRef) => {
                return txRef.key === oppTxRef.key;
              });

              fieldRecordDetailsOnTheFlyFormCtrl.txRefFilter = opportunistObsTxRef;
              oppTxRef.key = opportunistObsTxRef.ref
            });
          }

          TaxonRefRepository.getAllTypes().then((txRefs) => {
            const opportunistObsTxRef = _.find(txRefs, (txRef) => {
              return txRef.key === oppTxRef.key;
            });
            fieldRecordDetailsOnTheFlyFormCtrl.txRef = opportunistObsTxRef
              ? opportunistObsTxRef
              : getDefaultOpportunistObsTxRef();
              
            //fieldRecordDetailsOnTheFlyFormCtrl.txRefFilter = opportunistObsTxRef || getDefaultOpportunistObsTxRef();
          });


        });
      } else {
        fieldRecordDetailsOnTheFlyFormCtrl.txRef = getDefaultOpportunistObsTxRef();
      }
    }
  }

  function getDefaultOpportunistObsTxRef() {
    return _.chain(fieldRecordDetailsOnTheFlyFormCtrl.txRefState.availableReferentiels)
      .reject('deleted')
      .head()
      .value();
  }

  function reloadTxRefFilter() {
    fieldRecordDetailsOnTheFlyFormCtrl.taxon = null;

    if (fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState && fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.surveyType.defaultTxRefFilter &&
        fieldRecordDetailsOnTheFlyFormCtrl.surveyType === DETAILS) {
      var availableFilters = _.filter(fieldRecordDetailsOnTheFlyFormCtrl.txRefFilterState.availableFilters,
        {key: fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.surveyType.defaultTxRefFilter});
      if (_.every(availableFilters, 'deleted')) {
        fieldRecordDetailsOnTheFlyFormCtrl.txRefFilter = _.chain(availableFilters).sortBy('created').last().value();
      } else {
        fieldRecordDetailsOnTheFlyFormCtrl.txRefFilter = _.find(availableFilters, function(value) {
          return !value.deleted;
        });
      }
    } else {
      fieldRecordDetailsOnTheFlyFormCtrl.txRefFilter = _.chain(fieldRecordDetailsOnTheFlyFormCtrl.txRefFilterState.availableFilters)
      .reject('deleted')
      .head()
      .value();
    }
  }

  function refresh(onSubmit = false) {
    const protocolKey = _.get(
      fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState,
      'fieldSurvey.surveyType.protocol.key'
    );
    fieldRecordDetailsOnTheFlyFormCtrl.isFreeObsLocationProtocol =
      fieldRecordDetailsOnTheFlyFormCtrl.freeObsLocationProtocols.indexOf(protocolKey) > -1;

    if (
      !fieldRecordDetailsOnTheFlyFormCtrl.studyState ||
      !fieldRecordDetailsOnTheFlyFormCtrl.studyState.loaded ||
      !fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState ||
      !fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.loaded
    ) {
      return;
    }

    fieldRecordDetailsOnTheFlyFormCtrl.txHeadcountAccuracy = ACCURACY.DEFAULT;
    fieldRecordDetailsOnTheFlyFormCtrl.txHeadcount = 1;
    fieldRecordDetailsOnTheFlyFormCtrl.taxon = null;
    fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm = null;

    if (onSubmit) {
      if (fieldRecordDetailsOnTheFlyFormCtrl.surveyType === ON_THE_FLY) {
        fieldRecordDetailsOnTheFlyFormCtrl.txGroup = fieldRecordDetailsOnTheFlyFormCtrl.availableGroups[0];
      }
    } else {
      fieldRecordDetailsOnTheFlyFormCtrl.surveyType = DETAILS;
      onSurveyTypeChange();
    }
  }

  function onCreateFieldRecord(creationMode) {

    if(fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key==='RI' && !creationMode){
      creationMode ='DEFINE_ON_MAP';
    }

    let data = {
      studyId: fieldRecordDetailsOnTheFlyFormCtrl.studyId,
      creationMode: creationMode,
      txKey: fieldRecordDetailsOnTheFlyFormCtrl.taxon.key,
      txRefKey: fieldRecordDetailsOnTheFlyFormCtrl.txRef.key,
      txRefVersion: fieldRecordDetailsOnTheFlyFormCtrl.txRef.version,
      txRefFilter: fieldRecordDetailsOnTheFlyFormCtrl.txRefFilter.key,
      txGroup: fieldRecordDetailsOnTheFlyFormCtrl.txGroup,
      txHeadcount: fieldRecordDetailsOnTheFlyFormCtrl.txHeadcount,
      txHeadcountAccuracy: fieldRecordDetailsOnTheFlyFormCtrl.txHeadcountAccuracy,
      ghost: false,
    };

    let extraTablesForm = {};

    if (fieldRecordDetailsOnTheFlyFormCtrl.surveyType === DETAILS) {
      data.fieldSurveyId = fieldRecordDetailsOnTheFlyFormCtrl.fieldSurveyState.fieldSurvey.id;
      if (fieldRecordDetailsOnTheFlyFormCtrl.extraTable && fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm) {
        extraTablesForm[fieldRecordDetailsOnTheFlyFormCtrl.extraTable.name] =
          fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm;
      }
    }
    // Gestion de fromState et fromParams
    var fromState = $state.current.name;
    var fromParams = _.clone($stateParams);
    if (fieldRecordDetailsOnTheFlyFormCtrl.openMapTab) {
      fromParams.openMapTab = true;
    }

    switch (creationMode) {
      case CREATION_MODE.WITHOUT_GEOMETRY:
        addExtraData(data);
        FieldRecordDetailsService.createFieldRecordOnTheFly(
          fieldRecordDetailsOnTheFlyFormCtrl.studyId,
          data,
          {},
          extraTablesForm,
          $scope.fieldRecordDetailsOnTheFlyForm
        );
        refresh(true);
        return;
      case CREATION_MODE.ACTIVATE_GPS:
        Geolocation.getCurrentPosition().then(
          (position) => {
            var gpsPoint = { type: 'Point', coordinates: [position.coords.longitude, position.coords.latitude] };
            var mapData = {
              expertPosition: gpsPoint,
              txPosition: gpsPoint,
              expertPositionAccuracy: position.coords.accuracy,
            };
            addExtraData(data);
            FieldRecordDetailsService.createFieldRecordOnTheFly(
              fieldRecordDetailsOnTheFlyFormCtrl.studyId,
              data,
              mapData,
              extraTablesForm,
              $scope.fieldRecordDetailsOnTheFlyForm
            );
            refresh(true);
          },
          () => {
            LxNotificationService.error('Une erreur est survenue lors de la récupération de la position GPS');
            return;
          }
        );
        return;
      case CREATION_MODE.DEFINE_ON_MAP:
        $state.go(
          'field-record-creation',
          _.defaults(
            { fromState, fromParams: JSON.stringify(fromParams), extraTables: JSON.stringify(extraTablesForm) },
            data
          )
        );
        return;
      // Par défaut, ouverture du formulaire avec les données courantes (avec WITHOUT_GEOMETRY par défaut)
      default:
        $state.go(
          'field-record-creation',
          _.defaults(
            { fromState, fromParams: JSON.stringify(fromParams), extraTables: JSON.stringify(extraTablesForm) },
            data
          )
        );
        return;
    }
  }

  function addExtraData(data) {
    var txTime =
      moment(new Date().getTime()).tz(fieldRecordDetailsOnTheFlyFormCtrl.studyState.study.timezone).toDate().getTime() /
      1000;
    if (fieldRecordDetailsOnTheFlyFormCtrl.taxon.vernacularName) {
      data.txVernacularName = fieldRecordDetailsOnTheFlyFormCtrl.taxon.vernacularName[0];
    }
    data.txRefType = fieldRecordDetailsOnTheFlyFormCtrl.txRef.key;
    data.txRefFilter = fieldRecordDetailsOnTheFlyFormCtrl.txRefFilter.key;
    data.txName = fieldRecordDetailsOnTheFlyFormCtrl.taxon.name;
    data.txSure = true;
    data.txPresent = true;
    data.txTime = txTime;
    data.txGroupKey = fieldRecordDetailsOnTheFlyFormCtrl.txGroup;
    data.status = STATUS.DEFAULT;
  }

  function setExtraDataToggle() {
    fieldRecordDetailsOnTheFlyFormCtrl.extraDataToggle = !fieldRecordDetailsOnTheFlyFormCtrl.extraDataToggle;
    FieldRecordRepository.setExtraDataToggle(fieldRecordDetailsOnTheFlyFormCtrl.extraDataToggle);
  }

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    if (!fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm) {
      fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm = {};
    }
    fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm.stadeDeveloppement = stadeDeveloppement
      ? stadeDeveloppement.id
      : null;
  }

  function onComportementChanged(comportement) {
    if (!fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm) {
      fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm = {};
    }
    //use to put birds comportement OBLIGATORY
    if(fieldRecordDetailsOnTheFlyFormCtrl.extraTable.comportementTable === "birds_comportement"){
      fieldRecordDetailsOnTheFlyFormCtrl.isAvailable = false;
    }
    fieldRecordDetailsOnTheFlyFormCtrl.extraDataForm.comportement = comportement ? comportement.id : null;
    
    //use to put birds comportement OBLIGATORY
    if(comportement != null && fieldRecordDetailsOnTheFlyFormCtrl.extraTable.comportementTable === "birds_comportement"){
      fieldRecordDetailsOnTheFlyFormCtrl.isAvailable = true;
    }
  }

  function reloadExtraTablesState() {
    extraDataClear();
    fieldRecordDetailsOnTheFlyFormCtrl.extraTablesState = FieldRecordExtraTablesStore.getState();
    if (
      !fieldRecordDetailsOnTheFlyFormCtrl.extraTablesState ||
      fieldRecordDetailsOnTheFlyFormCtrl.extraTablesState.loading
    ) {
      return;
    }
    extraDataChange();
  }
}
