import _ from 'lodash';

import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as FIELD_SURVEY_STORE_EVENTS } from '../../stores/field-survey-details.store';
import { EVENTS as SURVEY_TYPE_STORE_EVENTS } from '../../../survey-type/stores/survey-type-details.store';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as STUDY_EXPERT_LAYER_STORE_EVENTS } from '../../../study/stores/study-expert-layer.store';
import { EVENTS as FIELD_SURVEY_CLONAGE_STORE_EVENTS } from '../../stores/field-survey-clonage.store';
import { EVENTS as KML_SERVICE_EVENTS } from '../../services/field-survey-kml.service';
import { EVENTS as FIELD_RECORD_STATUS_SERVICE_EVENTS } from '../../../field-record/services/field-record-status.service';
import { geoJsonFromLatlng } from '../../../map/tools/map-util.js';

require('./field-survey-details-container.scss');

export default FieldSurveyDetailsContainerDirective;

const MAP_TAB_INDEX = 1;

// @ngInject
function FieldSurveyDetailsContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      fieldSurveyId: '@',
      surveyTypeId: '@',
      fieldSurveyClonageId: '@',
      openMapTabProvider: '&openMapTab',
    },
    bindToController: true,
    controller: FieldSurveyDetailsContainerController,
    controllerAs: 'fieldSurveyDetailsContainerCtrl',
    template: require('./field-survey-details-container.html'),
    replace: true,
  };
}

// @ngInject
function FieldSurveyDetailsContainerController(
  $scope,
  $state,
  FieldSurveyDetailsService,
  StudyDetailsService,
  SurveyTypeDetailsService,
  HeaderService,
  FieldSurveyDetailsStore,
  StudyDetailsStore,
  SurveyTypeDetailsStore,
  LxNotificationService,
  FieldSurveyKmlService,
  MediaService,
  FieldRecordStatusService,
  FieldRecordListMapService,
  StudyMapService,
  StudyExpertLayerService,
  StudyExpertLayerStore,
  Geolocation,
  FieldSurveyClonageService,
  FieldSurveyClonageStore
) {
  var fieldSurveyDetailsContainerCtrl = this;

  fieldSurveyDetailsContainerCtrl.mainTabFormData = null;
  fieldSurveyDetailsContainerCtrl.mediaTabFormData = null;
  fieldSurveyDetailsContainerCtrl.extraTabsFormData = {};
  fieldSurveyDetailsContainerCtrl.extraTables = null;
  fieldSurveyDetailsContainerCtrl.fieldSurveyMetadata = null;
  fieldSurveyDetailsContainerCtrl.uploadingKml = false;
  fieldSurveyDetailsContainerCtrl.processingFieldRecordStatus = false;
  fieldSurveyDetailsContainerCtrl.headerUpdated = false;

  fieldSurveyDetailsContainerCtrl.isWebClient = isWebClient;
  fieldSurveyDetailsContainerCtrl.isLoading = isLoading;
  fieldSurveyDetailsContainerCtrl.isLoaded = isLoaded;
  fieldSurveyDetailsContainerCtrl.isProcessing = isProcessing;

  fieldSurveyDetailsContainerCtrl.isNew = isNew;
  fieldSurveyDetailsContainerCtrl.hasDetailsForm = hasDetailsForm;
  fieldSurveyDetailsContainerCtrl.useExtraTable = useExtraTable;
  fieldSurveyDetailsContainerCtrl.getExtraTable = getExtraTable;
  fieldSurveyDetailsContainerCtrl.isExtraTableValid = isExtraTableValid;
  fieldSurveyDetailsContainerCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsContainerCtrl.canCreateSubElements = canCreateSubElements;
  fieldSurveyDetailsContainerCtrl.canEditKml = canEditKml;
  fieldSurveyDetailsContainerCtrl.canEdit = canEdit;
  fieldSurveyDetailsContainerCtrl.onMainTabChanged = onMainTabChanged;
  fieldSurveyDetailsContainerCtrl.onMediaTabChanged = onMediaTabChanged;
  fieldSurveyDetailsContainerCtrl.onETTabChanged = onETTabChanged;
  fieldSurveyDetailsContainerCtrl.onSubmit = onSubmit;
  fieldSurveyDetailsContainerCtrl.onDelete = onDelete;
  fieldSurveyDetailsContainerCtrl.onSubmitKml = onSubmitKml;
  fieldSurveyDetailsContainerCtrl.onUploadMedia = onUploadMedia;
  fieldSurveyDetailsContainerCtrl.onMakeFieldRecordsPublic = onMakeFieldRecordsPublic;
  fieldSurveyDetailsContainerCtrl.onPositionChange = onPositionChange;
  fieldSurveyDetailsContainerCtrl.onRemoveGeom = onRemoveGeom;
  fieldSurveyDetailsContainerCtrl.onActivateMapTab = onActivateMapTab;
  fieldSurveyDetailsContainerCtrl.onLocalLayerChanged = onLocalLayerChanged;
  fieldSurveyDetailsContainerCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldSurveyDetailsContainerCtrl.isAutoLocatedError = isAutoLocatedError;

  $scope.$watch(
    fieldSurveyDetailsContainerCtrl.openMapTabProvider,
    (openMapTab) => (fieldSurveyDetailsContainerCtrl.openMapTab = openMapTab)
  );

  $scope.$on(FIELD_SURVEY_STORE_EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(SURVEY_TYPE_STORE_EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED, reload);
  $scope.$on(STUDY_EXPERT_LAYER_STORE_EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED, reload);
  $scope.$on(FIELD_SURVEY_CLONAGE_STORE_EVENTS.FIELD_SURVEY_CLONAGE_STATE_CHANGED, reload);

  $scope.$on(KML_SERVICE_EVENTS.FIELD_SURVEY_KML_UPDATING, onUploadingKml);
  $scope.$on(KML_SERVICE_EVENTS.FIELD_SURVEY_KML_UPDATE_SUCCESS, onUploadKmlSuccess);
  $scope.$on(KML_SERVICE_EVENTS.FIELD_SURVEY_KML_UPDATE_ERROR, onUploadKmlError);

  $scope.$on(FIELD_RECORD_STATUS_SERVICE_EVENTS.FIELD_RECORD_STATUS_PROCESSING, onProcessingFieldRecordStatus);
  $scope.$on(
    FIELD_RECORD_STATUS_SERVICE_EVENTS.FIELD_RECORD_STATUS_PROCESSING_SUCCESS,
    onProcessFieldRecordStatusSuccess
  );
  $scope.$on(FIELD_RECORD_STATUS_SERVICE_EVENTS.FIELD_RECORD_STATUS_PROCESSING_ERROR, onProcessFieldRecordStatusError);

  function onLocalLayerChanged(layerId) {
    StudyMapService.saveLayerVisibility(fieldSurveyDetailsContainerCtrl.studyId, layerId);
  }

  StudyDetailsService.load(fieldSurveyDetailsContainerCtrl.studyId);

  StudyExpertLayerService.load(fieldSurveyDetailsContainerCtrl.studyId);
  if (isNew()) {
    if (fieldSurveyDetailsContainerCtrl.surveyTypeId) {
      SurveyTypeDetailsService.load(fieldSurveyDetailsContainerCtrl.surveyTypeId);
    } else {
      getPositionFromGPS().then(
        (position) => {
          FieldSurveyClonageService.load(fieldSurveyDetailsContainerCtrl.fieldSurveyClonageId, position);
        },
        (err) => {
          FieldSurveyClonageService.load(fieldSurveyDetailsContainerCtrl.fieldSurveyClonageId, null);
        }
      );
    }
  } else {
    FieldSurveyDetailsService.load(fieldSurveyDetailsContainerCtrl.fieldSurveyId);
  }

  function useExtraTable(extraTable) {
    return _.some(fieldSurveyDetailsContainerCtrl.extraTables, { name: extraTable });
  }

  function getExtraTable(extraTable) {
    return _.find(fieldSurveyDetailsContainerCtrl.extraTables, { name: extraTable }).value;
  }

  function isLoading() {
    return (
      !fieldSurveyDetailsContainerCtrl.fieldSurveyState ||
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.loading ||
      !fieldSurveyDetailsContainerCtrl.studyState ||
      fieldSurveyDetailsContainerCtrl.studyState.processing
    );
  }

  function isLoaded() {
    return (
      fieldSurveyDetailsContainerCtrl.fieldSurveyState &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.loaded &&
      fieldSurveyDetailsContainerCtrl.studyState &&
      fieldSurveyDetailsContainerCtrl.studyState.loaded
    );
  }

  function isProcessing() {
    return (
      (fieldSurveyDetailsContainerCtrl.fieldSurveyState &&
        (fieldSurveyDetailsContainerCtrl.fieldSurveyState.processing ||
          fieldSurveyDetailsContainerCtrl.fieldSurveyState.loading)) ||
      fieldSurveyDetailsContainerCtrl.uploadingKml ||
      fieldSurveyDetailsContainerCtrl.processingFieldRecordStatus
    );
  }

  function isWebClient() {
    return !IS_CORDOVA;
  }

  function isNew() {
    return !fieldSurveyDetailsContainerCtrl.fieldSurveyId;
  }

  // Indique pour si l'onglet utilise le formulaire de saisie rapide
  function hasDetailsForm() {
    return fieldSurveyDetailsContainerCtrl.activeTab === MAP_TAB_INDEX;
  }

  function canSubmit() {
    return (
      isLoaded() &&
      !fieldSurveyDetailsContainerCtrl.fieldSurveyState.processing &&
      !!fieldSurveyDetailsContainerCtrl.mainTabFormData &&
      (isNew() || !!fieldSurveyDetailsContainerCtrl.mediaTabFormData) &&
      !fieldSurveyDetailsContainerCtrl.uploadingKml &&
      !fieldSurveyDetailsContainerCtrl.processingFieldRecordStatus &&
      extraTablesLoaded()
    );
  }

  function canCreateSubElements() {
    return (
      fieldSurveyDetailsContainerCtrl.studyState &&
      fieldSurveyDetailsContainerCtrl.studyState.study &&
      fieldSurveyDetailsContainerCtrl.studyState.study.canCreateSubElements &&
      fieldSurveyDetailsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      fieldSurveyDetailsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE
    );
  }

  function extraTablesLoaded() {
    var extraTables = getExtraTables();
    for (let i = 0; i < extraTables.length; i++) {
      if (!fieldSurveyDetailsContainerCtrl.extraTabsFormData[extraTables[i].name]) {
        return false;
      }
    }

    return true;
  }

  function canEdit() {
    return (
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.canEdit &&
      fieldSurveyDetailsContainerCtrl.studyState.study &&
      fieldSurveyDetailsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      fieldSurveyDetailsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE
    );
  }

  function canEditKml() {
    return canEdit() && isWebClient();
  }

  function onSubmitKml(file) {
    if (!isNew()) {
      FieldSurveyKmlService.onUpdateKml(fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.id, file);
    } else {
      FieldSurveyKmlService.onExtractKml(
        fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.fieldSurveyGeometryType,
        file
      );
    }
  }

  function getExtraTables() {
    return _.map(
      _.toPairs(fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.extraTables),
      ([name, value]) => {
        return { name, value };
      }
    );
  }

  function isExtraTableValid(tableName) {

    return (
      fieldSurveyDetailsContainerCtrl.extraTabsFormData[tableName] &&
      fieldSurveyDetailsContainerCtrl.extraTabsFormData[tableName].formValid
    );
  }

  function isStudyAndFieldSurveyLoaded() {
    // l'étude n'est pas chargée
    if (!fieldSurveyDetailsContainerCtrl.studyState || !fieldSurveyDetailsContainerCtrl.studyState.study) {
      return false;
    }
    // on a un fieldSurveyId mais le relevé n'est pas chargé
    if (
      fieldSurveyDetailsContainerCtrl.fieldSurveyId &&
      (!fieldSurveyDetailsContainerCtrl.fieldSurveyState ||
        !fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey)
    ) {
      return false;
    }
    return true;
  }

  function canBeAutoLocated() {
    return (
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'RP' ||
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'PE' ||
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'RA' ||
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'ADNE' ||
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'LS'
    );
  }

  function isAutoLocatedValid() {

    return fieldSurveyDetailsContainerCtrl.isAutoLocated && fieldSurveyDetailsContainerCtrl.locationPrecision !== null;
  }

  function isAutoLocatedError() {
    return fieldSurveyDetailsContainerCtrl.isAutoLocated && fieldSurveyDetailsContainerCtrl.locationPrecision === null;
  }

  function reload() {
    fieldSurveyDetailsContainerCtrl.fieldSurveyState = buildFieldSurveyState();
    fieldSurveyDetailsContainerCtrl.studyState = StudyDetailsStore.getState();
    fieldSurveyDetailsContainerCtrl.layersState = StudyExpertLayerStore.getState();

    if (
      !isNew() &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.loaded &&
      !fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey
    ) {
      $state.go('study-field-surveys', { studyId: fieldSurveyDetailsContainerCtrl.studyId });
      return;
    }

    if (
      fieldSurveyDetailsContainerCtrl.studyState.study &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey
    ) {
      fieldSurveyDetailsContainerCtrl.needSync =
        !isWebClient() &&
        fieldSurveyDetailsContainerCtrl.studyState.study.lockTime &&
        (fieldSurveyDetailsContainerCtrl.studyState.study.latestSyncTime ||
          fieldSurveyDetailsContainerCtrl.studyState.study.lockTime) <
          fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.updated;
      fieldSurveyDetailsContainerCtrl.extraTables = getExtraTables();
      fieldSurveyDetailsContainerCtrl.fieldSurveyMetadata = { canEdit: canEdit() };
      let geom = fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.geometry;
      if (isNew() && !geom && canBeAutoLocated()) {
        Geolocation.getCurrentPosition().then(
          (position) => {
            onPositionChange(
              geoJsonFromLatlng(position.coords.latitude, position.coords.longitude),
              true,
              position.coords.accuracy,
              false
            );
          },
          () => {
            onPositionChange(null, true, null, false);
            LxNotificationService.error('Une erreur est survenue lors de la récupération de la position GPS');
          }
        );
      } else {
        onPositionChange(geom, false, null, false);
      }
    } else {
      fieldSurveyDetailsContainerCtrl.needSync = false;
      fieldSurveyDetailsContainerCtrl.extraTables = [];
      fieldSurveyDetailsContainerCtrl.fieldSurveyMetadata = {};
      fieldSurveyDetailsContainerCtrl.mapTabFormData = {};
    }

    if (
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol &&
      (fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.fieldSurveyGeometryType ===
        'POINT' ||
        fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.fieldSurveyGeometryType ===
          'LINESTRING' ||
        fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.fieldSurveyGeometryType ===
          'ALL_SIMPLE')
    ) {
      fieldSurveyDetailsContainerCtrl.geomCanBePoint = true;
    } else {
      fieldSurveyDetailsContainerCtrl.geomCanBePoint = false;
    }

    if (
      isNew() &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType &&
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol &&
      (fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'LS' ||
      fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key === 'ADNE')
    ) {
      fieldSurveyDetailsContainerCtrl.showLocationHelper = true;
    } else {
      fieldSurveyDetailsContainerCtrl.showLocationHelper = false;
    }

    checkActiveTab();
    updateHeader();
  }

  function updateHeader() {
    if (
      !fieldSurveyDetailsContainerCtrl.studyState.study ||
      !fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey ||
      fieldSurveyDetailsContainerCtrl.headerUpdated
    ) {
      return;
    }
    HeaderService.updateTitle(buildTitles());
    fieldSurveyDetailsContainerCtrl.headerUpdated = true;
  }

  function onMainTabChanged(mainTabFormData) {
    fieldSurveyDetailsContainerCtrl.mainTabFormData = mainTabFormData;
  }

  function onETTabChanged(extraTableName, tabFormData) {
    fieldSurveyDetailsContainerCtrl.extraTabsFormData[extraTableName] = tabFormData;
  }

  function onMediaTabChanged(mediaTabFormData) {
    fieldSurveyDetailsContainerCtrl.mediaTabFormData = mediaTabFormData;
  }

  function onSubmit() {
    if (isNew()) {
      if (fieldSurveyDetailsContainerCtrl.surveyTypeId) {
        FieldSurveyDetailsService.createFieldSurvey(
          fieldSurveyDetailsContainerCtrl.studyId,
          fieldSurveyDetailsContainerCtrl.surveyTypeId,
          fieldSurveyDetailsContainerCtrl.mainTabFormData.data,
          fieldSurveyDetailsContainerCtrl.mapTabFormData,
          _.mapValues(fieldSurveyDetailsContainerCtrl.extraTabsFormData, 'data'),
          $scope.fieldSurveyForm
        );
      } else {
        FieldSurveyDetailsService.cloneFieldSurvey(
          fieldSurveyDetailsContainerCtrl.studyId,
          fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.id,
          fieldSurveyDetailsContainerCtrl.fieldSurveyClonageId,
          fieldSurveyDetailsContainerCtrl.mainTabFormData.data,
          fieldSurveyDetailsContainerCtrl.mapTabFormData,
          _.mapValues(fieldSurveyDetailsContainerCtrl.extraTabsFormData, 'data'),
          $scope.fieldSurveyForm
        );
      }
    } else {
      FieldSurveyDetailsService.updateFieldSurvey(
        fieldSurveyDetailsContainerCtrl.fieldSurveyId,
        fieldSurveyDetailsContainerCtrl.mainTabFormData.data,
        fieldSurveyDetailsContainerCtrl.mapTabFormData,
        fieldSurveyDetailsContainerCtrl.mediaTabFormData.data,
        _.mapValues(fieldSurveyDetailsContainerCtrl.extraTabsFormData, 'data'),
        $scope.fieldSurveyForm
      );
    }
  }

  function onUploadMedia(input) {
    MediaService.uploadMedia(input);
  }

  function buildFieldSurveyState() {
    if (!isNew()) {
      return FieldSurveyDetailsStore.getState();
    }

    if (fieldSurveyDetailsContainerCtrl.surveyTypeId) {
      var surveyTypeState = SurveyTypeDetailsStore.getState();
      var fieldSurveyState = {
        fieldSurvey: null,
        loading: surveyTypeState.loading,
        loaded: surveyTypeState.loaded,
        error: surveyTypeState.error,
      };

      if (!!surveyTypeState.surveyType) {
        let extraTablesArray = _.chain(surveyTypeState.surveyType.protocolTxGroups)
          .filter('fieldSurveyExtraTables')
          .map('fieldSurveyExtraTables')
          .flatten()
          .uniq()
          .map((extraTable) => {
            return { [extraTable]: null };
          })
          .value();
        let extraTables = _.reduce(
          extraTablesArray,
          (acc, extraTable) => {
            return _.merge(acc, extraTable);
          },
          {}
        );
        fieldSurveyState.fieldSurvey = {
          startTime: new Date().getTime() / 1000,
          surveyType: surveyTypeState.surveyType,
          extraTables,
          canEdit: true,
        };
      }
      return fieldSurveyState;
    }
    // Clonage
    var clonageState = FieldSurveyClonageStore.getState();
    if (
      clonageState &&
      clonageState.fieldSurvey &&
      clonageState.fieldSurvey.geometry &&
      clonageState.fieldSurvey.geometry.type !== 'Point'
    ) {
      fieldSurveyDetailsContainerCtrl.warnGeometryClonage = true;
    } else {
      fieldSurveyDetailsContainerCtrl.warnGeometryClonage = false;
    }
    return clonageState;
  }

  function onPositionChange(geometry, isAutoLocated, precision, goToFirstTab) {
      fieldSurveyDetailsContainerCtrl.mapTabFormData = { geometry };
      fieldSurveyDetailsContainerCtrl.isAutoLocated = isAutoLocated;
      fieldSurveyDetailsContainerCtrl.locationPrecision = precision;

    if (goToFirstTab) {
      fieldSurveyDetailsContainerCtrl.activeTab = 0;
    }

  }

  function onActivateMapTab() {
    fieldSurveyDetailsContainerCtrl.activeTab = 1;
  }
  function onRemoveGeom() {
    fieldSurveyDetailsContainerCtrl.isAutoLocated = false;
    fieldSurveyDetailsContainerCtrl.mapTabFormData.geometry = null;
  }

  function checkActiveTab() {
    if (fieldSurveyDetailsContainerCtrl.activeTabChecked || !isStudyAndFieldSurveyLoaded()) {
      return;
    }
    if (isNew() && fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey) {
      fieldSurveyDetailsContainerCtrl.activeTabChecked = true;
      if (
        _.includes(
          ['PE', 'RP', 'RA', 'ADNE'],
          fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key
        )
      ) {
        if (fieldSurveyDetailsContainerCtrl.extraTables && fieldSurveyDetailsContainerCtrl.extraTables.length) {
          fieldSurveyDetailsContainerCtrl.activeTab = fieldSurveyDetailsContainerCtrl.geomCanBePoint
            ? fieldSurveyDetailsContainerCtrl.extraTables.length + 1
            : fieldSurveyDetailsContainerCtrl.extraTables.length;
        } else {
          fieldSurveyDetailsContainerCtrl.activeTab = fieldSurveyDetailsContainerCtrl.geomCanBePoint ? 1 : 0;
        }
      }
      return;
    }

    if (fieldSurveyDetailsContainerCtrl.openMapTab) {
      fieldSurveyDetailsContainerCtrl.activeTabChecked = true;
      fieldSurveyDetailsContainerCtrl.activeTab = 1;
    }
  }

  function getPositionFromGPS() {
    return Geolocation.getCurrentPosition().then(
      (position) => {
        return geoJsonFromLatlng(position.coords.latitude, position.coords.longitude);
      },
      () => {
        LxNotificationService.error('Une erreur est survenue lors de la récupération de la position GPS');
      }
    );
  }

  function buildTitles() {
    var titles = [
      {
        label: fieldSurveyDetailsContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: { studyId: fieldSurveyDetailsContainerCtrl.studyId },
        },
      },
      {
        label: 'Relevés',
        createFieldSurvey: fieldSurveyDetailsContainerCtrl.studyId,
        link: {
          sref: 'study-field-surveys',
          params: { studyId: fieldSurveyDetailsContainerCtrl.studyId },
        },
      },
    ];

    if (isNew()) {
      titles.push({
        label: "Création d'un relevé",
        link: null,
      });
    } else {
      let sref = 'field-survey-field-records';
      if (
        fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey &&
        'RP' === fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.surveyType.protocol.key
      ) {
        sref = 'field-survey-field-records-rp';
      }
      titles = titles.concat([
        {
          label: fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.name,
          link: {
            sref: sref,
            params: {
              studyId: fieldSurveyDetailsContainerCtrl.studyId,
              fieldSurveyId: fieldSurveyDetailsContainerCtrl.fieldSurveyId,
            },
          },
        },
        {
          label: 'Détails',
          clonage: fieldSurveyDetailsContainerCtrl.canCreateSubElements(),
          favori: {
            state: 'field-survey-details',
            params: {
              studyId: fieldSurveyDetailsContainerCtrl.studyId,
              fieldSurveyId: fieldSurveyDetailsContainerCtrl.fieldSurveyId,
              openMapTab: false,
            },
            picto: 'map',
            title: fieldSurveyDetailsContainerCtrl.fieldSurveyState.fieldSurvey.name,
          },
          link: null,
        },
      ]);
    }
    return titles;
  }

  function onDelete() {
    LxNotificationService.confirm(
      'Attention',
      'Êtes-vous sur de vouloir supprimer le relevé ?',
      { cancel: 'Non', ok: 'Oui' },
      (answer) =>
        answer &&
        FieldSurveyDetailsService.onDelete(
          fieldSurveyDetailsContainerCtrl.studyId,
          fieldSurveyDetailsContainerCtrl.fieldSurveyId
        )
    );
  }

  function onUploadingKml() {
    fieldSurveyDetailsContainerCtrl.uploadingKml = true;
  }
  function onUploadKmlSuccess(event, uploadedGeometry) {
    fieldSurveyDetailsContainerCtrl.mapTabFormData.geometry = uploadedGeometry;
    fieldSurveyDetailsContainerCtrl.uploadingKml = false;
  }
  function onUploadKmlError() {
    fieldSurveyDetailsContainerCtrl.uploadingKml = false;
  }

  function onProcessingFieldRecordStatus() {
    fieldSurveyDetailsContainerCtrl.processingFieldRecordStatus = true;
  }
  function onProcessFieldRecordStatusSuccess() {
    fieldSurveyDetailsContainerCtrl.processingFieldRecordStatus = false;
    FieldSurveyDetailsService.load(fieldSurveyDetailsContainerCtrl.fieldSurveyId);
  }
  function onProcessFieldRecordStatusError() {
    fieldSurveyDetailsContainerCtrl.processingFieldRecordStatus = false;
  }

  function onMakeFieldRecordsPublic() {
    FieldRecordStatusService.makePublicForFieldSurvey(fieldSurveyDetailsContainerCtrl.fieldSurveyId);
  }
}
