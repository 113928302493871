export default AutoSyncService;

// @ngInject
function AutoSyncService($rootScope) {
  return {
    setAutoSyncStudyId,
    openDialogAutoSync,
    onSubmitAutoSyncInterval,
    saveAutoSyncInterval,
    saveAutoSyncEnabled,
    saveAutoSync
  };

  function setAutoSyncStudyId(curStudyId){
    console.error('Auto sync not available in web mode');
  }

  function openDialogAutoSync(autoSync, interval) {
    console.error('Auto sync not available in web mode');
  }

  function onSubmitAutoSyncInterval() {
    console.error('Auto sync not available in web mode');
  }

  function saveAutoSyncInterval() {
    saveAutoSync();
  }

  function saveAutoSyncEnabled(isEnabled) {
    console.error('Auto sync not available in web mode');
  }

  function saveAutoSync(interval, autosync) {
    console.error('Auto sync not available in web mode');
  }

}
