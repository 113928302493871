import angular from 'angular';
import _ from 'lodash';

import fieldRecordLatestStore from './stores/field-record-latest.store';
import fieldRecordListMapStore from './stores/field-record-list-map.store';
import studyFieldRecordsStore from './stores/study-field-records.store';
import fieldSurveyFieldRecordsStore from './stores/field-survey-field-records.store';
import fieldSurveyFieldRecordsRpStore from './stores/field-survey-field-records-rp.store';
import fieldRecordDescriptionStore from './stores/field-record-description.store';
import fieldRecordDetailsStore from './stores/field-record-details.store';
import fieldRecordExtraTablesStore from './stores/field-record-extra-tables.store';
import fieldRecordLatestService from './services/field-record-latest.service';
import fieldRecordListMapService from './services/field-record-list-map.service';
import fieldRecordStatusService from './services/field-record-status.service';
import studyFieldRecordsService from './services/study-field-records.service';
import fieldSurveyFieldRecordsService from './services/field-survey-field-records.service';
import fieldSurveyFieldRecordsRpService from './services/field-survey-field-records-rp.service';
import fieldRecordDescriptionService from './services/field-record-description.service';
import fieldRecordHeadcountService from './services/field-record-headcount.service';
import fieldRecordValidateService from './services/field-record-validate.service';
import fieldRecordDetailsService from './services/field-record-details.service';
import fieldRecordExtraTablesService from './services/field-record-extra-tables.service';
import fieldRecordLatestComponent from './components/field-record-latest/field-record-latest.component';
import studyFieldRecordsContainerComponent from './components/study-field-records-container/study-field-records-container.component';
import studyFieldRecordsFormComponent from './components/study-field-records-form/study-field-records-form.component';
import fieldRecordListComponent from './components/field-record-list/field-record-list.component';
import fieldRecordVignetteComponent from './components/field-record-vignette/field-record-vignette.component';
import fieldRecordDescriptionDialogComponent from
  './components/field-record-description-dialog/field-record-description-dialog.component';
import fieldSurveyFieldRecordsRpComponent from
  './components/field-survey-field-records-rp/field-survey-field-records-rp.component';
import fieldSurveyFieldRecordsContainerComponent from
  './components/field-survey-field-records-container/field-survey-field-records-container.component';
import fieldSurveyFieldRecordsFormComponent from './components/field-survey-field-records-form/field-survey-field-records-form.component';
import fieldSurveyFieldRecordsRpFormComponent from './components/field-survey-field-records-rp-form/field-survey-field-records-rp-form.component';
import fieldRecordDetailsContainerComponent from './components/field-record-details-container/field-record-details-container.component';
import fieldRecordDetailsMainTabComponent from './components/field-record-details-main-tab/field-record-details-main-tab.component';
import fieldRecordDetailsMapTabComponent from './components/field-record-details-map-tab/field-record-details-map-tab.component';
import fieldRecordDetailsMediasTabComponent from './components/field-record-details-medias-tab/field-record-details-medias-tab.component';
import fieldRecordDetailsLsPlantsTabComponent from './components/field-record-details-ls-plants-tab/field-record-details-ls-plants-tab.component';
import fieldRecordDetailsLsMolluscsTabComponent from
  './components/field-record-details-ls-molluscs-tab/field-record-details-ls-molluscs-tab.component';
import fieldRecordDetailsLsMammalsTabComponent from './components/field-record-details-ls-mammals-tab/field-record-details-ls-mammals-tab.component';
import fieldRecordDetailsLsAmphibiaTabComponent from
  './components/field-record-details-ls-amphibia-tab/field-record-details-ls-amphibia-tab.component';
import fieldRecordDetailsLsInsectsTabComponent from './components/field-record-details-ls-insects-tab/field-record-details-ls-insects-tab.component';
import fieldRecordDetailsLsFishesTabComponent from './components/field-record-details-ls-fishes-tab/field-record-details-ls-fishes-tab.component';
import fieldRecordDetailsLsBirdsTabComponent from './components/field-record-details-ls-birds-tab/field-record-details-ls-birds-tab.component';
import fieldRecordDetailsLsReptilesTabComponent from
  './components/field-record-details-ls-reptiles-tab/field-record-details-ls-reptiles-tab.component';
import fieldRecordDetailsRpPlantsTabComponent from './components/field-record-details-rp-plants-tab/field-record-details-rp-plants-tab.component';
import fieldRecordOnTheFlyButtonsComponent from './components/field-record-on-the-fly-buttons/field-record-on-the-fly-buttons.component';
import fieldRecordDetailsOnTheFlyFormComponent from
  './components/field-record-details-on-the-fly-form/field-record-details-on-the-fly-form.component';
import remoteFieldRecordRepository from './repositories/field-record.remote.repository';
import localFieldRecordRepository from './repositories/field-record.local.repository';
import fieldRecordAccuracyFilter from './filters/field-record-accuracy.filter';
import fieldRecordStatusFilter from './filters/field-record-status.filter';
import fieldRecordExtraTableFilter from './filters/field-record-extra-table.filter';
import fieldRecordConfiguration from './configuration';
import routerConfig from './routes';
import fieldRecordDetailsRaBatsTabComponent from './components/field-record-details-ra-bats-tab/field-record-details-ra-bats-tab.component';
import fieldRecordDetailsGeneticSamplingTabComponent from './components/field-record-details-genetic-sampling-tab/field-record-details-genetic-sampling-tab.component';


const moduleName = 'shu-field-record';

angular.module(moduleName, ['lumx'])
  .directive('shuFieldRecordLatest', fieldRecordLatestComponent)
  .directive('shuStudyFieldRecordsContainer', studyFieldRecordsContainerComponent)
  .directive('shuStudyFieldRecordsForm', studyFieldRecordsFormComponent)
  .directive('shuFieldRecordList', fieldRecordListComponent)
  .directive('shuFieldRecordVignette', fieldRecordVignetteComponent)
  .directive('shuFieldRecordDescriptionDialog', fieldRecordDescriptionDialogComponent)
  .directive('shuFieldSurveyFieldRecordsRp', fieldSurveyFieldRecordsRpComponent)
  .directive('shuFieldSurveyFieldRecordsContainer', fieldSurveyFieldRecordsContainerComponent)
  .directive('shuFieldSurveyFieldRecordsForm', fieldSurveyFieldRecordsFormComponent)
  .directive('shuFieldSurveyFieldRecordsRpForm', fieldSurveyFieldRecordsRpFormComponent)
  .directive('shuFieldRecordDetailsContainer', fieldRecordDetailsContainerComponent)
  .directive('shuFieldRecordDetailsMainTab', fieldRecordDetailsMainTabComponent)
  .directive('shuFieldRecordDetailsMapTab', fieldRecordDetailsMapTabComponent)
  .directive('shuFieldRecordDetailsMediasTab', fieldRecordDetailsMediasTabComponent)
  .directive('shuFieldRecordDetailsLsPlantsTab', fieldRecordDetailsLsPlantsTabComponent)
  .directive('shuFieldRecordDetailsLsMolluscsTab', fieldRecordDetailsLsMolluscsTabComponent)
  .directive('shuFieldRecordDetailsLsMammalsTab', fieldRecordDetailsLsMammalsTabComponent)
  .directive('shuFieldRecordDetailsLsAmphibiaTab', fieldRecordDetailsLsAmphibiaTabComponent)
  .directive('shuFieldRecordDetailsLsInsectsTab', fieldRecordDetailsLsInsectsTabComponent)
  .directive('shuFieldRecordDetailsLsFishesTab', fieldRecordDetailsLsFishesTabComponent)
  .directive('shuFieldRecordDetailsLsBirdsTab', fieldRecordDetailsLsBirdsTabComponent)
  .directive('shuFieldRecordDetailsLsReptilesTab', fieldRecordDetailsLsReptilesTabComponent)
  .directive('shuFieldRecordDetailsRpPlantsTab', fieldRecordDetailsRpPlantsTabComponent)
  .directive('shuFieldRecordOnTheFlyButtons', fieldRecordOnTheFlyButtonsComponent)
  .directive('shuFieldRecordDetailsOnTheFlyForm', fieldRecordDetailsOnTheFlyFormComponent)
  .directive('shuFieldRecordDetailsRaBatsTab', fieldRecordDetailsRaBatsTabComponent)
  .directive('shuFieldRecordDetailsGeneticSamplingTab', fieldRecordDetailsGeneticSamplingTabComponent)
  .factory('FieldRecordLatestStore', fieldRecordLatestStore)
  .factory('FieldRecordListMapStore', fieldRecordListMapStore)
  .factory('StudyFieldRecordsStore', studyFieldRecordsStore)
  .factory('FieldSurveyFieldRecordsStore', fieldSurveyFieldRecordsStore)
  .factory('FieldSurveyFieldRecordsRpStore', fieldSurveyFieldRecordsRpStore)
  .factory('FieldRecordDescriptionStore', fieldRecordDescriptionStore)
  .factory('FieldRecordDetailsStore', fieldRecordDetailsStore)
  .factory('FieldRecordExtraTablesStore', fieldRecordExtraTablesStore)
  .factory('FieldRecordLatestService', fieldRecordLatestService)
  .factory('FieldRecordListMapService', fieldRecordListMapService)
  .factory('FieldRecordStatusService', fieldRecordStatusService)
  .factory('StudyFieldRecordsService', studyFieldRecordsService)
  .factory('FieldSurveyFieldRecordsService', fieldSurveyFieldRecordsService)
  .factory('FieldSurveyFieldRecordsRpService', fieldSurveyFieldRecordsRpService)
  .factory('FieldRecordDescriptionService', fieldRecordDescriptionService)
  .factory('FieldRecordHeadcountService', fieldRecordHeadcountService)
  .factory('FieldRecordValidateService', fieldRecordValidateService)
  .factory('FieldRecordDetailsService', fieldRecordDetailsService)
  .factory('FieldRecordExtraTablesService', fieldRecordExtraTablesService)
  .factory('FieldRecordRepository', IS_CORDOVA ? localFieldRecordRepository : remoteFieldRecordRepository)
  .filter('fieldRecordAccuracyLabel', fieldRecordAccuracyFilter)
  .filter('fieldRecordStatusLabel', fieldRecordStatusFilter)
  .filter('fieldRecordExtraTableLabel', fieldRecordExtraTableFilter)
  .constant('FieldRecordConfiguration', fieldRecordConfiguration)
  .config(routerConfig)
  .run(/* @ngInject */ ($rootScope, $state, $stateParams) => {
    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
      if (event.defaultPrevented) {
        return;
      }
      // Dans le cas où l'utilisateur va sur la page de création d'une observation,
      // on doit récupérer d'où il vient pour qu'il puisse y retourner
      if (toState.name !== 'field-record-creation') {
        return;
      }

      // On vérifie que l'utilisateur ne vient pas d'une autre page
      if (!fromState.name) {
        return;
      }

      // Le state est déjà passé par là
      if (toParams.fromParams) {
        return;
      }

      event.preventDefault();

      $state.go(toState.name, _.merge({
        fromState: fromState.name,
        fromParams: fromParams && JSON.stringify(fromParams)
      }, toParams));
    });
  });

export default {moduleName};
