import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-details.service';

const EVENTS = {
  STUDY_DETAILS_STATE_CHANGED: 'shu-study-details.study-details-state-changed'
};

export default StudyDetailsStore;

// @ngInject
function StudyDetailsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_DETAILS_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_DETAILS_PROCESSING, onProcessing);
  $rootScope.$on(SERVICE_EVENTS.STUDY_DETAILS_PROCESS_DONE, onProcessDone);
  $rootScope.$on(SERVICE_EVENTS.STUDY_DETAILS_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_DETAILS_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      study: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_DETAILS_STATE_CHANGED);
  }

  function onLoadSuccess(event, study) {
    state = Immutable({
      study,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_DETAILS_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      study: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_DETAILS_STATE_CHANGED);
  }

  function onProcessing() {
    state = Immutable({
      study: state.study,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_DETAILS_STATE_CHANGED);
  }

  function onProcessDone() {
    state = Immutable({
      study: state.study,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_DETAILS_STATE_CHANGED);
  }
}

export { EVENTS };
