import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/survey-type-list.service';
import { EVENTS as SYNC_SERVICE_EVENTS } from '../../synchro/services/study-synchronizer.service';

const EVENTS = {
  SURVEY_TYPE_LIST_STATE_CHANGED: 'shu-survey-type.survey-type-list-state-changed'
};

export default StudySearchStore;

// @ngInject
function StudySearchStore($rootScope, StudySearchService) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_LIST_LOADING, onSurveyTypeListLoading);
  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_LIST_LOAD_SUCCESS, onSurveyTypeListLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.SURVEY_TYPE_LIST_LOAD_ERROR, onSurveyTypeListLoadError);
  $rootScope.$on(SYNC_SERVICE_EVENTS.STUDY_SYNC_TERMINATED, onSyncStudy);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStudy() {
    state = Immutable({});
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED);
  }

  function onSurveyTypeListLoading() {
    state = Immutable({
      results: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED);
  }

  function onSurveyTypeListLoadSuccess(event, results) {
    state = Immutable({
      results,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED);
  }

  function onSurveyTypeListLoadError() {
    state = Immutable({
      results: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED);
  }

}

export { EVENTS };
