import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxon-search.service';

const EVENTS = {
  TX_REF_FILTER_STATE_CHANGED: 'shu-taxon-search.taxon-search-state-changed'
};

export default TaxonRefFilterStore;

// @ngInject
function TaxonRefFilterStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TX_REF_FILTER_LOADING, onTxRefFilterLoading);
  $rootScope.$on(SERVICE_EVENTS.TX_REF_FILTER_LOAD_SUCCESS, onTxRefFilterLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TX_REF_FILTER_LOAD_ERROR, onTxRefFilterLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onTxRefFilterLoading() {
    state = Immutable({
      availableFilters: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TX_REF_FILTER_STATE_CHANGED);
  }

  function onTxRefFilterLoadSuccess(event, availableFilters) {
    state = Immutable({
      availableFilters,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TX_REF_FILTER_STATE_CHANGED);
  }

  function onTxRefFilterLoadError() {
    state = Immutable({
      availableFilters: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TX_REF_FILTER_STATE_CHANGED);
  }
}

export { EVENTS };
