import { STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as SURVEY_TYPE_STORE_EVENTS } from '../../stores/survey-type-details.store';

require('./survey-type-new-container.scss');

export default SurveyTypeNewContainerDirective;

// @ngInject
function SurveyTypeNewContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@'
    },
    bindToController: true,
    controller: SurveyTypeNewContainerController,
    controllerAs: 'surveyTypeNewContainerCtrl',
    template: require('./survey-type-new-container.html'),
    replace: true
  };
}

// @ngInject
function SurveyTypeNewContainerController($scope, $state, HeaderService,
                  StudyDetailsStore, StudyDetailsService,
                  SurveyTypeDetailsService, SurveyTypeDetailsStore) {
  var surveyTypeNewContainerCtrl = this;

  surveyTypeNewContainerCtrl.studyState = {};
  surveyTypeNewContainerCtrl.surveyTypeState = {};
  surveyTypeNewContainerCtrl.headerUpdated = false;

  surveyTypeNewContainerCtrl.loaded = loaded;
  surveyTypeNewContainerCtrl.processing = processing;

  surveyTypeNewContainerCtrl.onFormSubmit = SurveyTypeDetailsService.onNewFormSubmit;

  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reloadStudyDetails);

  $scope.$on(SURVEY_TYPE_STORE_EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED, reloadSurveyTypeNew);

  loadSurveyType();

  function loaded() {
    return surveyTypeNewContainerCtrl.studyState.loaded;
  }

  function reloadSurveyTypeNew() {
    surveyTypeNewContainerCtrl.surveyTypeState = SurveyTypeDetailsStore.getState();
    if (surveyTypeNewContainerCtrl.surveyTypeState.error) {
      return;
    }
    if (surveyTypeNewContainerCtrl.surveyTypeState.loaded && surveyTypeNewContainerCtrl.surveyTypeState.surveyType) {
      $state.go('survey-type-details',
        {
          studyId: surveyTypeNewContainerCtrl.surveyTypeState.surveyType.study,
          surveyTypeId: surveyTypeNewContainerCtrl.surveyTypeState.surveyType.id
        });
    }
  }

  function processing() {
    return surveyTypeNewContainerCtrl.studyState.processing || surveyTypeNewContainerCtrl.surveyTypeState.processing;
  }

  function reloadStudyDetails() {
    surveyTypeNewContainerCtrl.studyState = StudyDetailsStore.getState();

    if (surveyTypeNewContainerCtrl.studyState.error) {
      return;
    }
    updateHeader();
  }

  function updateHeader() {
    if (!surveyTypeNewContainerCtrl.studyState.study || surveyTypeNewContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: surveyTypeNewContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: {studyId: surveyTypeNewContainerCtrl.studyId}
        }
      },
      'Nouveau relevé-type'
    ]);
    surveyTypeNewContainerCtrl.headerUpdated = true;
  }

  function loadSurveyType() {
    StudyDetailsService.load(surveyTypeNewContainerCtrl.studyId);
  }
}
