import _ from 'lodash';

require('./biotope-ref-button.scss');

export default BiotopeRefButtonDirective;

// @ngInject
function BiotopeRefButtonDirective() {
  return {
    restrict: 'E',
    scope: {
      name: '@',
      currentValueId: '=ngModel',
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit',
      requiredProvider: '&isRequired',
      refValueProvider: '&refValue',
    },
    bindToController: true,
    controller: BiotopeRefButtonController,
    controllerAs: 'biotopeRefButtonCtrl',
    template: require('./biotope-ref-button.html'),
    replace: true
  };
}

// @ngInject
function BiotopeRefButtonController($scope) {
  var biotopeRefButtonCtrl = this;

  biotopeRefButtonCtrl.onChange = onChange;
  $scope.$watch(biotopeRefButtonCtrl.canEditProvider, (canEdit) => {
    biotopeRefButtonCtrl.canEdit = canEdit;
  });
  $scope.$watch(biotopeRefButtonCtrl.requiredProvider, (required) => {
    biotopeRefButtonCtrl.required = required;
  });
  $scope.$watch(biotopeRefButtonCtrl.refValueProvider, (refValue) => {
    biotopeRefButtonCtrl.refValue = refValue;
  });

  function onChange() {
    biotopeRefButtonCtrl.currentValueId = biotopeRefButtonCtrl.refValue ? biotopeRefButtonCtrl.refValue.id : null;
    biotopeRefButtonCtrl.onChangeFn() && biotopeRefButtonCtrl.onChangeFn()(biotopeRefButtonCtrl.refValue);
  }
}
