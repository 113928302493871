import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxons-protection-list.service';

const EVENTS = {
  TAXONS_PROTECTION_LIST_STATE_CHANGED: 'shu-protection.taxons-protection-list-state-changed'
};

export default TaxonsProtectionListStore;

// @ngInject
function TaxonsProtectionListStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXONS_PROTECTION_LIST_LOADING, onTaxonsProtectionListLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXONS_PROTECTION_LIST_LOAD_SUCCESS, onTaxonsProtectionListLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXONS_PROTECTION_LIST_LOAD_ERROR, onTaxonsProtectionListLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onTaxonsProtectionListLoading() {
    state = Immutable({
      protectionsWithTaxons: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXONS_PROTECTION_LIST_STATE_CHANGED);
  }

  function onTaxonsProtectionListLoadSuccess(event, protectionsWithTaxons) {
    state = Immutable({
      protectionsWithTaxons,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXONS_PROTECTION_LIST_STATE_CHANGED);
  }

  function onTaxonsProtectionListLoadError() {
    state = Immutable({
      protectionsWithTaxons: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXONS_PROTECTION_LIST_STATE_CHANGED);
  }

}

export { EVENTS };
