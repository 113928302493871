import _ from 'lodash';
import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as ASIDE_EVENTS } from '../aside/aside.component';
import { EVENTS as HEADER_STORE_EVENTS } from '../../stores/header.store';
import { EVENTS as PROFILE_STORE_EVENTS } from '../../../profile/stores/profile.store';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as FAVORIS_STORE_EVENTS } from '../../../favoris/stores/favoris.store';
import { EVENTS as LOCATION_EVENTS } from '../../../map/services/geolocation.local.service.js';
import { EVENTS as FIELD_SURVEY_DETAILS_STORE_EVENTS } from '../../../field-survey/stores/field-survey-details.store';
import { EVENTS as SERVICE_EVENTS } from '../../../field-survey/services/study-field-surveys.service';
import { EVENTS as SYNCHRO_EVENTS } from '../../../synchro/repositories/synchro.repository';

require('./header.scss');

export default HeaderDirective;

// @ngInject
function HeaderDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: HeaderController,
    controllerAs: 'headerCtrl',
    template: require('./header.html'),
    replace: true,
  };
}

// @ngInject
function HeaderController(
  $rootScope,
  $scope,
  HeaderStore,
  AuthStore,
  ProfileStore,
  ProfileService,
  StudyDetailsStore,
  $state,
  FavorisStore,
  FavorisService,
  FavorisUtils,
  FieldSurveyDetailsStore,
  Geolocation,
  $timeout,
  LxDialogService,
  LxNotificationService,
  StudyRepository,
  FieldSurveyRepository,
  $q,
  AutoSyncService
) {
  var headerCtrl = this;

  $rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_SURVEYS_LOAD_SUCCESS, (fieldSurveys) => {
    console.log('fieldSurveys', fieldSurveys);
  });

  //field - survey - current - state - changed;
  headerCtrl.headerState = HeaderStore.getState();
  headerCtrl.profileState = ProfileStore.get();

  if (AuthStore.isAuthenticated()) {
    ProfileService.load();
  }

  headerCtrl.getHref = getHref;
  headerCtrl.showAside = showAside;
  headerCtrl.canCreateFieldRecord = canCreateFieldRecord;
  headerCtrl.toggleFavori = toggleFavori;
  headerCtrl.isTitleFavori = isTitleFavori;
  headerCtrl.openFavori = openFavori;

  headerCtrl.openToggleTracking = openToggleTracking;
  headerCtrl.saveTracking = saveTracking;
  headerCtrl.startTrackingService = startTrackingService;
  headerCtrl.reStartTrackingService = reStartTrackingService;
  headerCtrl.stopTrackingService = stopTrackingService;
  headerCtrl.toggleLocationService = toggleLocationService;
  headerCtrl.tracking = {};
  headerCtrl.trackingStatus = Geolocation.getTrackingStatus();
  const trackingStatusColors = {
    off: 'grey',
    starting: 'orange',
    running: 'green',
    error: 'red',
  };
  headerCtrl.trackingStatusColor = trackingStatusColors[headerCtrl.trackingStatus] || trackingStatusColors.off;
  headerCtrl.trackingSurveys = [];
  headerCtrl.trackableSurveys = []; //NOT USED

  headerCtrl.autoSync = {};
  headerCtrl.openDialogAutoSync = openDialogAutoSync;
  headerCtrl.onSubmitAutoSyncInterval = onSubmitAutoSyncInterval;
  headerCtrl.saveAutoSyncInterval = saveAutoSyncInterval;
  headerCtrl.saveAutoSyncEnabled = saveAutoSyncEnabled;
  headerCtrl.isAutoSyncRunning = false;
  $rootScope.$on(SYNCHRO_EVENTS.AUTO_SYNC_STATUS, (e, value) => {
    headerCtrl.isAutoSyncRunning = value === 'running';
  });

  $rootScope.$on('$stateChangeStart', (e, toState, toParams, fromState, fromParams) => {
    const curStudyId = toState.url.indexOf('/study/{studyId}') === 0 ? Number(_.get(toParams, 'studyId', 0)) : null;
    AutoSyncService.setAutoSyncStudyId(curStudyId || null);
  });

  headerCtrl.isCordovaUser = isCordovaUser;
  headerCtrl.onFieldSurveyForClonage = onFieldSurveyForClonage;

  headerCtrl.logoUrl = require('./logo-biotope.png');

  headerCtrl.canFindPrecisionSignal = canFindPrecisionSignal;
  headerCtrl.cannotFindPrecisionSignal = cannotFindPrecisionSignal;
  headerCtrl.isPrecisionLevelLow = isPrecisionLevelLow;
  headerCtrl.isPrecisionLevelMedium = isPrecisionLevelMedium;
  headerCtrl.isPrecisionLevelHigh = isPrecisionLevelHigh;
  headerCtrl.isPrecisionLevelExcellent = isPrecisionLevelExcellent;

  headerCtrl.isLocationServiceStarted = isLocationServiceStarted;
  headerCtrl.isLocationServiceStopped = isLocationServiceStopped;
  headerCtrl.isLocationServiceInitializing = isLocationServiceInitializing;

  $scope.$on(HEADER_STORE_EVENTS.HEADER_STATE_CHANGED, onTitleStateChanged);
  $scope.$on(PROFILE_STORE_EVENTS.PROFILE_STATE_CHANGED, onProfileStateChanged);
  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, onStudyStateChanged);
  $scope.$on(FAVORIS_STORE_EVENTS.FAVORIS_STATE_CHANGED, onFavorisStateChanged);
  // Timeout pour qu'angular réalise que onLocationRefreshed va modifier des valeurs
  $scope.$on(LOCATION_EVENTS.LOCATION_REFRESHED, () => $timeout(onLocationRefreshed));
  $scope.$on(FIELD_SURVEY_DETAILS_STORE_EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED, onFieldSurveyDetailsStateChanged);

  // On ne demande pas le chargement de l'étude, ce sera fait par les autres pages
  onStudyStateChanged();
  onLocationRefreshed();

  $rootScope.$on(LOCATION_EVENTS.TRACKING_STATUS, (e, status) => {
    $timeout(() => {
      headerCtrl.trackingStatus = status;
      headerCtrl.trackingStatusColor = trackingStatusColors[status] || trackingStatusColors.off;
    });
  });
  headerCtrl.BACKGROUND_LOCATION = null;
  $scope.$on(LOCATION_EVENTS.BACKGROUND_LOCATION, (e, location) => {
    $timeout(() => {
      headerCtrl.BACKGROUND_LOCATION = location;
    });
  });

  function getHref(link) {
    return $state.href(link.sref, link.params);
  }

  function showAside() {
    $rootScope.$broadcast(ASIDE_EVENTS.ASIDE_SHOW);
  }

  function onTitleStateChanged() {
    headerCtrl.headerState = HeaderStore.getState();
    const isStudy =
      headerCtrl.headerState &&
      headerCtrl.headerState.titles &&
      headerCtrl.headerState.titles[0] &&
      headerCtrl.headerState.titles[0].link &&
      headerCtrl.headerState.titles[0].link.params &&
      headerCtrl.headerState.titles[0].link.params.studyId;
    if (!isStudy) {
      headerCtrl.favorisState = {};
      headerCtrl.persistentFavoris = [];
    }
  }

  function onProfileStateChanged() {
    headerCtrl.profileState = ProfileStore.get();
  }

  function onStudyStateChanged() {
    headerCtrl.studyState = StudyDetailsStore.getState();
    // On charge les favoris de l'étude
    if (headerCtrl.studyState.study) {
      FavorisService.load(headerCtrl.studyState.study.id);
    }
  }

  function onFavorisStateChanged() {
    if (!headerCtrl.studyState.study || !headerCtrl.studyState.study.id) {
      return;
    }
    const studyId = headerCtrl.studyState.study.id;
    headerCtrl.favorisState = FavorisStore.getState(studyId);

    headerCtrl.persistentFavoris = [
      {
        title: 'Relevés',
        name: 'study-field-surveys',
        picto: 'calendar',
        params: {
          studyId: studyId,
        },
      },
      {
        title: 'Observations',
        name: 'study-field-records',
        picto: 'eye',
        params: {
          studyId: studyId,
        },
      },
      {
        isPersistent: true,
        title: 'Carte',
        name: 'study-map',
        picto: 'map',
        params: {
          studyId: studyId,
        },
      },
    ];
  }

  function onLocationRefreshed() {
    if (Geolocation.isInitializing()) {
      headerCtrl.locationServiceStatus = 'initializing';
      headerCtrl.gpsPrecisionValue = null;
      headerCtrl.gpsPrecision = '';
      return;
    }

    if (Geolocation.isLocationServiceActive()) {
      headerCtrl.locationServiceStatus = 'active';
      let rawPrecision = Geolocation.getPrecision();
      if (rawPrecision) {
        headerCtrl.gpsPrecisionValue = Math.round(rawPrecision);
        headerCtrl.gpsPrecision = headerCtrl.gpsPrecisionValue + 'm';
      } else {
        headerCtrl.gpsPrecisionValue = null;
        headerCtrl.gpsPrecision = '-';
      }
    } else {
      headerCtrl.locationServiceStatus = 'inactive';
      headerCtrl.gpsPrecision = '';
      headerCtrl.gpsPrecisionValue = null;
    }
  }

  function isLocationServiceInitializing() {
    return headerCtrl.locationServiceStatus === 'initializing';
  }

  function isLocationServiceStopped() {
    return headerCtrl.locationServiceStatus === 'inactive';
  }

  function isLocationServiceStarted() {
    return headerCtrl.locationServiceStatus === 'active';
  }

  function canFindPrecisionSignal() {
    return headerCtrl.isLocationServiceStarted() && headerCtrl.gpsPrecisionValue;
  }

  function cannotFindPrecisionSignal() {
    return headerCtrl.isLocationServiceStarted() && !headerCtrl.gpsPrecisionValue;
  }

  function isPrecisionLevelLow() {
    return headerCtrl.canFindPrecisionSignal() && headerCtrl.gpsPrecisionValue > 12;
  }

  function isPrecisionLevelMedium() {
    return (
      headerCtrl.canFindPrecisionSignal() && headerCtrl.gpsPrecisionValue <= 12 && headerCtrl.gpsPrecisionValue > 6
    );
  }

  function isPrecisionLevelHigh() {
    return headerCtrl.canFindPrecisionSignal() && headerCtrl.gpsPrecisionValue <= 6 && headerCtrl.gpsPrecisionValue > 3;
  }

  function isPrecisionLevelExcellent() {
    return headerCtrl.canFindPrecisionSignal() && headerCtrl.gpsPrecisionValue <= 3;
  }
  function onFieldSurveyDetailsStateChanged() {
    headerCtrl.fieldSurveyDetailState = FieldSurveyDetailsStore.getState();
    if (headerCtrl.fieldSurveyDetailState.fieldSurvey && headerCtrl.fieldSurveyDetailState.loaded) {
      headerCtrl.protocolSurveyType = headerCtrl.fieldSurveyDetailState.fieldSurvey.surveyType.protocol.key;
    }
  }

  function onFieldSurveyForClonage() {
    $state.go('field-survey-clonage', {
      studyId: headerCtrl.studyState.study.id,
      fieldSurveyClonageId: headerCtrl.fieldSurveyDetailState.fieldSurvey.id,
    });
  }

  function toggleFavori(input) {
    var favori = FavorisUtils.toFavori(input.state, input.params, input.picto, input.title);

    if (isFavori(favori)) {
      FavorisService.remove(headerCtrl.studyState.study.id, favori);
    } else {
      FavorisService.add(headerCtrl.studyState.study.id, favori);
    }
  }

  function isFavori(favori) {
    if (!headerCtrl.favorisState.loaded) {
      return false;
    }
    return FavorisUtils.isFavori(favori, headerCtrl.favorisState.favoris);
  }

  function isTitleFavori(input) {
    return isFavori(FavorisUtils.toFavori(input.state, input.params, input.picto, input.title));
  }

  function openFavori(favori) {
    $state.go(favori.name, favori.params);
  }

  function openToggleTracking() {
    $q.all([StudyRepository.query(), FieldSurveyRepository.getList(), Geolocation.getTracking()]).then(
      (res) => {
        if (!res[0] || !res[0].studies || !res[1] || !res[2]) {
          return;
        }
        headerCtrl.tracking = res[2];
        headerCtrl.tracking.params.interval /= 1000;
        headerCtrl.tracking.params.maxStandby /= 1000 * 60;

        const curStudy = headerCtrl.studyState.study;
        if (!curStudy) {
          headerCtrl.trackingSurveys = [];
          return;
        }
        headerCtrl.trackingSurveys = res[1]
          .filter((survey) => {
            return survey.study === curStudy.id && ['RI'].indexOf(survey.protocolKey) > -1;
          })
          .map((survey) => {
            const trackingSurvey =
              headerCtrl.tracking.surveys.find((trackingSurvey) => {
                return trackingSurvey.id === survey.id;
              }) || {};
            return Object.assign(survey, {
              isTrackable: trackingSurvey.isTrackable,
            });
          });
      },
      (err) => {
        console.log('Get studies or surveys errors', err);
      }
    );
    LxDialogService.open('dialog-tracking');
  }

  function saveTracking() {
    return $q((resolve, reject) => {
      Geolocation.getTracking().then((tracking) => {
        tracking.params = { ...headerCtrl.tracking.params };
        tracking.params.interval *= 1000;
        tracking.params.maxStandby *= 1000 * 60;
        tracking.surveys.forEach((trackingSurvey) => {
          trackingSurvey.isTrackable = false;
        });
        headerCtrl.trackingSurveys.forEach((survey) => {
          let trackingSurvey = tracking.surveys.find((trackingSurvey) => {
            return trackingSurvey.id === survey.id;
          });
          if (!survey.isTrackable && trackingSurvey) {
            trackingSurvey.isTrackable = false;
          } else if (survey.isTrackable) {
            if (!trackingSurvey) {
              trackingSurvey = {
                id: survey.id,
              };
              tracking.surveys.push(trackingSurvey);
            }
            trackingSurvey.isTrackable = true;
          }
        });
        Geolocation.setTracking(tracking).then(() => {
          resolve();
        });
      });
    });
  }

  function startTrackingService() {
    saveTracking().then(() => {
      LxDialogService.close('dialog-tracking');
      Geolocation.getTrackedSurveys().then((surveys) => {
        if (surveys.length) {
          Geolocation.startTrackingService();
        } else {
          LxNotificationService.warning("Aucun relevé n'est sélectionné");
        }
      });
    });
  }

  function reStartTrackingService() {
    Geolocation.stopTrackingService();
    setTimeout(() => {
      startTrackingService();
    });
  }

  function stopTrackingService() {
    Geolocation.stopTrackingService();
    LxDialogService.close('dialog-tracking');
  }

  function openDialogAutoSync() {
    AutoSyncService.openDialogAutoSync(headerCtrl);
  }

  function onSubmitAutoSyncInterval() {
    AutoSyncService.onSubmitAutoSyncInterval(headerCtrl);
  }

  function saveAutoSyncInterval() {
    AutoSyncService.saveAutoSyncInterval(headerCtrl);
  }

  function saveAutoSyncEnabled() {
    AutoSyncService.saveAutoSyncEnabled(headerCtrl);
  }

  function toggleLocationService() {
    Geolocation.toggleLocationService();
  }

  function canCreateFieldRecord() {
    return (
      headerCtrl.studyState &&
      headerCtrl.studyState.study &&
      headerCtrl.studyState.study.canCreateSubElements &&
      headerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      headerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE
    );
  }

  function isCordovaUser() {
    return IS_CORDOVA;
  }
}
