import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-record-extra-tables.service';

const EVENTS = {
  FIELD_RECORD_EXTRA_TABLES_STATE_CHANGED: 'shu-field-record.field-record-extra-tables-state-changed'
};

export default FieldRecordExtraTablesStore;

// @ngInject
function FieldRecordExtraTablesStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_EXTRA_TABLES_LOADING, onFieldRecordExtraTablesLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_EXTRA_TABLES_LOAD_SUCCESS, onFieldRecordExtraTablesLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_EXTRA_TABLES_LOAD_ERROR, onFieldRecordExtraTablesLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStudy() {
    state = Immutable({});
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_EXTRA_TABLES_STATE_CHANGED);
  }

  function onFieldRecordExtraTablesLoading() {
    state = Immutable({
      extraTables: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_EXTRA_TABLES_STATE_CHANGED);
  }

  function onFieldRecordExtraTablesLoadSuccess(event, extraTables) {
    state = Immutable({
      extraTables,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_EXTRA_TABLES_STATE_CHANGED);
  }

  function onFieldRecordExtraTablesLoadError() {
    state = Immutable({
      extraTables: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_EXTRA_TABLES_STATE_CHANGED);
  }
}

export { EVENTS };
