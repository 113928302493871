import _ from 'lodash';

require('./field-record-details-ls-fishes-tab.scss');

export default FieldRecordDetailsLsFishesTabDirective;

// @ngInject
function FieldRecordDetailsLsFishesTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsFishesTabController,
    controllerAs: 'fieldRecordDetailsLsFishesTabCtrl',
    template: require('./field-record-details-ls-fishes-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsFishesTabController($scope, $timeout) {
  var fieldRecordDetailsLsFishesTabCtrl = this;
  fieldRecordDetailsLsFishesTabCtrl.onModeContactChanged = onModeContactChanged;
  fieldRecordDetailsLsFishesTabCtrl.onSexeChanged = onSexeChanged;
  fieldRecordDetailsLsFishesTabCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsLsFishesTabCtrl.onComportementChanged = onComportementChanged;
  fieldRecordDetailsLsFishesTabCtrl.onHabitatReproductionChanged = onHabitatReproductionChanged;
  fieldRecordDetailsLsFishesTabCtrl.onHabitatCroissanceChanged = onHabitatCroissanceChanged;
  fieldRecordDetailsLsFishesTabCtrl.onTypeIndicePresenceChanged = onTypeIndicePresenceChanged;
  fieldRecordDetailsLsFishesTabCtrl.onChange = onChange;
  fieldRecordDetailsLsFishesTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsFishesTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsFishesTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsFishesTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsFishesTabCtrl.isNew = isNew;
  fieldRecordDetailsLsFishesTabCtrl.isReleveItinerant = isReleveItinerant;

  $scope.$watch(fieldRecordDetailsLsFishesTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsFishesTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsFishesTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsLsFishesTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsFishesTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsFishesTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsFishesTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsFishesTabCtrl.form = form;
  });

  function onModeContactChanged(modeContact) {
    fieldRecordDetailsLsFishesTabCtrl.data.modeContact = modeContact ? modeContact.id : null;
    onChange();
  }

  function onSexeChanged(sexe) {
    fieldRecordDetailsLsFishesTabCtrl.data.sexe = sexe ? sexe.id : null;
    onChange();
  }

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    fieldRecordDetailsLsFishesTabCtrl.data.stadeDeveloppement = stadeDeveloppement ? stadeDeveloppement.id : null;
    onChange();
  }

  function onComportementChanged(comportement) {
    fieldRecordDetailsLsFishesTabCtrl.data.comportement = comportement ? comportement.id : null;
    onChange();
  }

  function onHabitatReproductionChanged(habitatReproduction) {
    fieldRecordDetailsLsFishesTabCtrl.data.habitatReproduction = habitatReproduction ? habitatReproduction.id : null;
    onChange();
  }

  function onHabitatCroissanceChanged(habitatCroissance) {
    fieldRecordDetailsLsFishesTabCtrl.data.habitatCroissance = habitatCroissance ? habitatCroissance.id : null;
    onChange();
  }

  function onTypeIndicePresenceChanged(typeIndicePresence) {
    fieldRecordDetailsLsFishesTabCtrl.data.typeIndicePresence = typeIndicePresence ? typeIndicePresence.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsFishesTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsFishesTabCtrl.onChangeFn()(fieldRecordDetailsLsFishesTabCtrl.extraTableEnum, {
        data: {
          modeContact: fieldRecordDetailsLsFishesTabCtrl.data.modeContact,
          sexe: fieldRecordDetailsLsFishesTabCtrl.data.sexe,
          stadeDeveloppement: fieldRecordDetailsLsFishesTabCtrl.data.stadeDeveloppement,
          comportement: fieldRecordDetailsLsFishesTabCtrl.data.comportement,
          habitatReproduction: fieldRecordDetailsLsFishesTabCtrl.data.habitatReproduction,
          habitatCroissance: fieldRecordDetailsLsFishesTabCtrl.data.habitatCroissance,
          typeIndicePresence: fieldRecordDetailsLsFishesTabCtrl.data.typeIndicePresence
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsFishesTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsFishesTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsFishesTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsFishesTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsFishesTabCtrl.isNewFn()();
  }

  function isReleveItinerant() {
    return fieldRecordDetailsLsFishesTabCtrl.extraTableEnum === 'RI_FISHES';
  }

  function isFormValid() {
    if (!isReleveItinerant()) {
      // Pas de champs obligatoires
      return true;
    }

    return fieldRecordDetailsLsFishesTabCtrl.form['fieldRecord.stadeDeveloppement'] &&
            fieldRecordDetailsLsFishesTabCtrl.form['fieldRecord.stadeDeveloppement'].$valid &&
            fieldRecordDetailsLsFishesTabCtrl.form['fieldRecord.habitatReproduction'] &&
            fieldRecordDetailsLsFishesTabCtrl.form['fieldRecord.habitatReproduction'].$valid;
  }
}
