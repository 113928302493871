import _ from 'lodash';

require('./taxon-search-results.scss');

export default TaxonSearchResultsDirective;

// @ngInject
function TaxonSearchResultsDirective() {
  return {
    restrict: 'E',
    scope: {
      resultsStateProvider: '&resultsState',
      onTaxonSelectedFn: '&onTaxonSelected',
      onNextResultsActionFn: '&onNextResultsAction',
    },
    controller: TaxonSearchResultsController,
    controllerAs: 'taxonSearchResultsCtrl',
    bindToController: true,
    template: require('./taxon-search-results.html'),
    replace: true,
  };
}

// @ngInject
function TaxonSearchResultsController($scope, TaxonDetailsService) {
  var taxonSearchResultsCtrl = this;
  taxonSearchResultsCtrl.taxonDetailsService = TaxonDetailsService;

  taxonSearchResultsCtrl.hasMoreResults = hasMoreResults;
  taxonSearchResultsCtrl.onTaxonSelected = onTaxonSelected;
  taxonSearchResultsCtrl.onNextResultsAction = onNextResultsAction;

  $scope.$watch(taxonSearchResultsCtrl.resultsStateProvider, (resultsState) => {
    taxonSearchResultsCtrl.results = resultsState.results;
    taxonSearchResultsCtrl.totalResults = resultsState.totalResults;
    taxonSearchResultsCtrl.loading = resultsState.loading;
  });

  function hasMoreResults() {
    return (
      taxonSearchResultsCtrl.results && taxonSearchResultsCtrl.totalResults > taxonSearchResultsCtrl.results.length
    );
  }

  function onTaxonSelected(taxonKey) {
    taxonSearchResultsCtrl.onTaxonSelectedFn()(taxonKey);
  }

  function onNextResultsAction() {
    taxonSearchResultsCtrl.onNextResultsActionFn()();
  }
}
