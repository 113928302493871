const EVENTS = {
  // Action lancée quand l'utilisateur met à jour les données,
  FIELD_RECORD_STATUS_PROCESSING: 'shu-field-record.field-record-status-processing',
  FIELD_RECORD_STATUS_PROCESSING_SUCCESS: 'shu-field-record.field-record-status-processing-success',
  FIELD_RECORD_STATUS_PROCESSING_ERROR: 'shu-field-record.field-record-status-processing-error'
};

export default FieldRecordStatusService;

// @ngInject
function FieldRecordStatusService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {makePublicForSurveyType, makePublicForFieldSurvey};

  function makePublicForSurveyType(surveyTypeId) {
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_STATUS_PROCESSING);
    FieldRecordRepository.makePublicForSurveyType(surveyTypeId)
      .then(() => {
        LxNotificationService.success('Statut modifié');
        $rootScope.$broadcast(EVENTS.FIELD_RECORD_STATUS_PROCESSING_SUCCESS, surveyTypeId);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la modification du statut. ' + (error.data || ''));
        $rootScope.$broadcast(EVENTS.FIELD_RECORD_STATUS_PROCESSING_ERROR);
      }
    );
  }

  function makePublicForFieldSurvey(fieldSurveyId) {
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_STATUS_PROCESSING);
    FieldRecordRepository.makePublicForFieldSurvey(fieldSurveyId)
      .then(() => {
        LxNotificationService.success('Statut modifié');
        $rootScope.$broadcast(EVENTS.FIELD_RECORD_STATUS_PROCESSING_SUCCESS, fieldSurveyId);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue à la modification du statut. ' + (error.data || ''));
        $rootScope.$broadcast(EVENTS.FIELD_RECORD_STATUS_PROCESSING_ERROR);
      }
    );
  }
}
export { EVENTS };
