import { REFERENTIEL } from '../base/services/url-resolver.service';

export default {
  txGroupsEndpoint: {
    server: REFERENTIEL,
    path: '/tx_group'
  },
  txGroupByKeyEndpoint: {
    server: REFERENTIEL,
    path: '/tx_group/{txGroupKey}'
  }
};
