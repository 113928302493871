const EVENTS = {
  // Actions lancées quand l'utilisateur consulte la description d'une observation
  FIELD_RECORD_DESCRIPTION_LOADING: 'shu-field-record.field-record-description-loading',
  FIELD_RECORD_DESCRIPTION_LOAD_SUCCESS: 'shu-field-record.field-record-description-load-success',
  FIELD_RECORD_DESCRIPTION_LOAD_ERROR: 'shu-field-record.field-record-description-load-error',
  // Action lancée quand l'utilisateur met à jour les données,
  FIELD_RECORD_DESCRIPTION_PROCESSING: 'shu-field-record.field-record-description-processing'
};

export default FieldRecordDescriptionService;

// @ngInject
function FieldRecordDescriptionService($rootScope, FieldRecordRepository, FieldRecordDescriptionStore, LxNotificationService) {
  return {load, update};

  function load(fieldRecordId) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DESCRIPTION_LOADING);

    FieldRecordRepository.getDescription(fieldRecordId)
      .then((fieldRecord) => {
        $rootScope.$emit(EVENTS.FIELD_RECORD_DESCRIPTION_LOAD_SUCCESS, fieldRecord);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la récupération des informations de l\'observation. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_RECORD_DESCRIPTION_LOAD_ERROR);
      });
  }

  function update(fieldRecordId, description) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_DESCRIPTION_PROCESSING);
    FieldRecordRepository.updateDescription(fieldRecordId, description)
      .then(
        () => {
          LxNotificationService.success('Description enregistrée');
          $rootScope.$emit(EVENTS.FIELD_RECORD_DESCRIPTION_LOAD_SUCCESS, null);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde de l\'observation. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_RECORD_DESCRIPTION_LOAD_ERROR);
        }
      );
  }
}
export { EVENTS };
