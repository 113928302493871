const EVENTS = {
  // Actions lancées quand l'utilisateur charge les txgroupes
  TX_GROUP_LIST_RESULTS_LOADING: 'shu-txgroup.txgroup-list-results-loading',
  TX_GROUP_LIST_RESULTS_LOAD_SUCCESS: 'shu-txgroup.txgroup-list-results-load-success',
  TX_GROUP_LIST_RESULTS_LOAD_ERROR: 'shu-txgroup.txgroup-list-results-load-error',
};

export default TxGroupListService;

// @ngInject
function TxGroupListService($rootScope, TxGroupListStore, TxGroupRepository, LxNotificationService) {
  return {load};

  function load() {

    $rootScope.$emit(EVENTS.TX_GROUP_LIST_RESULTS_LOADING);

    TxGroupRepository.list()
      .then((result) => {
        $rootScope.$emit(EVENTS.TX_GROUP_LIST_RESULTS_LOAD_SUCCESS, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des groupes taxonomiques. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.TX_GROUP_LIST_RESULTS_LOAD_ERROR);
      });
  }
}
export { EVENTS };
