import _ from 'lodash';

require('./field-survey-details-birds-tab.scss');

export default FieldSurveyDetailsBirdsTabDirective;

// @ngInject
function FieldSurveyDetailsBirdsTabDirective() {
  return {
    restrict: 'AE',
    scope: {
      initialDataProvider: '&initialData',
      fieldSurveyStateProvider: '&fieldSurveyState',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete',
      canSubmitFn: '&canSubmit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldSurveyDetailsBirdsTabController,
    controllerAs: 'fieldSurveyDetailsBirdsTabCtrl',
    template: require('./field-survey-details-birds-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyDetailsBirdsTabController($scope, $timeout) {
  var fieldSurveyDetailsBirdsTabCtrl = this;
  fieldSurveyDetailsBirdsTabCtrl.onPeriodeChange = onPeriodeChange;
  fieldSurveyDetailsBirdsTabCtrl.onChange = onChange;
  fieldSurveyDetailsBirdsTabCtrl.onDelete = onDelete;
  fieldSurveyDetailsBirdsTabCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsBirdsTabCtrl.isNew = isNew;

  $scope.$watch(fieldSurveyDetailsBirdsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldSurveyDetailsBirdsTabCtrl.data = {};
    } else {
      fieldSurveyDetailsBirdsTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldSurveyDetailsBirdsTabCtrl.onChange();
  });

  $scope.$watch(fieldSurveyDetailsBirdsTabCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }

    fieldSurveyDetailsBirdsTabCtrl.fieldSurveyState = fieldSurveyState;
  });

  $scope.$watch(fieldSurveyDetailsBirdsTabCtrl.formProvider, (form) => {
    fieldSurveyDetailsBirdsTabCtrl.form = form;
  });

  function onPeriodeChange(currentPeriode) {
    fieldSurveyDetailsBirdsTabCtrl.data.periode = currentPeriode ? currentPeriode.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldSurveyDetailsBirdsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldSurveyDetailsBirdsTabCtrl.onChangeFn()('BIRDS', {
        data: {
          periode: fieldSurveyDetailsBirdsTabCtrl.data.periode
        },
        formValid: isFormValid()
      });
    });
  }

  function onDelete() {
    fieldSurveyDetailsBirdsTabCtrl.onDeleteFn()();
  }

  function canSubmit() {
    return fieldSurveyDetailsBirdsTabCtrl.canSubmitFn()();
  }

  function isNew() {
    return fieldSurveyDetailsBirdsTabCtrl.isNewFn()();
  }

  // TODO : vérifier que les champs int sont bien des valeurs entières ?
  function isFormValid() {
    return true;
  }
}
