import _ from 'lodash';

import { STUDY_EXPERT_LAYER_FORMAT } from '../model.js';

const EVENTS = {
  STUDY_EXPERT_LAYER_LOADING: 'shu-study-expert-layer.study-expert-layer-loading',
  STUDY_EXPERT_LAYER_LOAD_SUCCESS: 'shu-study-expert-layer.study-expert-layer-load-success',
  STUDY_EXPERT_LAYER_LOAD_ERROR: 'shu-study-expert-layer.study-expert-layer-load-error',
  STUDY_EXPERT_LAYER_PROCESSING: 'shu-study-expert-layer.study-expert-layer-processing',
  STUDY_EXPERT_LAYER_PROCESS_ERROR: 'shu-study-expert-layer.study-expert-layer-process-error'
};

export default StudyExpertLayerService;

// @ngInject
function StudyExpertLayerService($rootScope, StudyExpertLayerRepository, StudyExpertLayerStore, LxNotificationService,
      StudyConfiguration, UrlResolverService, AuthStore) {
  return {load, onUploadLayer, onRemoveLayer, buildLayerList};

  function buildLayerList(layersState, studyId) {
    let layers = [];
    // Ajout des couches MbTiles puis KML
    _.forEach(_.filter(layersState.layers, (l) => !IS_CORDOVA || l.format === STUDY_EXPERT_LAYER_FORMAT.KML), (layer) => {
      var layerInfo = {
        id: layer.id,
        name: layer.name,
        kml: true,
        remote: !IS_CORDOVA,
        headers: {'Authorization': 'Bearer ' + AuthStore.getState().token},
        url: IS_CORDOVA ? layer.filepath : UrlResolverService.
          resolveUrl(StudyConfiguration.studyExpertLayerDetailEndpoint, {studyId, studyExpertLayerId: layer.id})
      };
      layers.push(layerInfo);
    });
    _.forEach(_.filter(layersState.layers, (l) => l.format === STUDY_EXPERT_LAYER_FORMAT.MBTILES), (layer) => {
      var layerInfo = {
        id: layer.id,
        name: layer.name,
        mbtiles: true,
        filepath: layer.filepath
      };
      layers.push(layerInfo);
    });
    return layers;
  }

  function load(studyId) {
    $rootScope.$emit(EVENTS.STUDY_EXPERT_LAYER_LOADING);

    StudyExpertLayerRepository.getForStudy(studyId)
      .then(
        (layers) => $rootScope.$emit(EVENTS.STUDY_EXPERT_LAYER_LOAD_SUCCESS, layers),
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement des couches personnelles de l\'étude. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.STUDY_EXPERT_LAYER_LOAD_ERROR);
        }
      );
  }

  function onUploadLayer(studyId, layerName, layerInfo) {

    $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_PROCESSING);

    StudyExpertLayerRepository.addLayer(studyId, layerName, layerInfo)
      .then(
        (layers) => {
          LxNotificationService.success('Couche ajoutée.');
          $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_LOAD_SUCCESS, layers);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement du fichier. ' + (error.data || ''));
          $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_PROCESS_ERROR);
        }
      );
  }

  function onRemoveLayer(studyId, studyExpertLayerId) {
    $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_PROCESSING);

    StudyExpertLayerRepository.removeLayer(studyId, studyExpertLayerId)
      .then(
        (layers) => {
          LxNotificationService.success('Couche supprimée.');
          $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_LOAD_SUCCESS, layers);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la suppression de la couche. ' + (error.data || ''));
          $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_PROCESS_ERROR);
        }
      );
  }
}
export { EVENTS };
