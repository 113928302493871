import { EVENTS as FIELD_RECORD_LATEST_STORE_EVENTS } from '../../../field-record/stores/field-record-latest.store';
import { EVENTS as FIELD_SURVEY_CURRENT_STORE_EVENTS } from '../../../field-survey/stores/field-survey-current.store';

require('./home.scss');

export default HomeDirective;

// @ngInject
function HomeDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: HomeController,
    controllerAs: 'homeCtrl',
    template: require('./home.html'),
    replace: true
  };
}

// @ngInject
function HomeController($scope, $rootScope, $state, HeaderService,
              FieldRecordLatestStore, FieldRecordLatestService,
              FieldSurveyCurrentStore, FieldSurveyCurrentService) {
  var homeCtrl = this;

  homeCtrl.fieldRecordLatestState = {};
  homeCtrl.fieldSurveyCurrentState = {};

  homeCtrl.onFieldRecordSelected = onFieldRecordSelected;
  homeCtrl.onFieldSurveySelected = onFieldSurveySelected;
  homeCtrl.onStudySelected = onStudySelected;
  homeCtrl.onTaxonSelected = onTaxonSelected;

  $scope.$on(FIELD_RECORD_LATEST_STORE_EVENTS.FIELD_RECORD_LATEST_STATE_CHANGED, reloadFieldRecord);
  $scope.$on(FIELD_SURVEY_CURRENT_STORE_EVENTS.FIELD_SURVEY_CURRENT_STATE_CHANGED, reloadFieldSurvey);

  init();

  function init() {
    FieldRecordLatestService.load();
    FieldSurveyCurrentService.load();
    HeaderService.updateTitle([
      'Accueil'
    ]);
  }

  function reloadFieldRecord() {
    homeCtrl.fieldRecordLatestState = FieldRecordLatestStore.getState();
  }

  function reloadFieldSurvey() {
    homeCtrl.fieldSurveyCurrentState = FieldSurveyCurrentStore.getState();
  }

  function onFieldRecordSelected(studyId, fieldRecordId) {
    $state.go('field-record-details', {studyId, fieldRecordId});
  }

  function onFieldSurveySelected(studyId, fieldSurveyId) {
    $state.go('field-survey-field-records', {studyId, fieldSurveyId});
  }

  function onTaxonSelected(txRefType, txRefVersion, taxonKey) {
    $state.go('taxon-details', {txRefType, txRefVersion, taxonKey});
  }

  function onStudySelected(studyId) {
    $state.go('study-details', {studyId});
  }
}
