export default TaxonRouterConfig;

// @ngInject
function TaxonRouterConfig($stateProvider) {
  $stateProvider.state('taxon-search', {
    url: '/taxon-search',
    template: '<shu-taxon-search-container></shu-taxon-search-container>',
    needAuthenticatedUser: true,
  }).state('taxon-details', {
    url: '/{txRefType}/{txRefVersion}/{txRefFilter}/taxon/{taxonKey}',
    template: `
      <shu-taxon-details tx-ref-type="{{ ::routeCtrl.txRefType }}"
                         tx-ref-version="{{ ::routeCtrl.txRefVersion }}"
                         tx-ref-filter="{{ ::routeCtrl.txRefFilter }}"
                         taxon-key="{{ ::routeCtrl.taxonKey }}">
      </shu-taxon-details>`,
    needAuthenticatedUser: true,
    controller: TaxonDetailRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function TaxonDetailRouteController($scope, $stateParams) {
  var routeCtrl = this;

  console.log(routeCtrl.txRefType);
  console.log(routeCtrl.txRefFilter);
  console.log(routeCtrl.txRefVersion);
  console.log(routeCtrl.taxonKey);
  routeCtrl.txRefType = $stateParams.txRefType;
  routeCtrl.txRefFilter = $stateParams.txRefFilter;
  routeCtrl.txRefVersion = $stateParams.txRefVersion;
  routeCtrl.taxonKey = $stateParams.taxonKey;
}
