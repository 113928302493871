import _ from 'lodash';

require('./field-survey-details-common-tab.scss');

export default FieldSurveyDetailsCommonTabDirective;

// @ngInject
function FieldSurveyDetailsCommonTabDirective() {
  return {
    restrict: 'AE',
    scope: {
      initialDataProvider: '&initialData',
      fieldSurveyStateProvider: '&fieldSurveyState',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete',
      canSubmitFn: '&canSubmit',
      isNewFn: '&isNew',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldSurveyDetailsCommonTabController,
    controllerAs: 'fieldSurveyDetailsCommonTabCtrl',
    template: require('./field-survey-details-common-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyDetailsCommonTabController($scope, $timeout) {
  var fieldSurveyDetailsCommonTabCtrl = this;
  fieldSurveyDetailsCommonTabCtrl.onDirectionVentChange = onDirectionVentChange;
  fieldSurveyDetailsCommonTabCtrl.onVitesseVentChange = onVitesseVentChange;
  fieldSurveyDetailsCommonTabCtrl.onTemperatureChange = onTemperatureChange;
  fieldSurveyDetailsCommonTabCtrl.onNebulositeChange = onNebulositeChange;
  fieldSurveyDetailsCommonTabCtrl.onTypePrecipitationChange = onTypePrecipitationChange;
  fieldSurveyDetailsCommonTabCtrl.onHauteurPrecipitationChange = onHauteurPrecipitationChange;
  fieldSurveyDetailsCommonTabCtrl.onVisibiliteChange = onVisibiliteChange;
  fieldSurveyDetailsCommonTabCtrl.onChange = onChange;
  fieldSurveyDetailsCommonTabCtrl.onDelete = onDelete;
  fieldSurveyDetailsCommonTabCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsCommonTabCtrl.isNew = isNew;
  fieldSurveyDetailsCommonTabCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldSurveyDetailsCommonTabCtrl.isAutoLocatedError = isAutoLocatedError;
  fieldSurveyDetailsCommonTabCtrl.locationPrecision = locationPrecision;

  $scope.$watch(fieldSurveyDetailsCommonTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldSurveyDetailsCommonTabCtrl.data = {};
    } else {
      fieldSurveyDetailsCommonTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldSurveyDetailsCommonTabCtrl.onChange();
  });

  $scope.$watch(fieldSurveyDetailsCommonTabCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }

    fieldSurveyDetailsCommonTabCtrl.fieldSurveyState = fieldSurveyState;
  });

  $scope.$watch(fieldSurveyDetailsCommonTabCtrl.formProvider, (form) => {
    fieldSurveyDetailsCommonTabCtrl.form = form;
  });

  function onDirectionVentChange(currentDirectionVent) {
    fieldSurveyDetailsCommonTabCtrl.data.directionVent = currentDirectionVent ? currentDirectionVent.id : null;
    onChange();
  }

  function onVitesseVentChange(currentVitesseVent) {
    fieldSurveyDetailsCommonTabCtrl.data.vitesseVent = currentVitesseVent ? currentVitesseVent.id : null;
    onChange();
  }

  function onTemperatureChange(currentTemperature) {
    fieldSurveyDetailsCommonTabCtrl.data.temperature = currentTemperature ? currentTemperature.id : null;
    onChange();
  }

  function onNebulositeChange(currentNebulosite) {
    fieldSurveyDetailsCommonTabCtrl.data.nebulosite = currentNebulosite ? currentNebulosite.id : null;
    onChange();
  }

  function onTypePrecipitationChange(currentTypePrecipitation) {
    fieldSurveyDetailsCommonTabCtrl.data.typePrecipitation = currentTypePrecipitation ? currentTypePrecipitation.id : null;
    onChange();
  }

  function onHauteurPrecipitationChange(currentHauteurPrecipitations) {
    fieldSurveyDetailsCommonTabCtrl.data.hauteurPrecipitations = currentHauteurPrecipitations || null;
    onChange();
  }

  function onVisibiliteChange(currentVisibilite) {
    fieldSurveyDetailsCommonTabCtrl.data.visibilite = currentVisibilite ? currentVisibilite.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldSurveyDetailsCommonTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldSurveyDetailsCommonTabCtrl.onChangeFn()('COMMON', {
        data: {
          temperature: fieldSurveyDetailsCommonTabCtrl.data.temperature,
          vitesseVent: fieldSurveyDetailsCommonTabCtrl.data.vitesseVent,
          directionVent: fieldSurveyDetailsCommonTabCtrl.data.directionVent,
          nebulosite: fieldSurveyDetailsCommonTabCtrl.data.nebulosite,
          hauteurPrecipitations: fieldSurveyDetailsCommonTabCtrl.data.hauteurPrecipitations,
          typePrecipitation: fieldSurveyDetailsCommonTabCtrl.data.typePrecipitation,
          visibilite: fieldSurveyDetailsCommonTabCtrl.data.visibilite
        },
        formValid: isFormValid()
      });
    });
  }

  function onDelete() {
    fieldSurveyDetailsCommonTabCtrl.onDeleteFn()();
  }

  function canSubmit() {
    return fieldSurveyDetailsCommonTabCtrl.canSubmitFn()();
  }

  function isNew() {
    return fieldSurveyDetailsCommonTabCtrl.isNewFn()();
  }

  // TODO : vérifier que les champs int sont bien des valeurs entières ?
  function isFormValid() {
    return true;
  }

  function locationPrecision() {
    return fieldSurveyDetailsCommonTabCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return fieldSurveyDetailsCommonTabCtrl.isAutoLocatedValidFn() &&
           fieldSurveyDetailsCommonTabCtrl.isAutoLocatedValidFn()();
  }

  function isAutoLocatedError() {
    return fieldSurveyDetailsCommonTabCtrl.isAutoLocatedErrorFn() &&
           fieldSurveyDetailsCommonTabCtrl.isAutoLocatedErrorFn()();
  }
}
