import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-survey-current.service';
import { EVENTS as SYNC_SERVICE_EVENTS } from '../../synchro/services/study-synchronizer.service';

const EVENTS = {
  FIELD_SURVEY_CURRENT_STATE_CHANGED: 'shu-field-survey.field-survey-current-state-changed'
};

export default FieldSurveyCurrentStore;

// @ngInject
function FieldSurveyCurrentStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_CURRENT_LOADING, onFieldSurveyCurrentLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_CURRENT_LOAD_SUCCESS, onFieldSurveyCurrentLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_CURRENT_LOAD_ERROR, onFieldSurveyCurrentLoadError);
  $rootScope.$on(SYNC_SERVICE_EVENTS.STUDY_SYNC_TERMINATED, onSyncStudy);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStudy() {
    state = Immutable({});
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED);
  }

  function onFieldSurveyCurrentLoading() {
    state = Immutable({
      results: null,
      count: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_CURRENT_STATE_CHANGED);
  }

  function onFieldSurveyCurrentLoadSuccess(event, results, count) {
    state = Immutable({
      results,
      count,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_CURRENT_STATE_CHANGED);
  }

  function onFieldSurveyCurrentLoadError() {
    state = Immutable({
      results: null,
      count: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_CURRENT_STATE_CHANGED);
  }

}

export { EVENTS };
