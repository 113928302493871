import _ from 'lodash';

import { EVENTS as SYNC_SERVICE_EVENTS } from '../../synchro/services/study-synchronizer.service';

const EVENTS = {
  FAVORIS_LOADING: 'shu-favoris.favoris-loading',
  FAVORIS_LOAD_SUCCESS: 'shu-favoris.favoris-load-success',
  FAVORIS_LOAD_ERROR: 'shu-favoris.favoris-load-error',
};

export default FavorisService;

// @ngInject
function FavorisService($rootScope, FavorisStore, FavorisRepository, FavorisUtils, LxNotificationService) {

  FavorisRepository.purge();

  return {load, add, remove, removeAll, removeStudy};

  function load(studyId) {

    $rootScope.$emit(EVENTS.FAVORIS_LOADING, studyId);

    FavorisRepository.list(studyId)
      .then((favoris) => {
        $rootScope.$emit(EVENTS.FAVORIS_LOAD_SUCCESS, studyId, favoris);
      }, () => {
        LxNotificationService.error('Une erreur est survenue au chargement des favoris.');
        $rootScope.$emit(EVENTS.FAVORIS_LOAD_ERROR);
      });
  }

  function save(studyId, favoris) {

    $rootScope.$emit(EVENTS.FAVORIS_LOADING, studyId);

    FavorisRepository.save(studyId, favoris)
      .then(() => {
        $rootScope.$emit(EVENTS.FAVORIS_LOAD_SUCCESS, studyId, favoris);
      }, () => {
        LxNotificationService.error('Une erreur est survenue à la gestion chargement des favoris.');
        $rootScope.$emit(EVENTS.FAVORIS_LOAD_ERROR);
      });
  }

  function add(studyId, favori) {
    var favoris = _.clone(FavorisStore.getState(studyId).favoris);
    if (!FavorisUtils.isFavori(favori, favoris)) {
      favoris.push(favori);
    }
    save(studyId, favoris);
  }

  function remove(studyId, favori) {
    var favoris = _.clone(FavorisStore.getState(studyId).favoris);
    if (FavorisUtils.isFavori(favori, favoris)) {
      favoris = _.filter(favoris, (f) => !FavorisUtils.equals(f, favori));
    }
    save(studyId, favoris);
  }
  function removeAll(studyId, favorisList) {
    var favoris = _.clone(FavorisStore.getState(studyId).favoris);

    _.forEach(favorisList, (favori) => {
      if (FavorisUtils.isFavori(favori, favoris)) {
        favoris = _.filter(favoris, (f) => !FavorisUtils.equals(f, favori));
      }
    });
    save(studyId, favoris);
  }

  function removeStudy(studyId) {
    FavorisRepository.remove(studyId);
  }
}
export { EVENTS };
