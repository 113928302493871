import { STUDY_STATUS } from '../model';

export default StudyStatusFilter;

// @ngInject
function StudyStatusFilter() {
  return function(input) {
    switch (input) {
      case STUDY_STATUS.INITIALIZED:
        return 'Initialisé';
      case STUDY_STATUS.ACTIVE:
        return 'Actif';
      case STUDY_STATUS.ARCHIVED:
        return 'Archivé';
      default:
        return 'Tous';
    }
  };
}

