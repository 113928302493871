import { EXTRA_TABLE } from '../model';

export default FieldRecordExtraTableFilter;

// @ngInject
function FieldRecordExtraTableFilter() {
  return function(input) {
    switch (input) {
      case EXTRA_TABLE.LS_AMPHIBIA:
        return 'Amphibiens';
      case EXTRA_TABLE.LS_BIRDS:
        return 'Oiseaux';
      case EXTRA_TABLE.LS_FISHES:
        return 'Poissons';
      case EXTRA_TABLE.LS_INSECTS_ODO:
        return 'Insectes odonates';
      case EXTRA_TABLE.LS_INSECTS_ORTHO:
        return 'Insectes orthoptères';
      case EXTRA_TABLE.LS_INSECTS_OTHER:
        return 'Autres insectes';
      case EXTRA_TABLE.LS_INSECTS_RHO:
        return 'Insectes rhopalocères';
      case EXTRA_TABLE.LS_MAMMALS:
        return 'Mammifères terrestres';
      case EXTRA_TABLE.LS_MOLLUSCS:
        return 'Mollusques';
      case EXTRA_TABLE.LS_PLANTS:
        return 'Plantes';
      case EXTRA_TABLE.LS_REPTILES:
        return 'Reptiles';
      case EXTRA_TABLE.RI_AMPHIBIA:
        return 'Amphibiens';
      case EXTRA_TABLE.RI_BIRDS:
        return 'Oiseaux';
      case EXTRA_TABLE.RI_FISHES:
        return 'Poissons';
      case EXTRA_TABLE.RI_INSECTS_ODO:
        return 'Insectes odonates';
      case EXTRA_TABLE.RI_INSECTS_ORTHO:
        return 'Insectes orthoptères';
      case EXTRA_TABLE.RI_INSECTS_OTHER:
        return 'Autres insectes';
      case EXTRA_TABLE.RI_INSECTS_RHO:
        return 'Insectes rhopalocères';
      case EXTRA_TABLE.RI_MAMMALS:
        return 'Mammifères terrestres';
      case EXTRA_TABLE.RI_MOLLUSCS:
        return 'Mollusques';
      case EXTRA_TABLE.RI_PLANTS:
        return 'Plantes';
      case EXTRA_TABLE.RI_REPTILES:
        return 'Reptiles';
      case EXTRA_TABLE.RP_PLANTS:
        return 'Plantes';
      case EXTRA_TABLE.RA_BATS:
        return 'Chiroptères';
      case EXTRA_TABLE.GENETIC_SAMPLING:
        return 'Prélèvement génétique'      
      default:
        return '-';
    }
  };
}
