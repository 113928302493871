import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-field-surveys.service';
import { EVENTS as SYNC_SERVICE_EVENTS } from '../../synchro/services/study-synchronizer.service';

const EVENTS = {
  STUDY_FIELD_SURVEYS_STATE_CHANGED: 'shu-field-survey.study-field-survey-state-changed'
};

export default StudyFieldSurveysStore;

// @ngInject
function StudyFieldSurveysStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_SURVEYS_LOADING, onStudyFieldSurveysLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_SURVEYS_LOAD_SUCCESS, onStudyFieldSurveysLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_FIELD_SURVEYS_LOAD_ERROR, onStudyFieldSurveysLoadError);
  $rootScope.$on(SYNC_SERVICE_EVENTS.STUDY_SYNC_TERMINATED, onSyncStudy);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStudy() {
    state = Immutable({});
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED);
  }

  function onStudyFieldSurveysLoading() {
    state = Immutable({
      fieldSurveyList: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_FIELD_SURVEYS_STATE_CHANGED);
  }

  function onStudyFieldSurveysLoadSuccess(event, fieldSurveyList) {
    state = Immutable({
      fieldSurveyList,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_FIELD_SURVEYS_STATE_CHANGED);
  }

  function onStudyFieldSurveysLoadError() {
    state = Immutable({
      fieldSurveyList: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_FIELD_SURVEYS_STATE_CHANGED);
  }
}

export { EVENTS };
