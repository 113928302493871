export default TxGroupRepository;

// @ngInject
function TxGroupRepository(TxGroupConfiguration, $http) {
  return {list};

  function list() {
    return $http({
      method: 'GET',
      url: TxGroupConfiguration.txGroupsEndpoint
    }).then((response) => response.data);
  }
}
