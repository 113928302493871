import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-survey-field-records-rp.service';

const EVENTS = {
  FIELD_SURVEY_FIELD_RECORDS_RP_STATE_CHANGED: 'shu-field-record.field-survey-field-record-rp-state-changed'
};

export default FieldSurveyFieldRecordsStore;

// @ngInject
function FieldSurveyFieldRecordsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOADING, onFieldSurveyFieldRecordsLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_SUCCESS, onFieldSurveyFieldRecordsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_LOAD_ERROR, onFieldSurveyFieldRecordsLoadError);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_PROCESSING, onFieldRecordProcessing);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_PROCESS_ERROR, onFieldRecordProcessError);

  return {getState};

  function getState() {
    return state;
  }

  function onFieldSurveyFieldRecordsLoading() {
    state = Immutable({
      fieldRecordList: null,
      loading: true,
      processing: false,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_STATE_CHANGED);
  }

  function onFieldSurveyFieldRecordsLoadSuccess(event, fieldRecordList) {
    state = Immutable({
      fieldRecordList,
      loading: false,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_STATE_CHANGED);
  }

  function onFieldSurveyFieldRecordsLoadError() {
    state = Immutable({
      fieldRecordList: null,
      loading: false,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_STATE_CHANGED);
  }

  function onFieldRecordProcessing() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_STATE_CHANGED);
  }

  function onFieldRecordProcessError() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: false,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_RP_STATE_CHANGED);
  }
}

export { EVENTS };
