import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-survey-field-records.service';
import { EVENTS as HEADCOUNT_SERVICE_EVENTS } from '../services/field-record-headcount.service';
import { EVENTS as VALIDATE_SERVICE_EVENTS } from '../services/field-record-validate.service';

const EVENTS = {
  FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED: 'shu-field-record.field-survey-field-record-state-changed'
};

export default FieldSurveyFieldRecordsStore;

// @ngInject
function FieldSurveyFieldRecordsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_LOADING, onFieldSurveyFieldRecordsLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_LOAD_SUCCESS, onFieldSurveyFieldRecordsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_LOAD_ERROR, onFieldSurveyFieldRecordsLoadError);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_PROCESSING, onFieldRecordProcessing);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_FIELD_RECORDS_PROCESS_ERROR, onFieldRecordProcessError);
  $rootScope.$on(HEADCOUNT_SERVICE_EVENTS.FIELD_RECORD_HEADCOUNT_PROCESSING_SUCCESS, onFieldRecordHeadcountProcessingSuccess);
  $rootScope.$on(HEADCOUNT_SERVICE_EVENTS.FIELD_RECORD_HEADCOUNT_PROCESSING_ERROR, onFieldRecordHeadcountProcessingError);
  $rootScope.$on(HEADCOUNT_SERVICE_EVENTS.FIELD_RECORD_HEADCOUNT_PROCESSING, onFieldRecordHeadcountProcessing);

  $rootScope.$on(VALIDATE_SERVICE_EVENTS.FIELD_RECORD_VALIDATE_PROCESSING_SUCCESS, onFieldRecordValidateProcessingSuccess);
  $rootScope.$on(VALIDATE_SERVICE_EVENTS.FIELD_RECORD_VALIDATE_PROCESSING_ERROR, onFieldRecordValidateProcessingError);
  $rootScope.$on(VALIDATE_SERVICE_EVENTS.FIELD_RECORD_VALIDATE_PROCESSING, onFieldRecordValidateProcessing);

  return {getState};

  function getState() {
    return state;
  }

  function onFieldSurveyFieldRecordsLoading() {
    state = Immutable({
      fieldRecordList: null,
      loading: true,
      processing: false,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldSurveyFieldRecordsLoadSuccess(event, fieldRecordList) {
    state = Immutable({
      fieldRecordList,
      loading: false,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldSurveyFieldRecordsLoadError() {
    state = Immutable({
      fieldRecordList: null,
      loading: false,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordHeadcountProcessingSuccess(event, fieldRecordId, txHeadcount) {
    state = Immutable({
      fieldRecordList: _.map(state.fieldRecordList, (fieldRecord) => {
        if (fieldRecord.id !== fieldRecordId) {
          return fieldRecord;
        }
        return _.defaults({txHeadcount}, fieldRecord);
      }),
      loading: false,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordHeadcountProcessingError() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: false,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordHeadcountProcessing() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: true,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordValidateProcessingSuccess(event, fieldRecordId, txHeadcount) {
    state = Immutable({
      fieldRecordList: _.map(state.fieldRecordList, (fieldRecord) => {
        if (fieldRecord.id !== fieldRecordId) {
          return fieldRecord;
        }
        return _.defaultsDeep({txHeadcount}, fieldRecord);
      }),
      loading: false,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordValidateProcessingError() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: false,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordValidateProcessing() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: true,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordProcessing() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }

  function onFieldRecordProcessError() {
    state = Immutable({
      fieldRecordList: state.fieldRecordList,
      loading: false,
      processing: false,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_FIELD_RECORDS_STATE_CHANGED);
  }
}

export { EVENTS };
