import surveyTypeListStore from './stores/survey-type-list.store';
import surveyTypeListService from './services/survey-type-list.service';
import surveyTypeDetailsContainerComponent from './components/survey-type-details-container/survey-type-details-container.component';
import surveyTypeNewContainerComponent from './components/survey-type-new-container/survey-type-new-container.component';
import surveyTypeDetailsComponent from './components/survey-type-details/survey-type-details.component';
import selectStudySurveyTypeComponent from './components/select-study-survey-type/select-study-survey-type.component';
import surveyTypeDetailsStore from './stores/survey-type-details.store';
import surveyTypeDetailsService from './services/survey-type-details.service';
import remoteSurveyTypeRepository from './repositories/survey-type.remote.repository';
import localSurveyTypeRepository from './repositories/survey-type.local.repository';
import routerConfig from './routes';
import surveyTypeConfiguration from './configuration';

const moduleName = 'shu-survey-type';

angular.module(moduleName, ['lumx'])
  .directive('shuSurveyTypeDetailsContainer', surveyTypeDetailsContainerComponent)
  .directive('shuSurveyTypeNewContainer', surveyTypeNewContainerComponent)
  .directive('shuSurveyTypeDetails', surveyTypeDetailsComponent)
  .directive('shuSelectStudySurveyType', selectStudySurveyTypeComponent)
  .factory('SurveyTypeListStore', surveyTypeListStore)
  .factory('SurveyTypeListService', surveyTypeListService)
  .factory('SurveyTypeDetailsStore', surveyTypeDetailsStore)
  .factory('SurveyTypeDetailsService', surveyTypeDetailsService)
  .factory('SurveyTypeRepository', IS_CORDOVA ? localSurveyTypeRepository : remoteSurveyTypeRepository)
  .constant('SurveyTypeConfiguration', surveyTypeConfiguration)
  .config(routerConfig);

export default {moduleName};
