import _ from 'lodash';

require('./field-survey-creation-dialog.scss');

export default FieldSurveyCreationDialogDirective;

// @ngInject
function FieldSurveyCreationDialogDirective() {
  return {
    restrict: 'E',
    scope: {
      studyIdProvider: '&studyId',
      dialogId: '@',
      onSubmitFn: '&onSubmit'
    },
    controller: FieldSurveyCreationDialogController,
    controllerAs: 'fieldSurveyCreationDialogCtrl',
    bindToController: true,
    template: require('./field-survey-creation-dialog.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyCreationDialogController($scope) {
  var fieldSurveyCreationDialogCtrl = this;

  // Il n'est pas nécessaire de surveiller ce provider
  fieldSurveyCreationDialogCtrl.studyId = fieldSurveyCreationDialogCtrl.studyIdProvider();

  fieldSurveyCreationDialogCtrl.onSubmit = onSubmit;
  fieldSurveyCreationDialogCtrl.onSurveyTypeChanged = onSurveyTypeChanged;

  function onSubmit() {
    fieldSurveyCreationDialogCtrl.onSubmitFn() && fieldSurveyCreationDialogCtrl.onSubmitFn()(fieldSurveyCreationDialogCtrl.surveyTypeId);
  }

  function onSurveyTypeChanged({id}) {
    fieldSurveyCreationDialogCtrl.surveyTypeId = id;
  }
}
