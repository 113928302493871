export default MapBackgroundRepository;

// @ngInject
function MapBackgroundRepository(MapConfiguration, $http, $q) {
  return {get};

  function get() {
    return $http({
      method: 'GET',
      url: MapConfiguration.mapBackgroundEndpoint
    }).then((response) => response.data);
  }

}
