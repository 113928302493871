import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../../stores/study-map.store';
import { EVENTS as STUDY_EXPERT_LAYER_STORE_EVENTS } from '../../stores/study-expert-layer.store';
import { EVENTS as GRID_STORE_EVENTS } from '../../stores/study-grid.store';
import { EVENTS as FIELD_RECORD_LIST_GEOM_STORE_EVENTS } from '../../../field-record/stores/field-record-list-map.store';
import { EVENTS as FIELD_SURVEY_LIST_GEOM_STORE_EVENTS } from '../../../field-survey/stores/field-survey-list-map.store';
import { LAYERS_CONFIG } from '../../../map/configuration';
import { APPLICATION_KEY } from '../../model.js';
import StudyConfiguration from '../../configuration';

require('./study-grid.scss');

export default StudyGridDirective;

// @ngInject
function StudyGridDirective() {
  return {
    restrict: 'E',
    scope: {studyId: '@'},
    controller: StudyGridController,
    controllerAs: 'studyGridCtrl',
    bindToController: true,
    template: require('./study-grid.html'),
    replace: true
  };
}

// @ngInject
function StudyGridController($scope, $state, $rootScope, StudyMapStore, StudyMapService, StudyGridService, StudyGridStore,
            HeaderService, FieldRecordListMapStore, FieldRecordListMapService, $timeout,
            FieldSurveyListMapStore, FieldSurveyListMapService, StudyExpertLayerService, StudyExpertLayerStore) {
  var studyGridCtrl = this;

  studyGridCtrl.onLayerChanged = StudyMapService.saveCurrentLayer;
  studyGridCtrl.isLoaded = isLoaded;
  studyGridCtrl.onLocalLayerChanged = onLocalLayerChanged;

  studyGridCtrl.noGeoms = true;
  studyGridCtrl.layersInitialized = false;
  studyGridCtrl.nbSelected = 0;
  studyGridCtrl.onSubmit = onSubmit;

  $scope.$on(STORE_EVENTS.STUDY_MAP_STATE_CHANGED, onStateChanged);
  $scope.$on(GRID_STORE_EVENTS.STUDY_GRID_STATE_CHANGED, onStateChanged);
  $scope.$on(STUDY_EXPERT_LAYER_STORE_EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED, onStateChanged);

  $scope.$on(FIELD_RECORD_LIST_GEOM_STORE_EVENTS.FIELD_RECORD_LIST_MAP_STATE_CHANGED, onStateChanged);
  $scope.$on(FIELD_SURVEY_LIST_GEOM_STORE_EVENTS.FIELD_SURVEY_LIST_MAP_STATE_CHANGED, onStateChanged);

  load();

  function onLocalLayerChanged(layerId) {
    StudyMapService.saveLayerVisibility(studyGridCtrl.studyId, layerId);
  }

  function isLoaded() {

    return studyGridCtrl.state && studyGridCtrl.state.loaded &&
        studyGridCtrl.fieldSurveyListGeomState && studyGridCtrl.fieldSurveyListGeomState.loaded &&
        studyGridCtrl.gridState && studyGridCtrl.gridState.loaded &&
        studyGridCtrl.layersState && studyGridCtrl.layersState.loaded;
  }

  function onStateChanged() {
    studyGridCtrl.state = StudyMapStore.getState();
    studyGridCtrl.fieldSurveyListGeomState = FieldSurveyListMapStore.getState();
    studyGridCtrl.gridState = StudyGridStore.getState();
    studyGridCtrl.layersState = StudyExpertLayerStore.getState();

    if (!isLoaded() || studyGridCtrl.layersInitialized) {
      return;
    }
    studyGridCtrl.layers = [];
    studyGridCtrl.layersInitialized = true;

    if (studyGridCtrl.layersState.loaded && studyGridCtrl.layersState.layers) {
      studyGridCtrl.layers = studyGridCtrl.layers.concat(
        StudyExpertLayerService.buildLayerList(studyGridCtrl.layersState, studyGridCtrl.state.study.id));
    }

    if (studyGridCtrl.state.loaded && studyGridCtrl.state.study.studyBoundary) {
      studyGridCtrl.layers.push({
          name: 'Aire d\'étude',
          config: LAYERS_CONFIG.STUDY,
          fitBounds: true,
          features:
            [_.defaults({
              properties: {name: studyGridCtrl.state.study.key}
            }, studyGridCtrl.state.study.studyBoundary)]
        });
    }
    if (studyGridCtrl.fieldSurveyListGeomState.fieldSurveys.length) {
      studyGridCtrl.layers.push({
        name: 'Relevés',
        config: LAYERS_CONFIG.FIELD_SURVEY,
        fitBounds: true,
        features:
          _.map(studyGridCtrl.fieldSurveyListGeomState.fieldSurveys,
            (fieldSurvey) => _.defaults({
              properties: {studyId: studyGridCtrl.studyId,
                fieldSurveyId: fieldSurvey.id,
                name: fieldSurvey.name},
            }, fieldSurvey.geometry)
          )
      });
    }
    if (studyGridCtrl.gridState.loaded && studyGridCtrl.gridState.grid) {
      studyGridCtrl.gridFeatures = _.cloneDeep(studyGridCtrl.gridState.grid);
      studyGridCtrl.layers.push({
        name: 'Grille',
        config: _.merge({
          onEachFeature: studyGridCtrl.onEachGridZone
        }, LAYERS_CONFIG.GRID),
        fitBounds: false,
        features: studyGridCtrl.gridFeatures
      });
      updateNbSelected();
      studyGridCtrl.noGeoms = false;
    }
    if (studyGridCtrl.state.study) {
      HeaderService.updateTitle([
        {
          label: studyGridCtrl.state.study.key,
          link: {
            sref: 'study-details',
            params: {studyId: studyGridCtrl.studyId}
          }
        },
        'grille de sélection'
      ]);
    }
  }

  studyGridCtrl.onEachGridZone = function(feature, layer) {
    layer.on({
      click: (e) => {
        e.target.feature.properties.selected = !e.target.feature.properties.selected;
        e.target.setStyle(LAYERS_CONFIG.GRID.style(e.target.feature));
        // Refresh du nombre sélectionné, on utilise le timeout sinon leafletjs
        // coupe des fois le binding avec le modèle.
        $timeout(updateNbSelected);
      }
    });
  };

  studyGridCtrl.gridSelected = function() {
    return _.chain(studyGridCtrl.gridFeatures)
               .filter((feature) => feature.properties.selected)
               .map((feature) => feature.geometry)
               .value();
  };

  function updateNbSelected() {
    studyGridCtrl.nbSelected = studyGridCtrl.gridSelected().length;
  }

  function onSubmit() {
    if (!studyGridCtrl.state.loaded) {
      return;
    }
    StudyGridService.saveGrid(studyGridCtrl.studyId, studyGridCtrl.gridSelected());
  }

  function load() {
    StudyMapService.load(studyGridCtrl.studyId);
    StudyGridService.loadGrid(studyGridCtrl.studyId);
    FieldRecordListMapService.loadForStudy(studyGridCtrl.studyId);
    FieldSurveyListMapService.loadForStudy(studyGridCtrl.studyId);
    StudyExpertLayerService.load(studyGridCtrl.studyId);
  }
}
