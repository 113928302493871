import angular from 'angular';

import selectProtocolComponent from './components/select-protocol/select-protocol.component.js';
import protocolService from './services/protocol.service';
import protocolListService from './services/protocol-list.service';
import protocolStore from './stores/protocol.store';
import protocolListStore from './stores/protocol-list.store';
import remoteProtocolRepository from './repositories/protocol.remote.repository';
import localProtocolRepository from './repositories/protocol.local.repository';
import protocolConfiguration from './configuration';

const moduleName = 'shu-protocol';

angular.module(moduleName, ['lumx'])
  .directive('shuSelectProtocol', selectProtocolComponent)
  .factory('ProtocolRepository', IS_CORDOVA ? localProtocolRepository : remoteProtocolRepository)
  .factory('ProtocolService', protocolService)
  .factory('ProtocolListService', protocolListService)
  .factory('ProtocolStore', protocolStore)
  .factory('ProtocolListStore', protocolListStore)
  .constant('ProtocolConfiguration', protocolConfiguration);

export default {moduleName};
