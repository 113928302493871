const EVENTS = {
  // Actions lancées quand l'utilisateur charge les biotope-refs
  BIOTOPE_REF_MAP_RESULTS_LOADING: 'shu-biotope-ref.biotope-ref-map-results-loading',
  BIOTOPE_REF_MAP_RESULTS_LOAD_SUCCESS: 'shu-biotope-ref.biotope-ref-map-results-load-success',
  BIOTOPE_REF_MAP_RESULTS_LOAD_ERROR: 'shu-biotope-ref.biotope-ref-map-results-load-error',
};

export default BiotopeRefMapService;

// @ngInject
function BiotopeRefMapService($rootScope, BiotopeRefMapStore, BiotopeRefRepository, LxNotificationService) {
  return {load};

  function load(table) {
    if (BiotopeRefMapStore.getState(table) && BiotopeRefMapStore.getState(table).loaded) {
      $rootScope.$emit(EVENTS.BIOTOPE_REF_MAP_RESULTS_LOAD_SUCCESS, table, BiotopeRefMapStore.getState(table).results);
      return;
    }

    $rootScope.$emit(EVENTS.BIOTOPE_REF_MAP_RESULTS_LOADING);

    BiotopeRefRepository.list(table)
      .then((result) => {
        $rootScope.$emit(EVENTS.BIOTOPE_REF_MAP_RESULTS_LOAD_SUCCESS, table, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des biotope-refs. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.BIOTOPE_REF_MAP_RESULTS_LOAD_ERROR, table);
      });
  }
}
export { EVENTS };
