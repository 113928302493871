import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../stores/taxon-ref-filter.store';

export default TxRefFilterKeyFilter;

// @ngInject
function TxRefFilterKeyFilter($rootScope, TaxonRefFilterStore, TaxonSearchService) {

  var state = TaxonRefFilterStore.getState();

  TaxonSearchService.loadTxRefAllFilters();
  $rootScope.$on(STORE_EVENTS.TX_REF_FILTER_STATE_CHANGED, () => { state = TaxonRefFilterStore.getState(); });
  function filter(input) {
    if (!state.loaded || state.error) {
      return input;
    }
    return _.chain(state.availableFilters).find({key: input}).get('name').value() || input;
  }
  filter.$stateful = true;
  return filter;
}
