import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-survey-map.service';

const EVENTS = {
  FIELD_SURVEY_MAP_STATE_CHANGED: 'shu-field-survey.field-survey-map-state-changed'
};

export default FieldSurveyMapStore;

// @ngInject
function FieldSurveyMapStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_MAP_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_MAP_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_MAP_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      fieldSurvey: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_MAP_STATE_CHANGED);
  }

  function onLoadSuccess(event, fieldSurvey) {
    state = Immutable({
      fieldSurvey,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_MAP_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      fieldSurvey: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_MAP_STATE_CHANGED);
  }
}

export { EVENTS };
