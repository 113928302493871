import _ from 'lodash';

require('./field-record-details-ls-reptiles-tab.scss');

export default FieldRecordDetailsLsReptilesTabDirective;

// @ngInject
function FieldRecordDetailsLsReptilesTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsReptilesTabController,
    controllerAs: 'fieldRecordDetailsLsReptilesTabCtrl',
    template: require('./field-record-details-ls-reptiles-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsReptilesTabController($scope, $timeout) {
  var fieldRecordDetailsLsReptilesTabCtrl = this;
  fieldRecordDetailsLsReptilesTabCtrl.onModeContactChanged = onModeContactChanged;
  fieldRecordDetailsLsReptilesTabCtrl.onSexeChanged = onSexeChanged;
  fieldRecordDetailsLsReptilesTabCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsLsReptilesTabCtrl.onComportementChanged = onComportementChanged;
  fieldRecordDetailsLsReptilesTabCtrl.onProbabiliteReproductionChanged = onProbabiliteReproductionChanged;
  fieldRecordDetailsLsReptilesTabCtrl.onTypeIndicePresenceChanged = onTypeIndicePresenceChanged;
  fieldRecordDetailsLsReptilesTabCtrl.onChange = onChange;
  fieldRecordDetailsLsReptilesTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsReptilesTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsReptilesTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsReptilesTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsReptilesTabCtrl.isNew = isNew;
  fieldRecordDetailsLsReptilesTabCtrl.isReleveItinerant = isReleveItinerant;

  $scope.$watch(fieldRecordDetailsLsReptilesTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsReptilesTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsReptilesTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsLsReptilesTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsReptilesTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsReptilesTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsReptilesTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsReptilesTabCtrl.form = form;
  });

  function onModeContactChanged(modeContact) {
    fieldRecordDetailsLsReptilesTabCtrl.data.modeContact = modeContact ? modeContact.id : null;
    onChange();
  }

  function onSexeChanged(sexe) {
    fieldRecordDetailsLsReptilesTabCtrl.data.sexe = sexe ? sexe.id : null;
    onChange();
  }

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    fieldRecordDetailsLsReptilesTabCtrl.data.stadeDeveloppement = stadeDeveloppement ? stadeDeveloppement.id : null;
    onChange();
  }

  function onComportementChanged(comportement) {
    fieldRecordDetailsLsReptilesTabCtrl.data.comportement = comportement ? comportement.id : null;
    onChange();
  }

  function onProbabiliteReproductionChanged(probabiliteReproduction) {
    fieldRecordDetailsLsReptilesTabCtrl.data.probabiliteReproduction = probabiliteReproduction ? probabiliteReproduction.id : null;
    onChange();
  }

  function onTypeIndicePresenceChanged(typeIndicePresence) {
    fieldRecordDetailsLsReptilesTabCtrl.data.typeIndicePresence = typeIndicePresence ? typeIndicePresence.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsReptilesTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsReptilesTabCtrl.onChangeFn()(fieldRecordDetailsLsReptilesTabCtrl.extraTableEnum, {
        data: {
          modeContact: fieldRecordDetailsLsReptilesTabCtrl.data.modeContact,
          sexe: fieldRecordDetailsLsReptilesTabCtrl.data.sexe,
          stadeDeveloppement: fieldRecordDetailsLsReptilesTabCtrl.data.stadeDeveloppement,
          comportement: fieldRecordDetailsLsReptilesTabCtrl.data.comportement,
          probabiliteReproduction: fieldRecordDetailsLsReptilesTabCtrl.data.probabiliteReproduction,
          typeIndicePresence: fieldRecordDetailsLsReptilesTabCtrl.data.typeIndicePresence
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsReptilesTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsReptilesTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsReptilesTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsReptilesTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsReptilesTabCtrl.isNewFn()();
  }

  function isReleveItinerant() {
    return fieldRecordDetailsLsReptilesTabCtrl.extraTableEnum === 'RI_REPTILES';
  }

  function isFormValid() {
    return true;
  }
}
