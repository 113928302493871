export default SurveyTypeRepository;

// @ngInject
function SurveyTypeRepository(SurveyTypeConfiguration, $http) {
  return {list, getSurveyType, updateSurveyType, createSurveyType, deleteSurveyType};

  function list(studyId) {
    return $http({
      method: 'GET',
      url: SurveyTypeConfiguration.surveyTypeListEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function getSurveyType(surveyTypeId) {
    return $http({
      method: 'GET',
      url: SurveyTypeConfiguration.surveyTypeDetailsEndpoint,
      pathParams: {surveyTypeId},
    }).then((response) => response.data);
  }

  function deleteSurveyType(surveyTypeId) {
    return $http({
      method: 'DELETE',
      url: SurveyTypeConfiguration.surveyTypeDetailsEndpoint,
      pathParams: {surveyTypeId},
    }).then((response) => response.data);
  }

  function updateSurveyType(surveyTypeId, surveyTypeForm) {
    return $http({
      method: 'PUT',
      url: SurveyTypeConfiguration.surveyTypeDetailsEndpoint,
      pathParams: {surveyTypeId},
      data: surveyTypeForm
    }).then((response) => response.data);
  }

  function createSurveyType(surveyTypeForm) {
    return $http({
      method: 'POST',
      url: SurveyTypeConfiguration.surveyTypeCreateEndpoint,
      data: surveyTypeForm
    }).then((response) => response.data);
  }
}
