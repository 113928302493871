import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-survey-clonage.service';

const EVENTS = {
  FIELD_SURVEY_CLONAGE_STATE_CHANGED: 'shu-field-survey.field-survey-cloange-state-changed'
};

export default FieldSurveyClonageStore;

// @ngInject
function FieldSurveyClonageStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_CLONAGE_LOADING, onFieldSurveyClonageLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_CLONAGE_LOAD_SUCCESS, onFieldSurveyClonageLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_CLONAGE_LOAD_ERROR, onFieldSurveyClonageLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onFieldSurveyClonageLoading() {
    state = Immutable({
      fieldSurvey: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_CLONAGE_STATE_CHANGED);
  }

  function onFieldSurveyClonageLoadSuccess(event, fieldSurvey) {
    state = Immutable({
      fieldSurvey,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_CLONAGE_STATE_CHANGED);
  }

  function onFieldSurveyClonageLoadError() {
    state = Immutable({
      fieldSurvey: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_CLONAGE_STATE_CHANGED);
  }
}

export { EVENTS };
