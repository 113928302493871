import _ from 'lodash';

import SimpleMime from 'simple-mime';

export default MediaRepository;

// @ngInject
function MediaRepository(DatabaseProvider, StorageService, UrlResolverService, MediaConfiguration, $q, $cordovaFileTransfer, $cordovaFileOpener2) {
  return {uploadMedia, openMedia, checkAndGetMediaLocalPath, getMediaHref, getLocalPath};

  function uploadMedia(fileUri) {
    var index = fileUri.lastIndexOf('/');
    var fileName = index >= 0 ? fileUri.substr(index + 1) : fileUri;
    var fileType = SimpleMime('application/octet-stream')(fileUri);

    return DatabaseProvider.getDatabase()
      .then((database) => {
        var now = new Date().getTime() / 1000;
        return StorageService.executeSqlQuery(
          database,
          'INSERT INTO media (mime_type, filename, created, updated) VALUES (?, ?, ?, ?)',
          [fileType, fileName, now, now]
        ).then((mediaId) => {
          return $q((resolve, reject) => {
            document.addEventListener('deviceready', () => {
              window.resolveLocalFileSystemURL('file://' + fileUri, (fileEntry) => {
                window.resolveLocalFileSystemURL(cordova.file.externalDataDirectory, (directory) => {
                  fileEntry.copyTo(directory, `${ mediaId }.${ fileName }`, resolve, reject);
                }, reject);
              }, reject);
            });
          }).then(() => StorageService.executeSqlQuery(database, `
            SELECT id, mime_type as mimeType, filename, created, updated, deleted
            FROM media
            WHERE id = ?
          `, [mediaId]))
          .then(([ media ]) => {
            return checkAndGetMediaLocalPath(media).then((localPath) => _.merge({localPath}, media));
          });
        });
      });
  }

  function openMedia(media) {
    if (media.href) {
      let localPath = getLocalPath(media);
      return $cordovaFileTransfer.download(media.href, localPath, {}, false)
          .then(() => {
            // On modifie l'objet pour ne plus avoir à retélécharger le fichier dans le cas où l'utilisateur resterait sur la même page
            // (et ne rechargerait donc pas ses données)
            media.href = null;
            media.localPath = localPath;

            $cordovaFileOpener2.open(localPath, media.mimeType);
          });
    }
    return $cordovaFileOpener2.open(media.localPath, media.mimeType);
  }

  function checkAndGetMediaLocalPath(media) {
    var localPath = getLocalPath(media);
    return checkFileExists(localPath).then(() => localPath);
  }

  function getLocalPath(media) {
    return `${ cordova.file.externalDataDirectory }${ media.id }.${ media.filename }`;
  }

  function getMediaHref(media) {
    return UrlResolverService.resolveUrl(MediaConfiguration.downloadMediaEndpoint, {mediaId: StorageService.prepareId(media.id)});
  }

  function checkFileExists(path) {
    return $q((resolve, reject) => {
      document.addEventListener('deviceready', () => {
        window.resolveLocalFileSystemURL(path, resolve, reject);
      });
    });
  }
}
