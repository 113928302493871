require('./select-study-survey-type.scss');

export default SelectStudySurveyTypeDirective;

// @ngInject
function SelectStudySurveyTypeDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      placeholder: '@',
      onChangeFn: '&onChange',
      name: '@',
      requiredProvider: '&required'
    },
    bindToController: true,
    controller: SelectStudySurveyTypeController,
    controllerAs: 'selectStudySurveyTypeCtrl',
    template: require('./select-study-survey-type.html'),
    replace: true
  };
}

// @ngInject
function SelectStudySurveyTypeController($scope, SurveyTypeListStore) {
  var selectStudySurveyTypeCtrl = this;

  selectStudySurveyTypeCtrl.currentValue = null;

  // On ne charge pas les données via le service car cela amènerait un conflit avec le composant parent
  selectStudySurveyTypeCtrl.availableValues = SurveyTypeListStore.getState().results;

  selectStudySurveyTypeCtrl.onChange = onChange;

  $scope.$watch(selectStudySurveyTypeCtrl.requiredProvider, (required) => {
    selectStudySurveyTypeCtrl.required = required;
  });

  function onChange(newValue) {
    if (!newValue) {
      return;
    }
    selectStudySurveyTypeCtrl.onChangeFn() && selectStudySurveyTypeCtrl.onChangeFn()(newValue);
  }
}
