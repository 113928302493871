import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/protocol.service';

const EVENTS = {
  PROTOCOL_STATE_CHANGED: 'shu-protocol.protocol-state-changed'
};

export default ProtocolStore;

// @ngInject
function ProtocolStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.PROTOCOL_LOADING, onProtocolLoading);
  $rootScope.$on(SERVICE_EVENTS.PROTOCOL_LOAD_SUCCESS, onProtocolLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.PROTOCOL_LOAD_ERROR, onProtocolLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onProtocolLoading() {
    state = Immutable({
      protocol: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PROTOCOL_STATE_CHANGED);
  }

  function onProtocolLoadSuccess(event, protocol) {
    state = Immutable({
      protocol,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.PROTOCOL_STATE_CHANGED);
  }

  function onProtocolLoadError() {
    state = Immutable({
      protocol: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.PROTOCOL_STATE_CHANGED);
  }

}

export { EVENTS };
