export default TxGroupRepository;

// @ngInject
function TxGroupRepository(DatabaseProvider, StorageService) {

  return {list};

  function list() {

    return DatabaseProvider.getDefaultReferentielDatabase()
      .then((database) => {
        return StorageService.executeSqlQuery(database, 'SELECT * FROM tx_group', []);
      });
  }
}
