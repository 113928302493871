import _ from 'lodash';
import Leaflet from 'leaflet';

require('./marker-icon.png');
require('./marker-icon-2x.png');
require('./marker-shadow.png');

var KML = Leaflet.FeatureGroup.extend({
  options: {
    async: true,
    pane: 'kml',
    headers: {}
  },

  initialize: function(kml, options) {
    Leaflet.setOptions(this, options);
    this._kml = kml;
    this._layers = {};

    if (kml) {
      // TODO: meilleure gestion des erreurs
      this.addKML(kml, options, this.options.async, this.options.pane);
    }
  },

  loadRemoteXML: function(urlPromise, cb, options, async) {
    if (!async) {
      async = this.options.async;
    }
    if (!options) {
      options = this.options;
    }

    urlPromise.then((url) => {
      var req = new window.XMLHttpRequest();
      req.open('GET', url, async);
      _.forOwn(options.headers, function(value, key) {
        req.setRequestHeader(key, value);
      });
      try {
        req.overrideMimeType('text/xml');
      } catch (e) {}
      req.onreadystatechange = function() {
        if (req.readyState !== 4) {
          return;
        }
        if (req.status === 200) {
          cb(req.responseXML, options);
        }
      };
      req.send(null);
    });
  },
  loadLocalXML: function(path, cb, options, async) {
    if (!async) {
      async = this.options.async;
    }
    if (!options) {
      options = this.options;
    }
    window.resolveLocalFileSystemURL(path, function(fileEntry) {
      fileEntry.file(function(file) {
        var reader = new FileReader();
        reader.onloadend = (evt) => cb(new window.DOMParser().parseFromString(evt.target.result, 'text/xml'), options);
        reader.readAsText(file);
      });
    });
  },
  addKML: function(url, options, async, pane) {
    var _this = this;
    var cb = function(gpx, options) { _this._addKML(gpx, options, pane); };
    if (options.remote) {
      this.loadRemoteXML(url, cb, options, async);
    } else {
      this.loadLocalXML(url, cb, options, async);
    }
  },

  _addKML: function(xml, options, pane) {
    var layers = KML.parseKML(xml, pane);
    if (!layers || !layers.length) {
      return;
    }
    for (var i = 0; i < layers.length; i++) {
      this.fire('addlayer', {
        layer: layers[i]
      });
      this.addLayer(layers[i]);
    }
    this.latLngs = KML.getLatLngs(xml);
    this.fire('loaded');
  },

  latLngs: []
});

Leaflet.Util.extend(KML, {

  parseKML: function(xml, pane) {
    var style = this.parseStyle(xml, true);
    var el = xml.getElementsByTagName('Folder');
    var layers = [];
    var l;
    for (var i = 0; i < el.length; i++) {
      if (!this._checkFolder(el[i])) { continue; }
      l = this.parseFolder(el[i], style, pane);
      if (l) { layers.push(l); }
    }
    el = xml.getElementsByTagName('Placemark');
    for (var j = 0; j < el.length; j++) {
      if (!this._checkFolder(el[j])) { continue; }
      l = this.parsePlacemark(el[j], xml, style, pane);
      if (l) { layers.push(l); }
    }
    return layers;
  },

  // Return false if e's first parent Folder is not [folder]
  // - returns true if no parent Folders
  _checkFolder: function(e, folder) {
    e = e.parentElement;
    while (e && e.tagName !== 'Folder') {
      e = e.parentElement;
    }
    return !e || e === folder;
  },

  parseStyle: function(xml, onlyUrl) {
    var style = {};
    var sl = xml.getElementsByTagName('Style');

    var attributes = {color: true, width: true, Icon: true, href: true,
          hotSpot: true};

    function _parse(xml) {
      var options = {};
      for (var i = 0; i < xml.childNodes.length; i++) {
        var e = xml.childNodes[i];
        var key = e.tagName;
        if (!attributes[key]) { continue; }
        if (key === 'hotSpot') {
          for (var j = 0; j < e.attributes.length; j++) {
            options[e.attributes[j].name] = e.attributes[j].nodeValue;
          }
        } else {
          var value = e.childNodes[0].nodeValue;
          if (key === 'color') {
            options.opacity = parseInt(value.substring(0, 2), 16) / 255.0;
            options.color = '#' + value.substring(2, 8);
          } else if (key === 'width') {
            options.weight = value;
          } else if (key === 'Icon') {
            ioptions = _parse(e);
            if (ioptions.href) { options.href = ioptions.href; }
          } else if (key === 'href') {
            options.href = value;
          }
        }
      }
      return options;
    }

    for (var i = 0; i < sl.length; i++) {
      var e = sl[i];
      if (onlyUrl && !e.getAttribute('id')) {
        // no id -> can not be used in style url
        return;
      }
      var el;
      var options = {};
      var poptions = {};
      var ioptions = {};
      el = e.getElementsByTagName('LineStyle');
      if (el && el[0]) { options = _parse(el[0]); }
      el = e.getElementsByTagName('PolyStyle');
      if (el && el[0]) { poptions = _parse(el[0]); }
      if (poptions.color) { options.fillColor = poptions.color; }
      if (poptions.opacity) { options.fillOpacity = poptions.opacity; }
      el = e.getElementsByTagName('IconStyle');
      if (el && el[0]) { ioptions = _parse(el[0]); }
      if (ioptions.href) {
        // save anchor info until the image is loaded
        options.icon = new KMLIcon({
          iconUrl: ioptions.href,
          shadowUrl: null,
          iconAnchorRef: {x: ioptions.x, y: ioptions.y},
          iconAnchorType:	{x: ioptions.xunits, y: ioptions.yunits}
        });
      }
      if (onlyUrl) {
        style['#' + e.getAttribute('id')] = options;
      } else {
        style = options;
      }
    }
    return style;
  },

  parseFolder: function(xml, style, pane) {
    var el;
    var layers = [];
    var l;
    el = xml.getElementsByTagName('Folder');
    for (var i = 0; i < el.length; i++) {
      if (!this._checkFolder(el[i], xml)) { continue; }
      l = this.parseFolder(el[i], style, pane);
      if (l) { layers.push(l); }
    }
    el = xml.getElementsByTagName('Placemark');
    for (var j = 0; j < el.length; j++) {
      if (!this._checkFolder(el[j], xml)) { continue; }
      l = this.parsePlacemark(el[j], xml, style, pane);
      if (l) { layers.push(l); }
    }
    if (!layers.length) { return; }
    if (layers.length === 1) { return layers[0]; }
    return new Leaflet.FeatureGroup(layers, {pane});
  },

  parsePlacemark: function(place, xml, style, pane) {
    var i;
    var j;
    var el ;
    var options = {};
    el = place.getElementsByTagName('styleUrl');
    for (i = 0; i < el.length; i++) {
      var url = el[i].childNodes[0].nodeValue;
      for (var a in style[url]) {
        options[a] = style[url][a];
      }
    }

    // test style in Placemark
    var placeStyle = this.parseStyle(place, false);
    if (placeStyle) {
      for (var b in placeStyle) {
        options[b] = placeStyle[b];
      }
    }

    var layers = [];
    options.pane = pane;

    var parse = ['LineString', 'Polygon', 'Point'];
    for (j in parse) {
      var tag = parse[j];
      el = place.getElementsByTagName(tag);
      for (i = 0; i < el.length; i++) {
        var l = this['parse' + tag](el[i], xml, options);
        if (l) { layers.push(l); }
      }
    }

    if (!layers.length) {
      return;
    }
    var layer = layers[0];
    if (layers.length > 1) {
      layer = new Leaflet.FeatureGroup(layers, {pane});
    }

    var name;
    var descr = '';
    el = place.getElementsByTagName('name');
    if (el.length) {
      name = el[0].childNodes[0].nodeValue;
    }
    el = place.getElementsByTagName('description');
    for (i = 0; i < el.length; i++) {
      for (j = 0; j < el[i].childNodes.length; j++) {
        descr = descr + el[i].childNodes[j].nodeValue;
      }
    }

    if (name) {
      layer.bindPopup('<h2>' + name + '</h2>' + descr);
    }

    return layer;
  },

  parseCoords: function(xml) {
    var el = xml.getElementsByTagName('coordinates');
    return this._readCoords(el[0]);
  },

  parseLineString: function(line, xml, options) {
    var coords = this.parseCoords(line);
    if (!coords.length) { return; }
    return new Leaflet.Polyline(coords, options);
  },

  parsePoint: function(line, xml, options) {
    var el = line.getElementsByTagName('coordinates');
    if (!el.length) {
      return;
    }
    var ll = el[0].childNodes[0].nodeValue.split(',');
    if (options.pane) {
      delete options.pane;
    }
    return new KMLMarker(new Leaflet.LatLng(ll[1], ll[0]), options);
  },

  parsePolygon: function(line, xml, options) {
    var el;
    var polys = [];
    var inner = [];
    var i;
    var coords;
    el = line.getElementsByTagName('outerBoundaryIs');
    for (i = 0; i < el.length; i++) {
      coords = this.parseCoords(el[i]);
      if (coords) {
        polys.push(coords);
      }
    }
    el = line.getElementsByTagName('innerBoundaryIs');
    for (i = 0; i < el.length; i++) {
      coords = this.parseCoords(el[i]);
      if (coords) {
        inner.push(coords);
      }
    }
    if (!polys.length) {
      return;
    }
    if (options.fillColor) {
      options.fill = true;
    }
    if (polys.length === 1) {
      return new Leaflet.Polygon(polys.concat(inner), options);
    }
    return new Leaflet.MultiPolygon(polys, options);
  },

  getLatLngs: function(xml) {
    var el = xml.getElementsByTagName('coordinates');
    var coords = [];
    for (var j = 0; j < el.length; j++) {
      // text might span many childnodes
      coords = coords.concat(this._readCoords(el[j]));
    }
    return coords;
  },

  _readCoords: function(el) {
    var text = '';
    var coords = [];
    var i;
    for (i = 0; i < el.childNodes.length; i++) {
      text = text + el.childNodes[i].nodeValue;
    }
    text = text.split(/[\s\n]+/);
    for (i = 0; i < text.length; i++) {
      var ll = text[i].split(',');
      if (ll.length < 2) {
        continue;
      }
      coords.push(new Leaflet.LatLng(ll[1], ll[0]));
    }
    return coords;
  }

});

var KMLIcon = Leaflet.Icon.extend({

  createIcon: function() {
    var img = this._createIcon('icon');
    img.onload = function() {
      var i = new Image();
      i.src = this.src;
      this.style.width = i.width + 'px';
      this.style.height = i.height + 'px';

      if (this.anchorType.x === 'UNITS_FRACTION' || this.anchorType.x === 'fraction') {
        img.style.marginLeft = (-this.anchor.x * i.width) + 'px';
      }
      if (this.anchorType.y === 'UNITS_FRACTION' || this.anchorType.x === 'fraction') {
        img.style.marginTop  = (-(1 - this.anchor.y) * i.height) + 'px';
      }
      this.style.display = '';
    };
    return img;
  },

  _setIconStyles: function(img, name) {
    Leaflet.Icon.prototype._setIconStyles.apply(this, [img, name]);
    // save anchor information to the image
    img.anchor = this.options.iconAnchorRef;
    img.anchorType = this.options.iconAnchorType;
  }
});

var KMLMarker = Leaflet.Marker.extend({
  options: {
    icon: new KMLIcon.Default()
  }
});

export {KML, KMLIcon, KMLMarker};
