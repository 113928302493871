import _ from 'lodash';

export default ProtectionRepository;

// @ngInject
function ProtectionRepository(DatabaseProvider, StorageService) {
  return {getForProtectionsForTaxons};

  function getForProtectionsForTaxons(txRefType, txRefVersion, txKeys, studyBoundary) {
    // studyBoundary n'est pas utilisé (pas de carto dans sqlite), il est juste là pour être cohérent avec la version remote
    return DatabaseProvider.getReferentielDatabase(txRefType)
      .then((database) => {
        return StorageService.executeSqlQuery(database, `
          SELECT
            p.id,
            p.code,
            p.name,
            p.article,
            p.decree,
            p.url,
            p.decree_year AS decreeYear,
            p.type,
            p.terr_unit AS terrUnit,
            p.deleted,
            tu.name AS terrUnitName,
            t.key AS taxonKey
          FROM protection p
          JOIN terr_unit tu ON (tu.id = p.terr_unit)
          JOIN taxon_protection tp ON (tp.protection = p.id)
          JOIN taxon t ON (t.id = tp.taxon)
          WHERE t.key in (${ _.times(txKeys.length, () => '?').join(',') })
          AND p.deleted = 0
        `, txKeys).then((protectionsRecords) => {
          return _.chain(protectionsRecords)
            .groupBy('id')
            .map((protections, id) => {
              var protection = _.omit(_.head(protections), 'taxonKey');
              var taxons = _.chain(protections).map('taxonKey').uniq().map((txKey) => {
                return {txKey, txRefType, txRefVersion};
              }).value();
              return _.merge(protection, {taxons});
            })
            .value();
        });
      }, () => []);
  }
}
