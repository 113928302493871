import { STATUS } from '../model';

export default FieldRecordStatusFilter;

// @ngInject
function FieldRecordStatusFilter() {
  return function(input) {
    switch (input) {
      case STATUS.PRIVATE:
        return 'Privé';
      case STATUS.PUBLIC:
        return 'Public';
      case STATUS.VALIDATED:
        return 'Validé';
      default:
        return '-';
    }
  };
}
