import _ from 'lodash';

require('./field-survey-field-records-form.scss');

export default FieldSurveyFieldRecordFormDirective;

// @ngInject
function FieldSurveyFieldRecordFormDirective() {
  return {
    restrict: 'E',
    scope: {
      txGroupsProvider: '&txGroups',
      queryStateProvider: '&queryState',
      vignetteStateProvider: '&vignetteState',
      allowVignetteProvider: '&allowVignette',
      onUpdateVignetteFormFn: '&onUpdateVignetteForm',
      onUpdateFilterFormFn: '&onUpdateFilterForm'
    },
    controller: FieldSurveyFieldRecordsController,
    controllerAs: 'fieldSurveyFieldRecordsCtrl',
    bindToController: true,
    template: require('./field-survey-field-records-form.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyFieldRecordsController($scope, SurveyTypeListStore, SurveyTypeListService) {
  var fieldSurveyFieldRecordsCtrl = this;

  fieldSurveyFieldRecordsCtrl.onQueryUpdated = onQueryUpdated;
  fieldSurveyFieldRecordsCtrl.onVignetteUpdated = onVignetteUpdated;

  $scope.$watch(fieldSurveyFieldRecordsCtrl.queryStateProvider, (queryState) => {
    fieldSurveyFieldRecordsCtrl.queryState = queryState;
    updateFromQueryState();
  });
  $scope.$watch(fieldSurveyFieldRecordsCtrl.vignetteStateProvider, (vignetteState) => {
    fieldSurveyFieldRecordsCtrl.vignette = vignetteState;
  });
  $scope.$watch(fieldSurveyFieldRecordsCtrl.txGroupsProvider, (txGroups) => {
    fieldSurveyFieldRecordsCtrl.availableTxGroups = txGroups;
  });
  $scope.$watch(fieldSurveyFieldRecordsCtrl.allowVignetteProvider, (allowVignette) => {
    fieldSurveyFieldRecordsCtrl.allowVignette = allowVignette;
  });

  function onQueryUpdated() {
    fieldSurveyFieldRecordsCtrl.onUpdateFilterFormFn()(fieldSurveyFieldRecordsCtrl.txGroups);
  }

  function onVignetteUpdated() {
    fieldSurveyFieldRecordsCtrl.onUpdateVignetteFormFn()(fieldSurveyFieldRecordsCtrl.vignette);
  }

  function updateFromQueryState() {
    if (!fieldSurveyFieldRecordsCtrl.queryState) {
      return;
    }
    fieldSurveyFieldRecordsCtrl.txGroups = fieldSurveyFieldRecordsCtrl.queryState.txGroups;
  }
}
