import _ from 'lodash';

import { EVENTS as TX_REF_FILTER_STORE_EVENTS } from '../../stores/taxon-ref-filter.store';

require('./select-filter.scss');

export default SelectFilterDirective;

// @ngInject
function SelectFilterDirective() {
  return {
    restrict: 'E',
    scope: {
      initialFilterKeyProvider: '&initialFilterKey',
      txRefTypeKeyProvider: '&txRefTypeKey',
      takeFirstValueIfNullProvider: '&takeFirstValueIfNull',
      canEditProvider: '&canEdit',
      onChangeFn: '&onChange',
      floatingLabel: '@',
      placeholder: '@',
      keepWatchingProvider: '&keepWatching',
      required: '@'
    },
    bindToController: true,
    controller: SelectFilterController,
    controllerAs: 'selectFilterCtrl',
    template: require('./select-filter.html'),
    replace: true
  };
}

// @ngInject
function SelectFilterController($scope, TaxonRefFilterStore, TaxonSearchService, $timeout) {
  var selectFilterCtrl = this;

  selectFilterCtrl.availableFilters = [];
  selectFilterCtrl.onChange = onChange;

  $scope.$watch(selectFilterCtrl.canEditProvider, (canEdit) => {
    selectFilterCtrl.canEdit = canEdit;
  });

  $scope.$watch(selectFilterCtrl.keepWatchingProvider, (keepWatching) => {
    selectFilterCtrl.keepWatching = keepWatching;
  });

  $scope.$watch(selectFilterCtrl.txRefTypeKeyProvider, (txRefTypeKey) => {
    if(txRefTypeKey != undefined){
      selectFilterCtrl.txRefTypeKey = txRefTypeKey;
      TaxonSearchService.loadTxRefFilters(selectFilterCtrl.txRefTypeKey);
    }

  });

  $scope.$on(TX_REF_FILTER_STORE_EVENTS.TX_REF_FILTER_STATE_CHANGED, reloadTxRefFilterResults);
  selectFilterCtrl.state = TaxonRefFilterStore.getState();

  var removeInitialTxRefFilterKeyWatcher = $scope.$watch(selectFilterCtrl.initialFilterKeyProvider, (initialFilterKey) => {
    if (!initialFilterKey) {
      return;
    }

    selectFilterCtrl.initialFilterKey = initialFilterKey
    updateInitialValue();

    if (!selectFilterCtrl.keepWatching) {
      removeInitialTxRefFilterKeyWatcher();
    }
  });

  function reloadTxRefFilterResults() {
        selectFilterCtrl.state = TaxonRefFilterStore.getState();
    if (!selectFilterCtrl.state.availableFilters) {
      return;
    }

    selectFilterCtrl.availableFilters = _.reject(selectFilterCtrl.state.availableFilters, function(o) {
       return (o.deleted != null);
     });

    updateInitialValue();
  }

  function updateInitialValue() {
    if (!selectFilterCtrl.availableFilters) {
      return;
    }
    var initialValue;
    if (selectFilterCtrl.initialFilterKey) {
      var initialValues = _.filter(selectFilterCtrl.state.availableFilters, {key: selectFilterCtrl.initialFilterKey});
      if (_.every(initialValues, 'deleted')) {
        initialValue = _.chain(initialValues).sortBy('created').last().value();
      } else{
        initialValue = _.find(initialValues, (value) => !value.deleted);
      }

      //if initialValues is empty that means that selectFilterCtrl.initialFilterKey is equal to "{key: "no filter", name: "Pas de filtre"}".
      // This object is not present in ws-response (the ws send just "no filter") but this object is present in selectFilterCtrl.availableFilters
      //because I add it at row 82. So with the value send by the web service I can have the entire object by filtering selectFilterCtrl.availableFilters
      //with the value
      /*if (initialValues.length==0){
        var item = _.filter(selectFilterCtrl.availableFilters, {key: selectFilterCtrl.initialFilterKey});
        initialValue = item[0];
      }*/

      if (!initialValue) {
        return;
      }
    } else if (selectFilterCtrl.takeFirstValueIfNullProvider()) {

      //selectFilterCtrl.state.availableFilters
      initialValue = _.chain(selectFilterCtrl.availableFilters)
        .reject('deleted')
        .head()
        .value();
    } else {
      return;
    }

    onChange(initialValue);
    if (initialValue.deleted) {
      selectFilterCtrl.deletedValue = initialValue;
      return;
    }

    selectFilterCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {
    selectFilterCtrl.onChangeFn() && selectFilterCtrl.onChangeFn()(newValue);
  }
}
