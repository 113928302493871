import angular from 'angular';

import mediaService from './services/media.service';
import mediaRemoteRepository from './repositories/media.remote.repository';
import mediaLocalRepository from './repositories/media.local.repository';
import mediaConfiguration from './configuration';

const moduleName = 'shu-media';

angular.module(moduleName, [])
  .factory('MediaRepository', IS_CORDOVA ? mediaLocalRepository : mediaRemoteRepository)
  .factory('MediaService', mediaService)
  .constant('MediaConfiguration', mediaConfiguration);

export default {moduleName};
