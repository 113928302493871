export default BiotopeRefRepository;

// @ngInject
function BiotopeRefRepository(BiotopeRefConfiguration, $http) {
  return {list};

  function list(tableName) {
    return $http({
      method: 'GET',
      url: BiotopeRefConfiguration.listEndpoint,
      pathParams: {tableName}
    }).then((response) => response.data);
  }
}
