export default TaxonRepository;

// @ngInject
function TaxonRepository(TaxonConfiguration, $http) {
  return {query, getTaxon, searchByTxGroupsAndQuery, findByKeys};

  function query(query, txRefType, txRefVersion, txRefFilter, txGroupKeys, offset, limit) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.searchEndpoint,
      pathParams: {txRefType, txRefVersion, txRefFilter, query},
      params: {offset, limit}
    }).then((response) => response.data);
  }


  function getTaxon(txRefType, txRefVersion, txRefFilter, taxonKey) {
    return $http({
      method: 'GET',
      url: TaxonConfiguration.taxonDetailsEndpoint,
      pathParams: {txRefType, txRefVersion, txRefFilter, taxonKey}
    }).then((response) => response.data);
  }

  function searchByTxGroupsAndQuery(txRefType, txRefVersion, txRefFilter, txGroupKeys, query, limit) {
    var params = {query, limit};
    if (txGroupKeys && txGroupKeys.length > 0) {
      params.txGroups = txGroupKeys;
    }

    return $http({
      method: 'GET',
      url: TaxonConfiguration.findTaxonsEndpoint,
      pathParams: {txRefType, txRefVersion, txRefFilter},
      params
    }).then((response) => response.data);
  }

  function findByKeys(txRefType, txRefVersion, txRefFilter, txKeys) {
    var params = {};
    if (txKeys && txKeys.length > 0) {
      params.txKeys = txKeys;
    }

    return $http({
      method: 'GET',
      url: TaxonConfiguration.findTaxonsEndpoint,
      pathParams: {txRefType, txRefVersion, txRefFilter},
      params
    }).then((response) => response.data);
  }
}
