import _ from 'lodash';

require('./field-survey-details-rp-tab.scss');

export default FieldSurveyDetailsRPTabDirective;

// @ngInject
function FieldSurveyDetailsRPTabDirective() {
  return {
    restrict: 'AE',
    scope: {
      initialDataProvider: '&initialData',
      fieldSurveyStateProvider: '&fieldSurveyState',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete',
      canSubmitFn: '&canSubmit',
      isNewFn: '&isNew',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldSurveyDetailsRPTabController,
    controllerAs: 'fieldSurveyDetailsRPTabCtrl',
    template: require('./field-survey-details-rp-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyDetailsRPTabController($scope, $timeout) {
  var fieldSurveyDetailsRPTabCtrl = this;
  fieldSurveyDetailsRPTabCtrl.onVegetationChange = onVegetationChange;
  fieldSurveyDetailsRPTabCtrl.onFormeAeraleChange = onFormeAeraleChange;
  fieldSurveyDetailsRPTabCtrl.onTopographieChange = onTopographieChange;
  fieldSurveyDetailsRPTabCtrl.onPenteChange = onPenteChange;
  fieldSurveyDetailsRPTabCtrl.onExpositionChange = onExpositionChange;
  fieldSurveyDetailsRPTabCtrl.onLuminositeChange = onLuminositeChange;
  fieldSurveyDetailsRPTabCtrl.onPropHydriqueSolChange = onPropHydriqueSolChange;
  fieldSurveyDetailsRPTabCtrl.onChange = onChange;
  fieldSurveyDetailsRPTabCtrl.onDelete = onDelete;
  fieldSurveyDetailsRPTabCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsRPTabCtrl.isNew = isNew;
  fieldSurveyDetailsRPTabCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldSurveyDetailsRPTabCtrl.isAutoLocatedError = isAutoLocatedError;
  fieldSurveyDetailsRPTabCtrl.locationPrecision = locationPrecision;

  $scope.$watch(fieldSurveyDetailsRPTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldSurveyDetailsRPTabCtrl.data = {};
      fieldSurveyDetailsRPTabCtrl.initialFormeAeraleKey = 'POLYGONALE';
    } else {
      fieldSurveyDetailsRPTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldSurveyDetailsRPTabCtrl.onChange();

  });

  $scope.$watch(fieldSurveyDetailsRPTabCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }

    fieldSurveyDetailsRPTabCtrl.fieldSurveyState = fieldSurveyState;
  });

  $scope.$watch(fieldSurveyDetailsRPTabCtrl.formProvider, (form) => {
    fieldSurveyDetailsRPTabCtrl.form = form;
  });

  function onVegetationChange(currentVegetation) {
    fieldSurveyDetailsRPTabCtrl.data.vegetation = currentVegetation ? currentVegetation.id : null;
    onChange();
  }

  function onFormeAeraleChange(currentFormeAerale) {
    fieldSurveyDetailsRPTabCtrl.data.formeAerale = currentFormeAerale ? currentFormeAerale.id : null;
    onChange();
  }

  function onTopographieChange(currentTopographie) {
    fieldSurveyDetailsRPTabCtrl.data.topographie = currentTopographie ? currentTopographie.id : null;
    onChange();
  }

  function onPenteChange(currentPente) {
    fieldSurveyDetailsRPTabCtrl.data.pente = currentPente ? currentPente.id : null;
    onChange();
  }

  function onExpositionChange(currentExposition) {
    fieldSurveyDetailsRPTabCtrl.data.exposition = currentExposition ? currentExposition.id : null;
    onChange();
  }

  function onLuminositeChange(currentLuminosite) {
    fieldSurveyDetailsRPTabCtrl.data.luminosite = currentLuminosite ? currentLuminosite.id : null;
    onChange();
  }

  function onPropHydriqueSolChange(currentPropHydriqueSol) {
    fieldSurveyDetailsRPTabCtrl.data.propHydriqueSol = currentPropHydriqueSol ? currentPropHydriqueSol.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldSurveyDetailsRPTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldSurveyDetailsRPTabCtrl.onChangeFn()('RP', {
        data: {
          vegetation: fieldSurveyDetailsRPTabCtrl.data.vegetation,
          vegetationSaisieLibre: fieldSurveyDetailsRPTabCtrl.data.vegetationSaisieLibre,
          surface: fieldSurveyDetailsRPTabCtrl.data.surface,
          formeAerale: fieldSurveyDetailsRPTabCtrl.data.formeAerale,
          longueurRayon: fieldSurveyDetailsRPTabCtrl.data.longueurRayon,
          largeur: fieldSurveyDetailsRPTabCtrl.data.largeur,
          topographie: fieldSurveyDetailsRPTabCtrl.data.topographie,
          altitude: fieldSurveyDetailsRPTabCtrl.data.altitude,
          pente: fieldSurveyDetailsRPTabCtrl.data.pente,
          exposition: fieldSurveyDetailsRPTabCtrl.data.exposition,
          luminosite: fieldSurveyDetailsRPTabCtrl.data.luminosite,
          rocheMere: fieldSurveyDetailsRPTabCtrl.data.rocheMere,
          sol: fieldSurveyDetailsRPTabCtrl.data.sol,
          propHydriqueSol: fieldSurveyDetailsRPTabCtrl.data.propHydriqueSol,
          etatDynamique: fieldSurveyDetailsRPTabCtrl.data.etatDynamique,
          gestion: fieldSurveyDetailsRPTabCtrl.data.gestion,
          actionBiotique: fieldSurveyDetailsRPTabCtrl.data.actionBiotique,
          recouvStArbo: fieldSurveyDetailsRPTabCtrl.data.recouvStArbo,
          recouvStArbu: fieldSurveyDetailsRPTabCtrl.data.recouvStArbu,
          recouvStHerb: fieldSurveyDetailsRPTabCtrl.data.recouvStHerb,
          recouvStBryoLich: fieldSurveyDetailsRPTabCtrl.data.recouvStBryoLich,
          hautMoyStArbo: fieldSurveyDetailsRPTabCtrl.data.hautMoyStArbo,
          hautMoyStArbu: fieldSurveyDetailsRPTabCtrl.data.hautMoyStArbu,
          hautMoyStHerb: fieldSurveyDetailsRPTabCtrl.data.hautMoyStHerb,
          hautMoyStBryoLich: fieldSurveyDetailsRPTabCtrl.data.hautMoyStBryoLich
        },
        formValid: isFormValid()
      });
    });
  }

  function onDelete() {
    fieldSurveyDetailsRPTabCtrl.onDeleteFn()();
  }

  function canSubmit() {
    return fieldSurveyDetailsRPTabCtrl.canSubmitFn()();
  }

  function isNew() {
    return fieldSurveyDetailsRPTabCtrl.isNewFn()();
  }

  // TODO : vérifier que les champs int sont bien des valeurs entières ?
  function isFormValid() {
    return !!(fieldSurveyDetailsRPTabCtrl.form.surface && fieldSurveyDetailsRPTabCtrl.form.surface.$valid);
  }

  function locationPrecision() {
    return fieldSurveyDetailsRPTabCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return fieldSurveyDetailsRPTabCtrl.isAutoLocatedValidFn() &&
           fieldSurveyDetailsRPTabCtrl.isAutoLocatedValidFn()();
  }

  function isAutoLocatedError() {
    return fieldSurveyDetailsRPTabCtrl.isAutoLocatedErrorFn() &&
           fieldSurveyDetailsRPTabCtrl.isAutoLocatedErrorFn()();
  }
}
