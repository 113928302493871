import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-record-details.service';

const EVENTS = {
  FIELD_RECORD_DETAILS_STATE_CHANGED: 'shu-field-record.field-record-details-state-changed'
};

export default FieldRecordDetailsStore;

// @ngInject
function FieldRecordDetailsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DETAILS_LOADING, onFieldRecordDetailsLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DETAILS_LOAD_SUCCESS, onFieldRecordDetailsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DETAILS_LOAD_ERROR, onFieldRecordDetailsLoadError);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DETAILS_PROCESSING, onFieldRecordDetailsProcessing);
  $rootScope.$on(SERVICE_EVENTS.FIELD_RECORD_DETAILS_NEW_PROCESSING, onFieldRecordDetailsNewProcessing);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStudy() {
    state = Immutable({});
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED);
  }

  function onFieldRecordDetailsLoading() {
    state = Immutable({
      fieldRecord: null,
      processing: false,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED);
  }

  function onFieldRecordDetailsLoadSuccess(event, fieldRecord) {
    state = Immutable({
      fieldRecord,
      processing: false,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED);
  }

  function onFieldRecordDetailsLoadError() {
    state = Immutable({
      fieldRecord: null,
      processing: false,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED);
  }

  function onFieldRecordDetailsProcessing() {
    state = Immutable({
      fieldRecord: state.fieldRecord,
      processing: true,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED);
  }

  function onFieldRecordDetailsNewProcessing() {
    state = Immutable({
      fieldRecord: null,
      processing: true,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_RECORD_DETAILS_STATE_CHANGED);
  }
}

export { EVENTS };
