import Immutable from 'seamless-immutable';
import _ from 'lodash';

const EVENTS = {
  LOCATION_REFRESHED: 'shu-map.location-refreshed',
  BACKGROUND_LOCATION: 'shu-map.background-location',
  REMOVE_TRACKING_POINTS: 'shu-map.remove-tracking-points',
  TRACKING_STATUS: 'shu-map.tracking-status',
};

const TRACKING_PARAMS = {
  interval: 1000 * 10, // Plugin default: 60000
  distanceFilter: 10, //DEPRECATED Plugin default: 500
  activitiesInterval: 1000 * 10, // Plugin default: 10000
  debug: false,
  maxStandby: 1000 * 60 * 10,
};

export default LocalGeolocation;

//@ngInject
function LocalGeolocation($q, $rootScope, DatabaseProvider, StorageService, FieldSurveyRepository) {
  var state = Immutable({
    isConfigured: false, // DEPRECATED
    position: null,
    initializing: false,
    isTracking: false,
    isTrackingInitializing: false,
    trackingStatus: 'off',
  });

  let standbyTimeout = null;

  return {
    isInitializing,
    getCurrentPosition,
    isLocationServiceActive,
    toggleLocationService,
    getPrecision,
    startTrackingService,
    stopTrackingService,
    getTracking,
    getTrackingForStudy,
    setTracking,
    removeTrackingPoints,
    removeTrackings,
    getTrackingStatus,
    getTrackedSurveys,
  };

  function configure() {
    return new Promise(function(resolve, reject) {
      /* if (state.isConfigured) {
        return;
      } */

      getTracking().then((tracking) => {
        console.log(tracking.params);
        BackgroundGeolocation.configure({
          locationProvider: BackgroundGeolocation.ACTIVITY_PROVIDER, //DISTANCE_FILTER_PROVIDER RAW_PROVIDER ACTIVITY_PROVIDER
          desiredAccuracy: BackgroundGeolocation.HIGH_ACCURACY,
          distanceFilter: tracking.params.distanceFilter,
          interval: tracking.params.interval,
          fastestInterval: tracking.params.interval,
          activitiesInterval: tracking.params.activitiesInterval,
          notificationsEnabled: true,
          startForeground: true,
          debug: tracking.params.debug,
        });
        resolve();
      });
    });
  }

  function checkStatus(doActions) {
    doActions = typeof doActions !== 'undefined' ? doActions : true;
    return new Promise(function(resolve, reject) {
      configure().then(() => {
        BackgroundGeolocation.checkStatus(function(status) {
          console.log('BackgroundGeolocation status', status);
          if (status.hasPermissions && status.locationServicesEnabled) {
            return resolve(status);
          }
          if (doActions) {
            setTimeout(function() {
              if (!status.hasPermissions) {
                alert("Veuillez autoriser l'app à utiliser votre position.");
                BackgroundGeolocation.showAppSettings();
              } else if (!status.locationServicesEnabled) {
                alert('Veuillez activer la localisation.');
                BackgroundGeolocation.showLocationSettings();
              }
            }, 1000);
          }
          reject(status);
        });
      });
    });
  }

  function isInitializing() {
    return state.initializing;
  }

  function getPrecision() {
    return state.position ? state.position.coords.accuracy : null;
  }

  function getCurrentPosition() {
    return new Promise(function(resolve, reject) {
      state = state.merge({
        initializing: true,
      });
      $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
      checkStatus().then(
        function(status) {
          BackgroundGeolocation.getCurrentLocation(
            function(location) {
              console.log('getCurrentLocation success', location);
              state = state.merge({
                position: Immutable({
                  timestamp: location.timestamp,
                  coords: _.toPlainObject(location),
                }),
                initializing: false,
              });
              $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
              resolve(state.position);
            },
            function(locationError) {
              console.log('getCurrentLocation error', locationError);
              state = state.merge({
                position: null,
                initializing: false,
              });
              $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
              reject(locationError);
            },
            {
              maximumAge: 1 * 1000,
              timeout: 1000 * 30,
              enableHighAccuracy: true,
            }
          );
        },
        function(errorStatus) {
          state = state.merge({
            position: null,
            initializing: false,
          });
          $rootScope.$broadcast(EVENTS.LOCATION_REFRESHED);
          reject(errorStatus);
        }
      );
    });
  }

  function toggleLocationService() {
    getCurrentPosition();
  }

  function isLocationServiceActive() {
    return state.position !== null;
  }

  function stopTrackingService() {
    console.log('before stopTrackingService isTracking', state.isTracking);
    if (state.trackingStatus === 'off') {
      return;
    }
    state = state.merge({
      trackingStatus: 'off',
    });
    getTracking().then((tracking) => {
      for (const surveyId in tracking.surveys) {
        const trackingSurvey = tracking.surveys[surveyId];
        trackingSurvey.isTrackable = false;
      }
      setTracking(tracking).then(() => {
        BackgroundGeolocation.stop();
        BackgroundGeolocation.removeAllListeners();
        $rootScope.$broadcast(EVENTS.TRACKING_STATUS, 'off');
      });
    });
  }

  function startTrackingService() {
    console.log('before startTrackingService isTracking', state.isTracking);
    if (['starting', 'running'].indexOf(state.trackingStatus) > -1) {
      return;
    }
    state = state.merge({
      trackingStatus: 'starting',
    });
    $rootScope.$broadcast(EVENTS.TRACKING_STATUS, 'starting');

    // Only when ACTIVITY_PROIDER is used
    BackgroundGeolocation.on('activity', function(e) {
      console.log('[INFO] App activity', e);
      //BackgroundGeolocation.configure({ debug: false });
    });

    BackgroundGeolocation.on('background', function() {
      console.log('[INFO] App is in background');
      getTracking().then((tracking) => {
        console.log(tracking.params.maxStandby);
        standbyTimeout = setTimeout(() => {
          console.log('boom');
          stopTrackingService();
        }, tracking.params.maxStandby);
      });
      // you can also reconfigure service (changes will be applied immediately)
      //BackgroundGeolocation.configure({ debug: true });
    });

    BackgroundGeolocation.on('foreground', function() {
      console.log('[INFO] App is in foreground');
      if (standbyTimeout) {
        clearTimeout(standbyTimeout);
        standbyTimeout = null;
      }
      //BackgroundGeolocation.configure({ debug: false });
    });
    BackgroundGeolocation.on('start', () => {
      console.log('[INFO] BackgroundGeolocation service has been started');
      state = state.merge({
        trackingStatus: 'running',
      });
      $rootScope.$broadcast(EVENTS.TRACKING_STATUS, 'running');
    });

    BackgroundGeolocation.on('stop', () => {
      console.log('[INFO] BackgroundGeolocation service has been stopped');
    });

    BackgroundGeolocation.on('location', (location) => {
      console.log('location', location);
      getTracking().then((tracking) => {
        const updatedSurveyIds = [];
        for (const surveyId in tracking.surveys) {
          const trackingSurvey = tracking.surveys[surveyId];
          if (!trackingSurvey.isTrackable) {
            continue;
          }
          updatedSurveyIds.push(trackingSurvey.id);
          if (!trackingSurvey.points) {
            trackingSurvey.points = [];
          }
          const lastPoint = trackingSurvey.points[trackingSurvey.points.length - 1];
          if (!lastPoint || lastPoint.latitude != location.latitude || lastPoint.longitude != location.longitude) {
            trackingSurvey.points.push(location);
          }
        }
        setTracking(tracking).then(() => {
          setSurveysUpdate(updatedSurveyIds);
          $rootScope.$broadcast(EVENTS.BACKGROUND_LOCATION, location);
        });
      });
    });
    checkStatus().then(
      function(status) {
        console.log('status', status);
        if (!status.isRunning) {
          console.log('BackgroundGeolocation must start');
          BackgroundGeolocation.start();
        } else {
          state = state.merge({
            trackingStatus: 'running',
          });
          $rootScope.$broadcast(EVENTS.TRACKING_STATUS, 'running');
        }
      },
      function(errorStatus) {
        console.log('errorStatus', errorStatus);
        state = state.merge({
          trackingStatus: 'error',
        });
        $rootScope.$broadcast(EVENTS.TRACKING_STATUS, 'error');
        //reject(errorStatus);
      }
    );
  }

  function getTrackingStatus() {
    return state.trackingStatus;
  }

  function getTracking() {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        StorageService.executeSqlQuery(database, 'SELECT * FROM ShuSettings').then((rows) => {
          const row = rows.find((row) => {
            return row.key === 'tracking';
          });
          if (row) {
            const value = JSON.parse(row.value);
            value.params = { ...TRACKING_PARAMS, ...value.params };
            return resolve(value);
          }
          const value = {
            params: { ...TRACKING_PARAMS },
            surveys: [],
          };
          StorageService.executeSqlQuery(database, 'INSERT INTO ShuSettings (key, value) VALUES (?, ?)', [
            'tracking',
            JSON.stringify(value),
          ]).then((res) => {
            console.log('INSERT ShuSettings tracking res', res);
            resolve(value);
          });
        });
      });
    });
  }

  function getTrackingForStudy(studyId) {
    return $q((resolve, reject) => {
      $q.all([getTracking(), FieldSurveyRepository.getForStudy(studyId)]).then((res) => {
        const tracking = res[0];
        const studySurveys = res[1];
        if (!tracking.surveys.length || !studySurveys || !studySurveys.length) {
          return resolve({ ...tracking, surveys: [] });
        }
        resolve({
          ...tracking,
          surveys: studySurveys
            .map((studySurvey) => {
              return {
                ...studySurvey,
                tracking: tracking.surveys.find((trackingSurvey) => {
                  return trackingSurvey.id === studySurvey.id;
                }),
              };
            })
            .filter((studySurvey) => Boolean(studySurvey.tracking))
            .map((studySurvey) => {
              return {
                ...studySurvey,
                ...studySurvey.tracking,
              };
            }),
        });
      });
    });
  }

  function getTrackedSurveys() {
    return new Promise(function(resolve, reject) {
      getTracking().then((tracking) => {
        const trackeds = (tracking.surveys || []).filter((survey) => {
          return survey.isTrackable;
        });
        resolve(trackeds);
      });
    });
  }

  function removeTrackingPoints(timestamp, studyId, direction, surveyId) {
    getTrackingForStudy(studyId).then((tracking) => {
      const surveys = !surveyId
        ? tracking.surveys
        : [
            tracking.surveys.find((survey) => {
              return survey.id == surveyId;
            }),
          ];
      surveys.forEach((survey) => {
        const pointIndex = _.findIndex(survey.points, (point) => {
          return point.time == timestamp;
        });
        if (direction == '<') {
          survey.points.splice(0, pointIndex + 1);
        } else if (direction == '>') {
          survey.points.splice(pointIndex);
        } else {
          survey.points.splice(pointIndex, 1);
        }
      });
      $rootScope.$broadcast(EVENTS.REMOVE_TRACKING_POINTS, tracking);
      setTracking(tracking).then(() => {
        setSurveysUpdate(surveys.map((survey) => survey.id));
        $rootScope.$broadcast(EVENTS.REMOVE_TRACKING_POINTS, tracking);
      });
    });
  }

  function removeTrackings(studyId, surveyId) {
    getTrackingForStudy(studyId).then((tracking) => {
      const surveys = !surveyId
        ? tracking.surveys
        : [
            tracking.surveys.find((survey) => {
              return survey.id == surveyId;
            }),
          ];
      surveys.forEach((survey) => {
        survey.points = [];
      });
      $rootScope.$broadcast(EVENTS.REMOVE_TRACKING_POINTS, tracking);
      setTracking(tracking).then(() => {
        setSurveysUpdate(surveys.map((survey) => survey.id));
        $rootScope.$broadcast(EVENTS.REMOVE_TRACKING_POINTS, tracking);
      });
    });
  }

  function setTracking(value) {
    return $q((resolve, reject) => {
      DatabaseProvider.getDatabase().then((database) => {
        StorageService.executeSqlQuery(database, 'UPDATE ShuSettings SET value=? WHERE key=?', [
          JSON.stringify(value),
          'tracking',
        ]).then((res) => {
          console.log('UPDATE ShuSettings tracking res', res);
          resolve(value);
        });
      });
    });
  }

  function setSurveysUpdate(surveyIds) {
    FieldSurveyRepository.updateUpdatedFieldSurveys(surveyIds);
  }
}
//LocalGeolocation.$inject = ['$rootScope', '$q'];
export { EVENTS };
