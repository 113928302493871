import _ from 'lodash';

require('./study-search-results.scss');

export default StudySearchResultsDirective;

// @ngInject
function StudySearchResultsDirective() {
  return {
    restrict: 'E',
    scope: {
      resultsStateProvider: '&resultsState',
      onStudySelectedFn: '&onStudySelected',
      onNextResultsActionFn: '&onNextResultsAction'
    },
    controller: StudySearchResultsController,
    controllerAs: 'studySearchResultsCtrl',
    bindToController: true,
    template: require('./study-search-results.html'),
    replace: true
  };
}

// @ngInject
function StudySearchResultsController($scope) {
  var studySearchResultsCtrl = this;

  studySearchResultsCtrl.hasMoreResults = hasMoreResults;
  studySearchResultsCtrl.onStudySelected = onStudySelected;
  studySearchResultsCtrl.onNextResultsAction = onNextResultsAction;

  $scope.$watch(studySearchResultsCtrl.resultsStateProvider, (resultsState) => {
    studySearchResultsCtrl.results = resultsState.results;
    studySearchResultsCtrl.totalResults = resultsState.totalResults;
    studySearchResultsCtrl.loading = resultsState.loading;
  });

  function hasMoreResults() {
    return studySearchResultsCtrl.totalResults > (studySearchResultsCtrl.results ? studySearchResultsCtrl.results.length : 0);
  }

  function onStudySelected(studyKey) {
    studySearchResultsCtrl.onStudySelectedFn()(studyKey);
  }

  function onNextResultsAction() {
    studySearchResultsCtrl.onNextResultsActionFn()();
  }
}
