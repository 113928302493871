require('./menu-study-survey-type.scss');

import { EVENTS as SURVEY_TYPE_STORE_EVENTS } from '../../../survey-type/stores/survey-type-list.store';

export default MenuStudySurveyTypeDirective;

// @ngInject
function MenuStudySurveyTypeDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@'
    },
    bindToController: true,
    controller: MenuStudySurveyTypeController,
    controllerAs: 'menuStudySurveyTypeCtrl',
    template: require('./menu-study-survey-type.html'),
    replace: true
  };
}

// @ngInject
function MenuStudySurveyTypeController($scope, $state, SurveyTypeListService, SurveyTypeListStore) {
  var menuStudySurveyTypeCtrl = this;

  $scope.$on(SURVEY_TYPE_STORE_EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED, () => {
    menuStudySurveyTypeCtrl.surveyTypeState = SurveyTypeListStore.getState();
  });

  menuStudySurveyTypeCtrl.surveyTypeState = null;
  menuStudySurveyTypeCtrl.onCreateFieldSurveyField = onCreateFieldSurveyField;

  SurveyTypeListService.load(menuStudySurveyTypeCtrl.studyId);

  function onCreateFieldSurveyField(surveyTypeId) {
    if (!surveyTypeId) {
      return;
    }
    $state.go('field-survey-creation', {studyId: menuStudySurveyTypeCtrl.studyId, surveyTypeId});
  }
}
