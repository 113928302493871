export default StudyExpertLayerRepository;

// @ngInject
function StudyExpertLayerRepository(StudyConfiguration, $http) {
  return {getForStudy, addLayer, removeLayer};

  function getForStudy(studyId) {
    return $http({
      method: 'GET',
      url: StudyConfiguration.studyExpertLayerEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function addLayer(studyId, layerName, layerInfo) {
    return $http({
      method: 'POST',
      url: StudyConfiguration.studyExpertLayerEndpoint,
      headers: {'Content-Type': undefined},
      pathParams: {studyId},
      data: toFormData(layerName, layerInfo)
    }).then((response) => response.data);
  }

  function toFormData(layerName, layerInfo) {
    var formData = new FormData();
    formData.append('file', layerInfo.file);
    formData.append('name', layerInfo.file.name);
    formData.append('type', layerInfo.file.type);
    formData.append('layerName', layerName);
    return formData;
  }

  function removeLayer(studyId, studyExpertLayerId) {
    return $http({
      method: 'DELETE',
      url: StudyConfiguration.studyExpertLayerDetailEndpoint,
      pathParams: {studyId, studyExpertLayerId},
    }).then((response) => response.data);
  }
}
