require('./field-record-latest.scss');

export default FieldRecordLatestDirective;

// @ngInject
function FieldRecordLatestDirective() {
  return {
    restrict: 'E',
    scope: {
      onFieldRecordSelectedFn: '&onFieldRecordSelected',
      onFieldSurveySelectedFn: '&onFieldSurveySelected',
      onStudySelectedFn: '&onStudySelected',
      onTaxonSelectedFn: '&onTaxonSelected',
      fieldRecordLatestStateProvider: '&fieldRecordLatestState'
    },
    controller: FieldRecordLatestController,
    controllerAs: 'fieldRecordLatestCtrl',
    bindToController: true,
    template: require('./field-record-latest.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordLatestController($scope) {
  var fieldRecordLatestCtrl = this;

  fieldRecordLatestCtrl.onFieldRecordSelected = onFieldRecordSelected;
  fieldRecordLatestCtrl.onFieldSurveySelected = onFieldSurveySelected;
  fieldRecordLatestCtrl.onStudySelected = onStudySelected;
  fieldRecordLatestCtrl.onTaxonSelected = onTaxonSelected;

  $scope.$watch(fieldRecordLatestCtrl.fieldRecordLatestStateProvider, (fieldRecordLatestState) => {
    fieldRecordLatestCtrl.fieldRecordLatestState = fieldRecordLatestState;
  });

  function onFieldRecordSelected(studyId, fieldRecordId) {
    fieldRecordLatestCtrl.onFieldRecordSelectedFn()(studyId, fieldRecordId);
  }

  function onFieldSurveySelected(studyId, fieldSurveyId) {
    fieldRecordLatestCtrl.onFieldSurveySelectedFn()(studyId, fieldSurveyId);
  }

  function onStudySelected(studyId) {
    fieldRecordLatestCtrl.onStudySelectedFn()(studyId);
  }

  function onTaxonSelected(txRefType, txRefVersion, txKey) {
    fieldRecordLatestCtrl.onTaxonSelectedFn()(txRefType, txRefVersion, txKey);
  }
}
