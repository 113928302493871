import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/field-survey-details.service';
import { EVENTS as SYNC_SERVICE_EVENTS } from '../../synchro/services/study-synchronizer.service';

const EVENTS = {
  FIELD_SURVEY_DETAILS_STATE_CHANGED: 'shu-field-survey.field-survey-details-state-changed'
};

export default FieldSurveyDetailsStore;

// @ngInject
function FieldSurveyDetailsStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_DETAILS_LOADING, onFieldSurveyDetailsLoading);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_DETAILS_LOAD_SUCCESS, onFieldSurveyDetailsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_DETAILS_LOAD_ERROR, onFieldSurveyDetailsLoadError);
  $rootScope.$on(SERVICE_EVENTS.FIELD_SURVEY_DETAILS_PROCESSING, onFieldSurveyDetailsProcessing);
  $rootScope.$on(SYNC_SERVICE_EVENTS.STUDY_SYNC_TERMINATED, onSyncStudy);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStudy() {
    state = Immutable({});
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED);
  }

  function onFieldSurveyDetailsLoading() {
    state = Immutable({
      fieldSurvey: null,
      processing: false,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED);
  }

  function onFieldSurveyDetailsLoadSuccess(event, fieldSurvey) {
    state = Immutable({
      fieldSurvey,
      processing: false,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED);
  }

  function onFieldSurveyDetailsLoadError() {
    state = Immutable({
      fieldSurvey: null,
      processing: false,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED);
  }

  function onFieldSurveyDetailsProcessing() {
    state = Immutable({
      fieldSurvey: state.fieldSurvey,
      processing: true,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.FIELD_SURVEY_DETAILS_STATE_CHANGED);
  }
}

export { EVENTS };
