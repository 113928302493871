import * as models from '@biotope/shuriken-status-api'
import { StatusApi } from '@biotope/shuriken-status-api'
import _ from 'lodash';

export const EVENTS = {
  TAXA_STATUTES_LOADING: 'shu-taxa-statutes.taxa-statutes-loading',
  TAXA_STATUTES_LOAD_SUCCESS: 'shu-taxa-statutes.taxa-statutes-load-success',
  TAXA_STATUTES_LOAD_ERROR: 'shu-taxa-statutes.taxa-statutes-load-error',
  TAXA_SIMPLE_STATUTES_LOADING: 'shu-taxa-simple-statutes.taxa-simple-statutes-loading',
  TAXA_SIMPLE_STATUTES_LOAD_SUCCESS: 'shu-taxa-simple-statutes.taxa-simple-statutes-load-success',
  TAXA_SIMPLE_STATUTES_LOAD_ERROR: 'shu-taxa-simple-statutes.taxa-simple-statutes-load-error',
}

export class TaxonStatusService {

  private statusApi: StatusApi;
  private toaster: any;
  private $rootScope: any;

  /** @ngInject */
  constructor($rootScope: ng.IRootScopeService, LxNotificationService: any, StatusApi: StatusApi) {
    this.statusApi = StatusApi;
    this.toaster = LxNotificationService;
    this.$rootScope = $rootScope;
  }

  public getTaxaStatus(txRefType, txRefVersion, taxonKey) {
     this.$rootScope.$emit(EVENTS.TAXA_STATUTES_LOADING);
     this.statusApi.getTaxonStatus(txRefType, txRefVersion, taxonKey)
     .then(
       (statutes) => {
         this.$rootScope.$emit(EVENTS.TAXA_STATUTES_LOAD_SUCCESS, statutes.data);
       },
       (error: any) => {
         this.toaster.error('Une erreur est survenue au chargement des statuts. ' + (error.data || ''));
         this.$rootScope.$emit(EVENTS.TAXA_STATUTES_LOAD_ERROR);
       }
     );
  }

  public getTaxaSimpleStatus(txRefType, txRefVersion, taxonKey) {
     this.$rootScope.$emit(EVENTS.TAXA_SIMPLE_STATUTES_LOADING);

     this.statusApi.getTaxonSimpleStatus(txRefType, txRefVersion, taxonKey)
     .then(
       (statutes) => {
         this.$rootScope.$emit(EVENTS.TAXA_SIMPLE_STATUTES_LOAD_SUCCESS, statutes.data);
       },
       (error: any) => {
         this.toaster.error('Une erreur est survenue au chargement des statuts simplifiés. ' + (error.data || ''));
         this.$rootScope.$emit(EVENTS.TAXA_SIMPLE_STATUTES_LOAD_ERROR);
       }
     );
  }
}
