import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur lance une recherche
  STUDY_KML_UPDATING: 'shu-study.study-kml-updating',
  STUDY_KML_UPDATE_SUCCESS: 'shu-study.study-kml-update-success',
  STUDY_KML_UPDATE_ERROR: 'shu-study.study-kml-update-error',
};

export default StudyKmlSearchService;

// @ngInject
function StudyKmlSearchService($rootScope, StudyRepository, LxNotificationService) {
  return {onUpdateKml};

  function onUpdateKml(studyId, file) {
    $rootScope.$broadcast(EVENTS.STUDY_KML_UPDATING);

    StudyRepository.updateGeom(studyId, toFormData(file))
      .then(
        (result) => {
          LxNotificationService.success('Fichier KML chargé.');
          $rootScope.$broadcast(EVENTS.STUDY_KML_UPDATE_SUCCESS, studyId, result.area);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement du fichier KML. ' + (error.data || ''));
          $rootScope.$broadcast(EVENTS.STUDY_KML_UPDATE_ERROR);
        }
      );
  }

  function toFormData(file) {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('type', file.type);
    return formData;
  }
}
export { EVENTS };
