import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'une étude
  FIELD_SURVEY_MAP_LOADING: 'shu-field-survey.field-survey-map-loading',
  FIELD_SURVEY_MAP_LOAD_SUCCESS: 'shu-field-survey.field-survey-map-load-success',
  FIELD_SURVEY_MAP_LOAD_ERROR: 'shu-field-survey.field-survey-map-load-error'
};

export default FieldSurveyMapService;

// @ngInject
function FieldSurveyMapService($rootScope, FieldSurveyRepository, LxNotificationService) {
  return {load};

  function load(fieldSurveyId) {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_MAP_LOADING);

    FieldSurveyRepository.getGeom(fieldSurveyId)
      .then(
        (fieldSurvey) => {
          $rootScope.$emit(EVENTS.FIELD_SURVEY_MAP_LOAD_SUCCESS, fieldSurvey);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement de la géométrie. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_SURVEY_MAP_LOAD_ERROR);
        }
      );
  }
}
export { EVENTS };
