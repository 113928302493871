import _ from 'lodash';

export default FavorisUtils;

// @ngInject
function FavorisUtils($urlMatcherFactory, $state) {

  return {toFavori, equals, isFavori};

  function toFavori(stateName, params, picto, title) {
    // L'identifiant du favori est compiledPath : l'URL compilée sans les queryParams
    var state = $state.get(stateName);
    // On compile l'URL comlète du state
    var matcher = $urlMatcherFactory.compile(state.url);
    // Ca permet de compiler ensuite uniquement la partie sourcePath
    var pathMatcher = $urlMatcherFactory.compile(matcher.sourcePath);
    return {
      compiledPath: pathMatcher.format(params),
      picto: picto,
      title: title,
      name: state.name,
      params: params
    };
  }
  function equals(f1, f2) {
    return f1.compiledPath && f1.compiledPath === f2.compiledPath;
  }
  function isFavori(favori, favoris) {
    return _.some(favoris, (f) => equals(f, favori));
  }
}
