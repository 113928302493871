import UUID from 'uuid-js';
import _ from 'lodash';

import {STUDY_EXPERT_LAYER_FORMAT, STUDY_EXPERT_LAYER_SOURCE} from '../model';

export default StudyExpertLayerRepository;

// @ngInject
function StudyExpertLayerRepository(DatabaseProvider, StorageService, $q, $cordovaFile) {
  return {getForStudy, addLayer, removeLayer, removeLayerFile, removeLayers};

  function getForStudy(studyId) {
    return DatabaseProvider.getDatabase()
      .then((database) => {
        return StorageService.executeSqlQuery(database,
          `
            SELECT DISTINCT
              sel.*
            FROM study_expert_layer sel
            WHERE sel.study = ?
          `,
          [studyId]
        );
      });
  }
  function addLayer(studyId, layerName, layerInfo) {
    var newLayerDir = `${ cordova.file.applicationStorageDirectory }databases/`;
    var newLayerName;
    var uuid = UUID.create().toString();
    if (layerInfo.format === STUDY_EXPERT_LAYER_FORMAT.MBTILES) {
      newLayerName = `${ uuid }.mbtiles`;
    } else {
      newLayerName = `${ uuid }.kml`;
    }
    return $q((resolve, reject) => {
      document.addEventListener('deviceready', () => {
        window.resolveLocalFileSystemURL(layerInfo.path, (fileEntry) => {
          window.resolveLocalFileSystemURL(newLayerDir, (targetDirectory) => {
            fileEntry.copyTo(targetDirectory, newLayerName, resolve, reject);
          }, reject);
        }, reject);
      });
    })
    .then(() => {
        return DatabaseProvider.getDatabase()
          .then((database) => {
            return StorageService.executeSqlQuery(database,
              `
                INSERT INTO study_expert_layer
                (study, name, created, source, format, filepath) values (?, ?, ?, ?, ?, ?)
              `,
              [studyId, layerName, new Date().getTime() / 1000, layerInfo.source, layerInfo.format, `${newLayerDir}/${newLayerName}`]
            );
          });
      })
    .then((layerId) => getForStudy(studyId));
  }

  function removeLayerFile(layer) {
    var index = layer.filepath.lastIndexOf('/');
    var filename = layer.filepath.substr(index + 1);
    var filedir = layer.filepath.substr(0, index);

    if (layer.format === STUDY_EXPERT_LAYER_FORMAT.KML) {
      return $cordovaFile.removeFile(filedir, filename).then(null, () => true);
    }
    return DatabaseProvider.deleteSqliteDatabase(filename).then(null, () => true);
  }

  function removeLayer(studyId, studyExpertLayerId) {
    return DatabaseProvider.getDatabase()
      .then((database) => {
          return StorageService.executeSqlQuery(database,
              'SELECT filepath, format from study_expert_layer WHERE study = ? AND id = ?', [studyId, studyExpertLayerId])
          .then(([layer]) => removeLayerFile(layer))
            .then(() => StorageService.executeSqlQuery(database,
              'DELETE FROM study_expert_layer WHERE study = ? AND id = ?', [studyId, studyExpertLayerId]));
        })
      .then(() => getForStudy(studyId));
  }

  function removeLayers(database, studyId) {
    return StorageService.executeSqlQuery(database,
        'SELECT filepath from study_expert_layer WHERE study = ? AND source = ?', [studyId, STUDY_EXPERT_LAYER_SOURCE.LOCAL])
    .then((layers) => {
      return _.reduce(layers, (previousPromise, layer) => {
        return previousPromise.then(() => removeLayerFile(layer));
      }, $q.when(true));
    })
    .then(() => StorageService.executeSqlQuery(database,
        'DELETE FROM study_expert_layer WHERE study = ? AND source = ?', [studyId, STUDY_EXPERT_LAYER_SOURCE.LOCAL]));
  }
}
