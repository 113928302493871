import _ from 'lodash';

require('./field-record-details-ls-insects-tab.scss');

export default FieldRecordDetailsLsInsectsTabDirective;

// @ngInject
function FieldRecordDetailsLsInsectsTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      biotopeRefTablePrefix: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsInsectsTabController,
    controllerAs: 'fieldRecordDetailsLsInsectsTabCtrl',
    template: require('./field-record-details-ls-insects-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsInsectsTabController($scope, $timeout) {
  var fieldRecordDetailsLsInsectsTabCtrl = this;
  fieldRecordDetailsLsInsectsTabCtrl.onModeContactChanged = onModeContactChanged;
  fieldRecordDetailsLsInsectsTabCtrl.onSexeChanged = onSexeChanged;
  fieldRecordDetailsLsInsectsTabCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsLsInsectsTabCtrl.onComportementChanged = onComportementChanged;
  fieldRecordDetailsLsInsectsTabCtrl.onProbabiliteReproductionChanged = onProbabiliteReproductionChanged;
  fieldRecordDetailsLsInsectsTabCtrl.onTypeIndicePresenceChanged = onTypeIndicePresenceChanged;
  fieldRecordDetailsLsInsectsTabCtrl.onChange = onChange;
  fieldRecordDetailsLsInsectsTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsInsectsTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsInsectsTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsInsectsTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsInsectsTabCtrl.isNew = isNew;
  fieldRecordDetailsLsInsectsTabCtrl.isReleveItinerant = isReleveItinerant;

  $scope.$watch(fieldRecordDetailsLsInsectsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsInsectsTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsInsectsTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsLsInsectsTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsInsectsTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsInsectsTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsInsectsTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsInsectsTabCtrl.form = form;
  });

  function onModeContactChanged(modeContact) {
    fieldRecordDetailsLsInsectsTabCtrl.data.modeContact = modeContact ? modeContact.id : null;
    onChange();
  }

  function onSexeChanged(sexe) {
    fieldRecordDetailsLsInsectsTabCtrl.data.sexe = sexe ? sexe.id : null;
    onChange();
  }

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    fieldRecordDetailsLsInsectsTabCtrl.data.stadeDeveloppement = stadeDeveloppement ? stadeDeveloppement.id : null;
    onChange();
  }

  function onComportementChanged(comportement) {
    fieldRecordDetailsLsInsectsTabCtrl.data.comportement = comportement ? comportement.id : null;
    onChange();
  }

  function onProbabiliteReproductionChanged(probabiliteReproduction) {
    fieldRecordDetailsLsInsectsTabCtrl.data.probabiliteReproduction = probabiliteReproduction ? probabiliteReproduction.id : null;
    onChange();
  }

  function onTypeIndicePresenceChanged(typeIndicePresence) {
    fieldRecordDetailsLsInsectsTabCtrl.data.typeIndicePresence = typeIndicePresence ? typeIndicePresence.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsInsectsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsInsectsTabCtrl.onChangeFn()(fieldRecordDetailsLsInsectsTabCtrl.extraTableEnum, {
        data: {
          modeContact: fieldRecordDetailsLsInsectsTabCtrl.data.modeContact,
          sexe: fieldRecordDetailsLsInsectsTabCtrl.data.sexe,
          stadeDeveloppement: fieldRecordDetailsLsInsectsTabCtrl.data.stadeDeveloppement,
          comportement: fieldRecordDetailsLsInsectsTabCtrl.data.comportement,
          probabiliteReproduction: fieldRecordDetailsLsInsectsTabCtrl.data.probabiliteReproduction,
          typeIndicePresence: fieldRecordDetailsLsInsectsTabCtrl.data.typeIndicePresence
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsInsectsTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsInsectsTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsInsectsTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsInsectsTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsInsectsTabCtrl.isNewFn()();
  }

  function isReleveItinerant() {
    return _.includes(['RI_INSECTS_RHO', 'RI_INSECTS_ODO', 'RI_INSECTS_ORTHO', 'RI_INSECTS_OTHER'],
      fieldRecordDetailsLsInsectsTabCtrl.extraTableEnum);
  }

  function isFormValid() {
    return true;
  }
}
