import { NATURALISTE } from '../base/services/url-resolver.service';

export default {
  downloadMediaEndpoint: {
    server: NATURALISTE,
    path: '/media/{mediaId}'
  },
  uploadMediaEndpoint: {
    server: NATURALISTE,
    path: '/media'
  }
};
