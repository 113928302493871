import { EXTRA_TABLE } from '../model';

export default { toTableName, toInfoFieldsToClone };

function toTableName(enumName) {
  switch (enumName) {
    case EXTRA_TABLE.LS_AMPHIBIA:
      return 'ET_FR_LS_AMPHIBIA';
    case EXTRA_TABLE.LS_BIRDS:
      return 'ET_FR_LS_BIRDS';
    case EXTRA_TABLE.LS_FISHES:
      return 'ET_FR_LS_FISHES';
    case EXTRA_TABLE.LS_INSECTS_ODO:
      return 'ET_FR_LS_INSECTS_ODO';
    case EXTRA_TABLE.LS_INSECTS_ORTHO:
      return 'ET_FR_LS_INSECTS_ORTHO';
    case EXTRA_TABLE.LS_INSECTS_OTHER:
      return 'ET_FR_LS_INSECTS_OTHER';
    case EXTRA_TABLE.LS_INSECTS_RHO:
      return 'ET_FR_LS_INSECTS_RHO';
    case EXTRA_TABLE.LS_MAMMALS:
      return 'ET_FR_LS_MAMMALS';
    case EXTRA_TABLE.LS_MOLLUSCS:
      return 'ET_FR_LS_MOLLUSCS';
    case EXTRA_TABLE.LS_PLANTS:
      return 'ET_FR_LS_PLANTS';
    case EXTRA_TABLE.LS_REPTILES:
      return 'ET_FR_LS_REPTILES';
    case EXTRA_TABLE.RI_AMPHIBIA:
      return 'ET_FR_LS_AMPHIBIA';
    case EXTRA_TABLE.RI_BIRDS:
      return 'ET_FR_LS_BIRDS';
    case EXTRA_TABLE.RI_FISHES:
      return 'ET_FR_LS_FISHES';
    case EXTRA_TABLE.RI_INSECTS_ODO:
      return 'ET_FR_LS_INSECTS_ODO';
    case EXTRA_TABLE.RI_INSECTS_ORTHO:
      return 'ET_FR_LS_INSECTS_ORTHO';
    case EXTRA_TABLE.RI_INSECTS_OTHER:
      return 'ET_FR_LS_INSECTS_OTHER';
    case EXTRA_TABLE.RI_INSECTS_RHO:
      return 'ET_FR_LS_INSECTS_RHO';
    case EXTRA_TABLE.RI_MAMMALS:
      return 'ET_FR_LS_MAMMALS';
    case EXTRA_TABLE.RI_MOLLUSCS:
      return 'ET_FR_LS_MOLLUSCS';
    case EXTRA_TABLE.RI_PLANTS:
      return 'ET_FR_LS_PLANTS';
    case EXTRA_TABLE.RI_REPTILES:
      return 'ET_FR_LS_REPTILES';
    case EXTRA_TABLE.RP_PLANTS:
      return 'ET_FR_RP_PLANTS';
    case EXTRA_TABLE.RA_BATS:
      return 'ET_FR_RA_BATS';
    case EXTRA_TABLE.GENETIC_SAMPLING:
      return 'ET_FR_GENETIC_SAMPLING';
    default:
      return null;
  }
}

function toInfoFieldsToClone(enumName) {
  switch (enumName) {
    case EXTRA_TABLE.LS_AMPHIBIA:
      return null;
    case EXTRA_TABLE.LS_BIRDS:
      return null;
    case EXTRA_TABLE.LS_FISHES:
      return null;
    case EXTRA_TABLE.LS_INSECTS_ODO:
      return null;
    case EXTRA_TABLE.LS_INSECTS_ORTHO:
      return null;
    case EXTRA_TABLE.LS_INSECTS_OTHER:
      return null;
    case EXTRA_TABLE.LS_INSECTS_RHO:
      return null;
    case EXTRA_TABLE.LS_MAMMALS:
      return null;
    case EXTRA_TABLE.LS_MOLLUSCS:
      return ['TYPE_INDICE_PRESENCE'];
    case EXTRA_TABLE.LS_PLANTS:
      return null;
    case EXTRA_TABLE.LS_REPTILES:
      return null;
    case EXTRA_TABLE.RI_AMPHIBIA:
      return null;
    case EXTRA_TABLE.RI_BIRDS:
      return null;
    case EXTRA_TABLE.RI_FISHES:
      return null;
    case EXTRA_TABLE.RI_INSECTS_ODO:
      return null;
    case EXTRA_TABLE.RI_INSECTS_ORTHO:
      return null;
    case EXTRA_TABLE.RI_INSECTS_OTHER:
      return null;
    case EXTRA_TABLE.RI_INSECTS_RHO:
      return null;
    case EXTRA_TABLE.RI_MAMMALS:
      return null;
    case EXTRA_TABLE.RI_MOLLUSCS:
      return ['TYPE_INDICE_PRESENCE'];
    case EXTRA_TABLE.RI_PLANTS:
      return null;
    case EXTRA_TABLE.RI_REPTILES:
      return null;
    case EXTRA_TABLE.RP_PLANTS:
      return ['STRATE', 'ABONDANCE_DOMINANCE', 'SOCIABILITE'];
    default:
      return null;
  }
}
