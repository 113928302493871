import angular from 'angular';

import taxonSearchContainerComponent from './components/taxon-search-container/taxon-search-container.component';
import taxonSearchFormComponent from './components/taxon-search-form/taxon-search-form.component';
import taxonSearchResultsComponent from './components/taxon-search-results/taxon-search-results.component';
import taxonDetailsComponent from './components/taxon-details/taxon-details.component';
import selectReferentielComponent from './components/select-referentiel/select-referentiel.component';
import selectFilterComponent from './components/select-filter/select-filter.component';
import selectTaxonComponent from './components/select-taxon/select-taxon.component';
import taxonSearchStore from './stores/taxon-search.store';
import taxonSearchByTxGroupStore from './stores/taxon-search-by-txgroups.store';
import taxonRefStore from './stores/taxon-ref.store';
import taxonRefFilterStore from './stores/taxon-ref-filter.store';
import taxonDetailsStore from './stores/taxon-details.store';
import taxonSearchService from './services/taxon-search.service';
import taxonSearchByTxGroupService from './services/taxon-search-by-txgroups.service';
import taxonDetailsService from './services/taxon-details.service';
import taxonRemoteRepository from './repositories/taxon.remote.repository';
import taxonLocalRepository from './repositories/taxon.local.repository';
import taxonRefRemoteRepository from './repositories/taxon-ref.remote.repository';
import taxonRefLocalRepository from './repositories/taxon-ref.local.repository';
import txRefTypeFilter from './filters/txreftype-key.filter';
import txRefFilterFilter from './filters/txreffilter-key.filter';
import taxonStatusTypeFilter from './filters/taxon-status-type.filter';
import taxonStatusRareteFilter from './filters/taxon-status-rarete.filter';
import { TaxonStatusService } from './services/taxon-status.service';
import taxonStatusServiceLocal from './services/taxon-status-fake.service';
import { TaxonService } from './services/taxon.service';
import taxonServiceLocal from './services/taxon-fake.service';
import taxonStatusStore from './stores/taxon-status.store';
import taxonStore from './stores/taxon.store';
import routerConfig from './routes';
import taxonConfiguration from './configuration';

require('./taxon.scss');

const moduleName = 'shu-taxon';

angular
  .module(moduleName, ['lumx', 'lumx.select'])
  .directive('shuTaxonSearchContainer', taxonSearchContainerComponent)
  .directive('shuTaxonSearchForm', taxonSearchFormComponent)
  .directive('shuTaxonSearchResults', taxonSearchResultsComponent)
  .directive('shuSelectReferentiel', selectReferentielComponent)
  .directive('shuSelectFilter', selectFilterComponent)
  .directive('shuSelectTaxon', selectTaxonComponent)
  .directive('shuTaxonDetails', taxonDetailsComponent)
  .service('TaxonStatusService', IS_CORDOVA ? taxonStatusServiceLocal : TaxonStatusService)
  .service('TaxonService', IS_CORDOVA ? taxonServiceLocal : TaxonService)
  .factory('TaxonStatusStore', taxonStatusStore)
  .factory('TaxonStore', taxonStore)
  .factory('TaxonSearchStore', taxonSearchStore)
  .factory('TaxonSearchByTxGroupStore', taxonSearchByTxGroupStore)
  .factory('TaxonRefStore', taxonRefStore)
  .factory('TaxonRefFilterStore', taxonRefFilterStore)
  .factory('TaxonDetailsStore', taxonDetailsStore)
  .factory('TaxonSearchService', taxonSearchService)
  .factory('TaxonSearchByTxGroupService', taxonSearchByTxGroupService)
  .factory('TaxonDetailsService', taxonDetailsService)
  // FIXME: Avoir un repository pour Cordova et un autre pour le web
  .factory('TaxonRepository', IS_CORDOVA ? taxonLocalRepository : taxonRemoteRepository)
  .factory('TaxonRefRepository', IS_CORDOVA ? taxonRefLocalRepository : taxonRefRemoteRepository)
  .filter('TaxonStatusTypeFilter', taxonStatusTypeFilter)
  .filter('TaxonStatusRareteFilter', taxonStatusRareteFilter)
  .filter('txRefTypeKeyLabel', txRefTypeFilter)
  .filter('txRefFilterKeyLabel', txRefFilterFilter)
  .constant('TaxonConfiguration', taxonConfiguration)
  .config(routerConfig);

export default { moduleName };
