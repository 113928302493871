import _ from 'lodash';

import { EVENTS as PROTOCOL_LIST_STORE_EVENTS } from '../../stores/protocol-list.store';

require('./select-protocol.scss');

export default SelectProtocolDirective;

// @ngInject
function SelectProtocolDirective() {
  return {
    restrict: 'E',
    scope: {
      initialValueIdProvider: '&initialValueId',
      placeholder: '@',
      required: '@',
      onChangeFn: '&onChange',
      canEditProvider: '&canEdit'
    },
    bindToController: true,
    controller: SelectProtocolController,
    controllerAs: 'selectProtocolCtrl',
    template: require('./select-protocol.html'),
    replace: true
  };
}

// @ngInject
function SelectProtocolController($rootScope, $scope, ProtocolListStore, ProtocolListService) {
  var selectProtocolCtrl = this;

  selectProtocolCtrl.onChange = onChange;
  selectProtocolCtrl.currentValue = null;
  selectProtocolCtrl.deletedValue = null;

  $scope.$on(PROTOCOL_LIST_STORE_EVENTS.PROTOCOL_LIST_RESULTS_STATE_CHANGED, reloadResults);
  selectProtocolCtrl.availableValues = [];
  selectProtocolCtrl.state = {};
  ProtocolListService.load();

  $scope.$watch(selectProtocolCtrl.canEditProvider, (canEdit) => {
    selectProtocolCtrl.canEdit = canEdit;
  });

  var removeWatcher = $scope.$watch(selectProtocolCtrl.initialValueIdProvider, (initialValueId) => {
    if (!initialValueId) {
      return;
    }

    selectProtocolCtrl.initialValueId = initialValueId;
    updateInitialValue();

    removeWatcher();
  });

  function reloadResults() {
    selectProtocolCtrl.state = ProtocolListStore.getState();

    if (!selectProtocolCtrl.state.results) {
      return;
    }

    selectProtocolCtrl.availableValues = _.reject(selectProtocolCtrl.state.results, 'deleted');
    updateInitialValue();
  }

  function updateInitialValue() {
    if (!selectProtocolCtrl.initialValueId || !selectProtocolCtrl.state.loaded) {
      return;
    }

    var initialValue = _.find(selectProtocolCtrl.state.results, {id: selectProtocolCtrl.initialValueId});
    if (!initialValue) {
      return;
    }

    onChange(initialValue);
    if (initialValue.deleted) {
      selectProtocolCtrl.deletedValue = initialValue;
      return;
    }

    selectProtocolCtrl.currentValue = initialValue;
  }

  function onChange(newValue) {
    if (!newValue) {
      return;
    }
    selectProtocolCtrl.onChangeFn() && selectProtocolCtrl.onChangeFn()(newValue);
  }
}
