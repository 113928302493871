const EVENTS = {
  // Action lancée quand l'utilisateur met à jour les données,
  FIELD_RECORD_HEADCOUNT_PROCESSING: 'shu-field-record.field-record-headcount-processing',
  FIELD_RECORD_HEADCOUNT_PROCESSING_SUCCESS: 'shu-field-record.field-record-headcount-processing-success',
  FIELD_RECORD_HEADCOUNT_PROCESSING_ERROR: 'shu-field-record.field-record-headcount-processing-error'
};

export default FieldRecordHeadcountService;

// @ngInject
function FieldRecordHeadcountService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {update};

  function update(fieldRecordId, headcount) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_HEADCOUNT_PROCESSING);
    FieldRecordRepository.updateHeadcount(fieldRecordId, headcount)
      .then(
        () => {
          LxNotificationService.success('Effectif enregistré');
          $rootScope.$emit(EVENTS.FIELD_RECORD_HEADCOUNT_PROCESSING_SUCCESS, fieldRecordId, headcount);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue à la sauvegarde de l\'effectif. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_RECORD_HEADCOUNT_PROCESSING_ERROR);
        }
      );
  }
}
export { EVENTS };
