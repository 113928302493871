
import _ from 'lodash';

require('./field-record-details-genetic-sampling-tab.scss');

export default FieldRecordDetailsGeneticSamplingTabDirective;

// @ngInject
function FieldRecordDetailsGeneticSamplingTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsGeneticSamplingTabController,
    controllerAs: 'fieldRecordDetailsGeneticSamplingTabCtrl',
    template: require('./field-record-details-genetic-sampling-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsGeneticSamplingTabController($scope, $timeout) {
  var fieldRecordDetailsGeneticSamplingTabCtrl = this;
  fieldRecordDetailsGeneticSamplingTabCtrl.onSampleTypeChanged = onSampleTypeChanged;
  fieldRecordDetailsGeneticSamplingTabCtrl.onChange = onChange;
  fieldRecordDetailsGeneticSamplingTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsGeneticSamplingTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsGeneticSamplingTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsGeneticSamplingTabCtrl.canEdit = canEdit;
  fieldRecordDetailsGeneticSamplingTabCtrl.isNew = isNew;

  fieldRecordDetailsGeneticSamplingTabCtrl.customSampleType = false;

  $scope.$watch(fieldRecordDetailsGeneticSamplingTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsGeneticSamplingTabCtrl.data = {};
    } else {
      fieldRecordDetailsGeneticSamplingTabCtrl.data = _.cloneDeep(initialData);
      //fieldRecordDetailsGeneticSamplingTabCtrl.defaultSampleTypeKey = 'A';
    }

    fieldRecordDetailsGeneticSamplingTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsGeneticSamplingTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsGeneticSamplingTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsGeneticSamplingTabCtrl.formProvider, (form) => {
    fieldRecordDetailsGeneticSamplingTabCtrl.form = form;
  });

  function onSampleTypeChanged(sampleType) {

    fieldRecordDetailsGeneticSamplingTabCtrl.customSampleType = (sampleType && sampleType.key === 'OTHER');
    
    fieldRecordDetailsGeneticSamplingTabCtrl.data.sampleType = sampleType ? sampleType.id : null;
	  onChange();
	}

  function onChange() {
    if (!fieldRecordDetailsGeneticSamplingTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsGeneticSamplingTabCtrl.onChangeFn()(fieldRecordDetailsGeneticSamplingTabCtrl.extraTableEnum, {
        data: {
        	sampleType: fieldRecordDetailsGeneticSamplingTabCtrl.data.sampleType,
          customSampleType: fieldRecordDetailsGeneticSamplingTabCtrl.data.customSampleType,
          sampleKey: fieldRecordDetailsGeneticSamplingTabCtrl.data.sampleKey,
          comment: fieldRecordDetailsGeneticSamplingTabCtrl.data.comment,
          pictureNames: fieldRecordDetailsGeneticSamplingTabCtrl.data.pictureNames,
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsGeneticSamplingTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsGeneticSamplingTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsGeneticSamplingTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsGeneticSamplingTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsGeneticSamplingTabCtrl.isNewFn()();
  }

  function isFormValid() {
    return true;
  }
}
