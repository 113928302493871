export default BiotopeRefRepository;

// @ngInject
function BiotopeRefRepository(DatabaseProvider, StorageService) {
  return {list};

  function list(tableName) {
    return DatabaseProvider.getDatabase()
      .then((database) => {
        return StorageService.executeSqlQuery(database, `
            SELECT *, is_header AS header
              FROM biotope_ref_${ tableName }
              ORDER BY level ASC, label ASC
          `);
      });
  }
}
