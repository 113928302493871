import _ from 'lodash';

import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as STUDY_STORE_EVENTS } from '../../stores/study-details.store';
import { EVENTS as SURVEY_TYPE_LIST_STORE_EVENTS } from '../../../survey-type/stores/survey-type-list.store';
import { EVENTS as STUDY_EXPERT_LAYER_STORE_EVENTS } from '../../stores/study-expert-layer.store';
import { EVENTS as KML_SERVICE_EVENTS } from '../../services/study-kml.service';
import { EVENTS as SYNC_STORE_EVENTS } from '../../../synchro/stores/study-synchronizer.store';
import { EVENTS as IMPORT_STORE_EVENTS } from '../../../synchro/stores/study-importer.store';
import Immutable from 'seamless-immutable';

require('./study-details-container.scss');

export default StudyDetailsContainerDirective;

// @ngInject
function StudyDetailsContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
    },
    bindToController: true,
    controller: StudyDetailsContainerController,
    controllerAs: 'studyDetailsContainerCtrl',
    template: require('./study-details-container.html'),
    replace: true,
  };
}

// @ngInject
function StudyDetailsContainerController(
  $scope,
  $state,
  HeaderService,
  LxNotificationService,
  StudyDetailsStore,
  StudyDetailsService,
  StudyKmlService,
  SurveyTypeListStore,
  SurveyTypeListService,
  StudySynchronizerStore,
  StudySynchronizerService,
  StudyImporterStore,
  StudyImporterService,
  StudyExpertLayerService,
  StudyExpertLayerStore,
  $rootScope,
  StudyRepository
) {
  var studyDetailsContainerCtrl = this;

  studyDetailsContainerCtrl.studyState = {};
  studyDetailsContainerCtrl.surveyTypeListState = {};
  studyDetailsContainerCtrl.layersState = {};
  studyDetailsContainerCtrl.uploadingKml = false;
  studyDetailsContainerCtrl.processingLayers = false;
  studyDetailsContainerCtrl.headerUpdated = false;
  studyDetailsContainerCtrl.isTxRefNeedUpdate = false;

  studyDetailsContainerCtrl.isLoaded = isLoaded;
  studyDetailsContainerCtrl.isProcessing = isProcessing;
  studyDetailsContainerCtrl.canCreateSubElements = canCreateSubElements;
  studyDetailsContainerCtrl.onFormSubmit = StudyDetailsService.onFormSubmit;
  studyDetailsContainerCtrl.onSubmitKml = StudyKmlService.onUpdateKml;
  studyDetailsContainerCtrl.onSubmitLayer = StudyExpertLayerService.onUploadLayer;
  studyDetailsContainerCtrl.onRemoveLayer = StudyExpertLayerService.onRemoveLayer;
  studyDetailsContainerCtrl.onCreateSurveyType = onCreateSurveyType;
  studyDetailsContainerCtrl.onSyncSubmit = onSyncSubmit;
  studyDetailsContainerCtrl.onSyncMapSubmit = onSyncMapSubmit;
  studyDetailsContainerCtrl.onExportMbTiles = onExportMbTiles;
  studyDetailsContainerCtrl.onJoinStudy = onJoinStudy;
  studyDetailsContainerCtrl.checkTxRefs = checkTxRefs;
  

  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(STUDY_EXPERT_LAYER_STORE_EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED, reloadLayers);

  $scope.$on(KML_SERVICE_EVENTS.STUDY_KML_UPDATING, onUploadingKml);
  $scope.$on(KML_SERVICE_EVENTS.STUDY_KML_UPDATE_SUCCESS, onUploadKmlSuccess);
  $scope.$on(KML_SERVICE_EVENTS.STUDY_KML_UPDATE_ERROR, onUploadKmlError);

  $scope.$on(SURVEY_TYPE_LIST_STORE_EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED, reloadSurveyTypeList);

  $rootScope.$on('AUTO_SYNC_TERMINATED', () => {
    StudyRepository.getStudy(studyDetailsContainerCtrl.studyId).then((study) => {
      studyDetailsContainerCtrl.studyState = Immutable({
        study,
        processing: false,
        loaded: true,
        error: false,
      });
    });
  });
  $scope.$on(SYNC_STORE_EVENTS.STUDY_SYNC_STATE_CHANGED, onSyncStateChanged);
  $scope.$on(IMPORT_STORE_EVENTS.STUDY_IMPORTER_SYNC_STATE_CHANGED, onImportStateChanged);

  loadStudy();
  checkTxRefs();

  function isLoaded() {
    return (
      studyDetailsContainerCtrl.surveyTypeListState &&
      studyDetailsContainerCtrl.surveyTypeListState.loaded &&
      studyDetailsContainerCtrl.studyState &&
      studyDetailsContainerCtrl.studyState.loaded
    );
  }

  function isProcessing() {
    return studyDetailsContainerCtrl.studyState && studyDetailsContainerCtrl.studyState.processing;
  }

  function canCreateSubElements() {
    return (
      studyDetailsContainerCtrl.studyState &&
      studyDetailsContainerCtrl.studyState.study &&
      studyDetailsContainerCtrl.studyState.study.canCreateSubElements &&
      studyDetailsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      studyDetailsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE
    );
  }

  function reload() {
    studyDetailsContainerCtrl.studyState = StudyDetailsStore.getState();

    if (studyDetailsContainerCtrl.studyState.error) {
      return;
    }
    updateHeader();
  }

  function reloadLayers() {
    studyDetailsContainerCtrl.layersState = StudyExpertLayerStore.getState();
  }

  function updateHeader() {
    if (!studyDetailsContainerCtrl.studyState.study || studyDetailsContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: studyDetailsContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: { studyId: studyDetailsContainerCtrl.studyId },
        },
      },
    ]);
    studyDetailsContainerCtrl.headerUpdated = true;
  }

  function reloadSurveyTypeList() {
    studyDetailsContainerCtrl.surveyTypeListState = SurveyTypeListStore.getState();
  }

  function loadStudy() {
    StudyDetailsService.load(studyDetailsContainerCtrl.studyId);
    StudyExpertLayerService.load(studyDetailsContainerCtrl.studyId);
    SurveyTypeListService.load(studyDetailsContainerCtrl.studyId);
  }

  function onCreateSurveyType() {
    $state.go('survey-type-new', { studyId: studyDetailsContainerCtrl.studyId });
  }

  function onUploadingKml() {
    studyDetailsContainerCtrl.uploadingKml = true;
  }
  function onUploadKmlSuccess(event, studyId, area) {
    studyDetailsContainerCtrl.uploadingKml = false;
    studyDetailsContainerCtrl.geographyArea = area;
  }
  function onUploadKmlError() {
    studyDetailsContainerCtrl.uploadingKml = false;
  }
  function onSyncStateChanged() {
    studyDetailsContainerCtrl.syncState = StudySynchronizerStore.getState();

    if (studyDetailsContainerCtrl.syncState.terminated && !studyDetailsContainerCtrl.syncState.unlock) {
      loadStudy();
    }
  }
  function onImportStateChanged() {
    var state = _.defaults(
      {
        terminated: StudyImporterStore.getSyncState().terminated,
      },
      StudyImporterStore.getSyncState().studies.length ? StudyImporterStore.getSyncState().studies[0] : {}
    );
    studyDetailsContainerCtrl.importState = state;
    if (studyDetailsContainerCtrl.importState.terminated) {
      // Rechargement des données de l'étude (couches KML)
      loadStudy();
    }
  }
  function onSyncSubmit(save, unlock) {
    if (save || !unlock) {
      StudySynchronizerService.syncStudy(studyDetailsContainerCtrl.studyId, save, unlock);
      return;
    }
    LxNotificationService.confirm(
      'Attention',
      'Êtes-vous sur de vouloir abandonner vos modifications en local ?',
      { cancel: 'Non', ok: 'Oui' },
      (answer) => answer && StudySynchronizerService.syncStudy(studyDetailsContainerCtrl.studyId, save, unlock)
    );
  }
  function onSyncMapSubmit(imagerySets, refreshExpertLayers) {
    StudyImporterService.importStudiesMap(
      studyDetailsContainerCtrl.studyId,
      imagerySets,
      studyDetailsContainerCtrl.studyState.study.imagerySets,
      refreshExpertLayers
    );
  }

  function onExportMbTiles() {
    StudyDetailsService.exportMbTiles(
      studyDetailsContainerCtrl.studyId,
      studyDetailsContainerCtrl.studyState.study.key,
      studyDetailsContainerCtrl.studyState.study.imagerySets
    );
  }
  function onJoinStudy() {
    StudyDetailsService.joinStudy(studyDetailsContainerCtrl.studyId);
  }

  function checkTxRefs(){
     StudyRepository.isStudyHasOutdatedTxRefs(studyDetailsContainerCtrl.studyId)
    .then((asOutdateds) => {
      studyDetailsContainerCtrl.isTxRefNeedUpdate = asOutdateds;
      });
    return studyDetailsContainerCtrl.isTxRefNeedUpdate;
  }
}
