import 'core-js/es6/symbol';
import 'jquery';
import 'velocity-animate';
import 'moment';
import angular  from 'angular';
import 'angular-sanitize';
import 'angular-i18n/fr-fr.js';
import '../../libs/lumx/dist/lumx.js';
import '@uirouter/angularjs'
import 'angular-filter'

import * as referentielApi from '@biotope/shuriken-ng-referentiel-api'
import * as statusApi from '@biotope/shuriken-status-api'

// Workaround pour que les modifications sur le fichier index.html soient
// surveillées par Webpack
// Le if permet de ne pas ajouter cette dépendence en mode production
if (process.env.NODE_ENV === 'development') {
  require('raw-loader!./index.html');
}

require('./app.scss');

import baseModule from '../../share/src/base/base.module';
import authModule from '../../share/src/auth/auth.module';
import profileModule from '../../share/src/profile/profile.module';
import settingsModule from '../../share/src/settings/settings.module';
import taxonModule from '../../share/src/taxon/taxon.module';
import studyModule from '../../share/src/study/study.module';
import mapModule from '../../share/src/map/map.module';
import synchroModule from '../../share/src/synchro/synchro-fake.module';
import expertModule from '../../share/src/expert/expert.module';
import biotopeRefModule from '../../share/src/biotope-ref/biotope-ref.module';
import protocolModule from '../../share/src/protocol/protocol.module';
import surveyTypeModule from '../../share/src/survey-type/survey-type.module';
import fieldRecordModule from '../../share/src/field-record/field-record.module';
import txGroupModule from '../../share/src/txgroup/txgroup.module';
import terrUnitModule from '../../share/src/terr-unit/terr-unit.module';
import fieldSurveyModule from '../../share/src/field-survey/field-survey.module';
import mediaModule from '../../share/src/media/media.module';
import protectionModule from '../../share/src/protection/protection.module';
import favorisModule from '../../share/src/favoris/favoris.module';
import asyncModule from '../../share/src/async/async.module';
import mainRouterConfig from '../../share/src/main-routes';

import { BasePath } from '../../share/src/base-path-provider';
import { REFERENTIEL } from '../../share/src/base/services/url-resolver.service';
import { STATUS } from '../../share/src/base/services/url-resolver.service';

referentielApi.apiModule
  .provider('referentielApiBasePath', BasePath)
  .config(/** @ngInject */ (referentielApiBasePathProvider: BasePath) => {
        referentielApiBasePathProvider.setBasePath(REFERENTIEL);
});

statusApi.apiModule
  .provider('basePath', BasePath)
  .config(/** @ngInject */ (basePathProvider: BasePath) => {
        basePathProvider.setBasePath(STATUS);
});

angular.module('shuriken-ng', [
  baseModule.moduleName,
  profileModule.moduleName,
  settingsModule.moduleName,
  authModule.moduleName,
  taxonModule.moduleName,
  studyModule.moduleName,
  expertModule.moduleName,
  biotopeRefModule.moduleName,
  protocolModule.moduleName,
  surveyTypeModule.moduleName,
  fieldSurveyModule.moduleName,
  mapModule.moduleName,
  txGroupModule.moduleName,
  terrUnitModule.moduleName,
  fieldRecordModule.moduleName,
  synchroModule.moduleName,
  mediaModule.moduleName,
  protectionModule.moduleName,
  favorisModule.moduleName,
  'referentiel-api', //referentielApi.apiModule
  'api', //statusApi.apiModule
  'lumx',
  'lumx.utils',
  'ngLocale',
  'ngSanitize',
  'ui.router',
  'angular.filter',
   asyncModule.moduleName
])
.config(mainRouterConfig);

angular.element(document.body).ready(function() {
  angular.bootstrap(document.body, ['shuriken-ng']);
});
