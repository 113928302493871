import angular from 'angular';

import favorisService from './services/favoris.service';
import favorisStore from './stores/favoris.store';
import favorisUtils from './tools/favoris-utils';
import favorisRepository from './repositories/favoris.repository';

const moduleName = 'shu-favoris';

angular.module(moduleName, ['lumx'])
  .factory('FavorisRepository', favorisRepository)
  .factory('FavorisService', favorisService)
  .factory('FavorisStore', favorisStore)
  .factory('FavorisUtils', favorisUtils);

export default {moduleName};
