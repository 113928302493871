import _ from 'lodash';

require('./field-survey-list.scss');

import TxgroupUtils from '../../../txgroup/tools/txgroup-utils';

export default FieldSurveyListDirective;

// @ngInject
function FieldSurveyListDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      webClientProvider: '&webClient',
      fieldSurveyListProvider: '&fieldSurveyList',
      canCreateStudySubElementsFn: '&canCreateStudySubElements',
      onFieldSurveySelectedFn: '&onFieldSurveySelected',
      onFieldSurveyForClonageFn: '&onFieldSurveyForClonage',
    },
    bindToController: true,
    controller: FieldSurveyListController,
    controllerAs: 'fieldSurveyListCtrl',
    template: require('./field-survey-list.html'),
    replace: true,
  };
}

// @ngInject
function FieldSurveyListController($scope, $state) {
  var fieldSurveyListCtrl = this;

  fieldSurveyListCtrl.webClient = fieldSurveyListCtrl.webClientProvider();
  fieldSurveyListCtrl.canCreateFieldRecord = canCreateFieldRecord;
  fieldSurveyListCtrl.onFieldSurveySelected = onFieldSurveySelected;
  fieldSurveyListCtrl.onCreateFieldRecord = onCreateFieldRecord;
  fieldSurveyListCtrl.onFieldSurveyForClonage = onFieldSurveyForClonage;
  fieldSurveyListCtrl.canCreateStudySubElements = fieldSurveyListCtrl.canCreateStudySubElementsFn();

  $scope.$watch(fieldSurveyListCtrl.fieldSurveyListProvider, (fieldSurveyList) => {
    fieldSurveyListCtrl.fieldSurveyList = _.map(fieldSurveyList, (fieldSurvey) => {
      let surveyType = _.defaults(
        { protocolTxGroups: TxgroupUtils.sortBy(fieldSurvey.surveyType.protocolTxGroups, 'txGroup') },
        fieldSurvey.surveyType
      );
      return _.defaults({ surveyType }, fieldSurvey);
    });
  });

  function canCreateFieldRecord(fieldSurvey) {
    return fieldSurveyListCtrl.canCreateStudySubElementsFn()() && fieldSurvey.canEdit;
  }

  function onFieldSurveySelected(fieldSurveyId) {
    fieldSurveyListCtrl.fieldSurveySelected = fieldSurveyId;
    fieldSurveyListCtrl.onFieldSurveySelectedFn()(fieldSurveyId);
  }

  function onFieldSurveyForClonage($event, fieldSurvey) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    $state.go('field-survey-clonage', { studyId: fieldSurveyListCtrl.studyId, fieldSurveyClonageId: fieldSurvey.id });
  }

  function onCreateFieldRecord($event, fieldSurvey, txGroup) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    let params = {
      studyId: fieldSurveyListCtrl.studyId,
      fieldSurveyId: fieldSurvey.id,
      txRefKey: fieldSurvey.surveyType.defaultTxRefType,
      txRefFilter: fieldSurvey.surveyType.defaultTxRefFilter,
      txGroup: txGroup,
    };
    $state.go('field-record-creation', params);
  }
}
