import _ from 'lodash';

require('./field-record-details-ls-mammals-tab.scss');

export default FieldRecordDetailsLsMammalsTabDirective;

// @ngInject
function FieldRecordDetailsLsMammalsTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsMammalsTabController,
    controllerAs: 'fieldRecordDetailsLsMammalsTabCtrl',
    template: require('./field-record-details-ls-mammals-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsMammalsTabController($scope, $timeout) {
  var fieldRecordDetailsLsMammalsTabCtrl = this;
  fieldRecordDetailsLsMammalsTabCtrl.onModeContactChanged = onModeContactChanged;
  fieldRecordDetailsLsMammalsTabCtrl.onSexeChanged = onSexeChanged;
  fieldRecordDetailsLsMammalsTabCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsLsMammalsTabCtrl.onComportementChanged = onComportementChanged;
  fieldRecordDetailsLsMammalsTabCtrl.onProbabiliteReproductionChanged = onProbabiliteReproductionChanged;
  fieldRecordDetailsLsMammalsTabCtrl.onTypeIndicePresenceChanged = onTypeIndicePresenceChanged;
  fieldRecordDetailsLsMammalsTabCtrl.onChange = onChange;
  fieldRecordDetailsLsMammalsTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsMammalsTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsMammalsTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsMammalsTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsMammalsTabCtrl.isNew = isNew;
  fieldRecordDetailsLsMammalsTabCtrl.isReleveItinerant = isReleveItinerant;

  $scope.$watch(fieldRecordDetailsLsMammalsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsMammalsTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsMammalsTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsLsMammalsTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsMammalsTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsMammalsTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsMammalsTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsMammalsTabCtrl.form = form;
  });

  function onModeContactChanged(modeContact) {
    fieldRecordDetailsLsMammalsTabCtrl.data.modeContact = modeContact ? modeContact.id : null;
    onChange();
  }

  function onSexeChanged(sexe) {
    fieldRecordDetailsLsMammalsTabCtrl.data.sexe = sexe ? sexe.id : null;
    onChange();
  }

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    fieldRecordDetailsLsMammalsTabCtrl.data.stadeDeveloppement = stadeDeveloppement ? stadeDeveloppement.id : null;
    onChange();
  }

  function onComportementChanged(comportement) {
    fieldRecordDetailsLsMammalsTabCtrl.data.comportement = comportement ? comportement.id : null;
    onChange();
  }

  function onProbabiliteReproductionChanged(probabiliteReproduction) {
    fieldRecordDetailsLsMammalsTabCtrl.data.probabiliteReproduction = probabiliteReproduction ? probabiliteReproduction.id : null;
    onChange();
  }

  function onTypeIndicePresenceChanged(typeIndicePresence) {
    fieldRecordDetailsLsMammalsTabCtrl.data.typeIndicePresence = typeIndicePresence ? typeIndicePresence.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsMammalsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsMammalsTabCtrl.onChangeFn()(fieldRecordDetailsLsMammalsTabCtrl.extraTableEnum, {
        data: {
          modeContact: fieldRecordDetailsLsMammalsTabCtrl.data.modeContact,
          sexe: fieldRecordDetailsLsMammalsTabCtrl.data.sexe,
          stadeDeveloppement: fieldRecordDetailsLsMammalsTabCtrl.data.stadeDeveloppement,
          comportement: fieldRecordDetailsLsMammalsTabCtrl.data.comportement,
          probabiliteReproduction: fieldRecordDetailsLsMammalsTabCtrl.data.probabiliteReproduction,
          typeIndicePresence: fieldRecordDetailsLsMammalsTabCtrl.data.typeIndicePresence
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsMammalsTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsMammalsTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsMammalsTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsMammalsTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsMammalsTabCtrl.isNewFn()();
  }

  function isReleveItinerant() {
    return fieldRecordDetailsLsMammalsTabCtrl.extraTableEnum === 'RI_MAMMALS';
  }

  function isFormValid() {
    return true;
  }
}
