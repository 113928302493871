const IMAGERY_SET = {
  AERIAL: 'Aerial',
  AERIAL_WITH_LABEL: 'AerialWithLabels',
  ROAD: 'Road',
  VALUES: [
    'Aerial',
    'AerialWithLabels',
    'Road'
  ],
  DEFAULT: 'Road',
  DEFAULT_TO_IMPORT: [
    'Aerial',
    'Road'
  ]
};

export { IMAGERY_SET };
