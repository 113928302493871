import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxon-details.service';

const EVENTS = {
  TAXON_DETAILS_STATE_CHANGED: 'shu-taxon-details.taxon-details-state-changed'
};

export default TaxonSearchStore;

// @ngInject
function TaxonSearchStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXON_DETAILS_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXON_DETAILS_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXON_DETAILS_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      taxon: null,
      referentiel: null,
      raretes: null,
      errorRaretes: false,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_DETAILS_STATE_CHANGED);
  }

  function onLoadSuccess(event, taxon, referentiel, filtre, raretes, errorRaretes) {
    state = Immutable({
      taxon,
      referentiel,
      raretes,
      errorRaretes: !!errorRaretes,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_DETAILS_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      taxon: null,
      referentiel: null,
      raretes: null,
      errorRaretes: false,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_DETAILS_STATE_CHANGED);
  }
}

export { EVENTS };
