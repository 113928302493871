import { EVENTS as STUDY_SEARCH_STORE_EVENTS } from '../../stores/study-search.store';

require('./study-search-container.scss');

export default StudySearchContainerDirective;

// @ngInject
function StudySearchContainerDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: StudySearchContainerController,
    controllerAs: 'studySearchContainerCtrl',
    template: require('./study-search-container.html'),
    replace: true
  };
}

// @ngInject
function StudySearchContainerController($scope, $rootScope, HeaderService, StudySearchStore, StudySearchService) {
  var studySearchContainerCtrl = this;

  studySearchContainerCtrl.queryState = {};
  studySearchContainerCtrl.resultsState = {};

  studySearchContainerCtrl.onUpdateForm = StudySearchService.onUpdateForm;
  studySearchContainerCtrl.onStudySelected = StudySearchService.onStudySelected;
  studySearchContainerCtrl.onNextResultsAction = onNextResultsAction;
  studySearchContainerCtrl.showResults = showResults;
  studySearchContainerCtrl.noResultsInNomade = noResultsInNomade;

  $scope.$on(STUDY_SEARCH_STORE_EVENTS.STUDY_SEARCH_QUERY_STATE_CHANGED, reloadQuery);
  $scope.$on(STUDY_SEARCH_STORE_EVENTS.STUDY_SEARCH_RESULTS_STATE_CHANGED, reloadResults);

  resetStudySearch();
  init();

  function init() {
    HeaderService.updateTitle([
      'Mes études'
    ]);
  }

  function showResults() {
    return studySearchContainerCtrl.resultsState.loaded || studySearchContainerCtrl.resultsState.totalResults > 0;
  }

  function noResultsInNomade() {
    return studySearchContainerCtrl.resultsState.loaded && studySearchContainerCtrl.resultsState.totalResults === 0 && IS_CORDOVA;
  }

  function reloadQuery() {
    studySearchContainerCtrl.queryState = StudySearchStore.getQueryState();
  }

  function reloadResults() {
    studySearchContainerCtrl.resultsState = StudySearchStore.getResultsState();
  }

  function resetStudySearch() {
    StudySearchService.reset();
  }

  function onNextResultsAction() {
    StudySearchService.loadNextResults(
      studySearchContainerCtrl.queryState.query,
      studySearchContainerCtrl.queryState.searchAll,
      studySearchContainerCtrl.queryState.status,
      studySearchContainerCtrl.resultsState.results.length
    );
  }
}
