export default PrecisionColorFilter;

// @ngInject
function PrecisionColorFilter() {
  return function(precision) {

    if (precision <= 3) {
      return 'tc-green-500';
    }

    if (precision <= 6) {
      return 'tc-yellow-500';
    }

    if (precision <= 12) {
      return 'tc-orange-500';
    }

    return 'tc-red-500';
  };
}
