require('./field-survey-current.scss');

export default FieldSurveyCurrentDirective;

// @ngInject
function FieldSurveyCurrentDirective() {
  return {
    restrict: 'E',
    scope: {
      onFieldSurveySelectedFn: '&onFieldSurveySelected',
      onStudySelectedFn: '&onStudySelected',
      fieldSurveyCurrentStateProvider: '&fieldSurveyCurrentState'
    },
    controller: FieldSurveyCurrentController,
    controllerAs: 'fieldSurveyCurrentCtrl',
    bindToController: true,
    template: require('./field-survey-current.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyCurrentController($scope) {
  var fieldSurveyCurrentCtrl = this;

  fieldSurveyCurrentCtrl.onFieldSurveySelected = onFieldSurveySelected;
  fieldSurveyCurrentCtrl.onStudySelected = onStudySelected;

  $scope.$watch(fieldSurveyCurrentCtrl.fieldSurveyCurrentStateProvider, (fieldSurveyCurrentState) => {
    fieldSurveyCurrentCtrl.fieldSurveyCurrentState = fieldSurveyCurrentState;
  });

  function onFieldSurveySelected(studyId, fieldSurveyId) {
    fieldSurveyCurrentCtrl.onFieldSurveySelectedFn()(studyId, fieldSurveyId);
  }

  function onStudySelected(studyId) {
    fieldSurveyCurrentCtrl.onStudySelectedFn()(studyId);
  }
}
