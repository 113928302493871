import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../stores/taxon-ref.store';

export default TxRefTypeKeyFilter;

// @ngInject
function TxRefTypeKeyFilter($rootScope, TaxonRefStore, TaxonSearchService) {

  var state = TaxonRefStore.getState();

  TaxonSearchService.loadTxRef();
  $rootScope.$on(STORE_EVENTS.TX_REF_STATE_CHANGED, () => { state = TaxonRefStore.getState(); });

  function filter(input) {

    if (!state.loaded || state.error) {
      return input;
    }

    return _.chain(state.availableReferentiels).find({key: input}).get('name').value() || input;
  }
  filter.$stateful = true;
  return filter;
}

