import _ from 'lodash';
import { EVENTS as STORE_EVENTS } from '../../../txgroup/stores/txgroup-list.store';
require('./field-survey-details-adne-tab.scss');

export default FieldSurveyDetailsADNETabDirective;

// @ngInject
function FieldSurveyDetailsADNETabDirective() {
  return {
    restrict: 'AE',
    scope: {
      initialDataProvider: '&initialData',
      fieldSurveyStateProvider: '&fieldSurveyState',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete',
      canSubmitFn: '&canSubmit',
      isNewFn: '&isNew',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision',
      formProvider: '&form',
    },
    bindToController: true,
    controller: FieldSurveyDetailsADNETabController,
    controllerAs: 'fieldSurveyDetailsADNETabCtrl',
    template: require('./field-survey-details-adne-tab.html'),
    replace: true,
  };
}

// @ngInject
function FieldSurveyDetailsADNETabController($scope, $timeout, TxGroupListService, TxGroupListStore,) {
  var fieldSurveyDetailsADNETabCtrl = this;
  fieldSurveyDetailsADNETabCtrl.onEnvironmentChange = onEnvironmentChange;
  fieldSurveyDetailsADNETabCtrl.onWaterTurbidityChange = onWaterTurbidityChange;
  fieldSurveyDetailsADNETabCtrl.onFiltrationTimeChange = onFiltrationTimeChange;
  fieldSurveyDetailsADNETabCtrl.onTypeAnalysisPerformedChange = onTypeAnalysisPerformedChange;
  fieldSurveyDetailsADNETabCtrl.onChange = onChange;
  fieldSurveyDetailsADNETabCtrl.onDelete = onDelete;
  fieldSurveyDetailsADNETabCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsADNETabCtrl.isNew = isNew;
  fieldSurveyDetailsADNETabCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldSurveyDetailsADNETabCtrl.isAutoLocatedError = isAutoLocatedError;
  fieldSurveyDetailsADNETabCtrl.locationPrecision = locationPrecision;
  fieldSurveyDetailsADNETabCtrl.isValid = false;
  fieldSurveyDetailsADNETabCtrl.isOtherEnvironment = false;
  fieldSurveyDetailsADNETabCtrl.availableGroups = [];
  fieldSurveyDetailsADNETabCtrl.protocolTxGroups = [];
  fieldSurveyDetailsADNETabCtrl.isProtocolTxGroupChecked = isProtocolTxGroupChecked;
  fieldSurveyDetailsADNETabCtrl.toggleProtocolTxGroupChecked = toggleProtocolTxGroupChecked;
  fieldSurveyDetailsADNETabCtrl.protocol = 'ADNE';


  $scope.$watch(fieldSurveyDetailsADNETabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldSurveyDetailsADNETabCtrl.data = {};

    } else {
      var jsonResult = JSON.parse(JSON.stringify(initialData));
      fieldSurveyDetailsADNETabCtrl.data = _.cloneDeep(jsonResult);
      
      //we transform string list of txgroup to an array
      if(initialData.txGroups){
        initialData.txGroups.split(", ").forEach((value)=>{
          fieldSurveyDetailsADNETabCtrl.protocolTxGroups.push(value);
        }) ;
      }
   

    }

    fieldSurveyDetailsADNETabCtrl.onChange();
  });


  $scope.$on(STORE_EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED, () => {
    
    fieldSurveyDetailsADNETabCtrl.availableGroups = _.chain(TxGroupListStore.getState().results)
      .filter((r) => !r.deleted)
      .value();
  });

  TxGroupListService.load();



  $scope.$watch(fieldSurveyDetailsADNETabCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }

    fieldSurveyDetailsADNETabCtrl.fieldSurveyState = fieldSurveyState;
  });

  $scope.$watch(fieldSurveyDetailsADNETabCtrl.formProvider, (form) => {
    fieldSurveyDetailsADNETabCtrl.form = form;

  });

  function isProtocolTxGroupChecked(protocolTxGroup) {

    //comprendre pourquoi 
    //   _.some(fieldSurveyDetailsADNETabCtrl.protocolTxGroups, protocolTxGroup.key);
    //retourne tous le temps false
    if(fieldSurveyDetailsADNETabCtrl.protocolTxGroups.indexOf(protocolTxGroup.key) !== -1) {
      return true;
    }else{
      return false;
    }
  }

  function toggleProtocolTxGroupChecked(protocolTxGroup) {
    if(fieldSurveyDetailsADNETabCtrl.protocolTxGroups.indexOf(protocolTxGroup.key) == -1) {
      fieldSurveyDetailsADNETabCtrl.protocolTxGroups.push(protocolTxGroup.key);
    }else{
      var index = fieldSurveyDetailsADNETabCtrl.protocolTxGroups.indexOf(protocolTxGroup.key)
      fieldSurveyDetailsADNETabCtrl.protocolTxGroups.splice(index, 1);
    }
    
    onChange();
  }

  function onEnvironmentChange(currentEnvironment) {
    fieldSurveyDetailsADNETabCtrl.data.environment = currentEnvironment ? currentEnvironment.id : null;
    if (fieldSurveyDetailsADNETabCtrl.data.environment != null) {
      switch (fieldSurveyDetailsADNETabCtrl.data.environment) {
        //when it's 3 'Autre' a new field is shown. If i'ts another environment
        //we don't show the new field
        case 3:
          fieldSurveyDetailsADNETabCtrl.isOtherEnvironment = true;
          break;
      }
    } else {
      fieldSurveyDetailsADNETabCtrl.isOtherEnvironment = false;
    }
    onChange();
  }

  function onWaterTurbidityChange(currentWaterTurbidity) {
    fieldSurveyDetailsADNETabCtrl.data.waterTurbidity = currentWaterTurbidity ? currentWaterTurbidity.id : null;
    onChange();
  }

  function onFiltrationTimeChange(currentFiltrationTime) {
    fieldSurveyDetailsADNETabCtrl.data.filtrationTime = currentFiltrationTime ;
    onChange();
  }

  function onTypeAnalysisPerformedChange(currentTypeAnalysisPerformed) {
    fieldSurveyDetailsADNETabCtrl.data.typeAnalysisPerformed = currentTypeAnalysisPerformed;
    onChange();
  }

  function onChange() {
    if (!fieldSurveyDetailsADNETabCtrl.data) {
      return;
    }
    if (fieldSurveyDetailsADNETabCtrl.data.environment != 3) fieldSurveyDetailsADNETabCtrl.isOtherEnvironment = false;

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldSurveyDetailsADNETabCtrl.onChangeFn()('ADNE', {
        data: {
          environment: fieldSurveyDetailsADNETabCtrl.data.environment,
          waterTurbidity: fieldSurveyDetailsADNETabCtrl.data.waterTurbidity,
          filtrationTime: fieldSurveyDetailsADNETabCtrl.data.filtrationTime,
          otherEnvironment: fieldSurveyDetailsADNETabCtrl.data.otherEnvironment,
          typeAnalysisPerformed: fieldSurveyDetailsADNETabCtrl.data.typeAnalysisPerformed,
          txGroups: fieldSurveyDetailsADNETabCtrl.protocolTxGroups.join(", "),
        },
        formValid: isFormValid(),
      });
    });


  }

  function onDelete() {
    fieldSurveyDetailsADNETabCtrl.onDeleteFn()();
  }

  function canSubmit() {
    return fieldSurveyDetailsADNETabCtrl.canSubmitFn()();
  }

  function isNew() {
    return fieldSurveyDetailsADNETabCtrl.isNewFn()();
  }

  // TODO : vérifier que les champs int sont bien des valeurs entières ?
  function isFormValid() {
    fieldSurveyDetailsADNETabCtrl.isValid = !!(fieldSurveyDetailsADNETabCtrl.data.environment);
    return !!(fieldSurveyDetailsADNETabCtrl.data.environment);
  }
  function locationPrecision() {
    return fieldSurveyDetailsADNETabCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return (
      fieldSurveyDetailsADNETabCtrl.isAutoLocatedValidFn() && fieldSurveyDetailsADNETabCtrl.isAutoLocatedValidFn()()
    );
  }

  function isAutoLocatedError() {
    return (
      fieldSurveyDetailsADNETabCtrl.isAutoLocatedErrorFn() && fieldSurveyDetailsADNETabCtrl.isAutoLocatedErrorFn()()
    );
  }
}
