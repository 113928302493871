import _ from 'lodash';
import latinize from '../../base/tools/latinize';

export default TaxonRepository;

// @ngInject
function TaxonRepository(DatabaseProvider, StorageService, $q, TaxonRefRepository) {
  return { query, getTaxon, searchByTxGroupsAndQuery, findByKeys, setHeadStatuses };

  function setHeadStatuses(taxon) {
    try {
      taxon.head_statuses = JSON.parse(taxon.head_statuses);
      if (taxon.head_statuses.extended) {
        taxon.head_statuses.extended.sort((a, b) => {
          return (a.rank || 0) - (b.rank || 0);
        });
      }
      if (taxon.head_statuses.simplified) {
        taxon.head_statuses.simplified.sort((a, b) => {
          return (a.rank || 0) - (b.rank || 0);
        });
      }
    } catch (error) {}
  }
  function query(rawQuery, txRefType, txRefVersion, txRefFilter, txGroupKeys, offset, limit) {
    let query = (rawQuery || '').trim();
    txRefType = txRefFilter;
    return DatabaseProvider.getReferentielDatabase(txRefType).then((database) => {
      var queryClause = '';
      var normalizedQuery = latinize.latinize(query).toLocaleLowerCase('FR');
      var queryStart = `${normalizedQuery}%`;
      var queryStartArray = `@${normalizedQuery}%`;
      var sqlParams = [queryStart, queryStartArray];
      if (query.match('^[^\\s]{3}\\s*[^\\s]{1,3}$')) {
        var partOne = query.substr(0, 3).toLocaleLowerCase('FR');
        var partTwo = query.substr(3, query.length).toLocaleLowerCase('FR');
        queryClause = `OR t.name_unaccent LIKE ?`;
        sqlParams.push(`${partOne}% ${partTwo}%`);
      }

      var txGroupQuery = '';
      if (txGroupKeys && txGroupKeys.length > 0) {
        var params = _.times(txGroupKeys.length, () => '?').join(',');
        txGroupQuery = `AND g.key IN (${params})`;
        sqlParams = sqlParams.concat(txGroupKeys);
      }

      var sqlParamsLimit = sqlParams.concat([limit, offset]);
      return StorageService.executeSqlQueries(database, [
        {
          sql: `
            SELECT t.*, g.key AS txGroup_key
            FROM taxon t
            JOIN tx_group g ON (g.id = t.tx_group)
            WHERE (
              t.name_unaccent LIKE ?
              OR t.vernacular_name_unaccent LIKE ?
              ${queryClause}
            )
            AND g.deleted = 0
            AND t.rank IS NOT NULL
            ${txGroupQuery}
            ORDER BY rank ASC, key ASC
            LIMIT ? OFFSET ?
          `,
          parameters: sqlParamsLimit,
        },
        {
          sql: `
            SELECT count(*) as count
            FROM (
              SELECT t.id
              FROM taxon t
              JOIN tx_group g ON (g.id = t.tx_group)
              WHERE (
                t.name_unaccent LIKE ?
                OR t.vernacular_name_unaccent LIKE ?
                ${queryClause}
              )
              AND g.deleted = 0
              AND t.rank IS NOT NULL
              ${txGroupQuery}
            ) t2
          `,
          parameters: sqlParams,
        },
      ]).then(([results, countData]) => {
        var taxons = _.map(results, (taxon) => {
          setHeadStatuses(taxon);
          return _.defaults(
            {
              vernacularName: taxon.vernacular_name && taxon.vernacular_name.split('||'),
            },
            taxon
          );
        });
        var count = _.head(countData).count;
        return { taxons, count };
      });
    });
  }

  function getTaxon(txRefType, txRefVersion, txRefFilter, taxonKey) {
    txRefType = txRefFilter;
    var txRefPromise = TaxonRefRepository.getTaxonRef(txRefType.toUpperCase());
    var taxonDetailsPromise = DatabaseProvider.getReferentielDatabase(txRefType.toUpperCase()).then((database) => {
      return StorageService.executeSqlQuery(
        database,
        `
          SELECT t.*, g.key AS txGroup_key
          FROM taxon t
          JOIN tx_group g ON (g.id = t.tx_group)
          WHERE t.key = ?
        `,
        [taxonKey]
      )
        .then(([taxon]) =>
          _.defaults({ vernacularName: taxon && taxon.vernacular_name && taxon.vernacular_name.split('||') }, taxon)
        )
        .then((taxon) => {
          return StorageService.executeSqlQuery(
            database,
            `
              SELECT
                p.id, p.code, p.name, p.article, p.decree, p.decree_year as decreeYear, p.type, p.deleted,
                tu.key as terrUnit_key, tu.name as terrUnit_name, tu.level as terrUnit_level,
                tu.boundary_type as terrUnit_boundaryType, tu.deleted as terrUnit_deleted
              FROM taxon_protection tp
              JOIN protection p ON (p.id = tp.protection)
              JOIN terr_unit tu ON (tu.id = p.terr_unit)
              WHERE tp.taxon = ?
            `,
            [taxon.id]
          )
            .then((protections) => {
              console.log(protections);
              return _.map(protections, (protection) => {
                return _.defaults(
                  {
                    deleted: !!protection.deleted,
                    terrUnit_deleted: !!protection.terrUnit_deleted,
                  },
                  protection
                );
              });
            })
            .then((protections) => _.merge({ protections }, taxon));
        });
    });
    return $q.all([txRefPromise, taxonDetailsPromise]).then(([txRef, taxon]) => {
      setHeadStatuses(taxon);
      return { txRef, taxon };
    });
  }

  function searchByTxGroupsAndQuery(txRefType, txRefVersion, txRefFilter, txGroupKeys, queryStr, limit) {
    return query(queryStr, txRefType, txRefVersion, txRefFilter, txGroupKeys, 0, limit).then(({ taxons }) => taxons);
  }

  function findByKeys(txRefType, txRefVersion, txRefFilter, txKeys) {
    return DatabaseProvider.getReferentielDatabase(txRefFilter)
      .then((database) => {
        var params = _.times(txKeys.length, () => '?').join(',');
        return StorageService.executeSqlQuery(
          database,
          `
          SELECT t.*, g.key AS txGroup_key
          FROM taxon t
          JOIN tx_group g ON (g.id = t.tx_group)
          WHERE t.key IN (${params})
        `,
          txKeys
        );
      })
      .then((taxons) => {
        return _.map(taxons, (taxon) => {
          return _.defaults({ vernacularName: StorageService.parseArrayData(taxon.vernacular_name) }, taxon);
        });
      });
  }
}
