import Leaflet from 'leaflet';
import _ from 'lodash';

export default LocalLayerProvider;

//@ngInject
function LocalLayerProvider(DatabaseProvider, StorageService, $q) {
  return {provideLayer, provideLocalLayer};

  function provideLayer(imagerySet, databasePrefix) {
    return $q.when({layer: createTileLayer(imagerySet, databasePrefix), imagerySet});
  }

  function provideLocalLayer(filepath, options) {
    var index = filepath.lastIndexOf('/');
    var dbName = filepath.substr(index + 1);

    return createTileLayerFromDatabase(DatabaseProvider.getSqliteDatabase(dbName), options);
  }

  function createTileLayer(imagerySet, databasePrefix) {
    return createTileLayerFromDatabase(DatabaseProvider.getMbTilesDatabase(imagerySet, databasePrefix), {imagerySet});
  }

  function createTileLayerFromDatabase(databasePromise, extraOptions) {
    var realOptions = _.defaults({
      minZoom: 1,
      maxZoom: 18
    }, extraOptions);

    var LocalTileLayer = Leaflet.TileLayer.extend({
      options: realOptions,

      initialize: function(options) {
        Leaflet.Util.setOptions(this, _.defaults({tms: true}, options));
      },

      // @method createTile(coords: Object, done?: Function): HTMLElement
      // Called only internally, overrides GridLayer's [`createTile()`](#gridlayer-createtile)
      // to return an `<img>` HTML element with the appropiate image URL given `coords`. The `done`
      // callback is called when the tile has been loaded.
      createTile: function(coords, done) {
        var tile = document.createElement('img');

        Leaflet.DomEvent.on(tile, 'load', Leaflet.Util.bind(this._tileOnLoad, this, done, tile));
        Leaflet.DomEvent.on(tile, 'error', Leaflet.Util.bind(this._tileOnError, this, done, tile));

        /*
         Alt tag is set to empty string to keep screen readers from reading URL and for compliance reasons
         http://www.w3.org/TR/WCAG20-TECHS/H67
        */
        tile.alt = '';

        /*
         Set role="presentation" to force screen readers to ignore this
         https://www.w3.org/TR/wai-aria/roles#textalternativecomputation
        */
        tile.setAttribute('role', 'presentation');

        // Becaus TMS
        var invertedY = this._globalTileRange.max.y - coords.y;
        databasePromise
        .then((database) => {
          return StorageService.executeSqlQuery(
            database,
            'SELECT BASE64(tile_data) AS tileData FROM tiles WHERE tile_column = ? AND tile_row = ? AND zoom_level = ?',
            [coords.x, invertedY, coords.z]
          ).then(([tileRow]) => {
            if (!tileRow) {
              tile.src = Leaflet.Util.emptyImageUrl;
            } else {
              tile.src = 'data:image/png;base64,' + tileRow.tileData;
            }
          });
        });

        return tile;
      }

    });
    return new LocalTileLayer();
  }
}
