import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as SURVEY_TYPE_STORE_EVENTS } from '../../stores/survey-type-details.store';
import { EVENTS as FIELD_RECORD_STATUS_SERVICE_EVENTS } from '../../../field-record/services/field-record-status.service';

require('./survey-type-details-container.scss');

export default SurveyTypeDetailsContainerDirective;

// @ngInject
function SurveyTypeDetailsContainerDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      surveyTypeId: '@'
    },
    bindToController: true,
    controller: SurveyTypeDetailsContainerController,
    controllerAs: 'surveyTypeDetailsContainerCtrl',
    template: require('./survey-type-details-container.html'),
    replace: true
  };
}

// @ngInject
function SurveyTypeDetailsContainerController($scope, $state, HeaderService,
                  StudyDetailsStore, StudyDetailsService,
                  SurveyTypeDetailsStore, SurveyTypeDetailsService, FieldRecordStatusService) {
  var surveyTypeDetailsContainerCtrl = this;

  surveyTypeDetailsContainerCtrl.studyState = {};
  surveyTypeDetailsContainerCtrl.surveyTypeState = {};
  surveyTypeDetailsContainerCtrl.headerUpdated = false;
  surveyTypeDetailsContainerCtrl.processingFieldRecordStatus = false;

  surveyTypeDetailsContainerCtrl.loaded = loaded;
  surveyTypeDetailsContainerCtrl.error = error;
  surveyTypeDetailsContainerCtrl.processing = processing;
  surveyTypeDetailsContainerCtrl.canCreateSubElements = canCreateSubElements;

  surveyTypeDetailsContainerCtrl.onFormSubmit = SurveyTypeDetailsService.onFormSubmit;
  surveyTypeDetailsContainerCtrl.onDelete = SurveyTypeDetailsService.onDelete;
  surveyTypeDetailsContainerCtrl.onMakeFieldRecordsPublic = onMakeFieldRecordsPublic;

  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reloadStudyDetails);

  $scope.$on(SURVEY_TYPE_STORE_EVENTS.SURVEY_TYPE_DETAILS_STATE_CHANGED, reloadSurveyTypeDetails);

  $scope.$on(FIELD_RECORD_STATUS_SERVICE_EVENTS.FIELD_RECORD_STATUS_PROCESSING, onProcessingFieldRecordStatus);
  $scope.$on(FIELD_RECORD_STATUS_SERVICE_EVENTS.FIELD_RECORD_STATUS_PROCESSING_SUCCESS, onProcessFieldRecordStatusSuccess);
  $scope.$on(FIELD_RECORD_STATUS_SERVICE_EVENTS.FIELD_RECORD_STATUS_PROCESSING_ERROR, onProcessFieldRecordStatusError);

  loadSurveyType();

  function loaded() {
    return surveyTypeDetailsContainerCtrl.studyState.loaded && surveyTypeDetailsContainerCtrl.surveyTypeState.loaded;
  }

  function error() {
    return surveyTypeDetailsContainerCtrl.studyState.error || surveyTypeDetailsContainerCtrl.surveyTypeState.error;
  }

  function processing() {
    return surveyTypeDetailsContainerCtrl.studyState.processing ||
        surveyTypeDetailsContainerCtrl.surveyTypeState.processing ||
        surveyTypeDetailsContainerCtrl.processingFieldRecordStatus;
  }

  function canCreateSubElements() {
    return surveyTypeDetailsContainerCtrl.studyState &&
        surveyTypeDetailsContainerCtrl.studyState.study &&
        surveyTypeDetailsContainerCtrl.studyState.study.canCreateSubElements &&
        surveyTypeDetailsContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
        surveyTypeDetailsContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE;
  }

  function reloadStudyDetails() {
    surveyTypeDetailsContainerCtrl.studyState = StudyDetailsStore.getState();

    if (surveyTypeDetailsContainerCtrl.studyState.error) {
      return;
    }
    updateHeader();
  }

  function reloadSurveyTypeDetails() {
    surveyTypeDetailsContainerCtrl.surveyTypeState = SurveyTypeDetailsStore.getState();

    if (surveyTypeDetailsContainerCtrl.surveyTypeState.error) {
      return;
    }
    if (surveyTypeDetailsContainerCtrl.surveyTypeState.loaded && !surveyTypeDetailsContainerCtrl.surveyTypeState.surveyType) {
      $state.go('study-details', {studyId: surveyTypeDetailsContainerCtrl.studyId});
      return;
    }

    updateHeader();
  }

  function updateHeader() {
    if (!surveyTypeDetailsContainerCtrl.studyState.study ||
          !surveyTypeDetailsContainerCtrl.surveyTypeState.surveyType ||
          surveyTypeDetailsContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: surveyTypeDetailsContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: {studyId: surveyTypeDetailsContainerCtrl.studyId}
        }
      },
      'Relevé type ' + surveyTypeDetailsContainerCtrl.surveyTypeState.surveyType.name
    ]);
    surveyTypeDetailsContainerCtrl.headerUpdated = true;
  }

  function loadSurveyType() {
    StudyDetailsService.load(surveyTypeDetailsContainerCtrl.studyId);
    SurveyTypeDetailsService.load(surveyTypeDetailsContainerCtrl.surveyTypeId);
  }

  function onMakeFieldRecordsPublic() {
    FieldRecordStatusService.makePublicForSurveyType(surveyTypeDetailsContainerCtrl.surveyTypeId);
  }

  function onProcessingFieldRecordStatus() {
    surveyTypeDetailsContainerCtrl.processingFieldRecordStatus = true;
  }
  function onProcessFieldRecordStatusSuccess() {
    surveyTypeDetailsContainerCtrl.processingFieldRecordStatus = false;
    SurveyTypeDetailsService.load(surveyTypeDetailsContainerCtrl.surveyTypeId);
  }
  function onProcessFieldRecordStatusError() {
    surveyTypeDetailsContainerCtrl.processingFieldRecordStatus = false;
  }
}
