const EVENTS = {
  // Actions lancées quand l'utilisateur téléverse un média
  MEDIA_UPLOADING: 'shu-media.media-uploading',
  MEDIA_UPLOAD_SUCCESS: 'shu-media.media-upload-success',
  MEDIA_UPLOAD_ERROR: 'shu-media.media-upload-error'
};

export default MediaService;

// @ngInject
function MediaService($rootScope, MediaRepository, LxNotificationService) {
  return {uploadMedia};

  function uploadMedia(file) {
    $rootScope.$broadcast(EVENTS.MEDIA_UPLOADING);

    MediaRepository.uploadMedia(file)
      .then((media) => {
        $rootScope.$broadcast(EVENTS.MEDIA_UPLOAD_SUCCESS, media);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue durant l\'envoi du média. ' + ((error && error.data) || ''));
        $rootScope.$broadcast(EVENTS.MEDIA_UPLOAD_ERROR);
      });
  }
}

export { EVENTS };
