import _ from 'lodash';

export default MediaRepository;

// @ngInject
function MediaRepository(MediaConfiguration, $http, UrlResolverService) {
  return {uploadMedia, getMediaHref};

  function uploadMedia(file) {
    var formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);
    formData.append('type', file.type);

    return $http({
      method: 'POST',
      url: MediaConfiguration.uploadMediaEndpoint,
      headers: {'Content-Type': undefined},
      data: formData
    }).then((response) => response.data)
    .then((media) => {
      return getMediaHref(media)
        .then((href) => {
          return _.merge({href}, media);
        });
    });
  }

  function getMediaHref(media) {
    return UrlResolverService.resolveUrl(MediaConfiguration.downloadMediaEndpoint, {mediaId: media.id});
  }
}
