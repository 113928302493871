import _ from 'lodash';
import moment from 'moment-timezone';

import {
  STUDY_STATUS,
  STUDY_ROLE,
  APPLICATION_KEY,
  STUDY_RESTRICTION,
  STUDY_EXPERT_LAYER_SOURCE,
  STUDY_EXPERT_LAYER_FORMAT,
} from '../../model.js';
import { EVENTS as EXPERT_SEARCH_STORE_EVENTS } from '../../../expert/stores/expert-search.store';
import TxgroupUtils from '../../../txgroup/tools/txgroup-utils';

require('./study-details.scss');

const PROMPT_LAYER_NAME_ID = 'PROMPT_LAYER_NAME_ID';

export default StudyDetailsDirective;

// @ngInject
function StudyDetailsDirective() {
  return {
    restrict: 'E',
    scope: {
      studyProvider: '&study',
      geographyAreaProvider: '&geographyArea',
      surveyTypeListProvider: '&surveyTypeList',
      studyExpertLayersProvider: '&studyExpertLayers',
      processingProvider: '&processing',
      onSubmitFn: '&onSubmit',
      onCreateSurveyTypeFn: '&onCreateSurveyType',
      uploadingKmlProvider: '&uploadingKml',
      processingLayersProvider: '&processingLayers',
      onSubmitKmlFn: '&onSubmitKml',
      onSubmitLayerFn: '&onSubmitLayer',
      onRemoveLayerFn: '&onRemoveLayer',
      onJoinStudyFn: '&onJoinStudy',
      onSyncSubmitFn: '&onSyncSubmit',
      onSyncMapSubmitFn: '&onSyncMapSubmit',
      onExportMbTilesFn: '&onExportMbTiles',
      checkTxRefsProvider: '&checkTxRefs',
    },
    bindToController: true,
    controller: StudyDetailsController,
    controllerAs: 'studyDetailsCtrl',
    template: require('./study-details.html'),
    replace: true,
  };
}

// @ngInject
function StudyDetailsController(
  $scope,
  $timeout,
  $state,
  LxNotificationService,
  ExpertSearchStore,
  ExpertSearchService,
  UrlResolverService,
  StudyConfiguration,
  LxDialogService,
) {
  var studyDetailsCtrl = this;

  studyDetailsCtrl.PROMPT_LAYER_NAME_ID = PROMPT_LAYER_NAME_ID;
  studyDetailsCtrl.STUDY_EXPERT_LAYER_FORMAT = STUDY_EXPERT_LAYER_FORMAT;

  studyDetailsCtrl.study = {};
  studyDetailsCtrl.surveyTypeList = [];
  studyDetailsCtrl.studyExpertLayers = [];
  studyDetailsCtrl.status = null;
  studyDetailsCtrl.layerInfo = null;
  studyDetailsCtrl.kmlUploading = false;
  studyDetailsCtrl.getStatusValues = getStatusValues;
  studyDetailsCtrl.getRolesValues = getRolesValues;
  studyDetailsCtrl.getRestrictionValues = getRestrictionValues;
  studyDetailsCtrl.getTimezones = getTimezones;
  studyDetailsCtrl.canEditRoles = canEditRoles;
  studyDetailsCtrl.canJoinStudy = canJoinStudy;
  studyDetailsCtrl.joinStudy = joinStudy;
  studyDetailsCtrl.canEditStudy = canEditStudy;
  studyDetailsCtrl.canEditStudySurveyType = canEditStudySurveyType;
  studyDetailsCtrl.canCreateSubElements = canCreateSubElements;
  studyDetailsCtrl.isExpertInRole = isExpertInRole;
  studyDetailsCtrl.toggleExpertInRole = toggleExpertInRole;
  studyDetailsCtrl.onSurveyTypeSelected = onSurveyTypeSelected;
  studyDetailsCtrl.onCreateFieldSurvey = onCreateFieldSurvey;
  studyDetailsCtrl.isWebUser = isWebUser;
  studyDetailsCtrl.isTxRefNeedUpdate = false;

  $scope.$on(EXPERT_SEARCH_STORE_EVENTS.EXPERT_SEARCH_RESULTS_STATE_CHANGED, reloadResults);
  studyDetailsCtrl.onUpdateExpertSearchForm = onUpdateExpertSearchForm;
  studyDetailsCtrl.addExpertRole = addExpertRole;
  studyDetailsCtrl.resultsState = {};

  studyDetailsCtrl.onSubmit = onSubmit;
  studyDetailsCtrl.onSubmitKml = onSubmitKml;
  studyDetailsCtrl.onSelectLayerServer = onSelectLayerServer;
  studyDetailsCtrl.onSelectLayerNomade = onSelectLayerNomade;
  studyDetailsCtrl.onCreateSurveyType = onCreateSurveyType;
  studyDetailsCtrl.onSyncSubmit = onSyncSubmit;
  studyDetailsCtrl.onSyncMapSubmit = onSyncMapSubmit;
  studyDetailsCtrl.onExportMbTiles = onExportMbTiles;
  studyDetailsCtrl.cleanupRoles = cleanupRoles;
  studyDetailsCtrl.canManagerLayers = canManagerLayers;
  studyDetailsCtrl.canManagerLayer = canManagerLayer;
  studyDetailsCtrl.onCancelPromptLayerName = onCancelPromptLayerName;
  studyDetailsCtrl.onValidatePromptLayerName = onValidatePromptLayerName;
  studyDetailsCtrl.onRemoveLayer = onRemoveLayer;

  studyDetailsCtrl.hrefCSV = null;

  studyDetailsCtrl.geographyArea = null;

  $scope.$watch(studyDetailsCtrl.studyProvider, (value) => {
    if (!value) {
      studyDetailsCtrl.study = {};
      studyDetailsCtrl.hrefCSV = null;
      studyDetailsCtrl.startTime = null;
      studyDetailsCtrl.endTime = null;
      studyDetailsCtrl.accessConstraint = null;
      studyDetailsCtrl.useConstraint = null;
      studyDetailsCtrl.status = null;
      studyDetailsCtrl.studyExperts = [];
      studyDetailsCtrl.timezone = moment.tz.guess();
      studyDetailsCtrl.hasMbTiles = false;
    } else {
      studyDetailsCtrl.study = value;
      studyDetailsCtrl.geographyArea = studyDetailsCtrl.study.geographyArea;
      UrlResolverService.resolveUrl(StudyConfiguration.studyCSVEndpoint, { studyId: studyDetailsCtrl.study.id }).then(
        (href) => (studyDetailsCtrl.hrefCSV = href)
      );
      studyDetailsCtrl.timezone = value.timezone;
      studyDetailsCtrl.startTime = value.startTime * 1000;
      studyDetailsCtrl.endTime = value.endTime ? value.endTime * 1000 : undefined;
      studyDetailsCtrl.accessConstraint = value.accessConstraint;
      studyDetailsCtrl.useConstraint = value.useConstraint;
      studyDetailsCtrl.status = value.status;
      studyDetailsCtrl.studyExperts = _.cloneDeep(value.studyExperts);
      studyDetailsCtrl.latestSyncAge = getLatestSyncAge();
      studyDetailsCtrl.hasMbTiles = value.imagerySets && value.imagerySets.length;      
    }
  });

  
  $scope.$watch(studyDetailsCtrl.checkTxRefsProvider, (value) => {
    studyDetailsCtrl.isTxRefNeedUpdate = value;
  });
  $scope.$watch(studyDetailsCtrl.geographyAreaProvider, (value) => {
    studyDetailsCtrl.geographyArea = value;
  });
  $scope.$watch(studyDetailsCtrl.surveyTypeListProvider, (value) => {
    studyDetailsCtrl.surveyTypeList = _.map(value, (surveyType) =>
      _.defaults({ protocolTxGroups: TxgroupUtils.sortBy(surveyType.protocolTxGroups, 'txGroup') }, surveyType)
    );
  });
  $scope.$watch(studyDetailsCtrl.studyExpertLayersProvider, (value) => {
    studyDetailsCtrl.studyExpertLayers = value;
  });

  $scope.$watch(studyDetailsCtrl.processingProvider, (value) => {
    studyDetailsCtrl.processing = value;
  });
  $scope.$watch(studyDetailsCtrl.uploadingKmlProvider, (value) => {
    studyDetailsCtrl.uploadingKml = value;
  });
  $scope.$watch(studyDetailsCtrl.processingLayersProvider, (value) => {
    studyDetailsCtrl.processingLayers = value;
  });

  function onSubmit() {
    var studyForm = _.pick(studyDetailsCtrl, [
      'accessConstraint',
      'useConstraint',
      'status',
      'studyExperts',
      'timezone',
    ]);
    studyForm.startTime = studyDetailsCtrl.startTime / 1000;
    studyForm.endTime = studyDetailsCtrl.endTime ? studyDetailsCtrl.endTime / 1000 : null;
    studyDetailsCtrl.onSubmitFn()(studyDetailsCtrl.study.id, studyForm, $scope.studyDetailsForm);
  }

  function onSubmitKml(input) {
    if (input.files.length <= 0) {
      return;
    }
    studyDetailsCtrl.onSubmitKmlFn()(studyDetailsCtrl.study.id, input.files[0]);
  }

  function onRemoveLayer(studyId, studyExpertLayerId) {
    LxNotificationService.confirm(
      'Attention',
      'Êtes-vous sur de vouloir supprimer cette couche ?',
      { cancel: 'Non', ok: 'Oui' },
      (answer) => answer && studyDetailsCtrl.onRemoveLayerFn()(studyId, studyExpertLayerId)
    );
  }

  function onSelectLayerNomade(event, format, extension) {
    if (!IS_CORDOVA) {
      return;
    }

    event.preventDefault();

    window.plugins.mfilechooser.open(
      [extension],
      (f) => {
        var lastSlashIndex = f.lastIndexOf('/');
        var name;
        if (lastSlashIndex === -1) {
          name = f;
        } else {
          name = f.substring(lastSlashIndex + 1);
        }
        onSelectLayer({ name }, 'file://' + f, format);
      },
      (error) => LxNotificationService.error('Une erreur est survenue lors de la sélection du fichier. ' + error)
    );
  }

  function onSelectLayerServer(input) {
    if (input.files.length <= 0) {
      return;
    }
    onSelectLayer(input.files[0], null, STUDY_EXPERT_LAYER_FORMAT.KML);
  }
  function onSelectLayer(file, path, format) {
    var lastDotIndex = file.name.lastIndexOf('.');
    if (lastDotIndex === -1) {
      studyDetailsCtrl.layerNameFromFile = file.name;
    } else {
      studyDetailsCtrl.layerNameFromFile = file.name.substring(0, lastDotIndex);
    }
    studyDetailsCtrl.layerInfo = {
      file,
      path,
      source: IS_CORDOVA ? STUDY_EXPERT_LAYER_SOURCE.LOCAL : STUDY_EXPERT_LAYER_SOURCE.SERVER,
      format,
    };
    // Demander du nom de la couche : par défaut input.files[0].name (sans extension)
    LxDialogService.open(PROMPT_LAYER_NAME_ID);
  }

  function onValidatePromptLayerName(name, layerInfo) {
    studyDetailsCtrl.onSubmitLayerFn()(studyDetailsCtrl.study.id, name, layerInfo);
  }

  function onCancelPromptLayerName() {
    studyDetailsCtrl.layerInfo = null;
  }

  function onSurveyTypeSelected(surveyTypeId) {
    $state.go('survey-type-details', { studyId: studyDetailsCtrl.study.id, surveyTypeId });
  }

  function onCreateFieldSurvey($event, surveyTypeId) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    $state.go('field-survey-creation', { studyId: studyDetailsCtrl.study.id, surveyTypeId });
  }

  function onCreateSurveyType() {
    studyDetailsCtrl.onCreateSurveyTypeFn()();
  }

  function cleanupRoles() {
    $timeout(() => {
      studyDetailsCtrl.studyExperts = _.filter(
        studyDetailsCtrl.studyExperts,
        (studyExpert) => studyExpert.roles.length > 0
      );
    });
  }

  function isExpertInRole(expert, role) {
    return _.includes(expert.roles, role);
  }

  function toggleExpertInRole(expert, role) {
    if (_.includes(expert.roles, role)) {
      _.pull(expert.roles, role);
    } else {
      expert.roles.push(role);
    }
  }

  function getStatusValues() {
    return STUDY_STATUS.VALUES;
  }

  function getRolesValues() {
    return STUDY_ROLE.VALUES;
  }

  function getRestrictionValues() {
    return STUDY_RESTRICTION.VALUES;
  }

  function getTimezones() {
    return moment.tz.names();
  }

  function canEditStudy() {
    // On ne peut pas modifier une étude si elle est verrouillée par un utilisateur
    return (
      studyDetailsCtrl.study &&
      studyDetailsCtrl.study.canEdit &&
      studyDetailsCtrl.study.locksInfo.length === 0 &&
      studyDetailsCtrl.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG
    );
  }

  function canManagerLayers() {
    return (
      !isWebUser() ||
      (isWebUser() &&
        studyDetailsCtrl.study &&
        (_.includes(studyDetailsCtrl.study.roles, STUDY_ROLE.OBSERVER) ||
          _.includes(studyDetailsCtrl.study.roles, STUDY_ROLE.MANAGER)) &&
        studyDetailsCtrl.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG)
    );
  }
  function canManagerLayer(layer) {
    // En web : pas de restrinction sur un layer (c'est tous les layers ou rien)
    if (isWebUser()) {
      return canManagerLayers();
    }
    // En nomade : on ne modifie pas les layers SERVER
    return !isWebUser() && layer.source !== STUDY_EXPERT_LAYER_SOURCE.SERVER;
  }

  function canEditStudySurveyType() {
    // On peut créer une relevé-type même si l'étude est verrouillée par un utilisateur
    return (
      studyDetailsCtrl.study &&
      studyDetailsCtrl.study.canEdit &&
      studyDetailsCtrl.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      studyDetailsCtrl.study.status !== STUDY_STATUS.ARCHIVED
    );
  }
  function canCreateSubElements() {
    return (
      studyDetailsCtrl.study &&
      studyDetailsCtrl.study.canCreateSubElements &&
      studyDetailsCtrl.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      studyDetailsCtrl.study.status === STUDY_STATUS.ACTIVE
    );
  }
  function canEditRoles() {
    return studyDetailsCtrl.study.canEdit && studyDetailsCtrl.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG;
  }

  function canJoinStudy() {
    // L'utilisateur n'a pas le droit de modifier l'étude et il n'a pas d'accès à l'étude
    return (
      !studyDetailsCtrl.study.canEdit &&
      isWebUser() &&
      studyDetailsCtrl.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
      (!studyDetailsCtrl.study.roles || !_.includes(studyDetailsCtrl.study.roles, STUDY_ROLE.MANAGER))
    );
  }

  function addExpertRole() {
    if (!_.some(studyDetailsCtrl.studyExperts, { expertId: studyDetailsCtrl.expertToAdd.id })) {
      studyDetailsCtrl.studyExperts.push({
        expertId: studyDetailsCtrl.expertToAdd.id,
        expertName: studyDetailsCtrl.expertToAdd.displayName,
        roles: [STUDY_ROLE.OBSERVER],
      });
    } else {
      LxNotificationService.info("Expert déjà géré dans l'étude");
    }
    studyDetailsCtrl.expertToAdd = undefined;
  }

  function reloadResults() {
    studyDetailsCtrl.resultsState = ExpertSearchStore.getResultsState();
  }

  function onUpdateExpertSearchForm(newValue) {
    if (!newValue) {
      return;
    }
    ExpertSearchService.onUpdateForm(newValue);
  }

  function isWebUser() {
    return !IS_CORDOVA;
  }

  function getLatestSyncAge() {
    if (!studyDetailsCtrl.study.lockTime && !studyDetailsCtrl.study.latestSyncTime) {
      return 0;
    }

    return moment().diff(
      moment((studyDetailsCtrl.study.latestSyncTime || studyDetailsCtrl.study.lockTime) * 1000),
      'days'
    );
  }

  function onSyncSubmit(save, unlock) {
    studyDetailsCtrl.onSyncSubmitFn()(save, unlock);
  }

  function onSyncMapSubmit(imagerySets, refreshExpertLayers) {
    studyDetailsCtrl.onSyncMapSubmitFn()(imagerySets, refreshExpertLayers);
  }

  function onExportMbTiles() {
    studyDetailsCtrl.onExportMbTilesFn()();
  }

  function joinStudy() {
    studyDetailsCtrl.onJoinStudyFn()();
  }
}
