import _ from 'lodash';

require('./field-record-details-rp-plants-tab.scss');

export default FieldRecordDetailsRpPlantsTabDirective;

// @ngInject
function FieldRecordDetailsRpPlantsTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsRpPlantsTabController,
    controllerAs: 'fieldRecordDetailsRpPlantsTabCtrl',
    template: require('./field-record-details-rp-plants-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsRpPlantsTabController($scope, $timeout) {
  var fieldRecordDetailsRpPlantsTabCtrl = this;
  fieldRecordDetailsRpPlantsTabCtrl.onStrateChanged = onStrateChanged;
  fieldRecordDetailsRpPlantsTabCtrl.onAbondanceDominanceChanged = onAbondanceDominanceChanged;
  fieldRecordDetailsRpPlantsTabCtrl.onSociabiliteChanged = onSociabiliteChanged;
  fieldRecordDetailsRpPlantsTabCtrl.onChange = onChange;
  fieldRecordDetailsRpPlantsTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsRpPlantsTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsRpPlantsTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsRpPlantsTabCtrl.canEdit = canEdit;
  fieldRecordDetailsRpPlantsTabCtrl.isNew = isNew;

  $scope.$watch(fieldRecordDetailsRpPlantsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsRpPlantsTabCtrl.data = {};
    } else {
      fieldRecordDetailsRpPlantsTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsRpPlantsTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsRpPlantsTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsRpPlantsTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsRpPlantsTabCtrl.formProvider, (form) => {
    fieldRecordDetailsRpPlantsTabCtrl.form = form;
  });

  function onStrateChanged(strate) {
    fieldRecordDetailsRpPlantsTabCtrl.data.strate = strate ? strate.id : null;
    onChange();
  }

  function onAbondanceDominanceChanged(abondanceDominance) {
    fieldRecordDetailsRpPlantsTabCtrl.data.abondanceDominance = abondanceDominance ? abondanceDominance.id : null;
    onChange();
  }

  function onSociabiliteChanged(sociabilite) {
    fieldRecordDetailsRpPlantsTabCtrl.data.sociabilite = sociabilite ? sociabilite.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsRpPlantsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsRpPlantsTabCtrl.onChangeFn()(fieldRecordDetailsRpPlantsTabCtrl.extraTableEnum, {
        data: {
          strate: fieldRecordDetailsRpPlantsTabCtrl.data.strate,
          abondanceDominance: fieldRecordDetailsRpPlantsTabCtrl.data.abondanceDominance,
          sociabilite: fieldRecordDetailsRpPlantsTabCtrl.data.sociabilite
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsRpPlantsTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsRpPlantsTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsRpPlantsTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsRpPlantsTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsRpPlantsTabCtrl.isNewFn()();
  }

  function isFormValid() {
    return true;
  }
}
