require('./field-record-description-dialog.scss');

export default FieldRecordDescriptionDialogDirective;

// @ngInject
function FieldRecordDescriptionDialogDirective() {
  return {
    restrict: 'E',
    scope: {
      fieldRecordDescriptionStateProvider: '&fieldRecordDescriptionState',
      canEditStudyProvider: '&canEditStudy',
      dialogId: '@',
      onSubmitFn: '&onSubmit'
    },
    controller: FieldRecordDescriptionDialogController,
    controllerAs: 'fieldRecordDescriptionDialogCtrl',
    bindToController: true,
    template: require('./field-record-description-dialog.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDescriptionDialogController($scope) {
  var fieldRecordDescriptionDialogCtrl = this;

  fieldRecordDescriptionDialogCtrl.state = {};
  fieldRecordDescriptionDialogCtrl.description = null;

  $scope.$watch(fieldRecordDescriptionDialogCtrl.canEditStudyProvider,
    (canEditStudy) => fieldRecordDescriptionDialogCtrl.canEditStudy = canEditStudy);

  $scope.$watch(fieldRecordDescriptionDialogCtrl.fieldRecordDescriptionStateProvider, (fieldRecordDescriptionState) => {
    fieldRecordDescriptionDialogCtrl.state = fieldRecordDescriptionState;

    if (fieldRecordDescriptionDialogCtrl.state) {
      fieldRecordDescriptionDialogCtrl.loaded = fieldRecordDescriptionDialogCtrl.state.loaded;
      fieldRecordDescriptionDialogCtrl.loading = fieldRecordDescriptionDialogCtrl.state.loading;
      fieldRecordDescriptionDialogCtrl.processing = fieldRecordDescriptionDialogCtrl.state.processing;
    } else {
      fieldRecordDescriptionDialogCtrl.loaded = false;
      fieldRecordDescriptionDialogCtrl.loading = false;
      fieldRecordDescriptionDialogCtrl.processing = false;
    }

    if (fieldRecordDescriptionDialogCtrl.state.processing) {
      return;
    }
    if (fieldRecordDescriptionDialogCtrl.state &&
        fieldRecordDescriptionDialogCtrl.state.loaded &&
        fieldRecordDescriptionDialogCtrl.state.fieldRecordDescription) {
      fieldRecordDescriptionDialogCtrl.canEdit =
        fieldRecordDescriptionDialogCtrl.state.fieldRecordDescription.canEdit && fieldRecordDescriptionDialogCtrl.canEditStudy;
      fieldRecordDescriptionDialogCtrl.description = fieldRecordDescriptionDialogCtrl.state.fieldRecordDescription.description;
    } else {
      fieldRecordDescriptionDialogCtrl.canEdit = false;
      fieldRecordDescriptionDialogCtrl.description = null;
    }
  });

  fieldRecordDescriptionDialogCtrl.onSubmit = onSubmit;

  function onSubmit() {
    if (!fieldRecordDescriptionDialogCtrl.canEdit) {
      return;
    }

    fieldRecordDescriptionDialogCtrl.onSubmitFn() &&
      fieldRecordDescriptionDialogCtrl.onSubmitFn()(fieldRecordDescriptionDialogCtrl.state.fieldRecordDescription.id,
        fieldRecordDescriptionDialogCtrl.description);
  }
}
