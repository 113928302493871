import _ from 'lodash';

import { IMAGERY_SET } from '../../map/model';

export default StudyRepository;

// @ngInject
function StudyRepository(StudyConfiguration, $http, $q) {
  return {query, getStudy, getGeom, getStudyMapCurrentLayer, storeStudyMapCurrentLayer, getStudyMapLayersVisibility, storeStudyMapLayersVisibility,
    updateStudy, updateGeom, joinStudy, getGrid, saveGrid, getPersonalStudyKey, addPersonalStudy, isStudyHasOutdatedTxRefs};

  function query(query, searchAll, status, noGeom, offset, limit) {
    return $http({
      method: 'GET',
      url: StudyConfiguration.searchEndpoint,
      params: {query, searchAll, status, noGeom, offset, limit}
    }).then((response) => response.data);
  }

  function getStudy(studyId) {
    return $http({
      method: 'GET',
      url: StudyConfiguration.studyDetailsEndpoint,
      pathParams: {studyId}
    }).then((response) => _.defaults({imagerySets: IMAGERY_SET.VALUES}, response.data));
  }

  function getGeom(studyId) {
    return $http({
      method: 'GET',
      url: StudyConfiguration.studyGeomEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function getGrid(studyId) {
    return $http({
      method: 'GET',
      url: StudyConfiguration.studyGridEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function saveGrid(studyId, gridData) {
    return $http({
      method: 'POST',
      url: StudyConfiguration.studyGridSaveEndPoint,
      pathParams: {studyId},
      data: gridData
    }).then((response) => response.data);
  }

  function updateGeom(studyId, formData) {
    return $http({
      method: 'POST',
      url: StudyConfiguration.studyUpdateGeomEndpoint,
      headers: {'Content-Type': undefined},
      pathParams: {studyId},
      data: formData
    }).then((response) => response.data);
  }

  function getStudyMapCurrentLayer() {
    return window.localStorage && window.localStorage.getItem('study-map.current-layer');
  }

  function storeStudyMapCurrentLayer(layer) {
    window.localStorage && window.localStorage.setItem('study-map.current-layer', layer);
  }

  function getStudyMapLayersVisibility(studyId) {
    var layersStr = window.localStorage && window.localStorage.getItem(`study.${studyId}.visible-layers`);
    try {
      return JSON.parse(layersStr);
    } catch (e) {
      return [];
    }
  }

  function storeStudyMapLayersVisibility(studyId, layerId) {
    var layers = getStudyMapLayersVisibility(studyId) || [];
    if (_.includes(layers, layerId)) {
      layers = _.without(layers, layerId);
    } else {
      layers = layers.concat([layerId]);
    }
    window.localStorage && window.localStorage.setItem(`study.${studyId}.visible-layers`, JSON.stringify(layers));
  }

  function updateStudy(studyId, studyForm) {
    return $http({
      method: 'PUT',
      url: StudyConfiguration.studyDetailsEndpoint,
      pathParams: {studyId},
      data: studyForm
    }).then((response) => response.data);
  }

  function joinStudy(studyId) {
    return $http({
      method: 'POST',
      url: StudyConfiguration.studyJoinEndpoint,
      pathParams: {studyId}
    }).then((response) => response.data);
  }

  function getPersonalStudyKey() {
    return $http({
      method: 'GET',
      url: StudyConfiguration.studyPersonalKeyEndpoint
    }).then((response) => response.data.studyKey);
  }

  function addPersonalStudy(key, name) {
    return $http({
      method: 'POST',
      url: StudyConfiguration.studyPersonalEndpoint,
      pathParams: {key, name}
    }).then((response) => response.data.studyId);
  }


  function isStudyHasOutdatedTxRefs(studyId) {
    return $q((resolve) => {
      if (!IS_CORDOVA) {
        return resolve(false);
      }
    });
  }
}
