import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-expert-layer.service';

const EVENTS = {
  STUDY_EXPERT_LAYER_STATE_CHANGED: 'shu-study-expert-layer.study-expert-layer-state-changed'
};

export default StudyExpertLayerStore;

// @ngInject
function StudyExpertLayerStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_EXPERT_LAYER_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_EXPERT_LAYER_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_EXPERT_LAYER_LOAD_ERROR, onLoadError);
  $rootScope.$on(SERVICE_EVENTS.STUDY_EXPERT_LAYER_PROCESSING, onProcessing);
  $rootScope.$on(SERVICE_EVENTS.STUDY_EXPERT_LAYER_PROCESS_ERROR, onProcessError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      layers: null,
      processing: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED);
  }

  function onLoadSuccess(event, layers) {
    state = Immutable({
      layers,
      processing: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      layers: null,
      processing: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED);
  }

  function onProcessing() {
    state = Immutable({
      layers: state.layers,
      processing: true,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED);
  }

  function onProcessError() {
    state = Immutable({
      layers: state.layers,
      processing: false,
      loaded: true,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_EXPERT_LAYER_STATE_CHANGED);
  }

}

export { EVENTS };
