import _ from 'lodash';

export default ProtocolRepository;

// @ngInject
function ProtocolRepository(DatabaseProvider, StorageService) {
  return {getByKeyWithGroups, list};

  function getByKeyWithGroups(protocolKey) {
    return listByProperty('p.key', protocolKey)
      .then(([ protocol ]) => protocol);
  }

  function list() {
    return listByProperty(null, null);
  }

  function listByProperty(property, value) {
    var wherePart = '';
    var params = [];
    if (property) {
      wherePart = `WHERE ${property} = ?`;
      params = [value];
    }

    return DatabaseProvider.getDatabase()
      .then((database) => {
        return StorageService.executeSqlQuery(database, `
          SELECT
            p.id,
            p.key,
            p.name,
            p.description,
            p.tx_groups AS txGroups,
            p.field_survey_geometry_type AS fieldSurveyGeometryType,
            p.created,
            p.created_by AS createdBy,
            p.updated,
            p.updated_by AS updatedBy,
            p.deleted,
            p.deleted_by AS deletedBy,
            ptg.id AS ptg_id,
            ptg.name AS ptg_name,
            ptg.tx_group AS ptg_txGroup,
            ptg.protocol AS ptg_protocol,
            ptg.field_survey_extra_tables AS ptg_fieldSurveyExtraTables,
            ptg.field_record_extra_table AS ptg_fieldRecordExtraTables,
            ptg.created AS ptg_created,
            ptg.created_by AS ptg_createdBy,
            ptg.updated AS ptg_updated,
            ptg.updated_by AS ptg_updatedBy,
            ptg.deleted AS ptg_deleted,
            ptg.deleted_by AS ptg_deletedBy
          FROM protocol p
          LEFT JOIN protocol_tx_group ptg ON (ptg.protocol = p.id)
          ${ wherePart }
        `, params);
      }).then((results) => {
        return _.chain(results)
          .groupBy('id')
          .mapValues((protocols, key) => {
            var protocol = _.reduce(_.toPairs(_.head(protocols)), (acc, [propertyKey, propertyValue]) => {
              if (_.startsWith(propertyKey, 'ptg_')) {
                return acc;
              }
              return _.merge({[propertyKey]: propertyValue}, acc);
            }, {});

            protocol.protocolTxGroups = _.chain(protocols)
              .map((protocolResult) => {
                return _.reduce(_.toPairs(protocolResult), (acc, [propertyKey, propertyValue]) => {
                  if (!_.startsWith(propertyKey, 'ptg_')) {
                    return acc;
                  }
                  return _.merge({[propertyKey.substr(4)]: propertyValue}, acc);
                }, {});
              })
              .map((protocolTxGroup) => {
                return _.defaults({
                  fieldSurveyExtraTables: StorageService.parseArrayData(protocolTxGroup.fieldSurveyExtraTables),
                  fieldRecordExtraTables: StorageService.parseArrayData(protocolTxGroup.fieldRecordExtraTables)
                }, protocolTxGroup);
              })
              .value();

            protocol.txGroups = StorageService.parseArrayData(protocol.txGroups);

            return protocol;
          })
          .values()
          .value();
      });
  }
}
