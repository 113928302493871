import _ from 'lodash';

require('./field-record-vignette.scss');

export default FieldRecordVignetteDirective;

// @ngInject
function FieldRecordVignetteDirective() {
  return {
    restrict: 'E',
    scope: {
      processingProvider: '&processing',
      fieldRecordListProvider: '&fieldRecordList',
      canEditProvider: '&canEdit',
      onUpdateHeadcountFn: '&onUpdateHeadcount',
      onShowDescriptionFn: '&onShowDescription',
      onRemoveFn: '&onRemove',
      canRemoveProvider: '&canRemove',
      onFieldRecordSelectedFn: '&onFieldRecordSelected',
      onValidateRecordFn: '&onValidateRecord'
    },
    bindToController: true,
    controller: FieldRecordVignetteController,
    controllerAs: 'fieldRecordVignetteCtrl',
    template: require('./field-record-vignette.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordVignetteController($scope) {
  var fieldRecordVignetteCtrl = this;

  fieldRecordVignetteCtrl.onFieldRecordSelected = onFieldRecordSelected;
  fieldRecordVignetteCtrl.onShowDescription = onShowDescription;
  fieldRecordVignetteCtrl.taxonCode = taxonCode;
  fieldRecordVignetteCtrl.onUpdateHeadcount = onUpdateHeadcount;
  fieldRecordVignetteCtrl.onRemove = onRemove;
  fieldRecordVignetteCtrl.onValidateRecord = onValidateRecord;

  $scope.$watch(fieldRecordVignetteCtrl.canRemoveProvider,
    (canRemove) => fieldRecordVignetteCtrl.canRemove = canRemove);

  $scope.$watch(fieldRecordVignetteCtrl.fieldRecordListProvider, (fieldRecordList) => fieldRecordVignetteCtrl.fieldRecordList = fieldRecordList);
  $scope.$watch(fieldRecordVignetteCtrl.processingProvider, (processing) => fieldRecordVignetteCtrl.processing = processing);
  $scope.$watch(fieldRecordVignetteCtrl.canEditProvider, (canEdit) => fieldRecordVignetteCtrl.canEdit = canEdit);

  function onRemove(fieldRecord) {
    fieldRecordVignetteCtrl.onRemoveFn()(fieldRecord);
  }

  function onFieldRecordSelected(fieldRecord) {
   
    if (fieldRecord.ghost) {
      console.log("here"+fieldRecord );
      return;
    }
    fieldRecordVignetteCtrl.onFieldRecordSelectedFn()(fieldRecord.id);
  }

  function onShowDescription($event, fieldRecordId) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    fieldRecordVignetteCtrl.onShowDescriptionFn()(fieldRecordId);
  }

  function onUpdateHeadcount($event, fieldRecordId, delta) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    var oldTxHeadcount = _.find(fieldRecordVignetteCtrl.fieldRecordList, {'id': fieldRecordId}).txHeadcount || 0;
    var headcount = oldTxHeadcount + delta;
    if (headcount < 0) {
      headcount = 0;
    }
    fieldRecordVignetteCtrl.onUpdateHeadcountFn()(fieldRecordId, headcount);
  }

  function onValidateRecord($event, fieldRecordId, delta) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    fieldRecordVignetteCtrl.onValidateRecordFn()(fieldRecordId, delta);
  }

  function taxonCode(name) {
    var taxonWords = name.split(' ');

    if (taxonWords.length < 2) {
      return name;
    }

    return `${taxonWords[0].substr(0, 3)}${taxonWords[1].substr(0, 3)}`.toLocaleUpperCase('FR');
  }
}
