import angular from 'angular';

import selectTxGroupComponent from './components/select-txgroup/select-txgroup.component';
import txGroupListService from './services/txgroup-list.service';
import txGroupListStore from './stores/txgroup-list.store';
import remoteTxGroupRepository from './repositories/txgroup.remote.repository';
import localTxGroupRepository from './repositories/txgroup.local.repository';
import txGroupKeyFilter from './filters/txgroup-key.filter';
import txGroupConfiguration from './configuration';

const moduleName = 'shu-txgroup';

angular.module(moduleName, ['lumx'])
  .directive('shuSelectTxGroup', selectTxGroupComponent)
  .factory('TxGroupRepository', IS_CORDOVA ? localTxGroupRepository : remoteTxGroupRepository)
  .factory('TxGroupListService', txGroupListService)
  .factory('TxGroupListStore', txGroupListStore)
  .filter('txGroupKeyLabel', txGroupKeyFilter)
  .constant('TxGroupConfiguration', txGroupConfiguration);

export default {moduleName};
