import _ from 'lodash';

require('./field-record-details-ls-birds-tab.scss');

export default FieldRecordDetailsLsBirdsTabDirective;

// @ngInject
function FieldRecordDetailsLsBirdsTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsBirdsTabController,
    controllerAs: 'fieldRecordDetailsLsBirdsTabCtrl',
    template: require('./field-record-details-ls-birds-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsBirdsTabController($scope, $timeout) {
  var fieldRecordDetailsLsBirdsTabCtrl = this;
  fieldRecordDetailsLsBirdsTabCtrl.onModeContactChanged = onModeContactChanged;
  fieldRecordDetailsLsBirdsTabCtrl.onSexeChanged = onSexeChanged;
  fieldRecordDetailsLsBirdsTabCtrl.onStadeDeveloppementChanged = onStadeDeveloppementChanged;
  fieldRecordDetailsLsBirdsTabCtrl.onComportementChanged = onComportementChanged;
  fieldRecordDetailsLsBirdsTabCtrl.onProbabiliteReproductionChanged = onProbabiliteReproductionChanged;
  fieldRecordDetailsLsBirdsTabCtrl.onTypeIndicePresenceChanged = onTypeIndicePresenceChanged;
  fieldRecordDetailsLsBirdsTabCtrl.onChange = onChange;
  fieldRecordDetailsLsBirdsTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsBirdsTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsBirdsTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsBirdsTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsBirdsTabCtrl.isNew = isNew;
  fieldRecordDetailsLsBirdsTabCtrl.isReleveItinerant = isReleveItinerant;

  $scope.$watch(fieldRecordDetailsLsBirdsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsBirdsTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsBirdsTabCtrl.data = _.cloneDeep(initialData);
    }

    fieldRecordDetailsLsBirdsTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsBirdsTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsBirdsTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsBirdsTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsBirdsTabCtrl.form = form;
  });

  $scope.$watch(fieldRecordDetailsLsBirdsTabCtrl.requiredProvider, (required) => {
    fieldRecordDetailsLsBirdsTabCtrl.required = required;
  });

  function onModeContactChanged(modeContact) {
    fieldRecordDetailsLsBirdsTabCtrl.data.modeContact = modeContact ? modeContact.id : null;
    onChange();
  }

  function onSexeChanged(sexe) {
    fieldRecordDetailsLsBirdsTabCtrl.data.sexe = sexe ? sexe.id : null;
    onChange();
  }

  function onStadeDeveloppementChanged(stadeDeveloppement) {
    fieldRecordDetailsLsBirdsTabCtrl.data.stadeDeveloppement = stadeDeveloppement ? stadeDeveloppement.id : null;
    onChange();
  }

  function onComportementChanged(comportement) {
    fieldRecordDetailsLsBirdsTabCtrl.data.comportement = comportement ? comportement.id : null;
    onChange();
  }

  function onProbabiliteReproductionChanged(probabiliteReproduction) {
    fieldRecordDetailsLsBirdsTabCtrl.data.probabiliteReproduction = probabiliteReproduction ? probabiliteReproduction.id : null;
    onChange();
  }

  function onTypeIndicePresenceChanged(typeIndicePresence) {
    fieldRecordDetailsLsBirdsTabCtrl.data.typeIndicePresence = typeIndicePresence ? typeIndicePresence.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsBirdsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsBirdsTabCtrl.onChangeFn()(fieldRecordDetailsLsBirdsTabCtrl.extraTableEnum, {
        data: {
          modeContact: fieldRecordDetailsLsBirdsTabCtrl.data.modeContact,
          sexe: fieldRecordDetailsLsBirdsTabCtrl.data.sexe,
          stadeDeveloppement: fieldRecordDetailsLsBirdsTabCtrl.data.stadeDeveloppement,
          comportement: fieldRecordDetailsLsBirdsTabCtrl.data.comportement,
          probabiliteReproduction: fieldRecordDetailsLsBirdsTabCtrl.data.probabiliteReproduction,
          typeIndicePresence: fieldRecordDetailsLsBirdsTabCtrl.data.typeIndicePresence
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsBirdsTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsBirdsTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsBirdsTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsBirdsTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsBirdsTabCtrl.isNewFn()();
  }

  function isReleveItinerant() {
    return fieldRecordDetailsLsBirdsTabCtrl.extraTableEnum === 'RI_BIRDS';
  }

  function isFormValid() {
    return true;
  }
}
