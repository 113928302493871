import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche une carte avec plusieurs observations
  FIELD_RECORD_LIST_MAP_LOADING: 'shu-field-record.field-record-list-map-loading',
  FIELD_RECORD_LIST_MAP_LOAD_SUCCESS: 'shu-field-record.field-record-list-map-load-success',
  FIELD_RECORD_LIST_MAP_LOAD_ERROR: 'shu-field-record.field-record-list-map-load-error'
};

export default FieldRecordMapService;

// @ngInject
function FieldRecordMapService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {load, loadForStudy};

  function load(fieldSurveyId) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_LIST_MAP_LOADING);
    FieldRecordRepository.getGeomForFieldSurvey(fieldSurveyId)
      .then(
        (fieldRecords) => {
          $rootScope.$emit(EVENTS.FIELD_RECORD_LIST_MAP_LOAD_SUCCESS, fieldRecords);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement de la géométrie. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_RECORD_LIST_MAP_LOAD_ERROR);
        }
      );
  }

  function loadForStudy(studyId) {
    $rootScope.$emit(EVENTS.FIELD_RECORD_LIST_MAP_LOADING);

    FieldRecordRepository.getGeomForStudy(studyId)
      .then(
        (fieldRecords) => {
          $rootScope.$emit(EVENTS.FIELD_RECORD_LIST_MAP_LOAD_SUCCESS, fieldRecords);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue au chargement de la géométrie. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.FIELD_RECORD_LIST_MAP_LOAD_ERROR);
        }
      );
  }
}
export { EVENTS };
