const EVENTS = {
  // Actions lancées quand l'utilisateur lance une recherche
  FIELD_SURVEY_CURRENT_LOADING: 'shu-field-survey.field-survey-current-loading',
  FIELD_SURVEY_CURRENT_LOAD_SUCCESS: 'shu-field-survey.field-survey-current-load-success',
  FIELD_SURVEY_CURRENT_LOAD_ERROR: 'shu-field-survey.field-survey-current-load-error',
};

const LIMIT = 20;

export default FieldSurveyCurrentService;

// @ngInject
function FieldSurveyCurrentService($rootScope, FieldSurveyRepository, LxNotificationService) {
  return {load};

  function load() {
    $rootScope.$emit(EVENTS.FIELD_SURVEY_CURRENT_LOADING);

    FieldSurveyRepository.current(LIMIT)
      .then((result) => {
        $rootScope.$emit(EVENTS.FIELD_SURVEY_CURRENT_LOAD_SUCCESS, result.fieldSurveys, result.count);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement des relevés. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.FIELD_SURVEY_CURRENT_LOAD_ERROR);
      });
  }
}
export { EVENTS };
