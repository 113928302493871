import _ from 'lodash';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page d'affichage de grille
  STUDY_GRID_LOADING: 'shu-study-grid.study-grid-loading',
  STUDY_GRID_LOAD_SUCCESS: 'shu-study-grid.study-grid-load-success',
  STUDY_GRID_LOAD_ERROR: 'shu-study-grid.study-grid-load-error',

  // Actions lancées quand l'utilisateur clique sur le bouton "sauvegarder les zones selectionnées" la page d'affichage de grille
  STUDY_GRID_SAVING: 'shu-study-grid.study-grid-saving',
  STUDY_GRID_SAVE_SUCCESS: 'shu-study-grid.study-grid-save-success',
};

export default StudyGridService;

// @ngInject
function StudyGridService($rootScope, StudyRepository, LxNotificationService) {
  return {saveCurrentLayer, loadGrid, saveGrid};

  function loadGrid(studyId) {
    $rootScope.$emit(EVENTS.STUDY_GRID_LOADING);

    StudyRepository.getGrid(studyId)
      .then(
        (grid) => {
          $rootScope.$emit(EVENTS.STUDY_GRID_LOAD_SUCCESS, {grid});
        },
        (error) =>  {
          LxNotificationService.error('Une erreur est survenue au chargement de la grille. ');
          $rootScope.$emit(EVENTS.STUDY_GRID_LOAD_ERROR);
        }
      );
  }

  function saveGrid(studyId, grid) {
    $rootScope.$broadcast(EVENTS.STUDY_GRID_SAVING);

    StudyRepository.saveGrid(studyId, grid)
      .then(
        () => {
          LxNotificationService.success('Grille sauvegardée avec succès.');
          $rootScope.$broadcast(EVENTS.STUDY_GRID_SAVE_SUCCESS);
        },
        (error) => {
          LxNotificationService.error('Une erreur est survenue lors de sauvegarde de la grille. ');
          $rootScope.$broadcast(EVENTS.STUDY_GRID_LOAD_ERROR);
        }
      );
  }

  function saveCurrentLayer(layer) {
    StudyRepository.storeStudyMapCurrentLayer(layer);
  }

}
export { EVENTS };
