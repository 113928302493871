export default ProtocolRepository;

// @ngInject
function ProtocolRepository(ProtocolConfiguration, $http) {
  return {getByKeyWithGroups, list};

  function getByKeyWithGroups(protocolKey) {
    return $http({
      method: 'GET',
      url: ProtocolConfiguration.protocolByKeyWithGroupsEndpoint,
      pathParams: {protocolKey}
    }).then((response) => response.data);
  }

  function list() {
    return $http({
      method: 'GET',
      url: ProtocolConfiguration.listEndpoint
    }).then((response) => response.data);
  }
}
