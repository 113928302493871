import Immutable from 'seamless-immutable';
import _ from 'lodash';

const EVENTS = {
  STUDY_IMPORTER_FORM_STATE_CHANGED: 'shu-synchro.study-importer-form-state-changed',
  STUDY_IMPORTER_SYNC_STATE_CHANGED: 'shu-synchro.study-importer-sync-state-changed'
};

export default StudyImporterStore;

// @ngInject
function StudyImporterStore($rootScope) {
  var formState = Immutable({});
  var syncState = Immutable({});

  return {getFormState, getSyncState};

  function getFormState() {
    return formState;
  }

  function getSyncState() {
    return syncState;
  }
}

export { EVENTS };
