import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../../../txgroup/stores/txgroup-list.store';

require('./field-record-on-the-fly-buttons.scss');

export default FieldRecordOnTheFlyButtonsDirective;

// @ngInject
function FieldRecordOnTheFlyButtonsDirective() {
  return {
    restrict: 'E',
    scope: {
      studyId: '@',
      processingProvider: '&processing',
    },
    bindToController: true,
    controller: FieldRecordOnTheFlyButtonsController,
    controllerAs: 'fieldRecordOnTheFlyButtonsCtrl',
    template: require('./field-record-on-the-fly-buttons.html'),
    replace: true,
  };
}

// @ngInject
function FieldRecordOnTheFlyButtonsController(
  $scope,
  $state,
  TxGroupListService,
  TxGroupListStore,
  SettingsService,
  TaxonRefRepository,
  SettingsOppService
) {
  var fieldRecordOnTheFlyButtonsCtrl = this;

  fieldRecordOnTheFlyButtonsCtrl.onCreateFieldRecord = onCreateFieldRecord;

  $scope.$on(STORE_EVENTS.TX_GROUP_LIST_RESULTS_STATE_CHANGED, () => {
    fieldRecordOnTheFlyButtonsCtrl.availableGroups = _.chain(TxGroupListStore.getState().results)
      .filter((r) => !r.deleted)
      .value();
  });

  TxGroupListService.load();

  $scope.$watch(fieldRecordOnTheFlyButtonsCtrl.processingProvider, (processing) => {
    fieldRecordOnTheFlyButtonsCtrl.processing = processing;
  });

  function onCreateFieldRecord(creationMode, txGroup) {
    const start = new Date().getTime();
    let params = {
      studyId: fieldRecordOnTheFlyButtonsCtrl.studyId,
      creationMode: creationMode,
      txGroup: txGroup,
      txHeadcount: 1,
      txRefKey:"",
      txRefVersion:"",
      txRefFilter:""
    };

    if (IS_CORDOVA) {
      const start = new Date().getTime();
      var paramsTxRefKey = null;
      var paramsTxRefVersion = null;
      var paramsTxRefFilter = null;
      SettingsOppService.getShuSettings('opportunistObsTxRef', null).then((opportunistObsTxRefKey) => {
        if (!opportunistObsTxRefKey) {
          $state.go('field-record-creation', params);
          return;
        }

        var oppObTxRefType = JSON.parse(opportunistObsTxRefKey);
          paramsTxRefKey = oppObTxRefType.ref;
          paramsTxRefVersion = oppObTxRefType.version;
          paramsTxRefFilter = oppObTxRefType.key;

        let paramsOpp = {
          studyId: fieldRecordOnTheFlyButtonsCtrl.studyId,
          creationMode: creationMode,
          txGroup: txGroup,
          txHeadcount: 1,
          txRefKey:paramsTxRefKey,
          txRefVersion:paramsTxRefVersion,
          txRefFilter:paramsTxRefFilter
        };
        $state.go('field-record-creation', paramsOpp);
        return;

      });

    }
    $state.go('field-record-creation', params);
  }
}
