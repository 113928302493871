const EVENTS = {
  // Actions lancées quand l'utilisateur consulte les observations d'une étude
  STUDY_FIELD_RECORDS_LOADING: 'shu-field-record.study-field-record-loading',
  STUDY_FIELD_RECORDS_LOAD_SUCCESS: 'shu-field-record.study-field-record-load-success',
  STUDY_FIELD_RECORDS_LOAD_ERROR: 'shu-field-record.study-field-record-load-error',
};

export default StudyFieldRecordsService;

// @ngInject
function StudyFieldRecordsService($rootScope, FieldRecordRepository, LxNotificationService) {
  return {load};

  function load(studyId) {
    $rootScope.$emit(EVENTS.STUDY_FIELD_RECORDS_LOADING);

    FieldRecordRepository.getForStudy(studyId)
      .then((fieldRecordListResult) => {
        $rootScope.$emit(EVENTS.STUDY_FIELD_RECORDS_LOAD_SUCCESS, fieldRecordListResult);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de la récupération des informations des observations. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.STUDY_FIELD_RECORDS_LOAD_ERROR);
      });
  }
}
export { EVENTS };
