const EVENTS = {
  // Actions lancées quand l'utilisateur charge un protocole
  PROTOCOL_LOADING: 'shu-protocol.protocol-loading',
  PROTOCOL_LOAD_SUCCESS: 'shu-protocol.protocol-load-success',
  PROTOCOL_LOAD_ERROR: 'shu-protocol.protocol-load-error',
};

export default ProtocolService;

// @ngInject
function ProtocolService($rootScope, ProtocolStore, ProtocolRepository, LxNotificationService) {
  return {loadByKey};

  function loadByKey(key) {
    if (ProtocolStore.getState().loaded && ProtocolStore.getState().protocol && ProtocolStore.getState().protocol.key === key) {
      $rootScope.$emit(EVENTS.PROTOCOL_LOAD_SUCCESS, ProtocolStore.getState().protocol);
      return;
    }

    $rootScope.$emit(EVENTS.PROTOCOL_LOADING);

    ProtocolRepository.getByKeyWithGroups(key)
      .then((result) => {
        $rootScope.$emit(EVENTS.PROTOCOL_LOAD_SUCCESS, result);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement d\'un protocole. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.PROTOCOL_LOAD_ERROR);
      });
  }
}
export { EVENTS };
