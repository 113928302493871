export default FieldSurveyRouterConfig;

// @ngInject
function FieldSurveyRouterConfig($stateProvider) {
  $stateProvider.state('study-field-surveys', {
    url: '/study/{studyId}/field-survey',
    template: '<shu-study-field-surveys-container study-id="{{ ::routeCtrl.studyId }}"></shu-study-field-surveys-container>',
    needAuthenticatedUser: true,
    controller: StudyFieldSurveysRouteController,
    controllerAs: 'routeCtrl'
  }).state('field-survey-details', {
    url: '/study/{studyId}/field-survey/{fieldSurveyId}/details?{openMapTab}',
    template: `
      <shu-field-survey-details-container study-id="{{ ::routeCtrl.studyId }}" field-survey-id="{{ ::routeCtrl.fieldSurveyId}}"
        open-map-tab="::routeCtrl.openMapTab">
      </shu-field-survey-details-container>
    `,
    needAuthenticatedUser: true,
    controller: FieldSurveyDetailsRouteController,
    controllerAs: 'routeCtrl'
  }).state('field-survey-creation', {
    url: '/study/{studyId}/field-survey/survey-type/{surveyTypeId}',
    template: `
      <shu-field-survey-details-container study-id="{{ ::routeCtrl.studyId }}" survey-type-id="{{ ::routeCtrl.surveyTypeId}}">
      </shu-field-survey-details-container>
    `,
    needAuthenticatedUser: true,
    controller: FieldSurveyCreationRouteController,
    controllerAs: 'routeCtrl'
  }).state('field-survey-clonage', {
    url: '/study/{studyId}/field-survey-clonage/{fieldSurveyClonageId}',
    template: `
      <shu-field-survey-details-container study-id="{{ ::routeCtrl.studyId }}" field-survey-clonage-id="{{ ::routeCtrl.fieldSurveyClonageId}}">
      </shu-field-survey-details-container>
    `,
    needAuthenticatedUser: true,
    controller: FieldSurveyClonageRouteController,
    controllerAs: 'routeCtrl'
  });
}

// @ngInject
function StudyFieldSurveysRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
}

// @ngInject
function FieldSurveyDetailsRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
  routeCtrl.fieldSurveyId = $stateParams.fieldSurveyId;
  routeCtrl.openMapTab = $stateParams.openMapTab;
}

// @ngInject
function FieldSurveyCreationRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
  routeCtrl.surveyTypeId = $stateParams.surveyTypeId;
}

// @ngInject
function FieldSurveyClonageRouteController($scope, $stateParams) {
  var routeCtrl = this;

  routeCtrl.studyId = $stateParams.studyId;
  routeCtrl.fieldSurveyClonageId = $stateParams.fieldSurveyClonageId;
}
