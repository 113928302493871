import _ from 'lodash';

require('./field-record-details-ls-plants-tab.scss');

export default FieldRecordDetailsLsPlantsTabDirective;

// @ngInject
function FieldRecordDetailsLsPlantsTabDirective() {
  return {
    restrict: 'EA',
    scope: {
      extraTableEnum: '@',
      initialDataProvider: '&initialData',
      fieldRecordStateProvider: '&fieldRecordState',
      onChangeFn: '&onChange',
      onDeleteFieldRecordFn: '&onDeleteFieldRecord',
      onCreateAndContinueClickedFn: '&onCreateAndContinueClicked',
      canSubmitFn: '&canSubmit',
      canEditFn: '&canEdit',
      isNewFn: '&isNew',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldRecordDetailsLsPlantsTabController,
    controllerAs: 'fieldRecordDetailsLsPlantsTabCtrl',
    template: require('./field-record-details-ls-plants-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldRecordDetailsLsPlantsTabController($scope, $timeout) {
  var fieldRecordDetailsLsPlantsTabCtrl = this;
  fieldRecordDetailsLsPlantsTabCtrl.onPhenologieChanged = onPhenologieChanged;
  fieldRecordDetailsLsPlantsTabCtrl.onMethodeEvalEffectifChanged = onMethodeEvalEffectifChanged;
  fieldRecordDetailsLsPlantsTabCtrl.onChange = onChange;
  fieldRecordDetailsLsPlantsTabCtrl.onDeleteFieldRecord = onDeleteFieldRecord;
  fieldRecordDetailsLsPlantsTabCtrl.onCreateAndContinueClicked = onCreateAndContinueClicked;
  fieldRecordDetailsLsPlantsTabCtrl.canSubmit = canSubmit;
  fieldRecordDetailsLsPlantsTabCtrl.canEdit = canEdit;
  fieldRecordDetailsLsPlantsTabCtrl.isNew = isNew;
  fieldRecordDetailsLsPlantsTabCtrl.useMethodeEvalEffectif = useMethodeEvalEffectif;

  $scope.$watch(fieldRecordDetailsLsPlantsTabCtrl.initialDataProvider, (initialData) => {
    if (!initialData) {
      fieldRecordDetailsLsPlantsTabCtrl.data = {};
    } else {
      fieldRecordDetailsLsPlantsTabCtrl.data = _.cloneDeep(initialData);
    }
    if (!fieldRecordDetailsLsPlantsTabCtrl.data.methodeEvalEffectif) {
      fieldRecordDetailsLsPlantsTabCtrl.defaultMethodeEvalEffectifKey = 'NOMBRE_PIEDS';
    }
    fieldRecordDetailsLsPlantsTabCtrl.onChange();
  });

  $scope.$watch(fieldRecordDetailsLsPlantsTabCtrl.fieldRecordStateProvider, (fieldRecordState) => {
    if (!fieldRecordState) {
      return;
    }

    fieldRecordDetailsLsPlantsTabCtrl.fieldRecordState = fieldRecordState;
  });

  $scope.$watch(fieldRecordDetailsLsPlantsTabCtrl.formProvider, (form) => {
    fieldRecordDetailsLsPlantsTabCtrl.form = form;
  });

  function onPhenologieChanged(phenologie) {
    fieldRecordDetailsLsPlantsTabCtrl.data.phenologie = phenologie ? phenologie.id : null;
    onChange();
  }
  function onMethodeEvalEffectifChanged(methodeEvalEffectif) {
    fieldRecordDetailsLsPlantsTabCtrl.data.methodeEvalEffectif = methodeEvalEffectif ? methodeEvalEffectif.id : null;
    onChange();
  }

  function onChange() {
    if (!fieldRecordDetailsLsPlantsTabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldRecordDetailsLsPlantsTabCtrl.onChangeFn()(fieldRecordDetailsLsPlantsTabCtrl.extraTableEnum, {
        data: {
          phenologie: fieldRecordDetailsLsPlantsTabCtrl.data.phenologie,
          methodeEvalEffectif: fieldRecordDetailsLsPlantsTabCtrl.data.methodeEvalEffectif,
        },
        formValid: isFormValid()
      });
    });
  }

  function onDeleteFieldRecord() {
    fieldRecordDetailsLsPlantsTabCtrl.onDeleteFieldRecordFn()();
  }

  function onCreateAndContinueClicked() {
    fieldRecordDetailsLsPlantsTabCtrl.onCreateAndContinueClickedFn()();
  }

  function canSubmit() {
    return fieldRecordDetailsLsPlantsTabCtrl.canSubmitFn()();
  }

  function canEdit() {
    return fieldRecordDetailsLsPlantsTabCtrl.canEditFn()();
  }

  function isNew() {
    return fieldRecordDetailsLsPlantsTabCtrl.isNewFn()();
  }
  function useMethodeEvalEffectif() {
    //return false;
    return fieldRecordDetailsLsPlantsTabCtrl.extraTableEnum === 'RI_PLANTS' && fieldRecordDetailsLsPlantsTabCtrl.data.methodeEvalEffectif;
  }
  function isFormValid() {
    if (useMethodeEvalEffectif()) {
      // Champ obligatoire pour RI
      return useMethodeEvalEffectif()
      /*return fieldRecordDetailsLsPlantsTabCtrl.form['fieldRecord.methodeEvalEffectif'] &&
      fieldRecordDetailsLsPlantsTabCtrl.form['fieldRecord.methodeEvalEffectif'].$valid;*/
    }
    // Aucun champ obligatoire pour LS
    return true;
  }
}
