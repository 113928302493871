import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/study-creation.service';

const EVENTS = {
  STUDY_CREATION_STATE_CHANGED: 'shu-study-creation.study-creation-state-changed'
};

export default StudyCreationStore;

// @ngInject
function StudyCreationStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_CREATE_LOADING, onLoading);
  $rootScope.$on(SERVICE_EVENTS.STUDY_CREATE_PROCESSING, onProcessing);
  $rootScope.$on(SERVICE_EVENTS.STUDY_CREATE_PROCESSING_DONE, onProcessingDone);
  $rootScope.$on(SERVICE_EVENTS.STUDY_CREATE_LOAD_SUCCESS, onLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.STUDY_CREATE_LOAD_ERROR, onLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onLoading() {
    state = Immutable({
      studyId: null,
      processing: false,
      studyKey: null,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_CREATION_STATE_CHANGED);
  }

  function onLoadSuccess(event, studyKey) {
    state = Immutable({
      studyId: null,
      processing: false,
      studyKey,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_CREATION_STATE_CHANGED);
  }

  function onLoadError() {
    state = Immutable({
      studyId: null,
      processing: false,
      studyKey: null,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_CREATION_STATE_CHANGED);
  }

  function onProcessing() {
    state = Immutable({
      studyId: null,
      processing: true,
      studyKey: null,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_CREATION_STATE_CHANGED);
  }

  function onProcessingDone(event, studyId) {
    state = Immutable({
      studyId: studyId,
      processing: false,
      studyKey: null,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_CREATION_STATE_CHANGED);
  }
}

export { EVENTS };
