//import { XXX } from '../model';

export default TaxonStatusRareteFilter;

// @ngInject
function TaxonStatusRareteFilter() {
  return function (items) {
    var filtered = [];
       for (var i = 0; i < items.length; i++) {
           var item = items[i];
           if (!angular.isUndefined(item) && !angular.isUndefined(item.type)
              && /^((?!protection|r[eéE]glementation).)*$/igm.test(item.type)) {
               //console.log(item.type);
               filtered.push(item);
           }
       }
       return filtered;
  };
}
