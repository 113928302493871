import Immutable from 'seamless-immutable';
import _ from 'lodash';

import { EVENTS as SERVICE_EVENTS } from '../services/study-synchronizer.service';

const EVENTS = {
  STUDY_SYNC_STATE_CHANGED: 'shu-synchro.study-synchronizer-state-changed'
};

export default StudySynchronizerStore;

// @ngInject
function StudySynchronizerStore($rootScope) {
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.STUDY_SYNC_STARTED, onSyncStarted);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SYNC_STEP_STARTED, onSyncStepStarted);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SYNC_STEP_DONE, onSyncStepDone);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SYNC_STEP_FAILED, onSyncStepFailed);
  $rootScope.$on(SERVICE_EVENTS.STUDY_SYNC_TERMINATED, onSyncTerminated);

  return {getState};

  function getState() {
    return state;
  }

  function onSyncStarted(event, studyId, unlock, save) {
    state = Immutable({
      studyId,
      unlock,
      save,
      stepsStarted: [],
      stepsDone: [],
      stepsFailed: [],
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SYNC_STATE_CHANGED);
  }

  function onSyncStepStarted(event, syncStep) {
    state = Immutable({
      studyId: state.studyId,
      unlock: state.unlock,
      save: state.save,
      stepsStarted: [syncStep].concat(state.stepsStarted),
      stepsDone: state.stepsDone,
      stepsFailed: state.stepsFailed,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SYNC_STATE_CHANGED);
  }

  function onSyncStepDone(event, syncStep) {
    state = Immutable({
      studyId: state.studyId,
      unlock: state.unlock,
      save: state.save,
      stepsStarted: _.without(state.stepsStarted, syncStep),
      stepsDone: [syncStep].concat(state.stepsDone),
      stepsFailed: state.stepsFailed,
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SYNC_STATE_CHANGED);
  }

  function onSyncStepFailed(event, syncStep) {
    state = Immutable({
      studyId: state.studyId,
      unlock: state.unlock,
      save: state.save,
      stepsStarted: _.without(state.stepsStarted, syncStep),
      stepsDone: state.stepsDone,
      stepsFailed: [syncStep].concat(state.stepsFailed),
      terminated: false
    });
    $rootScope.$broadcast(EVENTS.STUDY_SYNC_STATE_CHANGED);
  }

  function onSyncTerminated() {
    state = Immutable({
      studyId: state.studyId,
      unlock: state.unlock,
      save: state.save,
      stepsStarted: [],
      stepsDone: state.stepsDone,
      stepsFailed: state.stepsFailed,
      terminated: true
    });
    $rootScope.$broadcast(EVENTS.STUDY_SYNC_STATE_CHANGED);
  }
}

export { EVENTS };
