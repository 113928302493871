import angular from 'angular';

import taxonsProtectionListStore from './stores/taxons-protection-list.store';
import taxonsProtectionListService from './services/taxons-protection-list.service';
import protectionLocalRepository from './repositories/protection.local.repository';
import protectionRemoteRepository from './repositories/protection.remote.repository';
import protectionConfiguration from './configuration';

const moduleName = 'shu-protection';

angular.module(moduleName, ['lumx'])
  .factory('TaxonsProtectionListStore', taxonsProtectionListStore)
  .factory('TaxonsProtectionListService', taxonsProtectionListService)
  .factory('ProtectionRepository', IS_CORDOVA ? protectionLocalRepository : protectionRemoteRepository)
  .constant('ProtectionConfiguration', protectionConfiguration);

export default {moduleName};
