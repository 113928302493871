import settingsContainerComponent from './components/settings-container/settings-container.component';
import settingsFormComponent from './components/settings-form/settings-form.component';
import settingsStore from './stores/settings.store';
import settingsService from './services/settings.service';
import settingsRepository from './repositories/settings.repository';
import routerConfig from './routes';
import settingsConfiguration from './configuration';

const moduleName = 'shu-settings';

angular.module(moduleName, ['ui.router', 'lumx'])
  .directive('shuSettingsContainer', settingsContainerComponent)
  .directive('shuSettingsForm', settingsFormComponent)
  .factory('SettingsStore', settingsStore)
  .factory('SettingsService', settingsService)
  .factory('SettingsRepository', settingsRepository)
  .constant('SettingsConfiguration', settingsConfiguration)
  .config(routerConfig);

export default {moduleName};
