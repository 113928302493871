import { REFERENTIEL } from '../base/services/url-resolver.service';

export default {
  searchEndpoint: {
    server: REFERENTIEL,
    path: '/{txRefType}/{txRefVersion}/{txRefFilter}/taxon/search/{query}'
  },
  txRefTypesEndpoint: {
    server: REFERENTIEL,
    path: '/tx_ref/types'
  },
  txRefVersionsEndpoint: {
    server: REFERENTIEL,
    path: '/tx_ref/{key}/versions'
  },
  txRefEndpoint: {
    server: REFERENTIEL,
    path: '/tx_ref/{key}/version/{version}'
  },
  txRefFiltersEndpoint: {
    server: REFERENTIEL,
    path: '/tx_ref/{txRefTypeKey}/filters'
  },
  txRefAllFiltersEndpoint: {
    server: REFERENTIEL,
    path: '/tx_ref/filters'
  },
  taxonDetailsEndpoint: {
    server: REFERENTIEL,
    path: '/{txRefType}/{txRefVersion}/{txRefFilter}/taxon/{taxonKey}'
  },
  findTaxonsEndpoint: {
    server: REFERENTIEL,
    path: '/{txRefType}/{txRefVersion}/{txRefFilter}/taxon'
  }
};
