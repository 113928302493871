import _ from 'lodash';

import { APPLICATION_KEY, STUDY_STATUS } from '../../../study/model.js';
import { EVENTS as FIELD_SURVEY_STORE_EVENTS } from '../../stores/study-field-surveys.store';
import { EVENTS as STUDY_STORE_EVENTS } from '../../../study/stores/study-details.store';
import { EVENTS as SURVEY_TYPE_STORE_EVENTS } from '../../../survey-type/stores/survey-type-list.store';

require('./study-field-surveys-container.scss');

export default StudyFieldSurveysContainerDirective;

// @ngInject
function StudyFieldSurveysContainerDirective() {
  return {
    restrict: 'E',
    scope: {studyId: '@'},
    bindToController: true,
    controller: StudyFieldSurveysContainerController,
    controllerAs: 'studyFieldSurveysContainerCtrl',
    template: require('./study-field-surveys-container.html'),
    replace: true
  };
}

// @ngInject
function StudyFieldSurveysContainerController($scope,$location,$anchorScroll,$timeout,StudyFieldSurveysService, StudyDetailsService, SurveyTypeListService, HeaderService,
    StudyFieldSurveysStore, StudyDetailsStore, SurveyTypeListStore, $state, LxDialogService) {
  var studyFieldSurveysContainerCtrl = this;

  studyFieldSurveysContainerCtrl.fieldSurveyList = null;
  studyFieldSurveysContainerCtrl.headerUpdated = false;
  studyFieldSurveysContainerCtrl.fieldSurveyState = {};

  studyFieldSurveysContainerCtrl.isWebClient = isWebClient;
  studyFieldSurveysContainerCtrl.isLoading = isLoading;
  studyFieldSurveysContainerCtrl.isLoaded = isLoaded;
  studyFieldSurveysContainerCtrl.canCreateSubElements = canCreateSubElements;
  studyFieldSurveysContainerCtrl.onFieldSurveySelected = onFieldSurveySelected;
  studyFieldSurveysContainerCtrl.onCreateFieldSurvey = onCreateFieldSurvey;
  studyFieldSurveysContainerCtrl.onFieldSurveyCreated = onFieldSurveyCreated;

  $scope.$on(FIELD_SURVEY_STORE_EVENTS.STUDY_FIELD_SURVEYS_STATE_CHANGED, reload);
  $scope.$on(STUDY_STORE_EVENTS.STUDY_DETAILS_STATE_CHANGED, reload);
  $scope.$on(SURVEY_TYPE_STORE_EVENTS.SURVEY_TYPE_LIST_STATE_CHANGED, reload);

  StudyDetailsService.load(studyFieldSurveysContainerCtrl.studyId);
  StudyFieldSurveysService.load(studyFieldSurveysContainerCtrl.studyId);
  SurveyTypeListService.load(studyFieldSurveysContainerCtrl.studyId);

  function isLoading() {
    return (!studyFieldSurveysContainerCtrl.fieldSurveysState || studyFieldSurveysContainerCtrl.fieldSurveysState.loading) ||
        (!studyFieldSurveysContainerCtrl.studyState || studyFieldSurveysContainerCtrl.studyState.processing) ||
        (!studyFieldSurveysContainerCtrl.surveyTypeState || studyFieldSurveysContainerCtrl.surveyTypeState.loading);
  }

  function isLoaded() {
    return studyFieldSurveysContainerCtrl.fieldSurveysState && studyFieldSurveysContainerCtrl.fieldSurveysState.loaded &&
        studyFieldSurveysContainerCtrl.studyState && studyFieldSurveysContainerCtrl.studyState.loaded &&
        studyFieldSurveysContainerCtrl.surveyTypeState && studyFieldSurveysContainerCtrl.surveyTypeState.loaded;
  }

  function canCreateSubElements() {
    return studyFieldSurveysContainerCtrl.studyState &&
        studyFieldSurveysContainerCtrl.studyState.study &&
        studyFieldSurveysContainerCtrl.studyState.study.canCreateSubElements &&
        studyFieldSurveysContainerCtrl.studyState.study.applicationKey === APPLICATION_KEY.SHURIKEN_NG &&
        studyFieldSurveysContainerCtrl.studyState.study.status === STUDY_STATUS.ACTIVE;
  }

  function isWebClient() {
    return !IS_CORDOVA;
  }

  function onFieldSurveySelected(fieldSurveyId) {
    sessionStorage.setItem('fieldSurveyIdAnchor', fieldSurveyId);
    $state.go('field-survey-field-records', {studyId: studyFieldSurveysContainerCtrl.studyId, fieldSurveyId});

  }

  function onCreateFieldSurvey() {
    LxDialogService.open('fieldSurveyCreationDialog');
  }

  function reload(event) {
    studyFieldSurveysContainerCtrl.fieldSurveysState = StudyFieldSurveysStore.getState();
    studyFieldSurveysContainerCtrl.studyState = StudyDetailsStore.getState();
    studyFieldSurveysContainerCtrl.surveyTypeState = SurveyTypeListStore.getState();

    studyFieldSurveysContainerCtrl.fieldSurveyList = getFieldSurveyList();
    updateHeader();
 
    

  }
  function updateHeader() {
    
    if (!studyFieldSurveysContainerCtrl.studyState.study || studyFieldSurveysContainerCtrl.headerUpdated) {
      return;
    }
    HeaderService.updateTitle([
      {
        label: studyFieldSurveysContainerCtrl.studyState.study.key,
        link: {
          sref: 'study-details',
          params: {studyId: studyFieldSurveysContainerCtrl.studyId}
        }
      }, {
        label: 'Relevés',
        createFieldSurvey: studyFieldSurveysContainerCtrl.studyId
      }
    ]);
    studyFieldSurveysContainerCtrl.headerUpdated = true;
    var fieldSurveyIdAnchor = sessionStorage.getItem('fieldSurveyIdAnchor');
    $timeout(function() { 
      $location.hash(fieldSurveyIdAnchor);
      $anchorScroll(); 
  }, 300);

  }

  function getFieldSurveyList() {
    if (!isLoaded()) {
      return null;
    }
    return _.map(studyFieldSurveysContainerCtrl.fieldSurveysState.fieldSurveyList, (fieldSurvey) => {
      var surveyType = _.find(studyFieldSurveysContainerCtrl.surveyTypeState.results, {id: fieldSurvey.surveyType});
      return _.defaults({surveyType}, fieldSurvey);
    });
  }

  function onFieldSurveyCreated(surveyTypeId) {
    LxDialogService.close('fieldSurveyCreationDialog');
    $state.go('field-survey-creation', {studyId: studyFieldSurveysContainerCtrl.studyId, surveyTypeId});
  }
}
