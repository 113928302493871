import studySynchronizerStore from './stores/study-synchronizer-fake.store';
import studyImporterStore from './stores/study-importer-fake.store';
import studySynchronizerService from './services/study-synchronizer-fake.service';
import studyImporterService from './services/study-importer-fake.service';
import settingsOppService from './services/settings-opp-fake.service';

const moduleName = 'shu-synchro';

angular.module(moduleName, [])
  .factory('StudySynchronizerStore', studySynchronizerStore)
  .factory('StudyImporterStore', studyImporterStore)
  .factory('StudySynchronizerService', studySynchronizerService)
  .factory('StudyImporterService', studyImporterService)
  .factory('SettingsOppService', settingsOppService)
  //.factory('AutoSyncService', autoSyncService)
export default {moduleName};
