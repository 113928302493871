const EVENTS = {
  // Actions lancées quand l'utilisateur lance une recherche
  SURVEY_TYPE_LIST_LOADING: 'shu-survey-type.survey-type-list-loading',
  SURVEY_TYPE_LIST_LOAD_SUCCESS: 'shu-survey-type.survey-type-list-load-success',
  SURVEY_TYPE_LIST_LOAD_ERROR: 'shu-survey-type.survey-type-list-load-error',
};

export default SurveyTypeListService;

// @ngInject
function SurveyTypeListService($rootScope, SurveyTypeRepository, SurveyTypeListStore, LxNotificationService) {
  return {load};

  function load(studyId) {

    var currentState = SurveyTypeListStore.getState();
    if (currentState.loading) {
      // rien à faire le chargement est en cours
      return;
    }

    // On lance le chargement des données
    $rootScope.$emit(EVENTS.SURVEY_TYPE_LIST_LOADING);
    SurveyTypeRepository.list(studyId)
      .then((results) => {
        $rootScope.$emit(EVENTS.SURVEY_TYPE_LIST_LOAD_SUCCESS, results);
      }, (error) => {
        LxNotificationService.error('Une erreur est survenue au chargement du relevé-type. ' + (error.data || ''));
        $rootScope.$emit(EVENTS.SURVEY_TYPE_LIST_LOAD_ERROR);
      });
  }
}
export { EVENTS };
