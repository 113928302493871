import _ from 'lodash';

require('./field-survey-details-ra-tab.scss');

export default FieldSurveyDetailsRATabDirective;

// @ngInject
function FieldSurveyDetailsRATabDirective() {
  return {
    restrict: 'AE',
    scope: {
      initialDataProvider: '&initialData',
      fieldSurveyStateProvider: '&fieldSurveyState',
      onChangeFn: '&onChange',
      onDeleteFn: '&onDelete',
      canSubmitFn: '&canSubmit',
      isNewFn: '&isNew',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldSurveyDetailsRATabController,
    controllerAs: 'fieldSurveyDetailsRATabCtrl',
    template: require('./field-survey-details-ra-tab.html'),
    replace: true
  };
}

// @ngInject
function FieldSurveyDetailsRATabController($scope, $timeout) {
  var fieldSurveyDetailsRATabCtrl = this;
  fieldSurveyDetailsRATabCtrl.onHeureDebutChange = onHeureDebutChange;
  fieldSurveyDetailsRATabCtrl.onHeureFinChange = onHeureFinChange;
  fieldSurveyDetailsRATabCtrl.onTypeDeSuiviChange = onTypeDeSuiviChange;
  fieldSurveyDetailsRATabCtrl.onContextEnregistrementChange = onContextEnregistrementChange;
  fieldSurveyDetailsRATabCtrl.onTypeMilieuChange = onTypeMilieuChange;
  fieldSurveyDetailsRATabCtrl.onValidationChange = onValidationChange;
  fieldSurveyDetailsRATabCtrl.onHauteurMicroChange = onHauteurMicroChange;
  fieldSurveyDetailsRATabCtrl.onChange = onChange;
  fieldSurveyDetailsRATabCtrl.onDelete = onDelete;
  fieldSurveyDetailsRATabCtrl.canSubmit = canSubmit;
  fieldSurveyDetailsRATabCtrl.isNew = isNew;
  fieldSurveyDetailsRATabCtrl.isAutoLocatedValid = isAutoLocatedValid;
  fieldSurveyDetailsRATabCtrl.isAutoLocatedError = isAutoLocatedError;
  fieldSurveyDetailsRATabCtrl.locationPrecision = locationPrecision;

  $scope.$watch(fieldSurveyDetailsRATabCtrl.initialDataProvider, (initialData) => {

    if (!initialData) {
      fieldSurveyDetailsRATabCtrl.data = {};
      } else {

      var jsonResult=  JSON.parse(JSON.stringify(initialData));
      /* var date = new Date();

      jsonResult.heureDebut = moment(date.setHours(initialData.heureDebut[0], initialData.heureDebut[1])).format("HH:mm");

      jsonResult.heureFin = moment(date.setHours(initialData.heureFin[0], initialData.heureFin[1])).format("HH:mm"); */

      fieldSurveyDetailsRATabCtrl.data = _.cloneDeep(jsonResult);

    }
    fieldSurveyDetailsRATabCtrl.onChange();

  });

  $scope.$watch(fieldSurveyDetailsRATabCtrl.fieldSurveyStateProvider, (fieldSurveyState) => {
    if (!fieldSurveyState) {
      return;
    }

    fieldSurveyDetailsRATabCtrl.fieldSurveyState = fieldSurveyState;
  });

  $scope.$watch(fieldSurveyDetailsRATabCtrl.formProvider, (form) => {
    fieldSurveyDetailsRATabCtrl.form = form;
  });

  function onHeureDebutChange(currentHeureDebut) {
    fieldSurveyDetailsRATabCtrl.data.heureDebut = currentHeureDebut ? currentHeureDebut.id : null;
    onChange();
  }

  function onHeureFinChange(currentHeureFin) {
    fieldSurveyDetailsRATabCtrl.data.heureFin = currentHeureFin ? currentHeureFin.id : null;
    onChange();
  }

  function onTypeDeSuiviChange(currenttypeDeSuivi) {
    fieldSurveyDetailsRATabCtrl.data.typeDeSuivi = currenttypeDeSuivi ? currenttypeDeSuivi.id : null;
    onChange();
  }

  function onContextEnregistrementChange(currentContextEnregistrement) {
    fieldSurveyDetailsRATabCtrl.data.contextEnregistrement = currentContextEnregistrement ? currentContextEnregistrement.id : null;
    onChange();
  }

  function onTypeMilieuChange(currentTypeMilieu) {
    fieldSurveyDetailsRATabCtrl.data.typeMilieu = currentTypeMilieu ? currentTypeMilieu.id : null;
    onChange();
  }

  function onValidationChange(currentValidation) {
    fieldSurveyDetailsRATabCtrl.data.validation = currentValidation ? currentValidation.id : null;
    onChange();
  }

  function onHauteurMicroChange(currentHauteurMicro) {
	    fieldSurveyDetailsRATabCtrl.data.hauteurMicro = currentHauteurMicro ? currentHauteurMicro.id : null;
	    onChange();
	  }

  function onChange() {

    if (!fieldSurveyDetailsRATabCtrl.data) {
      return;
    }

    // utilisation du timeout pour attendre que les composants du formulaire soient bien initialisés avant d'évaluer isFormValid()
    $timeout(() => {
      fieldSurveyDetailsRATabCtrl.onChangeFn()('RA', {
        data: {
        	heureDebut: fieldSurveyDetailsRATabCtrl.data.heureDebut,
        	heureFin: fieldSurveyDetailsRATabCtrl.data.heureFin,
        	typeDeSuivi: fieldSurveyDetailsRATabCtrl.data.typeDeSuivi,
        	contextEnregistrement: fieldSurveyDetailsRATabCtrl.data.contextEnregistrement,
        	typeMilieu: fieldSurveyDetailsRATabCtrl.data.typeMilieu,
        	validation: fieldSurveyDetailsRATabCtrl.data.validation,
        	hauteurMicro: fieldSurveyDetailsRATabCtrl.data.hauteurMicro,
         },
        formValid: isFormValid()

      });

    });

  }

  function onDelete() {
    fieldSurveyDetailsRATabCtrl.onDeleteFn()();
  }

  function canSubmit() {
    return fieldSurveyDetailsRATabCtrl.canSubmitFn()();
  }

  function isNew() {
    return fieldSurveyDetailsRATabCtrl.isNewFn()();
  }

  // TODO : vérifier que les champs int sont bien des valeurs entières ?
  function isFormValid() {
    return !!(fieldSurveyDetailsRATabCtrl.form.heureDebut && fieldSurveyDetailsRATabCtrl.form.heureDebut.$valid || fieldSurveyDetailsRATabCtrl.form.heureFin && fieldSurveyDetailsRATabCtrl.form.heureFin.$valid);
  }
  function locationPrecision() {
    return fieldSurveyDetailsRATabCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return fieldSurveyDetailsRATabCtrl.isAutoLocatedValidFn() &&
           fieldSurveyDetailsRATabCtrl.isAutoLocatedValidFn()();
  }

  function isAutoLocatedError() {
    return fieldSurveyDetailsRATabCtrl.isAutoLocatedErrorFn() &&
           fieldSurveyDetailsRATabCtrl.isAutoLocatedErrorFn()();
  }
}
