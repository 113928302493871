import { EVENTS as TAXON_SEARCH_STORE_EVENTS } from '../../stores/taxon-search.store';
import { EVENTS as TX_REF_STORE_EVENTS } from '../../stores/taxon-ref.store';
import { EVENTS as TX_REF_FILTER_STORE_EVENTS } from '../../stores/taxon-ref-filter.store';
require('./taxon-search-container.scss');

export default TaxonSearchContainerDirective;

// @ngInject
function TaxonSearchContainerDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: TaxonSearchContainerController,
    controllerAs: 'taxonSearchContainerCtrl',
    template: require('./taxon-search-container.html'),
    replace: true
  };
}

// @ngInject
function TaxonSearchContainerController($scope, $rootScope, HeaderService, TaxonSearchStore, TaxonRefStore, TaxonRefFilterStore, TaxonSearchService) {
  var taxonSearchContainerCtrl = this;

  taxonSearchContainerCtrl.txRefState = {};
  taxonSearchContainerCtrl.txRefFilterState = {};
  taxonSearchContainerCtrl.queryState = {};
  taxonSearchContainerCtrl.resultsState = {};

  taxonSearchContainerCtrl.onUpdateForm = TaxonSearchService.onUpdateForm;
  taxonSearchContainerCtrl.onTaxonSelected = onTaxonSelected;
  taxonSearchContainerCtrl.onNextResultsAction = onNextResultsAction;
  taxonSearchContainerCtrl.showResults = showResults;

  $scope.$on(TX_REF_STORE_EVENTS.TX_REF_STATE_CHANGED, reloadTxRefs);
  $scope.$on(TX_REF_FILTER_STORE_EVENTS.TX_REF_FILTER_STATE_CHANGED, reloadTxRefFilters);

  $scope.$on(TAXON_SEARCH_STORE_EVENTS.TAXON_SEARCH_QUERY_STATE_CHANGED, reloadQuery);
  $scope.$on(TAXON_SEARCH_STORE_EVENTS.TAXON_SEARCH_RESULTS_STATE_CHANGED, reloadResults);

  TaxonSearchService.reset();

  init();

  function init() {
    HeaderService.updateTitle([
      'Catalogue de taxons'
    ]);
  }

  function showResults() {
    return taxonSearchContainerCtrl.resultsState.loaded || taxonSearchContainerCtrl.resultsState.totalResults > 0;
  }

  function reloadTxRefs() {
    taxonSearchContainerCtrl.txRefState = TaxonRefStore.getState();
  }

  function reloadTxRefFilters() {
    taxonSearchContainerCtrl.txRefFilterState = TaxonRefFilterStore.getState();
  }

  function reloadQuery() {
    taxonSearchContainerCtrl.queryState = TaxonSearchStore.getQueryState();
  }

  function reloadResults() {
    taxonSearchContainerCtrl.resultsState = TaxonSearchStore.getResultsState();
  }

  function onNextResultsAction() {
    TaxonSearchService.loadNextResults(
      taxonSearchContainerCtrl.queryState.query,
      taxonSearchContainerCtrl.queryState.selectedReferentiel,
      taxonSearchContainerCtrl.queryState.selectedFilter.key,
      taxonSearchContainerCtrl.resultsState.results.length
    );
  }

  function onTaxonSelected(taxonKey) {
    TaxonSearchService.onTaxonSelected(
      taxonSearchContainerCtrl.queryState.selectedReferentiel.key,
      taxonSearchContainerCtrl.queryState.selectedReferentiel.version,
      taxonSearchContainerCtrl.queryState.selectedFilter.key,
      taxonKey
    );
  }
}
