import _ from 'lodash';

require('./select-txgroup.scss');

export default SelectTxGroupDirective;

// @ngInject
function SelectTxGroupDirective() {
  return {
    restrict: 'E',
    scope: {
      placeholder: '@',
      initialValue: '@',
      availableValuesProvider: '&availableValues',
      onChangeFn: '&onChange',
      disabledProvider: '&disabled',
      name: '@',
      requiredProvider: '&required'
    },
    bindToController: true,
    controller: SelectTxGroupController,
    controllerAs: 'selectTxGroupCtrl',
    template: require('./select-txgroup.html'),
    replace: true
  };
}

// @ngInject
function SelectTxGroupController($scope) {
  var selectTxGroupCtrl = this;

  selectTxGroupCtrl.availableValues = [];
  selectTxGroupCtrl.protocolState = {};

  selectTxGroupCtrl.onSelect = onSelect;
  selectTxGroupCtrl.onChange = onChange;

  $scope.$watch(selectTxGroupCtrl.availableValuesProvider, (availableValues) => {
    selectTxGroupCtrl.availableValues = availableValues;
  });
  $scope.$watch(selectTxGroupCtrl.disabledProvider, (disabled) => {
    selectTxGroupCtrl.disabled = disabled;
  });
  $scope.$watch(selectTxGroupCtrl.requiredProvider, (required) => {
    selectTxGroupCtrl.required = required;
  });
  $scope.$watch(() => selectTxGroupCtrl.initialValue, (initialValue) => {
    selectTxGroupCtrl.currentValue = initialValue;
  });

  function onSelect() {
    if (selectTxGroupCtrl.currentValue == selectTxGroupCtrl.oldValue) {
      selectTxGroupCtrl.oldValue = null;
      selectTxGroupCtrl.currentValue = null;
    } else {
      selectTxGroupCtrl.oldValue = selectTxGroupCtrl.currentValue;
    }
    onChange(selectTxGroupCtrl.currentValue);
  }

  function onChange(selectedValue) {
    selectTxGroupCtrl.onChangeFn() && selectTxGroupCtrl.onChangeFn()(selectedValue);
  }
}
