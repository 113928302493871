require('./aside.scss');

// TODO: A déplacer dans un service
const EVENTS = {
  ASIDE_SHOW: 'shu-aside.show',
  ASIDE_HIDE: 'shu-aside.hide'
};

export default AsideDirective;

// @ngInject
function AsideDirective() {
  return {
    restrict: 'E',
    scope: {},
    controller: AsideController,
    controllerAs: 'asideCtrl',
    template: require('./aside.html'),
    replace: true
  };
}

export { EVENTS };

// @ngInject
function AsideController($scope, $templateCache, AuthStore, AuthService) {
  var asideCtrl = this;

  asideCtrl.isVisible = false;
  asideCtrl.show = show;
  asideCtrl.hide = hide;
  asideCtrl.isUserAuthenticated = AuthStore.isAuthenticated;
  asideCtrl.isCordovaUser = isCordovaUser;
  asideCtrl.logOut = logOut;
  asideCtrl.appVersion = APP_VERSION;
  asideCtrl.logoUrl = require('./logo-biotope.png');

  $scope.$on(EVENTS.ASIDE_SHOW, asideCtrl.show);
  $scope.$on(EVENTS.ASIDE_HIDE, asideCtrl.hide);
  $scope.$on('$stateChangeStart', asideCtrl.hide);

  function show() {
    if (asideCtrl.isVisible === false) {
      asideCtrl.isVisible = true;
    }
  }
  function hide() {
    if (asideCtrl.isVisible === true) {
      asideCtrl.isVisible = false;
    }
  }
  function logOut() {
    hide();
    AuthService.logOut();
  }

  function isCordovaUser() {
    return IS_CORDOVA;
  }
}
