import { NATURALISTE } from '../base/services/url-resolver.service';

export default {
  fieldRecordLatestEndpoint: {
    server: NATURALISTE,
    path: '/field_record/latest'
  },
  latestTxKeysForStudyAndTxGroupsEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/field_record/latest-tx-keys'
  },
  fieldRecordGeomForFieldRecordEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}/field_record/geom'
  },
  fieldRecordExtraTablesEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}/field_record_extra_tables'
  },
  fieldRecordGeomForStudyEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/field_record/geom'
  },
  fieldRecordByStudyEndpoint: {
    server: NATURALISTE,
    path: '/study/{studyId}/field_record'
  },
  fieldRecordByFieldSurveyEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}/field_record'
  },
  fieldRecordByFieldSurveyRpEndpoint: {
    server: NATURALISTE,
    path: '/field_survey_rp/{fieldSurveyId}/field_record'
  },
  fieldRecordEndpoint: {
    server: NATURALISTE,
    path: '/field_record/{fieldRecordId}'
  },
  fieldRecordDescriptionEndpoint: {
    server: NATURALISTE,
    path: '/field_record/{fieldRecordId}/description'
  },
  fieldRecordHeadcountEndpoint: {
    server: NATURALISTE,
    path: '/field_record/{fieldRecordId}/headcount'
  },
  fieldRecordMakePublicForSurveyTypeEndpoint: {
    server: NATURALISTE,
    path: '/survey_type/{surveyTypeId}/field_records/make_public'
  },
  fieldRecordMakePublicForFieldSurveyEndpoint: {
    server: NATURALISTE,
    path: '/field_survey/{fieldSurveyId}/field_records/make_public'
  },
  fieldRecordValidationEndpoint: {
    server: NATURALISTE,
    path: '/field_record/{fieldRecordId}/validate'
  }
};
