import { EVENTS as LOCATION_EVENTS } from '../map/services/geolocation.local.service.js';
import { REFERENTIEL } from '../base/services/url-resolver.service';
import { $stateProvider } from '../../../providers';
import Leaflet from 'leaflet';

const LAYERS_CONFIG = {
  DEFAULT: {
    style: {
      color: '#FF0FF',
      weight: 1,
    },
  },
  GPS: {
    circleStyle: {
      color: '#03A9F4',
      fillColor: '#03A9F4',
      weight: 1,
      fillOpacity: 0.1,
    },
    markerClass: Leaflet.marker,
    markerStyle: {
      icon: Leaflet.divIcon({
        className: '',
        iconSize: [24, 32],
        html: '<span class="tc-light-blue-500 text-shadow mdi mdi-crosshairs-gps fs-headline"></span>',
      }),
    },
  },
  GRID: {
    style: (feature) => {
      return {
        color: feature.properties.selected ? '#0000FF' : '#666',
        weight: 3,
        opacity: 0.7,
        fillOpacity: feature.properties.selected ? 0.5 : 0.1,
      };
    },
  },
  STUDY: {
    style: {
      color: '#FF0000',
      weight: 5,
      opacity: 0.8,
      dashArray: [20, 15],
      fillOpacity: 0,
    },
    onEachFeature: function(feature, layer) {
      layer.bindPopup(`Étude n°${feature.properties.name}`);
    },
  },
  TRACKING($rootScope, $compile, Geolocation, LxNotificationService, studyId) {
    const colors = ['#f44336', '#9c27b0', '#3f51b5', '#2196f3', '#00bcd4', '#4caf50', '#ffeb3b', '#ff9800'];
    const getColor = (i) => {
      return colors[i % colors.length];
    };

    const lines = [];
    let isPopupLinesOpen = false;

    const openPopupLines = () => {
      lines.forEach((line) => {
        const layer = line.layer;
        if (!layer.feature.geometry.coordinates.length) {
          return;
        }
        const i = layer.feature.geometry.properties.index;
        let pos = i % 3;
        if (pos) {
          pos = Math.round((layer.feature.geometry.coordinates.length - 1) / pos);
        }
        const coords = layer.feature.geometry.coordinates[pos];
        layer.openPopup({
          lat: coords[1],
          lng: coords[0],
        });
      });
      isPopupLinesOpen = true;
    };

    const closePopupLines = () => {
      lines.forEach((line) => {
        line.layer.closePopup();
      });
      isPopupLinesOpen = false;
    };

    const bringToFront = (line) => {
      if (!line.layer.feature.geometry.coordinates.length) {
        return;
      }
      line.layer.bringToFront();
      line.markerStart.bringToFront();
    };

    let curLineEditing;
    const openLineEdition = (line) => {
      if (curLineEditing && curLineEditing.name != line.name) {
        closeCurLineEdition();
      }
      bringToFront(line);
      overlay.addLayer(line.edition);
      curLineEditing = line;
    };

    const closeCurLineEdition = () => {
      if (curLineEditing) {
        curLineEditing.popupLineScope.isEditing = false;
        overlay.removeLayer(curLineEditing.edition);
        if (curLineEditing.popupEdition) {
          curLineEditing.popupEdition.remove();
          curLineEditing.popupEdition = null;
        }
        curLineEditing = null;
      }
    };

    let mapApi;
    let overlay;
    this.onMapReady = (_mapApi) => {
      mapApi = _mapApi;
      overlay = mapApi.getOverlayByName('tracking');
      $rootScope.$on(LOCATION_EVENTS.REMOVE_TRACKING_POINTS, (e, tracking) => {
        lines.forEach((line) => {
          line.markerStart.remove();
          line.edition.clearLayers();
          line.edition.remove();
        });

        overlay.clearLayers();
        overlay.addData(
          tracking.surveys.map((trackingSurvey, index) => {
            return {
              properties: {
                name: `Trace ${trackingSurvey.name}`,
                index,
                trackingSurvey,
              },
              type: 'LineString',
              coordinates: trackingSurvey.points.map((point) => {
                return [point.longitude, point.latitude];
              }),
            };
          })
        );

        lines.forEach((line) => {
          overlay.addLayer(line.markerStart);
        });

        if (isPopupLinesOpen) {
          openPopupLines();
        }
        if (curLineEditing) {
          const line = lines.find((line) => {
            return line.name === curLineEditing.name;
          });
          !line.layer.feature.geometry.coordinates.length ? closeCurLineEdition() : openLineEdition(line);
        }
      });

      lines.forEach((line) => {
        overlay.addLayer(line.markerStart);
      });
    };

    this.getConf = () => {
      return {
        name: 'tracking',
        style: (feature) => {
          return {
            color: getColor(feature.geometry.properties.index),
            weight: 5,
            opacity: 1,
            fillOpacity: 0,
          };
        },
        onEachFeature: function(feature, layer) {
          if (!feature.coordinates || !feature.coordinates.length) {
            return;
          }
          const name = `survey_${feature.properties.trackingSurvey.id}`;
          const color = getColor(feature.properties.index);
          let line = lines.find((line) => {
            return line.name == name;
          });
          if (!line) {
            line = { name, isVisible: true };
            lines.push(line);
          }
          line.layer = layer;
          layer.setStyle({ opacity: line.isVisible ? 1 : 0 });
          const coords = feature.coordinates[0];
          if (!coords) {
            return;
          }
          line.markerStart = Leaflet.circleMarker(
            {
              lat: coords[1],
              lng: coords[0],
            },
            { radius: 20, color: color, fillOpacity: 0.5 }
          );

          line.edition = Leaflet.geoJSON(
            feature.properties.trackingSurvey.points.map((point) => {
              return {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [point.longitude, point.latitude],
                },
                properties: {
                  point: point,
                },
              };
            }),
            {
              pointToLayer: function(pointFeature, latlng) {
                let marker = Leaflet.circleMarker(latlng, { radius: 10, color: color, fillOpacity: 0.5 });
                let popup;
                marker.addEventListener('click', (e) => {
                  if (popup) {
                    overlay.bindPopup(popup).openPopup(latlng);
                    line.popupEdition = popup;
                    return;
                  }
                  const switchId = Math.random()
                    .toString(36)
                    .replace(/[^a-z]+/g, '');
                  const tpl = `<div >
                  <div class="mb">
                    <b class="tc-red-500">SUPPRIMER</b></br />
                    {{ point.time|tzDate:'DD/MM/YYYY HH:mm:ss' }}<br />
                    lat : {{point.latitude}}<br />
                    lon : {{point.longitude}}
                    </div>
                    <button type="button" ng-click="rmPoints('=')" class="btn btn--xs btn--raised btn--grey mb">Ce point</button><br />
                    <button type="button" ng-click="rmPoints('>')" class="btn btn--xs btn--raised btn--grey mb">Ce point et les suivants</button><br />
                    <button type="button" ng-click="rmPoints('<')" class="btn btn--xs btn--raised btn--grey mb">Ce point et les précédants</button>
                    <div>
                      <button type="button" ng-click="rmAll()" class="btn btn--xs btn--raised btn--red mb">Tous les points</button>
                    <div>
                    <div class="switch" flex-item>
                      <input type="checkbox" id="cb_${switchId}" class="switch__input" ng-model="applyToAll" />
                      <label for="cb_${switchId}" class="switch__label">Appliquer à toutes les traces</label>
                    </div>
                    </div>`;
                  const scope = $rootScope.$new();
                  Object.assign(scope, {
                    survey: feature.properties.trackingSurvey,
                    point: pointFeature.properties.point,
                    applyToAll: false,
                    rmPoints: (direction) => {
                      const title = `ATTENTION${
                        !scope.applyToAll ? '' : " cette action s'applique à toutes les traces de l'étude"
                      }`;
                      let msg = 'Êtes-vous sur de vouloir supprimer ce point';

                      if (direction == '<') {
                        msg += ' et les précédants';
                      }
                      if (direction == '>') {
                        msg += ' et les suivants';
                      }
                      msg += ' ?';
                      LxNotificationService.confirm(title, msg, { cancel: 'Non', ok: 'Oui' }, (value) => {
                        if (value) {
                          Geolocation.removeTrackingPoints(
                            scope.point.time,
                            studyId,
                            direction,
                            scope.applyToAll ? undefined : scope.survey.id
                          );
                        }
                      });
                    },
                    rmAll: () => {
                      const title = `ATTENTION${
                        !scope.applyToAll ? '' : " cette action s'applique à toutes les traces de l'étude"
                      }`;
                      const msg = scope.applyToAll
                        ? 'Êtes-vous sur de vouloir supprimer TOUTES les traces de cette étude ?'
                        : 'Êtes-vous sur de vouloir supprimer cette trace';
                      LxNotificationService.confirm(title, msg, { cancel: 'Non', ok: 'Oui' }, (value) => {
                        if (value) {
                          const survey = feature.properties.trackingSurvey;
                          Geolocation.removeTrackings(studyId, scope.applyToAll ? undefined : survey.id);
                        }
                      });
                    },
                  });
                  const comp = $compile(tpl)(scope);
                  popup = Leaflet.popup({
                    autoClose: true,
                    closeOnClick: false,
                    autoPan: false,
                  })
                    .setLatLng(latlng)
                    .setContent(comp[0]);
                  overlay.bindPopup(popup).openPopup(latlng);
                  line.popupEdition = popup;
                });
                return marker;
              },
            }
          );

          const tpl = `<div style="width:156px;">
            <div>
              <b style="color:${getColor(feature.properties.index)};">${feature.properties.name}</b>
            </div>
            <button type="button" ng-click="bringToFront()" class="btn btn--xs btn--raised btn--grey">
              <span class="icon icon--s icon--flat tx-icon-container mdi mdi-arrow-up-bold mdi-24px"></span>
            </button>
            <button type="button" ng-click="toggleShow()" class="btn btn--xs btn--raised btn--grey">
              <span class="icon icon--s icon--flat tx-icon-container mdi mdi-24px" ng-class="[isVisible ? 'mdi-eye':'mdi-eye-off']"></span>
            </button>
            <button type="button" ng-click="toggleEdit()" class="btn btn--xs btn--raised btn--grey">
              <span class="icon icon--s icon--flat tx-icon-container mdi mdi-24px" ng-class="[isEditing ? 'mdi-map-marker':'mdi-map-marker-off']"></span>
            </button>
          </div>`;
          const scope = $rootScope.$new();
          line.popupLineScope = scope;
          Object.assign(scope, {
            isVisible: line.isVisible,
            isEditing: curLineEditing && curLineEditing.name == line.name,
            bringToFront: () => {
              bringToFront(line);
            },
            toggleShow: () => {
              scope.isVisible = line.isVisible = !scope.isVisible;
              const style = {
                opacity: scope.isVisible ? 1 : 0,
              };
              layer.setStyle({ ...style });
              line.markerStart.setStyle({ ...style, fillOpacity: scope.isVisible ? 0.5 : 0 });
              if (!scope.isVisible && scope.isEditing) {
                scope.toggleEdit();
              }
            },
            toggleEdit: () => {
              scope.isEditing = !scope.isEditing;
              scope.isEditing ? openLineEdition(line) : closeCurLineEdition();
            },
          });
          const comp = $compile(tpl)(scope);

          layer.bindPopup(
            Leaflet.popup({
              autoClose: false,
              closeOnClick: false,
              autoPan: false,
            }).setContent(comp[0])
          );
        },
      };
    };

    this.getPopupCtrl = () => {
      return {
        controlEventHooks: {
          overlayadd: () => {
            openPopupLines();
          },
          overlayremove: () => {
            closePopupLines();
          },
        },
      };
    };
  },
  TRACKING_POPUP: {},
  FIELD_SURVEY: {
    name: 'field_survey',
    style: function(geojson) {
      if (geojson.geometry.type === 'Point') {
        return {
          opacity: 0.8,
          color: '#00FF00',
          fillOpacity: 0.8,
        };
      }
      return {
        color: '#00FF00',
        weight: 3,
        opacity: 0.8,
        dashArray: [10, 5],
        fillOpacity: 0.05,
      };
    },
    pointToLayer: function(geojson, latlng) {
      return Leaflet.circleMarker(latlng).setRadius(6);
    },
    onEachFeature: function(feature, layer) {
      var href = $stateProvider().href('field-survey-field-records', {
        studyId: feature.properties.studyId,
        fieldSurveyId: feature.properties.fieldSurveyId,
      });
      layer.bindPopup(
        Leaflet.popup({
          autoClose: false,
          closeOnClick: false,
          autoPan: false,
        }).setContent(`<a class="map-container__popup-link" href="${href}">Relevé ${feature.properties.name}</a>`)
      );
    },
  },
  FIELD_SURVEY_DETAIL: {
    style: {
      color: '#00FF00',
      weight: 3,
      opacity: 0.8,
      dashArray: [10, 5],
      fillOpacity: 0.05,
    },
    pointToLayer: function(geojson, latlng) {
      return Leaflet.marker(latlng, {
        icon: Leaflet.divIcon({
          className: '',
          iconSize: [24, 32],
          html: '<span class="tc-green-100 text-shadow mdi mdi-crosshairs fs-headline"></span>',
        }),
      });
    },
    icon: Leaflet.divIcon({
      className: '',
      iconSize: [24, 32],
      html: '<span class="tc-green-100 text-shadow mdi mdi-crosshairs fs-headline"></span>',
    }),
    onEachFeature: function(feature, layer) {
      var href = $stateProvider().href('field-survey-field-records', {
        studyId: feature.properties.studyId,
        fieldSurveyId: feature.properties.fieldSurveyId,
      });
      layer.bindPopup(
        Leaflet.popup({
          autoClose: false,
          closeOnClick: false,
          autoPan: false,
        }).setContent(`<a class="map-container__popup-link" href="${href}">${feature.properties.name}</a>`)
      );
    },
  },
  TX_POSITION: {
    name: 'tx_position',
    style: {
      color: '#00FFFF',
      weight: 3,
      opacity: 0.8,
      dashArray: [10, 5],
      fillOpacity: 0.1,
    },
    pointToLayer: function(geojson, latlng) {
      return Leaflet.marker(latlng, {
        icon: Leaflet.divIcon({
          className: '',
          iconSize: [24, 32],
          html: '<span class="tc-blue-100 text-shadow mdi mdi-eye fs-headline"></span>',
        }),
      });
    },
    onEachFeature: function(feature, layer) {
      var href = $stateProvider().href('field-record-details', {
        studyId: feature.properties.studyId,
        fieldRecordId: feature.properties.fieldRecordId,
      });
      layer.bindPopup(
        Leaflet.popup({
          autoClose: false,
          closeOnClick: false,
          autoPan: false,
        }).setContent(`<a class="map-container__popup-link" href="${href}">${feature.properties.name}</a>`)
      );
    },
  },
  TX_POSITION_DETAIL: {
    style: {
      color: '#00FFFF',
      weight: 3,
      opacity: 0.8,
      dashArray: [10, 5],
      fillOpacity: 0.1,
    },
    icon: Leaflet.divIcon({
      className: '',
      iconSize: [24, 32],
      html: '<span class="tc-blue-100 text-shadow mdi mdi-crosshairs fs-headline"></span>',
    }),
    pointToLayer: function(geojson, latlng) {
      return Leaflet.marker(latlng, {
        icon: Leaflet.divIcon({
          className: '',
          iconSize: [24, 32],
          html: '<span class="tc-blue-100 text-shadow mdi mdi-crosshairs fs-headline"></span>',
        }),
      });
    },
    onEachFeature: function(feature, layer) {
      layer.bindPopup(feature.properties.name);
    },
  },
  TX_POPUP: {
    controlEventHooks: {
      overlayadd: function(layerEvent) {
        for (const key in layerEvent.layer._map._layers) {
          const layer = layerEvent.layer._map._layers[key];
          if (_.get(layer, 'options.name') == 'tx_position') {
            for (const markerKey in layer._layers) {
              const marker = layer._layers[markerKey];
              marker.openPopup && marker.openPopup();
            }
          }
        }
      },
      overlayremove: function(layerEvent) {
        for (const key in layerEvent.layer._map._layers) {
          const layer = layerEvent.layer._map._layers[key];
          if (_.get(layer, 'options.name') == 'tx_position') {
            for (const markerKey in layer._layers) {
              const marker = layer._layers[markerKey];
              marker.closePopup && marker.closePopup();
            }
          }
        }
      },
    },
  },
  FIELD_SURVEY_POPUP: {
    controlEventHooks: {
      overlayadd: function(layerEvent) {
        for (const key in layerEvent.layer._map._layers) {
          const layer = layerEvent.layer._map._layers[key];
          if (_.get(layer, 'options.name') == 'field_survey') {
            for (const markerKey in layer._layers) {
              const marker = layer._layers[markerKey];
              marker.openPopup && marker.openPopup();
            }
          }
        }
      },
      overlayremove: function(layerEvent) {
        for (const key in layerEvent.layer._map._layers) {
          const layer = layerEvent.layer._map._layers[key];
          if (_.get(layer, 'options.name') == 'field_survey') {
            for (const markerKey in layer._layers) {
              const marker = layer._layers[markerKey];
              marker.closePopup && marker.closePopup();
            }
          }
        }
      },
    },
  },
  EXPERT_POSITION: {
    style: {
      color: '#FF8F00',
      weight: 1,
    },
    circleStyle: {
      color: '#FF8F00',
      fillColor: '#FF8F00',
      weight: 1,
      fillOpacity: 0.1,
      clickable: false,
    },
    pointToLayer: function(geojson, latlng) {
      return Leaflet.marker(latlng, {
        icon: Leaflet.divIcon({
          className: '',
          iconSize: [24, 32],
          html: '<span class="tc-amber-800 text-shadow mdi mdi-crosshairs-gps fs-headline"></span>',
        }),
      });
    },
    onEachFeature: function(feature, layer) {
      layer.bindPopup(feature.properties.name);
    },
  },
};

export default {
  mapEndpoint: {
    server: REFERENTIEL,
    path: '/maps/tile/{imagerySet}/{z}/{x}/{y}',
  },

  mapBackgroundEndpoint: {
    server: REFERENTIEL,
    path: '/maps/background',
  }
};

export { LAYERS_CONFIG };
