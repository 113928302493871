import _ from 'lodash';

import Immutable from 'seamless-immutable';

import { EVENTS as SERVICE_EVENTS } from '../services/taxon-search-by-txgroups.service';

const EVENTS = {
  TAXON_SEARCH_BY_TXGROUPS_RESULTS_STATE_CHANGED: 'shu-taxon-search.taxon-search-by-txgroups-results-state-changed'
};

export default TaxonSearchByTxGroupsStore;

// @ngInject
function TaxonSearchByTxGroupsStore($rootScope) {
  var queryState = Immutable({});
  var state = Immutable({});

  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOADING, onTaxonSearchByTxGroupsResultsLoading);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_SUCCESS, onTaxonSearchByTxGroupsResultsLoadSuccess);
  $rootScope.$on(SERVICE_EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_LOAD_ERROR, onTaxonSearchByTxGroupsResultsLoadError);

  return {getState};

  function getState() {
    return state;
  }

  function onTaxonSearchByTxGroupsResultsLoading(event, {query, txGroups, txRefType, txRefVersion}) {
    queryState = Immutable({query, txGroups, txRefType, txRefVersion});
    state = Immutable({
      results: null,
      loading: true,
      loaded: false,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchByTxGroupsResultsLoadSuccess(event, results, {query, txGroups, txRefType, txRefVersion}) {
    if (queryState.query !== query || !_.isEqual(queryState.txGroups, txGroups) ||
        queryState.txRefType !== txRefType || queryState.txRefVersion !== txRefVersion) {
      return;
    }
    state = Immutable({
      results,
      loading: false,
      loaded: true,
      error: false
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_STATE_CHANGED);
  }

  function onTaxonSearchByTxGroupsResultsLoadError({query, txGroups, txRefType, txRefVersion}) {
    if (queryState.query !== query || !_.isEqual(queryState.txGroups, txGroups) ||
        queryState.txRefType !== txRefType || queryState.txRefVersion !== txRefVersion) {
      return;
    }
    state = Immutable({
      results: null,
      loading: false,
      loaded: false,
      error: true
    });
    $rootScope.$broadcast(EVENTS.TAXON_SEARCH_BY_TXGROUPS_RESULTS_STATE_CHANGED);
  }
}

export { EVENTS };
