import _ from 'lodash';

require('./taxon-search-form.scss');

export default TaxonSearchFormDirective;

// @ngInject
function TaxonSearchFormDirective() {
  return {
    restrict: 'E',
    scope: {
      queryStateProvider: '&queryState',
      txRefStateProvider: '&txRefState',
      txRefFilterStateProvider: '&txRefFilterState',
      onUpdateFormFn: '&onUpdateForm'
    },
    controller: TaxonSearchFormController,
    controllerAs: 'taxonSearchFormCtrl',
    bindToController: true,
    template: require('./taxon-search-form.html'),
    replace: true
  };
}

// @ngInject
function TaxonSearchFormController($scope, TaxonSearchService) {
  var taxonSearchFormCtrl = this;

  taxonSearchFormCtrl.getReferentielKeys = getReferentielKeys;
  taxonSearchFormCtrl.getReferentielName = getReferentielName;
  taxonSearchFormCtrl.getReferentielsForKey = getReferentielsForKey;
  taxonSearchFormCtrl.onQueryUpdated = onQueryUpdated;
  taxonSearchFormCtrl.onSelectReferentielType = onSelectReferentielType;
  taxonSearchFormCtrl.onSelectReferentielVersion = onSelectReferentielVersion;

  taxonSearchFormCtrl.getFilterKeys = getFilterKeys;
  taxonSearchFormCtrl.getFilterName = getFilterName;
  taxonSearchFormCtrl.getFilterForKey = getFilterForKey;
  taxonSearchFormCtrl.onSelectReferentielFilter = onSelectReferentielFilter;

  $scope.$watch(taxonSearchFormCtrl.queryStateProvider, (queryState) => {
    taxonSearchFormCtrl.query = queryState.query;
    taxonSearchFormCtrl.selectedReferentielKey = queryState.selectedReferentiel && queryState.selectedReferentiel.key;
    taxonSearchFormCtrl.selectedReferentiel = queryState.selectedReferentiel;
    /*taxonSearchFormCtrl.selectedFilterKey = queryState.selectedFilter && queryState.selectedFilter.key;
    taxonSearchFormCtrl.selectedFilter = queryState.selectedFilter;*/
  });
  $scope.$watch(taxonSearchFormCtrl.txRefStateProvider, (txRefState) => {
    taxonSearchFormCtrl.availableReferentiels = {};

    var sortedReferentiels = _.sortBy(txRefState.availableReferentiels, 'version');
    for (let i = 0; i < sortedReferentiels.length; i++) {
      if (!taxonSearchFormCtrl.availableReferentiels[sortedReferentiels[i].key]) {
        taxonSearchFormCtrl.availableReferentiels[sortedReferentiels[i].key] = [];
      }
      taxonSearchFormCtrl.availableReferentiels[sortedReferentiels[i].key].push(sortedReferentiels[i]);
    }
  });

  $scope.$watch(taxonSearchFormCtrl.txRefFilterStateProvider, (txRefFilterState) => {
    taxonSearchFormCtrl.availableFilters = {};
    var sortedFilters = _.sortBy(txRefFilterState.availableFilters, 'name');
    for (let i = 0; i < sortedFilters.length; i++) {
      if (!taxonSearchFormCtrl.availableFilters[sortedFilters[i].key]) {
        taxonSearchFormCtrl.availableFilters[sortedFilters[i].key] = [];
      }
      taxonSearchFormCtrl.availableFilters[sortedFilters[i].key].push(sortedFilters[i]);
    }
    if((taxonSearchFormCtrl.selectedFilterKey==null && sortedFilters.length !=0) || (taxonSearchFormCtrl.selectedFilter.txRefType !=taxonSearchFormCtrl.selectedReferentiel.key)){
      taxonSearchFormCtrl.selectedFilterKey = sortedFilters.find(item => item.terrUnitKey == taxonSearchFormCtrl.selectedReferentiel.terrUnitKey).key;
      taxonSearchFormCtrl.selectedFilter = sortedFilters.find(item => item.terrUnitKey == taxonSearchFormCtrl.selectedReferentiel.terrUnitKey);     
    }
  });

  function onQueryUpdated() {
    if (!taxonSearchFormCtrl.query) {
      return;
    }
    taxonSearchFormCtrl.onUpdateFormFn()(taxonSearchFormCtrl.query, taxonSearchFormCtrl.selectedReferentiel, taxonSearchFormCtrl.selectedFilter);
;
  }

  function onSelectReferentielType() {

    taxonSearchFormCtrl.selectedReferentiel = _.chain(getReferentielsForKey(taxonSearchFormCtrl.selectedReferentielKey))
      .sortBy('created')
      .last()
      .value();
    //here we load filters for the selected referentiel type
    TaxonSearchService.loadTxRefFilters(taxonSearchFormCtrl.selectedReferentiel.key);
    onQueryUpdated();
  }


  function onSelectReferentielFilter() {
    taxonSearchFormCtrl.selectedFilter = _.chain(getFilterForKey(taxonSearchFormCtrl.selectedFilterKey))
      .sortBy('created')
      .last()
      .value();
    onQueryUpdated();
  }

  function onSelectReferentielVersion() {
    onQueryUpdated();
  }

  function getReferentielKeys() {
    return _.keys(taxonSearchFormCtrl.availableReferentiels);
  }

  function getReferentielName(referentielKey) {
    // Tous les référentiels attachés à une clé ont le même nom
    return _.head(taxonSearchFormCtrl.availableReferentiels[referentielKey]).name;
  }

  function getReferentielsForKey(referentielKey) {
    if (_.isEmpty(taxonSearchFormCtrl.availableReferentiels)) {
      return [];
    }

    return taxonSearchFormCtrl.availableReferentiels[referentielKey];
  }



  function getFilterKeys() {
    return _.keys(taxonSearchFormCtrl.availableFilters);
  }

  function getFilterName(filterKey) {
    return _.head(taxonSearchFormCtrl.availableFilters[filterKey]).name;
  }

  function getFilterForKey(filterKey) {
    if (_.isEmpty(taxonSearchFormCtrl.availableFilters)) {
      return [];
    }
    return taxonSearchFormCtrl.availableFilters[filterKey];
  }
}
