import _ from 'lodash';

import { TX_GROUP_LEVEL } from '../model';

export default {sort, sortBy};

function sort(txGroups) {
  return sortBy(txGroups, 'key');
}
function sortBy(txGroups, fieldName) {

  var result = _.chain(txGroups)
      .map((txGroup) => {
        let levelInfo = _.find(TX_GROUP_LEVEL, {key: txGroup[fieldName]}) || {level: 9999};
        return _.merge({}, txGroup, levelInfo);
      })
      .sortBy('level')
      .value();

  return result;
}
