import _ from 'lodash';

import { EVENTS as MEDIA_SERVICE_EVENTS } from '../services/media.service';

export default MediaDetailsController;

// @ngInject
function MediaDetailsController($scope, LxNotificationService, UrlResolverService, MediaConfiguration, MediaRepository, $q) {
  var mediaDetailsCtrl = this;

  mediaDetailsCtrl.medias = [];
  mediaDetailsCtrl.addedMediaIds = [];
  mediaDetailsCtrl.removedMediaIds = [];

  mediaDetailsCtrl.getMainType = getMainType;
  mediaDetailsCtrl.removeMedia = removeMedia;
  mediaDetailsCtrl.openMedia = openMedia;
  mediaDetailsCtrl.onAddMedia = onAddMedia;
  mediaDetailsCtrl.onSelectFile = onSelectFile;
  mediaDetailsCtrl.onDelete = onDelete;
  mediaDetailsCtrl.isAutoLocatedValid = isAutoLocatedValid;
  mediaDetailsCtrl.isAutoLocatedError = isAutoLocatedError;
  mediaDetailsCtrl.locationPrecision = locationPrecision;

  $scope.$watch(mediaDetailsCtrl.mediasProvider, (medias) => {
    if (!medias) {
      return;
    }

    mediaDetailsCtrl.medias = _.cloneDeep(medias);
    mediaDetailsCtrl.addedMediaIds = [];
    mediaDetailsCtrl.removedMediaIds = [];

    onChange();
  });
  $scope.$watch(mediaDetailsCtrl.formProvider, (form) => {
    mediaDetailsCtrl.form = form;
  });
  $scope.$watch(mediaDetailsCtrl.canEditProvider, (canEdit) => {
    mediaDetailsCtrl.canEdit = canEdit;
  });

  $scope.$on(MEDIA_SERVICE_EVENTS.MEDIA_UPLOADING, onMediaUploading);
  $scope.$on(MEDIA_SERVICE_EVENTS.MEDIA_UPLOAD_SUCCESS, onMediaAdded);
  $scope.$on(MEDIA_SERVICE_EVENTS.MEDIA_UPLOAD_ERROR, onMediaUploadFailed);

  function getMainType(media) {
    return media.mimeType && media.mimeType.split('/')[0].toLowerCase();
  }

  function removeMedia({id}) {
    mediaDetailsCtrl.medias = _.reject(mediaDetailsCtrl.medias, {id});
    mediaDetailsCtrl.addedMediaIds = _.without(mediaDetailsCtrl.addedMediaIds, id);
    mediaDetailsCtrl.removedMediaIds.push(id);

    $scope.mediaForm.$setDirty();

    onChange();
  }

  function openMedia(event, media) {
    if (!IS_CORDOVA) {
      return;
    }

    event.preventDefault();

    MediaRepository.openMedia(media)
      .then(null, (error) => {
        LxNotificationService.error('Une erreur est survenue lors de l\'ouverture du fichier. ' + error);
      });
  }

  function onChange() {
    mediaDetailsCtrl.onChangeFn()({
      data: {
        addedMediaIds: mediaDetailsCtrl.addedMediaIds,
        removedMediaIds: mediaDetailsCtrl.removedMediaIds
      },
      // Aucun champ n'est obligatoire dans ce formulaire
      formValid: true
    });
  }

  function onAddMedia(input) {
    if (input.files.length <= 0) {
      return;
    }
    mediaDetailsCtrl.onAddMediaFn() && mediaDetailsCtrl.onAddMediaFn()(input.files[0]);
  }

  function onMediaUploading() {
    mediaDetailsCtrl.uploadingMedia = true;
  }

  function onMediaAdded(event, media) {
    mediaDetailsCtrl.uploadingMedia = false;

    mediaDetailsCtrl.medias.push(_.merge({href: null}, media));
    mediaDetailsCtrl.addedMediaIds.push(media.id);

    $scope.mediaForm.$setDirty();

    onChange();
  }

  function onMediaUploadFailed() {
    mediaDetailsCtrl.uploadingMedia = false;
  }

  function onSelectFile(event) {
    if (!IS_CORDOVA) {
      return;
    }

    event.preventDefault();

    window.plugins.mfilechooser.open([], mediaDetailsCtrl.onAddMediaFn(), (error) => {
      LxNotificationService.error('Une erreur est survenue lors de la sélection du fichier. ' + error);
    });
  }

  function onDelete() {
    mediaDetailsCtrl.onDeleteFn() && mediaDetailsCtrl.onDeleteFn()();
  }

  function locationPrecision() {
    return mediaDetailsCtrl.locationPrecisionProvider();
  }

  function isAutoLocatedValid() {
    return mediaDetailsCtrl.isAutoLocatedValidFn() &&
           mediaDetailsCtrl.isAutoLocatedValidFn()();
  }

  function isAutoLocatedError() {
    return mediaDetailsCtrl.isAutoLocatedErrorFn() &&
           mediaDetailsCtrl.isAutoLocatedErrorFn()();
  }
}
