
const EXTRA_TABLE = {
  COMMON: 'COMMON',
  BIRDS: 'BIRDS',
  PE: 'PE',
  RP: 'RP',
  RA: 'RA',
  ADNE: 'ADNE',
  VALUES: [
    'COMMON',
    'BIRDS',
    'PE',
    'RP',
    'RA',
    'ADNE'
  ]
};

export { EXTRA_TABLE };
