import _ from 'lodash';

import { EVENTS as STORE_EVENTS } from '../../../synchro/stores/study-synchronizer.store';
import { SYNC_STEP } from '../../../synchro/model';

require('./prompt.scss');

export default PromptDirective;

// @ngInject
function PromptDirective() {
  return {
    restrict: 'E',
    scope: {
      dialogId: '@',
      label: '@',
      title: '@',
      initialValueProvider: '&initialValue',
      extraDataProvider: '&extraData',
      onValidateFn: '&onValidate',
      onCancelFn: '&onCancel'
    },
    controller: PromptController,
    controllerAs: 'promptCtrl',
    bindToController: true,
    template: require('./prompt.html'),
    replace: true
  };
}

// @ngInject
function PromptController($scope, $state, LxDialogService, $timeout) {
  var promptCtrl = this;

  $scope.$on('lx-dialog__open-start', onDialogOpen);

  function onDialogOpen(event, eventDialogId) {

    // Si eventDialogId est différent de notre dialogue alors on ne fait rien
    if (eventDialogId !== promptCtrl.dialogId) {
      return;
    }

    promptCtrl.value = promptCtrl.initialValueProvider();
    promptCtrl.extraData = promptCtrl.extraDataProvider();
  }

  promptCtrl.onClose = onClose;
  promptCtrl.onSubmit = onSubmit;

  function onClose() {
    promptCtrl.onCancelFn() && promptCtrl.onCancelFn()();
  }
  function onSubmit() {
    promptCtrl.onValidateFn() && promptCtrl.onValidateFn()(promptCtrl.value, promptCtrl.extraData);
  }
}
