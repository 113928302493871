import _ from 'lodash';
import { geoJsonFromLatlng } from '../../map/tools/map-util';

const EVENTS = {
  // Actions lancées quand l'utilisateur affiche la page de détail d'une étude
  STUDY_MAP_LOADING: 'shu-study-map.study-map-loading',
  STUDY_MAP_LOAD_SUCCESS: 'shu-study-map.study-map-load-success',
  STUDY_MAP_LOAD_ERROR: 'shu-study-map.study-map-load-error'
};

export default StudyMapService;

// @ngInject
function StudyMapService($rootScope, StudyRepository, LxNotificationService) {
  return {load, saveCurrentLayer, saveLayerVisibility, saveCurrentPosition, getCurrentPositionAsGeoJson, deleteCurrentPosition};

  function load(studyId) {
    $rootScope.$emit(EVENTS.STUDY_MAP_LOADING);

    StudyRepository.getGeom(studyId)
      .then(
        (study) => {
          $rootScope.$emit(EVENTS.STUDY_MAP_LOAD_SUCCESS, {
            study,
            currentLayer: StudyRepository.getStudyMapCurrentLayer(),
            layersVisibility: StudyRepository.getStudyMapLayersVisibility(studyId),
          });

        },
        (error) =>  {
          LxNotificationService.error('Une erreur est survenue au chargement de la géométrie. ' + (error.data || ''));
          $rootScope.$emit(EVENTS.STUDY_MAP_LOAD_ERROR);
        }
      );
  }

  function saveCurrentLayer(layer) {
    StudyRepository.storeStudyMapCurrentLayer(layer);
  }
  function saveLayerVisibility(studyId, layerId) {
    StudyRepository.storeStudyMapLayersVisibility(studyId, layerId);
  }

  function saveCurrentPosition(studyId, lat, lng, zoom) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    window.localStorage.setItem(`study.${studyId}.current-position`, JSON.stringify({lat, lng, zoom}));
  }

  function getCurrentPositionAsGeoJson(studyId) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    try {
      var currentPosition = JSON.parse(window.localStorage.getItem(`study.${studyId}.current-position`));
      return {center: geoJsonFromLatlng(currentPosition.lat, currentPosition.lng), zoom: currentPosition.zoom};
    } catch (e) {
      return null;
    }
  }

  function deleteCurrentPosition(studyId) {
    if (!window.localStorage) {
      throw 'localStorage required';
    }
    window.localStorage.removeItem(`study.${studyId}.current-position`);
  }
}
export { EVENTS };
