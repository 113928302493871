import FieldSurveyDetailsMediaTabController from '../../../media/controllers/media-details.controller';

require('./field-survey-details-medias-tab.scss');

export default FieldSurveyDetailsMediasTabDirective;

// @ngInject
function FieldSurveyDetailsMediasTabDirective() {
  return {
    restrict: 'E',
    scope: {
      mediasProvider: '&medias',
      onChangeFn: '&onChange',
      onAddMediaFn: '&onAddMedia',
      onDeleteFn: '&onDelete',
      canSubmit: '&canSubmit',
      canEditProvider: '&canEdit',
      isAutoLocatedValidFn: '&isAutoLocatedValid',
      isAutoLocatedErrorFn: '&isAutoLocatedError',
      locationPrecisionProvider: '&locationPrecision',
      formProvider: '&form'
    },
    bindToController: true,
    controller: FieldSurveyDetailsMediaTabController,
    controllerAs: 'fieldSurveyDetailsMediasTabCtrl',
    template: require('./field-survey-details-medias-tab.html'),
    replace: true
  };
}
